import {
  UPDATE_RESET_PASSWORD,
  UPDATE_SUBMIT_LOADING,
} from '../actions/customerResetPasswordAction';

const defaultState = {
  isSubmitLoading: false,
  password: '',
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_RESET_PASSWORD:
      nextState.password = action.password || '';
      return nextState;
    case UPDATE_SUBMIT_LOADING:
      nextState.isSubmitLoading = action.isSubmitLoading || false;
      return nextState;
    default:
      return nextState;
  }
};
