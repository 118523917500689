interface AppConfigsType {
  moduleName: string;
  [key: string]: any;
}

class AppConfigs implements AppConfigsType {
  moduleName: string;
  constructor() {
    this.moduleName = 'google-optimize-ab-test';
  }

  extends = (config: any) => {
    const configNames = Object.keys(config);
    const obj: any = {};
    configNames.forEach((configName) => {
      obj[configName] = config[configName];
    });
    return { obj, ...this };
  };
}

export default new AppConfigs();
