export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const OK_DIALOG = 'OK_DIALOG';
export const CHECK_RADIO_DIALOG = 'CHECK_RADIO_DIALOG';
export const OTHER_DIALOG = 'OTHER_DIALOG';
export const TOGGLE_HIDE_DIALOG = 'TOGGLE_HIDE_DIALOG';
export const OPEN_TNC = 'OPEN_TNC';
export const ACCEPT_TNC = 'ACCEPT_TNC';
export const CLOSE_TNC = 'CLOSE_TNC';
export const CHANGE_CHECKBOX_VALUE_TNC = 'CHANGE_CHECKBOX_VALUE_TNC';
export const FETCH_TC_FAILURE = 'FETCH_TC_FAILURE';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
// tnc style model
export const OPEN_TNC_STYLE_MODEL = 'OPEN_TNC_STYLE_MODEL';
export const CLOSE_TNC_STYLE_MODEL = 'CLOSE_TNC_STYLE_MODEL';
export const OK_TNC_STYLE_MODEL = 'OK_TNC_STYLE_MODEL';
export const SET_TNC_STYLE_MODEL = 'SET_TNC_STYLE_MODEL';
export const OPEN_CALL_SUPPORT_MODEL = 'OPEN_CALL_SUPPORT_MODEL';
export const CLOSE_CALL_SUPPORT_MODEL = 'CLOSE_CALL_SUPPORT_MODEL';
export const SET_CALL_SUPPORT_MODEL = 'SET_CALL_SUPPORT_MODEL';
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';

export const openDialog = (payload: any) => ({
  type: OPEN_DIALOG,
  payload,
});
export const closeDialog = (payload: any) => ({
  type: CLOSE_DIALOG,
  payload,
});
export const okDialog = (payload: any) => ({
  type: OK_DIALOG,
  payload,
});
export const checkRadioDialog = (payload: any) => ({
  type: CHECK_RADIO_DIALOG,
  payload,
});
export const otherDialog = (payload: any) => ({
  type: OTHER_DIALOG,
  payload,
});
export const toggleHideDialog = () => ({
  type: TOGGLE_HIDE_DIALOG,
});

export const openTNC = (payload: any) => ({
  type: OPEN_TNC,
  payload,
});

export const acceptTNC = (payload: any) => ({
  type: ACCEPT_TNC,
  payload,
});

export const closeTNC = (payload: any) => ({
  type: CLOSE_TNC,
  payload,
});

export const fetchTNCError = (payload: any) => ({
  type: FETCH_TC_FAILURE,
  payload,
});

export const changeCheckBoxValue = (payload: any) => ({
  type: CHANGE_CHECKBOX_VALUE_TNC,
  payload,
});
export const showModal = () => ({
  type: SHOW_MODAL,
});
export const hideModal = () => ({
  type: HIDE_MODAL,
});

export const openTncStyleModel = (payload: any) => ({
  type: OPEN_TNC_STYLE_MODEL,
  payload,
});
export const closeTncStyleModel = (payload: any) => ({
  type: CLOSE_TNC_STYLE_MODEL,
  payload,
});
export const okTncStyleModel = (payload: any) => ({
  type: OK_TNC_STYLE_MODEL,
  payload,
});

export const putOpenEmmaTncStyleModel = (
  disclaimerId: string,
  isPreModel = false,
  modelData: any = null,
) => {
  return {
    type: SET_TNC_STYLE_MODEL,
    payload: {
      disclaimerId: disclaimerId,
      isPreModel: isPreModel,
      modelData,
    },
  };
};

export const openContactCallModel = (payload: any) => ({
  type: OPEN_CALL_SUPPORT_MODEL,
  payload,
});

export const closeContactCallModel = (payload: any) => ({
  type: CLOSE_CALL_SUPPORT_MODEL,
  payload,
});

export const putOpenEmmaContactCallModel = (action: any) => {
  return {
    type: SET_CALL_SUPPORT_MODEL,
    payload: {
      disclaimerId: action.disclaimerId,
      modelData: action.appointmentContact,
      onCloseCallBack: action.onCloseCallBack || null,
    },
  };
};

export const showErrorModal = () => ({
  type: SHOW_ERROR_MODAL,
});

export const hideErrorModal = () => ({
  type: HIDE_ERROR_MODAL,
});
