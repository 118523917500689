import { useEffect } from 'react';
import { useEmmaJS } from './useEmmaJS';

type Options = {
  showTopBar?: boolean;
  title?: string;
  showBackBtn?: boolean;
  showCloseBtn?: boolean;
};

export const useMobileTopBar = (options?: Options) => {
  const emmaJS = useEmmaJS();

  useEffect(() => {
    if (emmaJS) {
      if (options?.title !== undefined) {
        emmaJS.instance.setNavigationTitle(options.title);
      }
      if (options.showBackBtn !== undefined) {
        if (options.showBackBtn) {
          emmaJS.instance.showBackButton();
        } else {
          emmaJS.instance.hideBackButton();
        }
      }
      if (options.showCloseBtn !== undefined) {
        if (options.showCloseBtn) {
          emmaJS.instance.showCloseButton();
        } else {
          emmaJS.instance.hideCloseButton();
        }
      }
      if (options.showTopBar !== undefined) {
        if (options.showTopBar) {
          emmaJS.instance.showTopbar();
        } else {
          emmaJS.instance.hideTopbar();
        }
      }
    }
  }, [emmaJS, options]);
};

export default useMobileTopBar;
