import { SHOW_MODAL, HIDE_MODAL } from '../actions';

const defaultState = {
  isShowModal: false,
  payload: undefined,
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case SHOW_MODAL:
      nextState.isShowModal = true;
      nextState.payload = action.payload;
      return nextState;
    case HIDE_MODAL:
      nextState.isShowModal = false;
      nextState.payload = undefined;
      return nextState;
    default:
      return nextState;
  }
};
