export const FETCH_HAVE_TNC_DISCLAIMER_SUCCESS = `FETCH_HAVE_TNC_DISCLAIMER_SUCCESS`;
export const FETCH_HAVE_TNC_DISCLAIMER_FAILURE = `FETCH_HAVE_TNC_DISCLAIMER_FAILURE`;
export const PUSH_HAVE_TNC_DISCLAIMER = 'PUSH_HAVE_TNC_DISCLAIMER';
export const UPDATE_HAVE_TNC_DISCLAIMER_STATE =
  'UPDATE_HAVE_TNC_DISCLAIMER_STATE';
export const PUSH_HAVE_TNC_DISCLAIMER_SINGLE =
  'PUSH_HAVE_TNC_DISCLAIMER_SINGLE';

export const pushHaveTncDisclaimer = (serviceId: string) => {
  return {
    type: PUSH_HAVE_TNC_DISCLAIMER,
    payload: {
      serviceId: serviceId,
    },
  };
};
