import _ from 'lodash';

export const getModals = (state: {}): any =>
  _.get(state, `hk-emma-web-core/app.moduleConfig.emma-modal.modals`, {});

export const getJourneyNameByModal = (state: {}, modalName: string): any =>
  _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-modal.modals.${modalName}.query.journeyName`,
  );

export const isShowModal = (state: {}) =>
  _.get(state, `hk-emma-web-core/emmaModal.isShowModal`, false);

export const getPayload = (state: {}) =>
  _.get(state, `hk-emma-web-core/emmaModal.payload`, undefined);

export const getCurrentPath = (state: {}) =>
  _.get(state, `router.location.pathname`, '');
