import {
  CARD_VIEW_MOUNTED,
  FETCH_WOMANPRODUCT_AUTH_TOKEN_SUCCESS,
  FETCH_WOMANPRODUCT_AUTH_TOKEN_FAILURE,
  DETAILS_VIEW_MOUNTED,
  CARD_VIEW_CONTENT_LOADED,
  DETAILS_VIEW_CONTENT_LOADED,
} from '../actions/checkCoverageAction';

const defaultState = {};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case CARD_VIEW_MOUNTED:
      nextState.config = defaultState;
      return nextState;
    case DETAILS_VIEW_MOUNTED:
      nextState.config = defaultState;
      return nextState;
    case CARD_VIEW_CONTENT_LOADED:
      nextState.config = action.config;
      return nextState;
    case DETAILS_VIEW_CONTENT_LOADED:
      nextState.config = action.config;
      return nextState;
    case FETCH_WOMANPRODUCT_AUTH_TOKEN_SUCCESS:
      const { authToken } = action.payload;
      nextState.authToken = authToken;
      return nextState;
    case FETCH_WOMANPRODUCT_AUTH_TOKEN_FAILURE:
      nextState.authTokenFailure = action.payload;
      return nextState;
    default:
      return nextState;
  }
};
