import config from '../package.json';
const ID = `${config.name}/actions/customerResetUserName`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const SUBMIT_RESET_USER_NAME = createActionName(
  'SUBMIT_RESET_USER_NAME',
);

export const UPDATE_RESET_USER_NAME_EMAIL = createActionName(
  'UPDATE_RESET_USER_NAME_EMAIL',
);
export const UPDATE_RESET_USER_NAME_MOBILE = createActionName(
  'UPDATE_RESET_USER_NAME_MOBILE',
);

export const UPDATE_SUBMIT_LOADING = createActionName('UPDATE_SUBMIT_LOADING');

export const CHANGE_CUR_TAB = createActionName('CHANGE_CUR_TAB');

export const submitResetUserName = (payload: any) => ({
  type: SUBMIT_RESET_USER_NAME,
  payload,
});

export const changeCurTab = (curUserNameTab: string) => ({
  type: CHANGE_CUR_TAB,
  curUserNameTab,
});

export const updateResetUserNameEmail = (email: string) => ({
  type: UPDATE_RESET_USER_NAME_EMAIL,
  email,
});

export const updateResetUserNameMobile = (mobile: string) => ({
  type: UPDATE_RESET_USER_NAME_MOBILE,
  mobile,
});
