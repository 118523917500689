import _ from 'lodash';
import env from '../env';
import { _get } from '../utils';
import setting from '../modules/_system-status/_system_status-maintenance-1.0.0.web.json';

export const getSystemStatus = (state: {}) => {
  return env.EMMA_WEB_SYSTEM_MAINTENANCE
    ? setting
    : _get(state, `hk-emma-web-core/app.systemStatus`);
  // return _get<SystemStatus>(state, `hk-emma-web-core/app.systemStatus`)
};
