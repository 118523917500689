import config from '../package.json';

const ID = `${config.name}/actions/myPrivilegesActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const OPEN_WELLNESS_COMPANION = createActionName(
  'OPEN_WELLNESS_COMPANION',
);
export const CREATE_HEALTH_KEEPER_ACCOUNT = createActionName(
  'CREATE_HEALTH_KEEPER_ACCOUNT',
);
export const SHOW_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG = createActionName(
  'SHOW_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG',
);
export const HIDE_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG = createActionName(
  'HIDE_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG',
);
export const FETCH_HEALTH_KEEPER_TOKEN = createActionName(
  'FETCH_HEALTH_KEEPER_TOKEN',
);
export const STORE_HEALTH_KEEPER_TOKEN = createActionName(
  'STORE_HEALTH_KEEPER_TOKEN',
);
export const CLEAR_HEALTH_KEEPER_TOKEN = createActionName(
  'CLEAR_HEALTH_KEEPER_TOKEN',
);

export const fetchHealthKeeperToken = () => ({
  type: FETCH_HEALTH_KEEPER_TOKEN,
});
