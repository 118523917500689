import {
  UPDATE_PROFILE_MOBILE,
  UPDATE_SELECTED_LIST,
  UPDATE_FATCA_FLAG,
  UPDATE_CRS_FLAG,
  UPDATE_MOBILE_CUST_STATE,
  UPDATE_MOBILE_CUST_STATE_SUCCESS,
  UPDATE_MOBILE_CUST_STATE_FAILURE,
  RESET_CHANGE_MOBILE_FORM,
  FETCH_MOBILE_OTHER_POLICY_LIST,
  FETCH_MOBILE_OTHER_POLICY_LIST_SUCCESS,
  FETCH_MOBILE_OTHER_POLICY_LIST_FAIL,
  UPDATE_MOBILE_OTHER_POLICIES,
  SEND_MOBILE_OTP_REQUEST,
  SEND_MOBILE_OTP_REQUEST_SUCCESS,
  SEND_MOBILE_OTP_REQUEST_FAIL,
  SUBMIT_CHANGE_MOBILE_FORM,
  SUBMIT_CHANGE_MOBILE_FORM_SUCCESS,
  SUBMIT_CHANGE_MOBILE_FORM_FAILURE,
  UPDATE_MOBILE_FORM_STATES,
  UPDATE_MOBILE_GOGREEN_LIST,
} from '../actions/changeMobileAction';

const defaultState = {
  isLoading: false,
  fatcaFlag: null,
  crsFlag: null,
  countryCode: '852',
  newMobile: '',
  otherPolicyList: [],
  selectedList: [],
  transactions: [],
  successfulPolicies: [],
  failedPolicies: [],
  goGreenList: [],
  form: {
    notify: null,
    useOfData: null,
    providedValidData: null,
    agreeRevokeSms: null,
  },
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_MOBILE_GOGREEN_LIST:
      nextState.goGreenList = action.payload;
      return nextState;
    case UPDATE_MOBILE_FORM_STATES:
      nextState.form = { ...nextState.form, ...action.payload };
      return nextState;
    case UPDATE_MOBILE_OTHER_POLICIES:
    case FETCH_MOBILE_OTHER_POLICY_LIST:
      nextState.isLoading = true;
      return nextState;
    case FETCH_MOBILE_OTHER_POLICY_LIST_SUCCESS:
      nextState.otherPolicyList = action.otherPolicyList;
      nextState.isLoading = false;
      return nextState;
    case FETCH_MOBILE_OTHER_POLICY_LIST_FAIL:
      nextState.isLoading = false;
      return nextState;
    case UPDATE_PROFILE_MOBILE:
      return {
        ...nextState,
        newMobile: action.newMobile,
        countryCode: action.countryCode,
      };
    case UPDATE_SELECTED_LIST:
      return {
        ...nextState,
        selectedList: action.selectedList,
      };
    case UPDATE_FATCA_FLAG:
      return {
        ...nextState,
        fatcaFlag: action.value,
      };
    case UPDATE_CRS_FLAG:
      return {
        ...nextState,
        crsFlag: action.value,
      };
    case RESET_CHANGE_MOBILE_FORM:
      return {
        ...nextState,
        fatcaFlag: null,
        crsFlag: null,
        countryCode: '852',
        newMobile: '',
        selectedList: [],
        form: {
          notify: null,
          useOfData: null,
          providedValidData: null,
          agreeRevokeSms: null,
        },
      };
    case SEND_MOBILE_OTP_REQUEST:
      return {
        ...nextState,
        isLoading: true,
      };
    case SEND_MOBILE_OTP_REQUEST_SUCCESS:
      return {
        ...nextState,
        isLoading: false,
      };
    case SEND_MOBILE_OTP_REQUEST_FAIL:
      return {
        ...nextState,
        isLoading: false,
      };
    case SUBMIT_CHANGE_MOBILE_FORM:
      return {
        ...nextState,
        isLoading: true,
      };
    case SUBMIT_CHANGE_MOBILE_FORM_SUCCESS:
      return {
        ...nextState,
        isLoading: false,
        transactionId: action.transactionId,
        transactions: action.transactions,
        successfulPolicies: action.successfulPolicies,
        failedPolicies: action.failedPolicies,
      };
    case SUBMIT_CHANGE_MOBILE_FORM_FAILURE:
      return {
        ...nextState,
        isLoading: false,
      };
    default:
      return nextState;
  }
};
