import { Component } from 'react';

import { connect } from 'react-redux';
import rootContainerRegistry from '../../registries/RootComponentRegistry';

import './styles.scss';

interface RootContainerProps {}

class RootContainer extends Component<RootContainerProps> {
  render() {
    return rootContainerRegistry.render(this.props);
  }

  componentDidMount() {
    rootContainerRegistry.listenSet(this.forceUpdate.bind(this));
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {};
  }

  static mapDispatchToProps(dispatch: any) {
    return {};
  }
}

export default connect(
  RootContainer.mapStateToProps,
  RootContainer.mapDispatchToProps,
)(RootContainer);
