import {
  PUSH_TITLE,
  POP_TITLE,
  HIDE_BACK_BUTTON,
  SHOW_BACK_BUTTON,
} from '../actions/historyActions';
import _ from 'lodash';
import { Action } from 'redux';

interface PushTitleAction {
  title: string;
}

export interface HistoryState {
  titleStack: Array<string>;
  hideBackButton: boolean;
}

const initState: HistoryState = {
  titleStack: [],
  hideBackButton: false,
};

type HistoryActionType = Action & PushTitleAction;

export default (state: HistoryState = initState, action: HistoryActionType) => {
  const nextState = { ...state };
  switch (action.type) {
    case PUSH_TITLE:
      nextState.hideBackButton = false;
      nextState.titleStack.push(action.title);
      return nextState;
    case POP_TITLE:
      nextState.titleStack.pop();
      return nextState;
    case HIDE_BACK_BUTTON:
      nextState.hideBackButton = true;
      return nextState;
    case SHOW_BACK_BUTTON:
      nextState.hideBackButton = false;
      return nextState;
    default:
      return nextState;
  }
};
