export const FETCH_WOMAN_PRODUCT_POLICIES = 'FETCH_WOMAN_PRODUCT_POLICIES';
export const FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS =
  'FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS';
export const FETCH_WOMAN_PRODUCT_POLICIES_FAILURE =
  'FETCH_WOMAN_PRODUCT_POLICIES_FAILURE';
export const FETCH_WOMAN_PRODUCT_POLICY_COVERAGE =
  'FETCH_WOMAN_PRODUCT_POLICY_COVERAGE';
export const FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS =
  'FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS';
export const FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT =
  'FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT';
export const FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS =
  'FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS';

export const WOMAN_PRODUCT_INIT = 'WOMAN_PRODUCT_INIT';
export const SET_WOMAN_PRODUCT_IS_LOADING = 'SET_WOMAN_PRODUCT_IS_LOADING';
export const FETCH_WOMAN_PRODUCT_AUTH_TOKEN = 'FETCH_WOMAN_PRODUCT_AUTH_TOKEN';

export const fetchWomanProductPolicies = () => ({
  type: FETCH_WOMAN_PRODUCT_POLICIES,
});

export const fetchWomanProductPoliciesSuccess = (data: any) => ({
  type: FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS,
  payload: {
    womanProductPolicies: data,
  },
});

export const fetchWomanProductPolicyCoverage = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_POLICY_COVERAGE,
  payload,
});

export const fetchWomanProductPolicyCoverageSuccess = (data: any) => ({
  type: FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS,
  payload: {
    policyCoverage: data,
  },
});

export const fetchWomanProductAppointmentContact = () => ({
  type: FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT,
});

export const fetchWomanProductAppointmentContactSuccess = (data: any) => ({
  type: FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS,
  payload: {
    appointmentContact: data,
  },
});

export const setWomanProductIsLoading = (isLoadingFlag: boolean) => ({
  type: SET_WOMAN_PRODUCT_IS_LOADING,
  isLoading: isLoadingFlag,
});

export const fetchWomanProductAuthToken = (
  url: string,
  fetchWomanProductAuthTokenSuccess: (payload: any) => void,
  fetchWomanProductAuthTokenFailure: (error: any) => void,
) => ({
  type: FETCH_WOMAN_PRODUCT_AUTH_TOKEN,
  payload: {
    url,
    fetchWomanProductAuthTokenSuccess,
    fetchWomanProductAuthTokenFailure,
  },
});
