import { TopBarMode, TopBarOption } from '@axa-asia/hk-emma-js';
import { useEffect } from 'react';
import { useEmmaJS } from './useEmmaJS';

type Options = TopBarOption & {
  mode?: TopBarMode;
};

export const useNormalMobileTopBar = (options?: Options) => {
  const emmaJS = useEmmaJS();

  useEffect(() => {
    const { mode = 'normal', ...opts } = options || {};

    if (emmaJS) {
      emmaJS.instance.setTopBar(mode, opts);
      emmaJS.instance.showTopbar();
    }
  }, [emmaJS, options]);
};

export default useNormalMobileTopBar;
