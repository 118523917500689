import config from '../package.json';

const ID = `${config.name}/actions/valueAddedServiceActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const CARD_VIEW_MOUNTED = createActionName('CARD_VIEW_MOUNTED');

export const CARD_VIEW_CONTENT_LOADED = createActionName(
  'CARD_VIEW_CONTENT_LOADED',
);

export const DETAILS_VIEW_MOUNTED = createActionName('DETAILS_VIEW_MOUNTED');

export const DETAILS_VIEW_CONTENT_LOADED = createActionName(
  'DETAILS_VIEW_CONTENT_LOADED',
);
