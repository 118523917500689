import {
  all,
  call,
  put,
  take,
  takeLatest,
  race,
  select,
} from 'redux-saga/effects';
import {
  removeExpiredToken,
  FETCH_ACCESS_TOKEN_STARTED,
  FETCH_ACCESS_TOKEN_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  SIGN_OUT,
  CHECK_FORCE_LOGOUT,
  clearIdToken,
} from '../actions/authActions';
import { expired } from '../actions/sessionAction';
import {
  FETCH_APP_CONFIG_SUCCESS,
  FETCH_APP_CONFIG_REQUEST,
} from '../actions/appActions';
import { goto } from '../actions/historyActions';
import history from '../stores/browserHistory';
import Cookies from 'cookies-js';

import env from '../env';
import { joinPath, removeQueryParameters, isApp } from '../utils';
import { getLanguage } from '../selectors/userSelectors';

import { t } from '../locales';
import { OPEN_DIALOG, OK_DIALOG, CLOSE_DIALOG } from '../actions/uiActions';
import { LOCATION_CHANGE } from 'connected-react-router';
import sha1 from 'sha1';
import { AxaAuth } from '../types/authTypes';
import { Action, AnyAction } from 'redux';
import { getSignOutUrl } from '../selectors/configSelectors';
import { updateForceLogoutFlag } from '../actions/authActions';
import { FRAME_APP_MOUNTED } from '../actions/frameActions';
import { getIdToken, getIsForceLogout } from '../selectors/authSelectors';
import {
  TrackPartnerServiceDisclaimerIcon,
  trackPartnerServiceDisclaimer,
} from '../utils/generalGaEventAnalytics';
import { getCorpWebCookieName } from '../modules/emma-webview/selectors/webviewSelector';
import { getRandom } from '../utils/randomNumUtil';
import { SHA3 } from 'sha3';

import { setEmmaHeaders } from '@axa-asia/common-api-utils';
import prismicApiClient from '../utils/prismicApi';

function* signOutSaga(): Iterable<any> {
  // const url = new URL(window.location.href);
  // const names = Array.from(url.searchParams.keys());
  // removeQueryParameters(...names);

  // comment: remove A-HKG-0018-V010
  /***
  // yield put(removeExpiredToken());
  yield put(expired());
  ***/
  const signOutUrl: string = yield select(getSignOutUrl);
  const idToken = yield select(getIdToken);

  // Clear id token from state before logout.
  yield put(clearIdToken());

  const signOutEndpoint =
    signOutUrl && signOutUrl.replace('${idToken}', idToken);
  Cookies.expire('Authorization');
  Cookies.expire('AXAID');
  try {
    const corpWebCookieName = yield select(getCorpWebCookieName);
    if (corpWebCookieName) {
      Cookies.expire(corpWebCookieName);
    }
  } catch (e) {
    console.error(e);
  }
  // comment: remove A-HKG-0018-V010
  // Cookies.expire('persist:hk-emma-web-core')

  setEmmaHeaders({ AXAID: '' });
  prismicApiClient.clearCache();

  setTimeout(() => {
    window.location.href = signOutEndpoint;
  }, 500);
}

function* removeAuthCodeSaga(): Iterable<void> {
  const url = new URL(window.location.href);
  const names = Array.from(url.searchParams.keys());
  removeQueryParameters(...names);
}

function* setEmmaSessionCookieSaga(): Iterable<void> {
  const hash = new SHA3(512);
  hash.update(`${getRandom()}`);
  const sessionId = hash.digest('hex');
  // const sessionId = sha1(`${getRandom()}`);
  Cookies.set(env.EMMA_SESSION_ID_COOKIE_NAME, sessionId, {
    path: '/; sameSite=Lax;',
    secure: true,
  });
}

// function* setAuthorizationCookieSaga(action: AnyAction): Iterable<AnyAction> {
//   Cookies.set(`authApp`, `${action.auth.token}`);
// }

function* fetchAppConfigSaga(): Iterable<any> {
  const res = yield window.fetch(env.URL_APP_INFO);
  const appConfig = yield res.json();
  yield put({ type: FETCH_APP_CONFIG_SUCCESS, appConfig });
}

function* watchHealthLibrarySaga(): Iterable<any> {
  while (true) {
    const action = yield take(LOCATION_CHANGE);
    const { location } = action.payload;
    if (action.type === LOCATION_CHANGE) {
      if (location.pathname && location.hash) {
        if (location.pathname === '/home' && location.hash === '#sensely') {
          yield put({
            type: OPEN_DIALOG,
            payload: {
              modalType: 'lockedModal',
              title: `${t('DENSELY_LIBRARY_TITLE')}`,
              body: `${t('DENSELY_LIBRARY_CONTENT')}`,
              okButtonText: `${t('DENSELY_BUTTON_I_AGREE')}`,
              okAction: () => {
                trackPartnerServiceDisclaimer(
                  TrackPartnerServiceDisclaimerIcon.SENSELY,
                );
              },
              closeAction: () => {},
            },
          });

          const { OK, CLOSE } = yield race({
            OK: take(OK_DIALOG),
            CLOSE: take(CLOSE_DIALOG),
          });
          if (OK) {
            yield call(goto, 'sensely');
            history.replace('/home');
          }
          if (CLOSE) {
            history.replace('/home');
          }
        }
      }
    }
  }
}

function* watchWellnessCompanionSaga(): Iterable<any> {
  while (true) {
    const action = yield take(LOCATION_CHANGE);
    const { location } = action.payload;
    if (action.type === LOCATION_CHANGE) {
      if (location.pathname && location.hash) {
        if (
          location.pathname === '/home' &&
          location.hash === '#health-keeper'
        ) {
          yield put({
            type: OPEN_DIALOG,
            payload: {
              modalType: 'lockedModal',
              title: `${t('DENSELY_WELLNESS_COMPANION_TITLE')}`,
              body: `${t('DENSELY_WELLNESS_COMPANION_CONTENT')}`,
              okButtonText: `${t('DENSELY_BUTTON_OK')}`,
              okAction: () => {
                trackPartnerServiceDisclaimer(
                  TrackPartnerServiceDisclaimerIcon.HEALTH_KEEPER,
                );
              },
              closeAction: () => {},
            },
          });

          const { OK, CLOSE } = yield race({
            OK: take(OK_DIALOG),
            CLOSE: take(CLOSE_DIALOG),
          });
          if (OK) {
            yield call(goto, 'health-keeper');
            history.replace('/home');
          }
          if (CLOSE) {
            history.replace('/home');
          }
        }
      }
    }
  }
}

function* watchStressSaga(): Iterable<any> {
  while (true) {
    const action = yield take(LOCATION_CHANGE);
    const { location } = action.payload;
    if (action.type === LOCATION_CHANGE) {
      if (location.pathname && location.hash) {
        if (
          location.pathname === '/home' &&
          location.hash === '#cuhktourheart'
        ) {
          yield put({
            type: OPEN_DIALOG,
            payload: {
              modalType: 'lockedModal',
              title: `${t('DENSELY_STRESS_TITLE')}`,
              body: `${t('DENSELY_STRESS_CONTENT')}`,
              okButtonText: `${t('DENSELY_BUTTON_OK')}`,
              okAction: () => {},
              closeAction: () => {},
            },
          });
          const { OK, CLOSE } = yield race({
            OK: take(OK_DIALOG),
            CLOSE: take(CLOSE_DIALOG),
          });
          if (OK) {
            yield put({
              type: OPEN_DIALOG,
              payload: {
                modalType: 'lockedModal',
                title: `${t('DENSELY_STRESS_TITLE')}`,
                body: `${t('DENSELY_STRESS_JC_CONTENT')}`,
                okButtonText: `${t('DENSELY_BUTTON_OK')}`,
                okAction: () => {
                  trackPartnerServiceDisclaimer(
                    TrackPartnerServiceDisclaimerIcon.CUHK_TOUR_HEART,
                  );
                },
                closeAction: () => {},
              },
            });
            const { OK, CLOSE } = yield race({
              OK: take(OK_DIALOG),
              CLOSE: take(CLOSE_DIALOG),
            });
            if (OK) {
              yield call(goto, 'cuhktourheart');
              history.replace('/home');
            }
            if (CLOSE) {
              history.replace('/home');
            }
          }
          if (CLOSE) {
            history.replace('/home');
          }
        }
      }
    }
  }
}

function* watchFindMyPolicySaga(): Iterable<any> {
  while (true) {
    const action = yield take(LOCATION_CHANGE);
    const { location } = action.payload;
    if (action.type === LOCATION_CHANGE) {
      if (location.pathname && location.hash) {
        if (
          location.pathname === '/home' &&
          location.hash === '#find-my-policy'
        ) {
          yield put({
            type: OPEN_DIALOG,
            payload: {
              modalType: 'lockedModal',
              title: `${t('DENSELY_TITLE')}`,
              body: `${t('DENSELY_FIND_MY_POLICY_CONTENT')}`,
              okButtonText: `${t('DENSELY_BUTTON_FIND_MY_POLICY')}`,
              okAction: () => {},
              closeAction: () => {},
            },
          });

          const { OK, CLOSE } = yield race({
            OK: take(OK_DIALOG),
            CLOSE: take(CLOSE_DIALOG),
          });
          if (OK) {
            history.replace('/home');
          }
          if (CLOSE) {
            history.replace('/home');
          }
        }
      }
    }
  }
}

function* watchLockModalSaga(): Iterable<any> {
  while (true) {
    const action = yield take(LOCATION_CHANGE);
    const { location } = action.payload;
    if (action.type === LOCATION_CHANGE) {
      if (location.pathname && location.hash) {
        if (location.pathname === '/home' && location.hash === '#lockModal') {
          yield put({
            type: OPEN_DIALOG,
            payload: {
              modalType: 'lockedModal',
              title: `${t('DENSELY_TITLE')}`,
              body: `${t('DENSELY_FIND_MY_POLICY_CONTENT')}`,
              okButtonText: `${t('TOP_NAV_GO')}`,
              okAction: () => {},
              closeAction: () => {},
            },
          });

          const { OK, CLOSE } = yield race({
            OK: take(OK_DIALOG),
            CLOSE: take(CLOSE_DIALOG),
          });
          if (OK) {
            history.replace('/home');
          }
          if (CLOSE) {
            history.replace('/home');
          }
        }
      }
    }
  }
}

function* forceLogoutSaga(): Iterable<any> {
  const isForceLogout = yield select(getIsForceLogout);

  if (isForceLogout) {
    yield put(updateForceLogoutFlag(false));
    yield put({
      type: SIGN_OUT,
    });
  }
}

function* appSaga() {
  yield takeLatest(FETCH_ACCESS_TOKEN_SUCCESS, setEmmaSessionCookieSaga);
  //yield takeLatest(FETCH_ACCESS_TOKEN_SUCCESS,setAuthorizationCookieSaga);
  //yield takeLatest(REFRESH_TOKEN_SUCCESS,setAuthorizationCookieSaga);
  yield takeLatest(FETCH_ACCESS_TOKEN_STARTED, removeAuthCodeSaga);
  yield takeLatest(FETCH_APP_CONFIG_REQUEST, fetchAppConfigSaga);
  yield takeLatest(SIGN_OUT, signOutSaga);
  yield takeLatest([FRAME_APP_MOUNTED, CHECK_FORCE_LOGOUT], forceLogoutSaga);

  yield all([
    watchHealthLibrarySaga(),
    watchWellnessCompanionSaga(),
    watchStressSaga(),
    watchFindMyPolicySaga(),
    watchLockModalSaga(),
  ]);
}

export default appSaga;
