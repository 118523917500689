import config from '../package.json';

const ID = `${config.name}/actions/checkEBCoverageAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_WOMAN_PRODUCT_POLICIES = createActionName(
  'FETCH_WOMAN_PRODUCT_POLICIES',
);
export const FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS = createActionName(
  'FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS',
);
export const FETCH_WOMAN_PRODUCT_POLICIES_FAILURE = createActionName(
  'FETCH_WOMAN_PRODUCT_POLICIES_FAILURE',
);
export const FETCH_WOMAN_PRODUCT_POLICY_COVERAGE = createActionName(
  'FETCH_WOMAN_PRODUCT_POLICY_COVERAGE',
);
export const FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS = createActionName(
  'FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS',
);
export const FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT = createActionName(
  'FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT',
);
export const FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS = createActionName(
  'FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS',
);
export const FETCH_WOMAN_PRODUCT_POLICY_EB_DEPENDENTS = createActionName(
  'FETCH_WOMAN_PRODUCT_POLICY_EB_DEPENDENTS',
);
export const FETCH_WOMAN_PRODUCT_EB_DEPENDENTS_CONTACT_SUCCESS =
  createActionName('FETCH_WOMAN_PRODUCT_EB_DEPENDENTS_CONTACT_SUCCESS');

export const WOMAN_PRODUCT_INIT = createActionName('WOMAN_PRODUCT_INIT');
export const SET_WOMAN_PRODUCT_IS_LOADING = createActionName(
  'SET_WOMAN_PRODUCT_IS_LOADING',
);
export const FETCH_WOMAN_PRODUCT_AUTH_TOKEN = createActionName(
  'FETCH_WOMAN_PRODUCT_AUTH_TOKEN',
);

export const FETCH_WOMAN_PRODUCT_STATIC_BANNER = createActionName(
  'FETCH_WOMAN_PRODUCT_STATIC_BANNER',
);
export const FETCH_WOMAN_PRODUCT_STATIC_BANNER_SUCCESS = createActionName(
  'FETCH_WOMAN_PRODUCT_STATIC_BANNER_SUCCESS',
);
export const FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS = createActionName(
  'FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS',
);
export const FETCH_WOMAN_PRODUCT_SELECT_EB_BENEFIT = createActionName(
  'FETCH_WOMAN_PRODUCT_SELECT_EB_BENEFIT',
);
export const FETCH_WOMAN_PRODUCT_INIT_EB_VALUE = createActionName(
  'FETCH_WOMAN_PRODUCT_INIT_EB_VALUE',
);
export const FETCH_WOMAN_PRODUCT_SWITCH_EB_BENEFIT_TAB = createActionName(
  'FETCH_WOMAN_PRODUCT_SWITCH_EB_BENEFIT_TAB',
);
export const FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS_TO_INIT_EB_VALUE =
  createActionName('FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS_TO_INIT_EB_VALUE');
export const FETCH_WOMAN_PRODUCT_SELECT_EB_RESET_BENEFIT_DROPDOWN_VALUE =
  createActionName(
    'FETCH_WOMAN_PRODUCT_SELECT_EB_RESET_BENEFIT_DROPDOWN_VALUE',
  );

export const FETCH_WOMAN_PRODUCT_INIT_EB_BENEFIT_DROPDOWN_VALUE =
  createActionName('FETCH_WOMAN_PRODUCT_INIT_EB_BENEFIT_DROPDOWN_VALUE');

export const FETCH_PRIVILEGES_FEATURES = createActionName(
  'FETCH_PRIVILEGES_FEATURES',
);

export const INIT_CARD_VIEW_IDS = createActionName('INIT_CARD_VIEW_IDS');

export const SET_CARD_VIEW_IDS = createActionName('SET_CARD_VIEW_IDS');

export const fetchWomanProductEBDependents = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_POLICY_EB_DEPENDENTS,
  payload,
});

export const fetchWomanProductEBDependentsSuccess = (
  data: any,
  currentDependentValue: string,
) => ({
  type: FETCH_WOMAN_PRODUCT_EB_DEPENDENTS_CONTACT_SUCCESS,
  payload: {
    dependentsList: data,
    currentDependentValue,
  },
});

export const fetchWomanProductPolicies = () => ({
  type: FETCH_WOMAN_PRODUCT_POLICIES,
});

export const fetchWomanProductPoliciesSuccess = (data: any) => ({
  type: FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS,
  payload: {
    womanProductPolicies: data,
  },
});

export const fetchWomanProductPolicyCoverage = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_POLICY_COVERAGE,
  payload,
});

export const fetchWomanProductPolicyCoverageSuccess = (data: any) => ({
  type: FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS,
  payload: {
    policyCoverage: data,
  },
});

export const fetchWomanProductAppointmentContact = () => ({
  type: FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT,
});

export const fetchWomanProductAppointmentContactSuccess = (data: any) => ({
  type: FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS,
  payload: {
    appointmentContact: data,
  },
});

export const setWomanProductIsLoading = (isLoadingFlag: boolean) => ({
  type: SET_WOMAN_PRODUCT_IS_LOADING,
  isLoading: isLoadingFlag,
});

export const fetchWomanProductAuthToken = (
  url: string,
  fetchWomanProductAuthTokenSuccess: (payload: any) => void,
  fetchWomanProductAuthTokenFailure: (error: any) => void,
) => ({
  type: FETCH_WOMAN_PRODUCT_AUTH_TOKEN,
  payload: {
    url,
    fetchWomanProductAuthTokenSuccess,
    fetchWomanProductAuthTokenFailure,
  },
});

export const fetchStaticBanner = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_STATIC_BANNER,
  payload,
});

export const fetchStaticBannerSuccess = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_STATIC_BANNER_SUCCESS,
  payload,
});

export const selectWomanProductEBDependents = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS,
  payload,
});

export const selectWomanProductEBBenefit = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_SELECT_EB_BENEFIT,
  payload,
});

export const initWomanProductEBBenefitValue = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_INIT_EB_VALUE,
  payload,
});

export const switchWomanProductEBBenefitTab = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_SWITCH_EB_BENEFIT_TAB,
  payload,
});

export const resetWomanProductEBBenefitValue = () => ({
  type: FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS_TO_INIT_EB_VALUE,
});

export const resetWomanProductEBBenefitDropDownValue = () => ({
  type: FETCH_WOMAN_PRODUCT_SELECT_EB_RESET_BENEFIT_DROPDOWN_VALUE,
});

export const initWomanProductEBBenefitDropDownValue = (payload: any) => ({
  type: FETCH_WOMAN_PRODUCT_INIT_EB_BENEFIT_DROPDOWN_VALUE,
  payload,
});

export const initEbBenefitCardViewIds = () => ({
  type: INIT_CARD_VIEW_IDS,
});
