import _ from 'lodash';
import {
  call,
  put,
  take,
  select,
  race,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import {
  PUSH_HAVE_TNC_DISCLAIMER,
  FETCH_HAVE_TNC_DISCLAIMER_SUCCESS,
  FETCH_HAVE_TNC_DISCLAIMER_FAILURE,
  UPDATE_HAVE_TNC_DISCLAIMER_STATE,
} from '../actions/tncDisclaimerActions';
import { FRAME_APP_MOUNTED } from '../actions/frameActions';
import { fetchCustState, putCustState } from '../actions/userActions';
import {
  getHaveTncDisclaimerCustomApiConfig,
  getHaveTncDisclaimerState,
} from '../selectors/tncDisclaimersSelectors';
import { HaveTncDisclaimerCustomApiRes } from '../types/tncDisclaimerTypes';

function* fetchHaveTncDisclaimerState() {
  try {
    const config = yield select(getHaveTncDisclaimerCustomApiConfig);
    const res = yield fetchCustState(config.key, config.channel);
    let haveTncDisclaimer: HaveTncDisclaimerCustomApiRes;
    try {
      haveTncDisclaimer = JSON.parse(decodeURI(res));
    } catch (error) {
      haveTncDisclaimer = {
        disclaimerIds: [],
      };
    }
    yield put({
      type: FETCH_HAVE_TNC_DISCLAIMER_SUCCESS,
      payload: haveTncDisclaimer,
    });
  } catch (e) {
    yield put({ type: FETCH_HAVE_TNC_DISCLAIMER_FAILURE, error: e });
  }
}

function* pushCommonHaveTncDisclaimer(serviceId: any) {
  const haveTncDisclaimer: HaveTncDisclaimerCustomApiRes = yield select(
    getHaveTncDisclaimerState,
  );
  const config = yield select(getHaveTncDisclaimerCustomApiConfig);
  if (haveTncDisclaimer && serviceId) {
    yield put({
      type: UPDATE_HAVE_TNC_DISCLAIMER_STATE,
      payload: { serviceId: serviceId },
    });

    const newHaveTncDisclaimer: HaveTncDisclaimerCustomApiRes = yield select(
      getHaveTncDisclaimerState,
    );
    yield putCustState(
      config.key,
      JSON.stringify(newHaveTncDisclaimer),
      config.channel,
    );
  }
}

function* pushHaveTncDisclaimer(action: any) {
  const { serviceId } = action.payload;
  yield pushCommonHaveTncDisclaimer(serviceId);
}

function* tncDisclaimerSaga() {
  yield take(FRAME_APP_MOUNTED);
  yield fork(fetchHaveTncDisclaimerState);
  yield takeLatest(PUSH_HAVE_TNC_DISCLAIMER, pushHaveTncDisclaimer);
}

export default tncDisclaimerSaga;
