import {
  WOMAN_PRODUCT_INIT,
  FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS,
  FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS,
  SET_WOMAN_PRODUCT_IS_LOADING,
  FETCH_WOMAN_PRODUCT_EB_DEPENDENTS_CONTACT_SUCCESS,
  FETCH_WOMAN_PRODUCT_STATIC_BANNER_SUCCESS,
  FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS,
  FETCH_WOMAN_PRODUCT_SELECT_EB_BENEFIT,
  FETCH_WOMAN_PRODUCT_INIT_EB_VALUE,
  FETCH_WOMAN_PRODUCT_SWITCH_EB_BENEFIT_TAB,
  FETCH_WOMAN_PRODUCT_INIT_EB_BENEFIT_DROPDOWN_VALUE,
  SET_CARD_VIEW_IDS,
} from '../actions/checkEBCoverageAction';

const defaultState = {
  currentDependentValue: '',
  currentBenefitTab: '',
  currentBenefitDropDownValue: '',
  ebCoverageList: [
    {
      womanProductPolicies: [],
      policyNumber: '',
      entityCode: '',
      planCode: '',
      planName: '',
      coreBenefitList: [],
      benefitRemarks: [],
      keyExculsions: {},
      productRemarks: {},
      initBenefitTab: '',
      benefitTabs: [],
      EBCoreBenefitList: [
        {
          benefitType: '',
          initBenefitDropDownValue: '',
          benefitDropDownList: [],
          benefitList: [],
        },
      ],
    },
  ],
  appointmentContact: [],
  isLoading: true,
  dependentsList: [],
  bannerList: {},
  cardViewIds: [],
  privilegeFeaturesError: '',
};

export interface EBCoreBenefitListItem {
  benefitType: string;
  initBenefitDropDownValue: string;
  benefitDropDownList: Array<any>;
  benefitList: Array<any>;
}

export interface checkEBCoverageItem {
  womanProductPolicies: Array<any>;
  policyNumber: string;
  entityCode: string;
  planCode: string;
  planName: string;
  certificateNumber: string;
  dependentNumber: string;
  benefitClass: string;
  coreBenefitList: Array<coreBenefitItem>;
  benefitRemarks: Array<any>;
  keyExculsions: object;
  productRemarks: object;
  initBenefitTab: string;
  benefitTabs: Array<any>;
  EBCoreBenefitList: Array<EBCoreBenefitListItem>;
  isTeleconsultationEnabled: string;
}

export interface checkEBCoverageState {
  currentDependentValue: string;
  currentBenefitTab: string;
  currentBenefitDropDownValue: string;
  ebCoverageList: Array<checkEBCoverageItem>;
  appointmentContact: Array<any>;
  isLoading: boolean;
  dependentsList: Array<any>;
  bannerList: object;
}

export interface coreBenefitItem {
  category: string;
  benefitNames: Array<any>;
  benefitRemarks: Array<any>;
  ordering: string;
  appointment: string;
  appointmentButtonDisable: boolean;
  textLink: string;
  textLinkContent: string;
  textLinkUrl: string;
  coverages: Array<any>;
  benefitCode: string;
  benefitUsedupMessage: string;
}

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case WOMAN_PRODUCT_INIT:
      return defaultState;
    case FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS:
      nextState.womanProductPolicies = action.payload.womanProductPolicies;
      return nextState;
    case FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS:
      // Fetch per each dependent, so append it.
      nextState.ebCoverageList = state.ebCoverageList.concat(
        action.payload.policyCoverage,
      );
      return nextState;
    case FETCH_WOMAN_PRODUCT_STATIC_BANNER_SUCCESS:
      nextState.bannerList = action.payload || {};
      return nextState;
    case SET_WOMAN_PRODUCT_IS_LOADING:
      nextState.isLoading = action.isLoading;
      return nextState;
    case FETCH_WOMAN_PRODUCT_EB_DEPENDENTS_CONTACT_SUCCESS:
      nextState.currentDependentValue = action.payload.currentDependentValue;
      nextState.dependentsList = action.payload.dependentsList;
      return nextState;
    case FETCH_WOMAN_PRODUCT_SELECT_EB_DEPENDENTS:
      nextState.currentDependentValue = action.payload;
      return nextState;
    case FETCH_WOMAN_PRODUCT_SELECT_EB_BENEFIT:
      nextState.currentBenefitDropDownValue = action.payload;
      return nextState;
    case FETCH_WOMAN_PRODUCT_INIT_EB_VALUE:
      nextState.currentBenefitTab = action.payload.currentBenefitTab;
      nextState.currentBenefitDropDownValue =
        action.payload.currentBenefitDropDownValue;
      return nextState;
    case FETCH_WOMAN_PRODUCT_SWITCH_EB_BENEFIT_TAB:
      nextState.currentBenefitTab = action.payload;
      return nextState;
    case FETCH_WOMAN_PRODUCT_INIT_EB_BENEFIT_DROPDOWN_VALUE:
      nextState.currentBenefitDropDownValue = action.payload;
      return nextState;
    case SET_CARD_VIEW_IDS:
      nextState.cardViewIds = action.payload.cardViewIds || [];
      return nextState;
    default:
      return nextState;
  }
};
