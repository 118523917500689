import { LoadingWidget } from '@axa-asia/emma-loading-widget';
import React, { PureComponent } from 'react';

interface LoadingViewProps {
  fromScreenTop: boolean;
}

export default class LoadingView extends PureComponent<LoadingViewProps> {
  constructor(props: LoadingViewProps) {
    super(props);
  }

  static defaultProps = {
    fromScreenTop: false,
  };

  render() {
    const { fromScreenTop } = this.props;

    return (
      <LoadingWidget isLoading target={fromScreenTop ? 'overlay' : 'rhs'} />
    );
  }
}
