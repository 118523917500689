import config from '../package.json';
const ID = `${config.name}/actions/customerProfileVerify`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const SEND_OTP = createActionName('SEND_OTP');
export const SEND_OTP_LOADING = createActionName('SEND_OTP_LOADING');

export const sendOtp = (payload: any) => ({
  type: SEND_OTP,
  payload,
});
