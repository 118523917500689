/**
 * This is screen name mapping config. The format is
 * [
 *      screenPathPrefixRegex: {
 *          screenPathRegex: ""
 *          webScreenName: ""
 *          appScreeName: ""
 *          tabletScreenName: ""
 *      }
 * ]
 * 
 * screenPathPrefix - The prefix of screen path in regex format. This is first layer to scan.
 * screenPathRegex - The regex of screen path in regex format. This is second layer to scan.
 * xxScreenName - The name of screen to be sent to GA KPI #64.
 */
/* Sample:
    const GA_SCREEN_NAME_MAPPING = {
        // Sample
        "(/sample)": [
            {
                "screenPathRegex": "(/sample/details)",
                "screenName": "NA",
                "webScreenName": "NA",
                "appScreeName": "NA",
                "tabletScreenName": "NA"
            }
        ]
    }
*/

/**
 * Check if web
 * @param {*} platform 
 * @returns 
 */
 function isWeb(platform) {
    return platform === 'WEB';
}

/**
 * Check if app
 * @param {*} platform 
 * @returns 
 */
function isApp(platform) {
    return platform === 'APP';
}

/**
 * Check if web compatible
 * @returns 
 */
function isWebCompatible() {
    try {
        const isCompatible = (window && window.URLSearchParams && window.location && window.location.pathname && window.location.search) !== undefined;
        if (isCompatible) {
            new URLSearchParams(window.location.search).entries(); // It should throw error on react-native
            return true;
        }
        return false
    } catch (e) {
        return false;
    }
}

/**
 * Check if screen path in screenNameConfig is match with the given screen path
 * @param {*} screenPath 
 * @param {*} screenNameConfig 
 * @returns 
 */
function isPathMatch(screenPath, screenNameConfig) {
    const screenPathRegex = new RegExp(screenNameConfig.screenPathRegex, 'g');
    const isPathMatch = screenPath.match(screenPathRegex);
    return isPathMatch;
}

/**
 * FOR WEB ONLY
 * Check if query params in screenNameConfig is match with the given screen path
 * @param {*} screenNameConfig 
 * @returns 
 */
function isQueryParamsMatchForWeb(screenNameConfig) {
    let isQueryStrMatch = true;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const queryParamsConfig = screenNameConfig.queryParams || {};
    for (const [key, value] of Object.entries(queryParamsConfig)) {
        if (!params[key] || params[key] !== value) {
            isQueryStrMatch = false;
        }
    }
    return isQueryStrMatch;
}

/**
 * FOR WEB ONLY.
 * Check if screenNameConfig is match with the given screen path
 * @param {*} screenPath 
 * @param {*} screenNameConfig 
 * @returns 
 */
function isMatchForWeb(screenPath, screenNameConfig) {    
    return isPathMatch(screenPath, screenNameConfig) && isQueryParamsMatchForWeb(screenNameConfig);
}

/**
 * FOR APP ONLY.
 * Check if screenNameConfig is match with the given screen path
 * @param {*} screenPath 
 * @param {*} screenNameConfig 
 * @returns 
 */
 function isMatchForApp(screenPath, screenNameConfig) {    
    return isPathMatch(screenPath, screenNameConfig);
}

/**
 * Check if screenNameConfig is match with the given screen path
 * @param {*} screenPath 
 * @param {*} screenNameConfig 
 * @param {*} platform 
 * @returns 
 */
function isMatch(screenPath, screenNameConfig, platform) {
    if (isApp(platform)) {
        return isMatchForApp(screenPath, screenNameConfig);
    } else {
        return isMatchForWeb(screenPath, screenNameConfig);
    }
}

/**
 * FOR WEB ONLY
 * Replace language placeholder
 * @param {*} screenName 
 */
function replaceLanguageForWeb(screenName) {
    // Replace language placeholder
    try {
        const language = window.location.pathname.split('/')[1];
        const result = screenName.replace('${language}', language.toUpperCase() === 'ZH' ? 'Zh' : 'En');
        return result;
    } catch (e) {
        console.warn('Failed to identify language.', e);
    }
}

/**
 * FOR WEB ONLY
 * Replace query params for web
 * @param {*} screenName 
 * @returns 
 */
function replaceQueryParamsForWeb(screenName) {
    const splitedResults = screenName.split('/');
    splitedResults.forEach((i) => {
        const regexResult = i.match(/\${queryStr\.(?<queryParam>.*)}/);
        const queryParamName = regexResult && regexResult.groups && regexResult.groups.queryParam;
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const paramValue = params[queryParamName];
        if (paramValue) {
            screenName = screenName.replace(`\${queryStr.${queryParamName}}`, paramValue);
        }
    });
    return screenName;
}


/**
 * Return you a screen name by given a screenPath. You can pass it to GA KPI #64.
 * @param {*} screenPath - This is URL pathname. For example: "/en/emma/marketplace/myprivileges/axagoal/dashboard"
 * @param {*} platform - "WEB" | "APP" | "TABLET"
 */
function getScreenName(screenPath: string, platform: string, screenNameMapping: { [prefix: string]: Array<any>}) {
    let result = screenPath;

    // Scan the screen name mapping
    Object.entries(screenNameMapping).forEach(([prefix, screenPathList]) => {

        // Match the prefix. Skip if cannot match.
        const prefixRegex = new RegExp(prefix, 'g');
        if (!screenPath.match(prefixRegex)) return;

        // Scan screen path list of matched prefix
        screenPathList.forEach((v) => {
            if (isMatch(screenPath, v, platform)) {
                switch (platform) {
                    case "APP":
                        result = v.screenName || v.appScreeName || screenPath;
                        result = result.replace('${device}', 'M');
                        break;
                    case "TABLET":
                        result = v.screenName || v.tabletScreenName || screenPath;
                        result = result.replace('${device}', 'T');
                        break;
                    case "WEB":
                        result = v.screenName || v.webScreenName || screenPath;
                        result = result.replace('${device}', 'D');
                        break;
                    default:
                        break;
                }
            }
        });
    });

    // Replace language placeholder
    if (isWebCompatible()) {
        result = replaceLanguageForWeb(result);
    }

    // Replace query string placeholder ${queryStr.XXX} to corrspondent query string value from URL.
    if (isWebCompatible()) {
        result = replaceQueryParamsForWeb(result);
    }
    
    return result; 
}

/**
 * Must be async to avoid blocking main thread
 * @param {*} screenPath - This is URL pathname. For example: "/en/emma/marketplace/myprivileges/axagoal/dashboard"
 * @param {*} platform - "WEB" | "APP" | "TABLET"
 */
export function getScreenNameAsync(screenPath: string, platform: string, screenNameMapping: any) {
    return new Promise((resolve) => {
        try {
            const screenName = getScreenName(screenPath, platform, screenNameMapping);
            resolve(screenName);
        } catch (error) {
            console.log(`Error in getting web screen name mapping. Will fallback to original screen path: ${screenPath}.`, error);
            resolve(screenPath);
        }
    });
}



