import _ from 'lodash';

export const getIsBetterMeShowPopup = (state: {}): boolean => {
  return _.get(state, `hk-emma-web-core/myPrivilegesOverlay.isShowPopupModal`);
};

export const getIsLaunchpadShowPopup = (state: {}): boolean => {
  return _.get(state, `hk-emma-web-core/myPrivilegesOverlay.isShowLaunchpad`);
};

export const getIsLoginLaunchpad = (state: {}): boolean => {
  return _.get(
    state,
    `hk-emma-web-core/myPrivilegesOverlay.isLoadingLaunchpad`,
  );
};
