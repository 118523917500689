import { put } from '../stores';

export const FETCH_MOBILE_MODULE_CONFIG = 'FETCH_MOBILE_MODULE_CONFIG';
export const FETCH_MOBILE_MODULE_CONFIG_SUCCESS =
  'FETCH_MOBILE_MODULE_CONFIG_SUCCESS';

export const fetchMobileModuleConfig = () => {
  return {
    type: FETCH_MOBILE_MODULE_CONFIG,
    payload: {},
  };
};

export const fetchMobileModuleConfigSuccess = (config: any) => {
  return {
    type: FETCH_MOBILE_MODULE_CONFIG_SUCCESS,
    payload: {
      config,
    },
  };
};
