import {
  FETCH_HAVE_TNC_DISCLAIMER_SUCCESS,
  FETCH_HAVE_TNC_DISCLAIMER_FAILURE,
  UPDATE_HAVE_TNC_DISCLAIMER_STATE,
} from '../actions/tncDisclaimerActions';

const defaultState = {};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_HAVE_TNC_DISCLAIMER_SUCCESS:
      const { payload } = action;
      if (payload) {
        nextState.tncDisclaimerState = action.payload;
      }
      return nextState;
    case FETCH_HAVE_TNC_DISCLAIMER_FAILURE:
      nextState.tncDisclaimerStateError = action.error;
      return nextState;
    case UPDATE_HAVE_TNC_DISCLAIMER_STATE:
      const { serviceId } = action.payload;
      if (nextState.tncDisclaimerState) {
        nextState.tncDisclaimerState.disclaimerIds =
          nextState.tncDisclaimerState.disclaimerIds || [];
        if (!nextState.tncDisclaimerState.disclaimerIds.includes(serviceId)) {
          nextState.tncDisclaimerState.disclaimerIds.push(serviceId);
        }
      }
      return nextState;
    default:
      return nextState;
  }
};
