import screenRegistry from '../registries/RouteRegistry';
import reducerRegistry from '../registries/ReducerRegistry';
import sagaRegistry from '../registries/SagaRegistry';
import { injectAsyncReducer, put, runSagas, select } from '../stores';
import rootComponentRegistry from '../registries/RootComponentRegistry';
import FrameContainer from '../containers/FrameContainer';
import MobileFrameContainer from '../containers/MobileFrameContainer';
import {
  getToken,
  getIsTeamChallengeRule,
  getIsManningsAppointment,
  getIsNeurumHealthContainer,
  getIsCheckCoverage,
  getIsOffersContainer,
  getIsCustomerProfileContainer,
  getIsFanclAppointment,
  getIsStorytalerContainer,
  getIsGoGreenSurveyContainer,
  getIsAktivoHealthSurveyContainer,
  getIsDeleteAccount,
  getIsAktivoIndividualChallenge,
  getIsSenselyWidget,
} from '../selectors/authSelectors';
import WelcomeScreen from '../modules/welcome/containers/WelcomeScreen';

import env from '../env';
import {
  fetchWithTimeout,
  myAxaFetchWithToken,
} from '../utils/myAxaFetchWithToken';
import { getUserConfigUrl } from '../selectors/configSelectors';
import _ from 'lodash';
import MaintenanceScreen from '../modules/maintenance/containers/MaintenanceScreen';
import CoreApiErrorMaintenanceScreen from '../modules/maintenance/containers/CoreApiErrorMaintenanceScreen';
import TeamChallengeRuleScreen from '../modules/team-challenge/containers/Rules';
import { getSystemStatus } from '../selectors/systemSelectors';
import { SystemStatus } from '../types/systemTypes';
import {
  isApp,
  getValidToken,
  getPlatformStr,
  isIosSafariBrowser,
} from '../utils';
import Cookies from 'cookies-js';

import {
  gaClickableElementCallback,
  logPreviousUrlCallback,
} from '../utils/generalGaEventAnalytics';
import EmmaGaListener from '@axa-asia/hk-emma-web-module/lib/ga-lib/EmmaGaListener';
import history from '../stores/browserHistory';
import logPreviousUrl from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';
import { maskPN } from '@axa-asia/hk-emma-web-module/lib/ga-lib/MaskingUtil';
import { getScreenNameAsync } from '@axa-asia/hk-emma-web-module/lib/ga-lib/WebScreenNameMappingUtil';
import { FETCH_CUSTOMER_SUCCESS } from '../actions/userActions';

import { getMyPrivilegesFeatureUrl } from '../modules/myPrivileges/selectors/myPrivilegesSelector';
import uuidv4 from 'uuid/v4';
import {
  FETCH_MY_PRIVILEGES_FEATURES_SUCCESS,
  FETCH_MY_PRIVILEGES_FEATURES_FAILURE,
} from '../modules/myPrivileges/actions/myPrivilegesAction';
import { getLanguage } from '../selectors/userSelectors';
import { signOut } from './authActions';
import { getCorpWebCookieName } from '../modules/emma-webview/selectors/webviewSelector';
import { getScreenNameMapping } from '../modules/google-analytics/selectors/homeSelector';
import {
  getExperimentsConfigFromModuleConfig,
  getGTMEnvironmentConfig,
} from '../modules/google-optimize-ab-test/selectors/googleOptimizeABTestSelector';
import { fetchMobileModuleConfig } from './mobileModuleConfigAction';
import { asyncLoadModule } from '../components/lazyLoading/async-load-util';
import { EB_FELXI_REDIRECT } from '../constants/constants';
import { trackDefaultPageViewGA4 } from '../utils/firebaseAnalyticsEvents';
import { FETCH_EXPERIMENT_CONFIG_SUCCESS } from '../modules/google-optimize-ab-test/actions/experimentsConfigActions';
import EnterPassword from '../modules/delete-account/containers/EnterPassword';
import deleteAccountConfirmation from '../modules/delete-account/containers/confirmation';
import { EmmaFactory } from '@axa-asia/hk-emma-js';
import { setEmmaHeaders } from '@axa-asia/common-api-utils';

export const FETCH_APP_CONFIG_REQUEST = `FETCH_APP_CONFIG_REQUEST`;
export const FETCH_APP_CONFIG_SUCCESS = `FETCH_APP_CONFIG_SUCCESS`;

export const FETCH_USER_CONFIG_REQUEST = `FETCH_USER_CONFIG_REQUEST`;
export const FETCH_USER_CONFIG_SUCCESS = `FETCH_USER_CONFIG_SUCCESS`;
export const BANNER_TO_SHOW = 'BANNER_TO_SHOW';

const onFetchAppConfigSuccess = (serverConfig) => {
  const moduleConfig = _.get(serverConfig, `moduleConfig.modules`, {});
  const preVersion = _.get(serverConfig, `moduleConfig.version`, ``);
  const systemStatus = _.get(serverConfig, `systemStatus`, {});

  put({
    type: FETCH_APP_CONFIG_SUCCESS,
    config: {
      moduleConfig,
      preVersion,
      systemStatus,
    },
  });
};

export const fetchAppConfig = () => {
  if (env.INIT_APP_INFO) {
    // fetch in server-side in prod
    onFetchAppConfigSuccess(env.INIT_APP_INFO);

    // setEmmaLoginConfig(env.INIT_APP_INFO.moduleConfig.modules['emma-login']);

    return Promise.resolve(true);
  }

  const match = env.WEBAPP_VERSION.match(/^[0-9]+\.[0-9]+\.[0-9]+/);
  const webAppVersion = match ? match[0] : ``;
  const fetchInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-AXA-APP-CONFIG-VERSION': '0.0.0',
      'X-AXA-APP-INFO': `${env.APP_VERSION_USER_AGENT_PREFIX}/WEB/${webAppVersion}-${env.APP_VERSION_ENV}`, // e.g. MYAXA_HK/IOS/3.1.0-uat
      'X-AXA-POLICY-FETCH-TIME': '0000-01-01T00:00:00Z',
    },
  };
  return new Promise<Response>(async (resolve, reject) => {
    try {
      const res = await fetchWithTimeout('/meta/app-info', fetchInit);
      if (res.status !== 200) {
        throw new Error('API error');
      }
      resolve(res);
    } catch (e) {
      fetchWithTimeout(env.URL_APP_INFO, fetchInit)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    }
  })
    .then((res) => {
      if (res.status >= 300) {
        throw new Error('API error');
      }
      return res;
    })
    .then((res) => res.json())
    .then((serverConfig) => {
      onFetchAppConfigSuccess(serverConfig);

      // if (serverConfig.INIT_APP_INFO) {
      //   setEmmaLoginConfig(
      //     serverConfig.INIT_APP_INFO.moduleConfig.modules['emma-login'],
      //   );
      // }

      return Promise.resolve(true);
    })
    .catch((err) => Promise.resolve(false));
};
export const fetchUserConfig = () => {
  /**
   * old flow before Apr 2021
   * FrameContainer > onFetchCustomerRequest > fetchCustomerRequest > FETCH_CUSTOMER_REQUEST > fetchCustomer() > FETCH_CUSTOMER_SUCCESS > axagoalHomeSaga
   * new flow
   * index.tsx > post-login > FETCH_CUSTOMER_SUCCESS > FrameContainer
   * FrameContainer > FRAME_APP_MOUNTED > axagoalHomeSaga
   */
  const match = env.WEBAPP_VERSION.match(/^[0-9]+\.[0-9]+\.[0-9]+/);
  const webAppVersion = match ? match[0] : ``;
  const token = select(getToken);
  if (token) {
    const userConfigUrl = select(getUserConfigUrl);
    return myAxaFetchWithToken(
      userConfigUrl,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-AXA-APP-CONFIG-VERSION': '0.0.0',
          'X-AXA-APP-INFO': `${env.APP_VERSION_USER_AGENT_PREFIX}/WEB/${webAppVersion}-${env.APP_VERSION_ENV}`, // e.g. MYAXA_HK/IOS/3.1.0-uat
          'X-AXA-POLICY-FETCH-TIME': '0000-01-01T00:00:00Z',
        },
      },
      undefined,
      undefined,
      0,
    )
      .then((res) => res.json())
      .then((serverConfig) => {
        const moduleConfig = _.get(serverConfig, `moduleConfig.modules`, {});
        const postVersion = _.get(serverConfig, `moduleConfig.version`, ``);
        const deepLinkMapping = _.get(serverConfig, `deepLinkMapping`, {});
        const systemStatus = _.get(serverConfig, `systemStatus`, {});
        const avators = _.get(serverConfig, `avators`, {});
        const googleOptimizeExperimentsConfig =
          getExperimentsConfigFromModuleConfig(moduleConfig);

        const consumerLogin = _.get(serverConfig, `consumerLogin`, {});

        put({
          type: FETCH_USER_CONFIG_SUCCESS,
          config: {
            moduleConfig,
            deepLinkMapping,
            postVersion,
            systemStatus,
            avators,
            consumerLogin,
          },
        });
        put({
          type: FETCH_CUSTOMER_SUCCESS,
          customer: consumerLogin,
        });

        // Store Experiment Config into state
        put({
          type: FETCH_EXPERIMENT_CONFIG_SUCCESS,
          payload: {
            ...googleOptimizeExperimentsConfig,
          },
        });
        return Promise.resolve(true);
      })
      .catch((err) => {
        if (err.json) {
          return err.json().then((json: any) => {
            const res = json.response;
            if (res && res.reasonCode) {
              const language = getLanguage();
              const url = '/${language}/iam-fe/emma/login-fail/${reasonCode}'
                .replace('${reasonCode}', res.reasonCode)
                .replace('${language}', language);

              put(signOut());

              setTimeout(() => {
                window.location.href = url;
              }, 500);
            }
            return Promise.resolve(false);
          });
        }
        return Promise.resolve(false);
      });
  } else {
    return Promise.resolve(true);
  }
};
export const fetchPrivileges = () => {
  const myPrivilegesFeatureUrl = select(getMyPrivilegesFeatureUrl);
  const fetchPrivilegesSuccess = (serverConfig: {
    retrievePriviliegesResponse: any;
  }) => {
    const features =
      serverConfig && serverConfig.retrievePriviliegesResponse
        ? serverConfig.retrievePriviliegesResponse
        : [];
    put({
      type: FETCH_MY_PRIVILEGES_FEATURES_SUCCESS,
      featuresItems: features,
    });
    return Promise.resolve();
  };
  const fetchPrivilegesFailure = (err: any) => {
    put({ type: FETCH_MY_PRIVILEGES_FEATURES_FAILURE, error: err });
    return Promise.resolve();
  };
  startFetching(
    myPrivilegesFeatureUrl,
    fetchPrivilegesSuccess,
    fetchPrivilegesFailure,
  );
};

export const fetchWebUserConfigFromApp = () => {
  put(fetchMobileModuleConfig());
};

/**
 * Preload module for web portal ONLY.
 * @returns
 */
export const initPreloadModules = () =>
  Promise.all([import('../'), import('../lazy-conifg')]).then((config) => {
    config
      .map((c) => c.default)
      .forEach((c) => {
        setTimeout(() => {
          const preloadModules = c.preloadModules || [];
          preloadModules.forEach((module: any) => {
            // pre-load reducer
            const preloadReducers = module.preloadReducers || {};
            Object.keys(preloadReducers).forEach((key) => {
              injectAsyncReducer(module.moduleName, preloadReducers[key]);
            });

            // pre-load saga
            if (module.preloadSagas) {
              runSagas(module.moduleName, module.preloadSagas);
            }

            // pre-load modules
            const moduleFolderNames = module.moduleFolderNames || [];
            moduleFolderNames.forEach((v: string) => {
              asyncLoadModule(v, false);
            });
          });
        });
      });

    return Promise.resolve();
  });

export const initLazyModules = () =>
  Promise.all([import('../'), import('../lazy-conifg')]).then((config) => {
    config
      .map((c) => c.default)
      .forEach((c) => {
        /**
         * Check for modules
         */
        const modules = c.modules || [];
        modules.forEach((module: any) => {
          // Load route
          const rootPaths = module.routes.rootPaths;
          const subPaths = module.routes.subPaths;
          Object.keys(subPaths).forEach((subPath: string) => {
            rootPaths.forEach((routePath: string) => {
              // Register for the route which is enabled for mobile
              const enableMobileRoute = subPaths[subPath].enableMobileRoute;
              if (enableMobileRoute) {
                screenRegistry.register(
                  module.moduleName,
                  routePath === ''
                    ? `/${MOB_PREFIX}${subPath}`
                    : `/${MOB_PREFIX}/${routePath}${subPath}`,
                  subPaths[subPath],
                );
              }
              // Register normal route
              screenRegistry.register(
                module.moduleName,
                routePath === '' ? subPath : `/${routePath}${subPath}`,
                subPaths[subPath],
              );
            });
          });
        });

        /**
         * Only init mobile reducers and mobile sagas
         */
        if (isMobileRoute()) {
          const reducers = c.mobileReducers || {};
          Object.keys(reducers).forEach((r) => {
            reducerRegistry.register(c.config.name, r, reducers[r]);
          });
          const sagas = c.mobileSagas || [];
          sagaRegistry.register(sagas);
        }
      });

    return Promise.resolve();
  });

export const initModules = () =>
  Promise.all([
    import('../'),
    import('../modules/home/'),
    import('../modules/chatbot/'),
    import('../modules/healthkeeper/'),
    import('../modules/emma-common/'),
    import('../modules/emma-modal/'),
    import('../modules/google-analytics/'),
    import('../modules/google-optimize-ab-test/'),
    import('../modules/emma-axa-promotional-offer'),
    import('../modules/emma-webinar'),
    import('../modules/eb-flexi'),
    import('../modules/aktivo-onboarding/'),
    import('../modules/ps-luckydraw'),

    // HKEMMA-85191 - Housekeeping emma-notice. To find the source code, please checkout 2022rls5.
    // import('../modules/emma-notices/'),

    // Enabled lazy loading. Please go to lazy loading config to view the details.
    // import('../modules/myPrivileges/'),
    // import('../modules/exploremore/'),
    // import('../modules/settings/'),
    // import('../modules/life/'),
    // import('../modules/axagoal/'),
    // import('../modules/team-challenge/'),
    // import('../modules/partners/'),
    // import('../modules/findMyPolicy/'),
    // import('../modules/policy-list/'),
    // import('../modules/ps-oxford-vr/'),
    // import('../modules/ps-stress-care/'),
    // import('../modules/ps-mental-health-guru/'),
    // import('../modules/ps-smiling-mind/'),
    // import('../modules/ps-moodgym/'),
    // import('../modules/e-hub/'),
    // import('../modules/mannings/'),
    // import('../modules/fancl/'),
    // import('../modules/ps-hkshwellnessexperience/'),
    // import('../modules/ps-casemanagement/'),
    // import('../modules/ps-manninghealthcollaboration/'),
    // import('../modules/ps-fanclskinandhealthtest'),
    // import('../modules/ps-wellnessprogramme/'),
    // import('../modules/ps-preventionstage/'),
    // import('../modules/ps-preventionstageplus/'),
    // import('../modules/ps-treatmentandrehabilitationstafe/'),
    // import('../modules/ps-treatmentandrehabilitationstafeplus/'),
    // import('../modules/ps-jadediabetes/'),
    // import('../modules/ps-chronicdiseasemanagement/'),
    // import('../modules/ps-sensely/'),
    // import('../modules/ps-senselysymptomchecker/'),
    // import('../modules/ps-meditation'),
    // import('../modules/ps-storytaler/'),
    // import('../modules/storytaler-health/'),
    // import('../modules/neurum-health'),
    // import('../modules/ps-leadformmeetourconsultant'),
    // import('../modules/ps-offer-one'),
    // import('../modules/check-coverage/'),
    // import('../modules/ps-fertilitysolution/'),
    // import('../modules/ps-cosmeticdermatology/'),
    // import('../modules/ps-refractivesurgery'),
    // import('../modules/ps-maternityservices/'),
    // import('../modules/ps-womenproductlead/'),
    // import('../modules/offers/'),
    // import('../modules/ps-general/'),
    // import('../modules/emma-customer-profile/'),
    // import('../modules/go-green-survey'),
  ]).then((modules) => {
    modules
      .map((m) => m.default)
      .forEach((m) => {
        const routes = m.routes || {};
        Object.keys(routes).forEach((r) => {
          //screenRegistry.register(m.config.name, `/${m.config.route}${r}`, routes[r]);
          screenRegistry.register(
            m.config.name,
            `/${m.config.route}${r}`,
            routes[r],
          );

          if (m.config.routes) {
            m.config.routes.forEach((mainRoute) => {
              screenRegistry.register(
                m.config.name,
                `/${mainRoute}${r}`,
                routes[r],
              );
            });
          }
        });

        const reducers = m.reducers || {};
        Object.keys(reducers).forEach((r) => {
          reducerRegistry.register(m.config.name, r, reducers[r]);
        });

        const sagas = m.sagas || [];
        sagaRegistry.register(sagas);
      });

    return Promise.resolve();
  });

export const MOB_PREFIX = 'mob';
export const isMobileRoute = () => {
  const pathname = window.location.pathname;
  const splitedPathname = pathname.split('/');
  const isMobileRoute = splitedPathname.indexOf(MOB_PREFIX) !== -1;
  return isMobileRoute;
};

/**
 * #1. If access mobile route by app, load mobile frame container.
 * You can enable this by turn enableMobileRoute to true in route config.
 * Please note that enable this will skip app-info API and post-login API for better performance.
 * Therefore, recommend use this for new module from now.
 * For old modules, please refer to #2 below.
 */
export const registerMobileRoot = (displayErrorPage: boolean) => {
  if (isMobileRoute()) {
    if (displayErrorPage) {
      rootComponentRegistry.set(CoreApiErrorMaintenanceScreen);
    } else {
      rootComponentRegistry.set(MobileFrameContainer);
    }
    return;
  }
};

/**
 * #2. If access normal web portal route, load frame container
 * This is old way that support access web portal by app.
 * For new development from now, please refer to #1 above mobile route for better performance.
 */
export const registerRoot = (isCoreApiCallSuccess: boolean) => {
  const systemStatus: any = select(getSystemStatus);

  const isTeamChallengeRule = select(getIsTeamChallengeRule);
  const isManningsAppointment = select(getIsManningsAppointment);
  const isFanclAppointment = select(getIsFanclAppointment);
  const isCheckCoverageAppointment = select(getIsCheckCoverage);
  const isNeurumHealthContainer = select(getIsNeurumHealthContainer);
  const isStorytalerContainer = select(getIsStorytalerContainer);
  const isOffersContainer = select(getIsOffersContainer);
  const isCustomerProfileContainer = select(getIsCustomerProfileContainer);
  const isGetIsGoGreenSurveyContainer = select(getIsGoGreenSurveyContainer);
  const isGetIsAktivoHealthSurveyContainer = select(
    getIsAktivoHealthSurveyContainer,
  );
  const isDeleteAccount = select(getIsDeleteAccount);
  // const isAktivoIndividualChallenge = select(getIsAktivoIndividualChallenge);
  const isSenselyWidget = select(getIsSenselyWidget);

  const showFrameContainer =
    (isSenselyWidget ||
      isManningsAppointment ||
      isFanclAppointment ||
      isCheckCoverageAppointment ||
      isNeurumHealthContainer ||
      isStorytalerContainer ||
      isOffersContainer ||
      isCustomerProfileContainer ||
      isGetIsGoGreenSurveyContainer ||
      isDeleteAccount ||
      isGetIsAktivoHealthSurveyContainer) &&
    isApp();

  if (isTeamChallengeRule) {
    rootComponentRegistry.set(TeamChallengeRuleScreen);
  } else if (showFrameContainer) {
    const token = select(getToken);
    rootComponentRegistry.set(token ? FrameContainer : WelcomeScreen);
  } else if (systemStatus.message) {
    const token = select(getToken);
    if (window && window.location.href.includes(EB_FELXI_REDIRECT) && token) {
      rootComponentRegistry.set(FrameContainer);
    } else {
      rootComponentRegistry.set(MaintenanceScreen);
    }
  } else if (!isCoreApiCallSuccess) {
    rootComponentRegistry.set(CoreApiErrorMaintenanceScreen);
  } else {
    const token = select(getToken);
    rootComponentRegistry.set(token ? FrameContainer : WelcomeScreen);
  }
};

/**
 * scriptUrl - the script URL to load in <script src>
 * targetTagName - the first tag to import the script e.g. <head>
 */
export const loadScriptToTag = async (
  scriptUrl: string,
  targetTagName: string,
  position: 'start' | 'end' = 'end',
) => {
  return new Promise((resolve, reject) => {
    try {
      if (!scriptUrl) {
        reject(new Error('Script URL is undefined.'));
      }
      const doc = document.getElementsByTagName(targetTagName)[0];
      if (!doc) {
        reject(new Error(`Tag name ${targetTagName} not found.`));
      }
      const script = document.createElement('script');
      const firstChild = doc.firstChild;
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', scriptUrl);
      script.onload = () => {
        resolve(true);
      };
      script.onerror = (error) => {
        reject(error);
      };
      if (position === 'start') {
        doc.insertBefore(script, firstChild);
      } else {
        doc.appendChild(script);
      }
    } catch (error) {
      reject(error);
    }
  });
};

// Init Tag Management Systems
export const initTMS = async () => {
  // Load tag commander
  // HKEMMA-100917 - GA3 Decommission - Remove TC Scripts
  // try {
  //   await Promise.all([
  //     loadScriptToTag(env.TAGCOMMANDER_HEADER_SCRIPT, 'head'),
  //     loadScriptToTag(env.TAGCOMMANDER_BODY_SCRIPT, 'body'),
  //   ]);
  //   const win: any = window;
  //   if (win.tc_vars) {
  //     win.tc_vars.work_env = env.GA_ENV;
  //     const token = select(getToken);
  //     const atoken = getValidToken(token);
  //     win.tc_vars.AXAID = atoken;
  //   } else {
  //     console.warn('tc_vars not found.');
  //   }
  // } catch (error) {
  //   console.warn('Error occured when load tag commnader script', error);
  // }
  /**
   * [HKEMMA-102819]
   * Because of CSP Nonce, disable this line of code for AB Testing
   * AB Testing is not in use and only did a PoC long time ago
   */
  // const GTMenvironment: any = select(getGTMEnvironmentConfig);
  /**
   * [HKEMMA-102819] Moved to index.html to load the script with nonce
   */
  // Load tag manager
  // const tagManagerArgs = {
  //   gtmId: env.GOOGLE_TAG_MANAGER_ID,
  //   ...GTMenvironment,
  // };
  // TagManager.initialize(tagManagerArgs);
};

export const initGaClickableElements = (): Promise<void> => {
  const emmaGa = new (EmmaGaListener as any)();
  emmaGa.serve([gaClickableElementCallback]);
  return Promise.resolve();
};

export const initLogPreviousUrl = (): Promise<void> => {
  const platformStr = getPlatformStr();
  const screenNameMapping = select(getScreenNameMapping);
  logPreviousUrl(
    logPreviousUrlCallback,
    history,
    platformStr,
    screenNameMapping,
  );

  // GA4 - Trigger initial page view
  const maskedCurrScreenPath = maskPN(
    window.location.pathname.replace('/mob', ''),
  );
  getScreenNameAsync(maskedCurrScreenPath, platformStr, screenNameMapping).then(
    (currScreenName: string) => {
      const maskedCurrScreenName = maskPN(currScreenName);
      if (isApp()) {
        EmmaFactory.createInstance().then((emma) => {
          emma.instance.getPreviousScreen().then((prevPathName) => {
            trackDefaultPageViewGA4(
              maskedCurrScreenName,
              prevPathName,
              maskedCurrScreenPath,
            );
          });
        });
      } else {
        trackDefaultPageViewGA4(maskedCurrScreenName, '', maskedCurrScreenPath);
      }
    },
  );
  return Promise.resolve();
};

export const initCookies = (): Promise<void> => {
  const options = {
    domain: 'axa.com.hk',
    path: '/; sameSite=Lax;',
    secure: true,
  };
  const token = select(getToken);
  const axaId = getValidToken(token);

  if (axaId) {
    const corpWebCookieName = select(getCorpWebCookieName);
    Cookies.set(corpWebCookieName, axaId, options);
    setEmmaHeaders({ AXAID: axaId });
  }
  return Promise.resolve();
};

function startFetching(
  featureUrl: string,
  fetchSuccess: any,
  fetchFailure: any,
) {
  const token = select(getToken);
  if (!token || !featureUrl) {
    return Promise.resolve();
  } else {
    const url = `${featureUrl}?device=WEB`;
    return myAxaFetchWithToken(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-axahk-msgid': uuidv4(),
      },
    })
      .then((res) => res.json())
      .then(fetchSuccess)
      .catch(fetchFailure);
  }
}

/** Initial lang attribute on html tag */
export function initLang() {
  const lang = select(getLanguage);
  document.documentElement.lang = lang;
}

export function initCssHack() {
  if (isIosSafariBrowser()) {
    /*
     * HKEMMA-72970 - iOS Safari browser has a bottom panel.
     * It causes scrolling on payment TNC not working well.
     * Minor 120px from the 100vh to remove height of the bottom panel.
     */
    document.body.style.minHeight = 'calc(100vh - 80px)';
  }
}

export async function initNewSession() {
  try {
    const url = new URL(window.location.href);
    const code = url.searchParams.get(`code`);
    const scopeParam = url.searchParams.get(`state`);
    if (!code || !scopeParam) {
      window.sessionStorage.removeItem('source');
      return;
    }
    const corpWebCookieName = select(getCorpWebCookieName);
    const oldAxaId = Cookies.get(corpWebCookieName);
    if (!oldAxaId) {
      window.sessionStorage.setItem('source', 'ADB2C');
    }
  } catch (err) {
    console.error(err);
  }
}
