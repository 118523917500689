import _ from 'lodash';
import { EmmaFactory } from '@axa-asia/hk-emma-js';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getPolicyNumberSuccess,
  GET_POLICY_NUMBER_START,
} from '../actions/policyNumberActions';
import { PN_EMPTY } from '../hoc/policyNumber/withPNHoc';
import { fetchPolicyListFeatures } from '../../src/modules/policy-list/saga/policylistSaga';
import { FETCH_PROLICY_LIST } from '../../src/modules/policy-list/actions';

export function getPolicyNumber(): Promise<string> {
  return new Promise((resolve, reject) => {
    EmmaFactory.createInstance()
      .then((e) => {
        e.instance
          .getPolicyNumber()
          .then((res: any) => {
            const policyNumber =
              (res && res.onlyPolicyNumber) || (res && res.policyNumber) || '';
            resolve(policyNumber);
          })
          .catch((error) => {
            console.error(error);
            resolve(PN_EMPTY);
          });
      })
      .catch((error) => {
        console.error(error);
        resolve(PN_EMPTY);
      });
  });
}

function* getPolicyNumberStart(): any {
  const policyNumber = yield call(getPolicyNumber);
  yield put(getPolicyNumberSuccess(policyNumber));
}

function* policyNumberSaga() {
  yield takeLatest(GET_POLICY_NUMBER_START, getPolicyNumberStart);
  yield takeLatest(FETCH_PROLICY_LIST, fetchPolicyListFeatures);
}

export default policyNumberSaga;
