import _ from 'lodash';
import {
  HaveReadNewFeatureCustomApiConfig,
  HaveReadNewFeatureCustomApiRes,
} from '../types/sideBarItemTypes';
import { getDefaultRedDotMaxCount } from './configSelectors';

export const getHaveReadNewFeatureCustomApiConfig =
  (state: {}): HaveReadNewFeatureCustomApiConfig => {
    return _.get(
      state,
      `hk-emma-web-core/app.moduleConfig.emma-home-revamp.customApi.haveReadNewFeature`,
    );
  };

/* Have Read New Feature */
export const getHaveReadNewFeatures =
  (state: {}): HaveReadNewFeatureCustomApiRes => {
    return _.get(state, `hk-emma-web-core/sidebaritem.haveReadNewFeatures`);
  };

export const getMyPrivilegesOverlay = (state: {}): object => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-my-privileges.overlay`,
    {},
  );
};

export const getBetterMeRedDotMaxCount = (state: {}): number => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-my-privileges.overlay.redDotMaxCount`,
    getDefaultRedDotMaxCount(state),
  );
};

export const getMyPrivilegesRedDotMaxCount = (state: {}): number => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-my-privileges.ui.bottomTab.redDotMaxCount`,
    getDefaultRedDotMaxCount(state),
  );
};

export const getIsShowRedDot = (state: {}): boolean => {
  return _.get(state, `hk-emma-web-core/sidebaritem.isShowRedDot`, false);
};
