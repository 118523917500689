export const AKTIVO_HEALTH_SURVEY_LBS = 'lbs';
export const AKTIVO_HEALTH_SURVEY_KG = 'kg';
export const AKTIVO_HEALTH_SURVEY_CANVAS_DEFAULT_WIDTH = 150;
export const AKTIVO_HEALTH_SURVEY_CANVAS_DEFAULT_HEIGHT = 300;
export const AKTIVO_HEALTH_SURVEY_LBS_MAX_VALUE = 615;
export const AKTIVO_HEALTH_SURVEY_KG_MAX_VALUE = 279;
export const AKTIVO_HEALTH_SURVEY_TALL_MAX_VALUE = 250;
export const AKTIVO_HEALTH_SURVEY_GENDER_MALE = 'Male';
export const AKTIVO_HEALTH_SURVEY_GENDER_FEMALE = 'Female';
export const AKTIVO_HEALTH_SURVEY_GENDER_DEFAULT_VALUE =
  AKTIVO_HEALTH_SURVEY_GENDER_MALE;
export const AKTIVO_HEALTH_SURVEY_DOB_DEFAULT_VALUE = '2000-01-01';
export const AKTIVO_HEALTH_SURVEY_LBS_DEFAULT_VALUE = '160';
export const AKTIVO_HEALTH_SURVEY_KG_DEFAULT_VALUE = '72.6';
export const AKTIVO_HEALTH_SURVEY_TALL_DEFAULT_VALUE = '170';
export const AKTIVO_HEALTH_SURVEY_BED_TIME_DEFAULT_VALUE = '23:00';
export const AKTIVO_HEALTH_SURVEY_WAKE_UP_TIME_DEFAULT_VALUE = '07:00';
export const AKTIVO_HEALTH_SURVEY_SMOKE_DEFAULT_VALUE = '0';
export const AKTIVO_HEALTH_SURVEY_DRINK_DEFAULT_VALUE = '0';
export const AKTIVO_HEALTH_SURVEY_HOME_TYPE = 'home';
export const AKTIVO_HEALTH_SURVEY_GENDER_TYPE = 'gender';
export const AKTIVO_HEALTH_SURVEY_DOB_TYPE = 'dob';
export const AKTIVO_HEALTH_SURVEY_TALL_TYPE = 'tall';
export const AKTIVO_HEALTH_SURVEY_WEIGHT_TYPE = 'weight';
export const AKTIVO_HEALTH_SURVEY_SLEEPING_TYPE = 'sleeping';
export const AKTIVO_HEALTH_SURVEY_SMOKE_TYPE = 'smoke';
export const AKTIVO_HEALTH_SURVEY_DRINK_TYPE = 'drink';
export const AKTIVO_HEALTH_SURVEY_END_TYPE = 'end';
export const AKTIVO_HEALTH_SURVEY_LBS_TO_KG = 0.4535924;
export const AKTIVO_HEALTH_SURVEY_KG_TO_LBS = 2.2046226;
export const AKTIVO_HEALTH_SURVEY_ENTRY_ON_BOARDING = 'onboarding';
export const AKTIVO_HEALTH_SURVEY_CIP_ERROR_CODE =
  'AKTIVO-HEALTH-SURVEY-EXP-E01';
export const AKTIVO_HEALTH_SURVEY_AKTIVO_ERROR_CODE =
  'AKTIVO-HEALTH-SURVEY-EXP-A01';
