import { singletonState } from "./singleton-hooks";

export interface CustomerLogin {
  axaId: string;
  loginId: string;
  profile: Profile[];
  lobs: string[];
  features: string[];
  insuredPopUpFlag: "Y" | "N";
  policies: Policy[];
}

export interface Policy {
  policyNumber: string;
  entityCode: string;
  isWiseGuardProPolicyFlag?: "Y" | "N";
  isSilverHairPolicyFlag?: "Y" | "N";
  planCode: string;
  policyCategory: string;
  policyType: string;
  betterMeFlag?: "Y" | "N";
  wellnessFlag?: "Y" | "N";
  betterMeWellnessFlag?: "Y" | "N";
  insuredAccessFlag?: "Y" | "N";
  partyRoleCd: string;
  isOwnIsuredFlag?: "Y" | "N";
  isInsuredFlag?: "Y" | "N";
  policyStatCd: string;
  axaGoalEnrollFlag?: "Y" | "N";
  ecommEligibleFlag?: "Y" | "N";
  isLapsedPolicy?: "Y" | "N";
}

export interface Profile {
  mdmId: string;
  gender: string;
  englishName: string;
  dateOfBirth: string;
  mobileCountryCode?: string;
  email: string[];
  mobileNo: string[];
}

const payload = singletonState<CustomerLogin>(undefined, "useCustomerLogin");

/**
 * Access goto function from any modules with hooks
 * @category Hooks
 * @example
const data = useCustomerLogin();
if (!data) return null;
**/
export const useCustomerLogin = payload.useGlobalState;

/**
 * Bridge shared state between application and modules
 * @category Setters
 * @example
setCustomerLogin((string) => router.push(string));
**/
export const setCustomerLogin = payload.setGlobalState;
