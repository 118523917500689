import {
  FETCH_PROLICY_LIST_FEATURES_FAILURE,
  FETCH_PROLICY_LIST_SUCCESS,
  RESET_PROLICY_LIST,
} from '../actions';

const defaultState = {
  policyList: [],
  isLoading: true,
  isError: false,
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_PROLICY_LIST_SUCCESS:
      nextState.policyList = action.policies;
      nextState.isLoading = false;
      nextState.isError = false;
      return nextState;
    case FETCH_PROLICY_LIST_FEATURES_FAILURE:
      nextState.policyList = action.policies;
      nextState.isLoading = false;
      nextState.isError = true;
      return nextState;
    case RESET_PROLICY_LIST:
      nextState.policyList = undefined;
      nextState.isLoading = true;
      nextState.isError = false;
      return nextState;
    default:
      return nextState;
  }
};
