import config from '../package.json';
const ID = `${config.name}/actions/changeMobileAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const UPDATE_PROFILE_MOBILE = createActionName('UPDATE_PROFILE_MOBILE');
export const UPDATE_SELECTED_LIST = createActionName('UPDATE_SELECTED_LIST');
export const UPDATE_FATCA_FLAG = createActionName('UPDATE_FATCA_FLAG');
export const UPDATE_CRS_FLAG = createActionName('UPDATE_CRS_FLAG');
export const RESET_CHANGE_MOBILE_FORM = createActionName(
  'RESET_CHANGE_MOBILE_FORM',
);
export const REVIEW_CHANGE_MOBILE_FORM = createActionName(
  'REVIEW_CHANGE_MOBILE_FORM',
);

export const UPDATE_MOBILE_CUST_STATE = createActionName(
  'UPDATE_MOBILE_CUST_STATE',
);
export const UPDATE_MOBILE_CUST_STATE_SUCCESS = createActionName(
  'UPDATE_MOBILE_CUST_STATE_SUCCESS',
);
export const UPDATE_MOBILE_CUST_STATE_FAILURE = createActionName(
  'UPDATE_MOBILE_CUST_STATE_FAILURE',
);

export const SUBMIT_CHANGE_MOBILE_REQUEST = createActionName(
  'SUBMIT_CHANGE_MOBILE_REQUEST',
);
export const SUBMIT_CHANGE_MOBILE_REQUEST_SUCCESS = createActionName(
  'SUBMIT_CHANGE_MOBILE_REQUEST_SUCCESS',
);
export const SUBMIT_CHANGE_MOBILE_REQUEST_FAIL = createActionName(
  'SUBMIT_CHANGE_MOBILE_REQUEST_FAIL',
);

export const SUBMIT_CHANGE_MOBILE_FORM = createActionName(
  'SUBMIT_CHANGE_MOBILE_FORM',
);
export const SUBMIT_CHANGE_MOBILE_FORM_SUCCESS = createActionName(
  'SUBMIT_CHANGE_MOBILE_FORM_SUCCESS',
);
export const SUBMIT_CHANGE_MOBILE_FORM_FAILURE = createActionName(
  'SUBMIT_CHANGE_MOBILE_FORM_FAILURE',
);

export const FETCH_MOBILE_OTHER_POLICY_LIST = createActionName(
  'FETCH_MOBILE_OTHER_POLICY_LIST',
);
export const FETCH_MOBILE_OTHER_POLICY_LIST_SUCCESS = createActionName(
  'FETCH_MOBILE_OTHER_POLICY_LIST_SUCCESS',
);
export const FETCH_MOBILE_OTHER_POLICY_LIST_FAIL = createActionName(
  'FETCH_MOBILE_OTHER_POLICY_LIST_FAIL',
);
export const UPDATE_MOBILE_OTHER_POLICIES = createActionName(
  'UPDATE_MOBILE_OTHER_POLICIES',
);
export const UPDATE_MOBILE_GOGREEN_LIST = createActionName(
  'UPDATE_MOBILE_GOGREEN_LIST',
);

export const SEND_MOBILE_OTP_REQUEST = createActionName(
  'SEND_MOBILE_OTP_REQUEST',
);
export const SEND_MOBILE_OTP_REQUEST_SUCCESS = createActionName(
  'SEND_MOBILE_OTP_REQUEST_SUCCESS',
);
export const SEND_MOBILE_OTP_REQUEST_FAIL = createActionName(
  'SEND_MOBILE_OTP_REQUEST_FAIL',
);

export const RESEND_MOBILE_OTP = createActionName('RESEND_MOBILE_OTP');
export const RESEND_MOBILE_OTP_SUCCESS = createActionName(
  'RESEND_MOBILE_OTP_SUCCESS',
);
export const RESEND_MOBILE_OTP_FAIL = createActionName(
  'RESEND_MOBILE_OTP_FAIL',
);

export const updateMobileOtherPolices = () => ({
  type: UPDATE_MOBILE_OTHER_POLICIES,
});

export const fetchMobileOtherPolicyList = () => ({
  type: FETCH_MOBILE_OTHER_POLICY_LIST,
});

export const UPDATE_MOBILE_FORM_STATES = createActionName(
  'UPDATE_MOBILE_FORM_STATES',
);

export const updateNewMobile = (newMobile: string, countryCode: string) => ({
  type: UPDATE_PROFILE_MOBILE,
  newMobile,
  countryCode,
});

export const updateSelectedList = (selectedList: any[]) => ({
  type: UPDATE_SELECTED_LIST,
  selectedList,
});

export const updateFatcaFlag = (value: boolean) => ({
  type: UPDATE_FATCA_FLAG,
  value,
});

export const updateCrsFlag = (value: boolean) => ({
  type: UPDATE_CRS_FLAG,
  value,
});

export const updateMobileCustState = (payload: any) => ({
  type: UPDATE_MOBILE_CUST_STATE,
  payload,
});

export const resetChangeMobileForm = () => ({
  type: RESET_CHANGE_MOBILE_FORM,
});

export const reviewChangeMobileForm = () => ({
  type: REVIEW_CHANGE_MOBILE_FORM,
});

export const submitChangeMobileForm = ({
  otp,
  defaultErrorCode,
  featureId,
}: {
  otp: string;
  defaultErrorCode: string;
  featureId: string;
}) => ({
  type: SUBMIT_CHANGE_MOBILE_FORM,
  payload: {
    otp,
    defaultErrorCode,
    featureId,
  },
});

export const sendChangeMobileOtpRequest = () => ({
  type: SEND_MOBILE_OTP_REQUEST,
});

export const resendMobileOtp = () => ({
  type: RESEND_MOBILE_OTP,
});

export const updateFormStates = (payload: any) => ({
  type: UPDATE_MOBILE_FORM_STATES,
  payload,
});

export const updateGoGreenList = (payload: any) => ({
  type: UPDATE_MOBILE_GOGREEN_LIST,
  payload,
});
