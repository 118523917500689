import _ from 'lodash';
import { useState, useCallback, useEffect, useMemo } from 'react';

/* Usage Example
 * const { page, setPage, pagedStatus, setPagedStatus, dataCount } =
 *   useInfiniteScroll({
 *     dataCount,
 *     pageSize: PAGE_SIZE,
 *   });
 *
 * const _useDoctor = useCallback(
 *   (page) => useDoctors(props, selectedOptions, page, setPagedStatus),
 *   [props, selectedOptions, setPagedStatus],
 * );
 *
 * <PagedList
 *   page={page}
 *   baseOpt={props}
 *   key={page}
 *   _useDoctor={_useDoctor}
 * />
 */

type UseInfiniteScrollProps = {
  dataCount: number;
  pageSize: number;
};

export const useInfiniteScroll = ({
  pageSize,
  dataCount,
}: UseInfiniteScrollProps) => {
  const [page, setPage] = useState<number>(1);
  const [pagedStatus, setPagedStatus] = useState<
    Record<string, { loading: boolean; error: string | Error | undefined }>
  >({});

  const hasMore = useMemo(() => {
    if (!dataCount) {
      return false;
    }
    return page + 1 <= Math.ceil(Number(dataCount || 0) / pageSize);
  }, [dataCount, page]);

  const onScroll = useCallback(() => {
    const scrollTop = Math.floor(document.documentElement.scrollTop);
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (
      scrollTop + clientHeight >= scrollHeight - 120 &&
      hasMore &&
      !pagedStatus[page]?.loading
    ) {
      setPage(page + 1);
    }
  }, [page, hasMore, pagedStatus, setPage]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  const resetPage = useCallback(() => {
    setPage(0);
    setPagedStatus({});
    setPage(1);
  }, [setPage, setPagedStatus]);

  return useMemo(
    () => ({
      page,
      pagedStatus,
      dataCount,
      setPage,
      setPagedStatus,
      resetPage,
    }),
    [page, setPage, setPagedStatus, pagedStatus, resetPage],
  );
};

const isFloat = (num: number) => {
  return Number(num) === num && num % 1 !== 0;
};

export const sliceVirtualPage = <T>({
  data,
  page,
  virtualPageSize,
  pageSize,
}: {
  data: T[] | undefined;
  page: number;
  virtualPageSize: number;
  pageSize: number;
}) => {
  const startIndex = isFloat(page / 2) ? 0 : virtualPageSize;
  const endIndex = isFloat(page / 2) ? virtualPageSize : pageSize;
  return data?.slice(startIndex, endIndex);
};
