import {
  FETCH_POLICY_CARDS_SUCCESS,
  SAVE_SELECTED_POLICY,
  FETCH_GO_GREEN_POLICY_LIST_SUCCESS,
  UPDATE_CHANGE_CONTACT_LOADING,
  UPDATE_VERIFY_LOADING,
  DISPLAY_ERROR_CODE,
  INIT_VERIFY_ERROR_CODE,
} from '../actions/changeContactAction';

const defaultState = {
  isLoading: false,
  isVerifyLoading: false,
  policyCards: [],
  selectedPolicy: {},
  goGreenPolicyList: [],
  errorCode: '',
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_CHANGE_CONTACT_LOADING:
      nextState.isLoading = action.isLoading || false;
      return nextState;
    case UPDATE_VERIFY_LOADING:
      nextState.isVerifyLoading = action.isVerifyLoading || false;
      return nextState;
    case FETCH_POLICY_CARDS_SUCCESS:
      nextState.policyCards = action.policyCards || [];
      return nextState;
    case SAVE_SELECTED_POLICY:
      nextState.selectedPolicy = action.policy || {};
      return nextState;
    case FETCH_GO_GREEN_POLICY_LIST_SUCCESS:
      nextState.goGreenPolicyList = action.goGreenPolicyList || [];
      return nextState;
    case DISPLAY_ERROR_CODE:
      nextState.errorCode = action.errorCode;
      return nextState;
    case INIT_VERIFY_ERROR_CODE:
      nextState.errorCode = '';
      return nextState;
    default:
      return nextState;
  }
};
