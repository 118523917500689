import config from '../package.json';
const ID = `${config.name}/actions/customerResetUNOrPW`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const CHANGE_CUR_UPDATE_KEY = createActionName('CHANGE_CUR_UPDATE_KEY');

export const changeCurUpdateKey = (curUpdateKey: string) => ({
  type: CHANGE_CUR_UPDATE_KEY,
  curUpdateKey,
});
