import $url from 'url';
import { getTabId } from './TabUtil';

/** Keys of policy number in query string of deeplink */
export const policyNumberKeys = ['policy_number', 'policyNumber'];

/**
 * Find policy number from deeplink
 * @param appDeepLink
 * @returns
 */
export function getPolicyNumber(appDeepLink: string) {
  const url = $url.parse(appDeepLink, true);

  const policyNumbers = policyNumberKeys
    .map((key) => {
      const val = url.query[key] as string;
      return val ? val : '';
    })
    .filter((val) => val);

  if (policyNumbers.length > 0) {
    return policyNumbers[0];
  }
  return '';
}

export function getPnStateKey() {
  const tabId = getTabId();
  return `policyNumber_${tabId}`;
}
