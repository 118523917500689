import config from '../package.json';
const ID = `${config.name}/actions/changeEmailAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const RESET_CHANGE_EMAIL_FORM = createActionName(
  'RESET_CHANGE_EMAIL_FORM',
);
export const UPDATE_PROFILE_EMAIL = createActionName('UPDATE_PROFILE_EMAIL');
export const UPDATE_SELECTED_LIST = createActionName('UPDATE_SELECTED_LIST');
export const SUBMIT_CHANGE_EMAIL_FORM = createActionName(
  'SUBMIT_CHANGE_EMAIL_FORM',
);
export const SUBMIT_CHANGE_EMAIL_FORM_SUCCESS = createActionName(
  'SUBMIT_CHANGE_EMAIL_FORM_SUCCESS',
);
export const SUBMIT_CHANGE_EMAIL_FORM_FAIL = createActionName(
  'SUBMIT_CHANGE_EMAIL_FORM_FAIL',
);
export const REVIEW_CHANGE_EMAIL_FORM = createActionName(
  'REVIEW_CHANGE_EMAIL_FORM',
);
export const SUBMIT_CHANGE_EMAIL_REQUEST = createActionName(
  'SUBMIT_CHANGE_EMAIL_REQUEST',
);
export const SUBMIT_CHANGE_EMAIL_REQUEST_SUCCESS = createActionName(
  'SUBMIT_CHANGE_EMAIL_REQUEST_SUCCESS',
);
export const UPDATE_EMAIL_CUST_STATE = createActionName(
  'UPDATE_EMAIL_CUST_STATE',
);
export const UPDATE_EMAIL_CUST_STATE_SUCCESS = createActionName(
  'UPDATE_EMAIL_CUST_STATE_SUCCESS',
);
export const UPDATE_EMAIL_CUST_STATE_FAILURE = createActionName(
  'UPDATE_EMAIL_CUST_STATE_FAILURE',
);
export const UPDATE_EMAIL_FORM_STATES = createActionName(
  'UPDATE_EMAIL_FORM_STATES',
);
export const FETCH_EMAIL_OTHER_POLICY_LIST = createActionName(
  'FETCH_EMAIL_OTHER_POLICY_LIST',
);
export const FETCH_EMAIL_OTHER_POLICY_LIST_SUCCESS = createActionName(
  'FETCH_EMAIL_OTHER_POLICY_LIST_SUCCESS',
);
export const FETCH_EMAIL_OTHER_POLICY_LIST_FAIL = createActionName(
  'FETCH_EMAIL_OTHER_POLICY_LIST_FAIL',
);
export const UPDATE_EMAIL_OTHER_POLICIES = createActionName(
  'UPDATE_EMAIL_OTHER_POLICIES',
);
export const UPDATE_EMAIL_GOGREEN_LIST = createActionName(
  'UPDATE_EMAIL_GOGREEN_LIST',
);

export const SEND_EMAIL_OTP_REQUEST = createActionName(
  'SEND_EMAIL_OTP_REQUEST',
);
export const SEND_EMAIL_OTP_REQUEST_SUCCESS = createActionName(
  'SEND_EMAIL_OTP_REQUEST_SUCCESS',
);
export const SEND_EMAIL_OTP_REQUEST_FAIL = createActionName(
  'SEND_EMAIL_OTP_REQUEST_FAIL',
);

export const RESEND_EMAIL_OTP = createActionName('RESEND_EMAIL_OTP');
export const RESEND_EMAIL_OTP_SUCCESS = createActionName(
  'RESEND_EMAIL_OTP_SUCCESS',
);
export const RESEND_EMAIL_OTP_FAIL = createActionName('RESEND_EMAIL_OTP_FAIL');

export const updateEmailOtherPolices = () => ({
  type: UPDATE_EMAIL_OTHER_POLICIES,
});

export const fetchEmailOtherPolicyList = () => ({
  type: FETCH_EMAIL_OTHER_POLICY_LIST,
});

export const updateNewEmail = (newEmail: string) => ({
  type: UPDATE_PROFILE_EMAIL,
  newEmail,
});

export const updateSelectedList = (selectedList: any[]) => ({
  type: UPDATE_SELECTED_LIST,
  selectedList,
});

export const resetChangeEmailForm = () => ({
  type: RESET_CHANGE_EMAIL_FORM,
});

export const submitChangeEmailForm = ({
  otp,
  defaultErrorCode,
  featureId,
}: {
  otp: string;
  defaultErrorCode: string;
  featureId: string;
}) => ({
  type: SUBMIT_CHANGE_EMAIL_FORM,
  payload: {
    otp,
    defaultErrorCode,
    featureId,
  },
});

export const updateEmailCustState = (payload: any) => ({
  type: UPDATE_EMAIL_CUST_STATE,
  payload,
});

export const reviewChangeEmailForm = () => ({
  type: REVIEW_CHANGE_EMAIL_FORM,
});

export const sendChangeEmailOtpRequest = () => ({
  type: SEND_EMAIL_OTP_REQUEST,
});

export const resendEmailOtp = () => ({
  type: RESEND_EMAIL_OTP,
});

export const updateFormStates = (payload: any) => ({
  type: UPDATE_EMAIL_FORM_STATES,
  payload,
});

export const updateGoGreenList = (payload: any) => ({
  type: UPDATE_EMAIL_GOGREEN_LIST,
  payload,
});
