// @flow

export const BANNER_ROUTING = 'BANNER_ROUTING';
export const BANNER_FUNNEL_TOKEN = 'BANNER_FUNNEL_TOKEN';
export const BANNER_INTERMEDIARY_PAGE = 'BANNER_INTERMEDIARY_PAGE';
export const BANNER_OPEN_URL = 'BANNER_OPEN_URL';

export const startBannerRouting = (query: any) => {
  return {
    type: BANNER_ROUTING,
    payload: {
      query,
    },
  };
};
