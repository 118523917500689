import { combineReducers, Reducer, Action } from 'redux';

export interface ReducerInfo {
  reducer: (state: any, action: any) => any;
  persist?: boolean;
}

export interface ModuleReducerInfo extends ReducerInfo {
  moduleName: string;
  reducerName: string;
}

export class ReducerRegistry {
  private reducers: {
    [moduleName: string]: { [reducerName: string]: ModuleReducerInfo };
  } = {};
  private getReducerName(moduleName: string, reducerName: string) {
    // Do no add hk-emma-web-core/ prefix for monorepos reducers
    if (reducerName.startsWith('@axa-asia/')) return reducerName;
    return `${moduleName}/${reducerName}`;
  }
  register(moduleName: string, reducerName: string, reducer: ReducerInfo) {
    if (!(moduleName in this.reducers)) {
      this.reducers[moduleName] = {};
    }

    // If already exists
    if (this.reducers[moduleName][reducerName]) {
      return false;
    }

    this.reducers[moduleName][reducerName] = {
      moduleName,
      reducerName,
      ...reducer,
    };

    return true;
  }

  getReducers() {
    const result: { [reducerName: string]: Reducer } = {};
    Object.keys(this.reducers).forEach((moduleName) => {
      Object.keys(this.reducers[moduleName]).forEach((name) => {
        result[this.getReducerName(moduleName, name)] =
          this.reducers[moduleName][name].reducer;
      });
    });
    return result;
  }

  getPersitWhiteList() {
    const result: Array<string> = [];
    Object.keys(this.reducers).forEach((moduleName) => {
      Object.keys(this.reducers[moduleName]).forEach((reducerName) => {
        if (this.reducers[moduleName][reducerName].persist) {
          result.push(this.getReducerName(moduleName, reducerName));
        }
      });
    });
    return result;
  }
}

export default new ReducerRegistry();
