import config from '../package.json';
const ID = `${config.name}/actions/aktivo-health-survey`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const UPDATE_HEALTH_SURVEY_DATA = createActionName(
  'UPDATE_HEALTH_SURVEY_DATA',
);
export const SAVE_UPDATE_HEALTH_SURVEY_DATA = createActionName(
  'SAVE_UPDATE_HEALTH_SURVEY_DATA',
);
export const SAVE_ACCESS_TOKEN = createActionName('SAVE_ACCESS_TOKEN');
export const UPDATE_BUTTON_LOADING = createActionName('UPDATE_BUTTON_LOADING');
export const UPDATE_PAGE_LOADING = createActionName('UPDATE_PAGE_LOADING');
export const GET_HEALTH_SURVEY_USER_DATA = createActionName(
  'GET_HEALTH_SURVEY_USER_DATA',
);
export const UPDATE_ENTRY = createActionName('UPDATE_ENTRY');

export const getHealthSurveyData = (payload: any = {}) => ({
  type: GET_HEALTH_SURVEY_USER_DATA,
  payload,
});

export const updateHealthSurveyData = (payload: any = {}) => ({
  type: UPDATE_HEALTH_SURVEY_DATA,
  payload,
});

export const saveUpdateHealthSurveyData = (payload: any = {}) => ({
  type: SAVE_UPDATE_HEALTH_SURVEY_DATA,
  payload,
});
