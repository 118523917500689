import {
  FETCH_INSURED_POLICY_LIST,
  FETCH_INSURED_POLICY_LIST_SUCCESS,
  FETCH_INSURED_POLICY_LIST_FAILURE,
  UPDATE_INSURED_ACCESS,
  UPDATE_INSURED_ACCESS_SUCCESS,
  UPDATE_INSURED_ACCESS_FAILURE,
  RESET_INSURED_ACCESS_PAGE,
  FETCH_INSURED_POLICY_DETAIL,
  FETCH_INSURED_POLICY_DETAIL_SUCCESS,
  FETCH_INSURED_POLICY_DETAIL_FAILURE,
  FETCH_INSURED_ACCESS_LAST_UPDATED_SUCCESS,
} from '../actions/insuredAccessAction';
import _ from 'lodash';

const defaultState = {
  isLoading: false,
  accessIsLoading: false,
  policyList: [],
  accessCustState: {},
  deactivationCustState: {},
  error: false,
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_INSURED_POLICY_LIST:
      return {
        ...nextState,
        isLoading: _.isEmpty(state.policyList),
      };
    case FETCH_INSURED_POLICY_LIST_SUCCESS:
      return {
        ...nextState,
        isLoading: false,
        policyList:
          nextState.policyList.length > 0
            ? nextState.policyList.map((item: any) => {
                const { policy = {} } = item;
                const newPolicy = _.find(action.policyList, [
                  'policy.policyNumber',
                  policy.policyNumber,
                ]);

                return newPolicy
                  ? {
                      ...newPolicy,
                      policyDetail: item.policyDetail,
                    }
                  : item;
              })
            : action.policyList,
      };
    case FETCH_INSURED_POLICY_LIST_FAILURE:
      return {
        ...nextState,
        isLoading: false,
      };
    case UPDATE_INSURED_ACCESS:
      return {
        ...nextState,
        accessIsLoading: true,
      };
    case UPDATE_INSURED_ACCESS_SUCCESS:
      return {
        ...nextState,
        accessIsLoading: false,
        [action.insuredAccessFlag === 'Y'
          ? 'accessCustState'
          : 'deactivationCustState']: action.accessCustState,
      };
    case UPDATE_INSURED_ACCESS_FAILURE:
      return {
        ...nextState,
        accessIsLoading: false,
        error: action.error,
      };
    case RESET_INSURED_ACCESS_PAGE:
      return {
        ...nextState,
        isLoading: false,
        accessIsLoading: false,
        policyList: [],
        error: false,
      };
    case FETCH_INSURED_POLICY_DETAIL:
      return {
        ...nextState,
        policyList: nextState.policyList.map((item: any) => {
          const { policy = {} } = item;
          if (policy.policyNumber === action.policyNumber) {
            return {
              ...item,
              isLoading: true,
            };
          }

          return item;
        }),
      };
    case FETCH_INSURED_POLICY_DETAIL_SUCCESS:
      return {
        ...nextState,
        policyList: nextState.policyList.map((item: any) => {
          const { policy = {} } = item;
          if (policy.policyNumber === action.policyNumber) {
            return {
              ...item,
              isLoading: false,
              policyDetail: action.policy,
            };
          }

          return item;
        }),
      };
    case FETCH_INSURED_POLICY_DETAIL_FAILURE:
      return {
        ...nextState,
        policyList: nextState.policyList.map((item: any) => {
          const { policy = {} } = item;
          if (policy.policyNumber === action.policyNumber) {
            return {
              ...item,
              isLoading: false,
              error: action.error,
            };
          }

          return item;
        }),
      };
    case FETCH_INSURED_ACCESS_LAST_UPDATED_SUCCESS:
      return {
        ...nextState,
        [action.destination]: action.accessCustState,
      };
    default:
      return nextState;
  }
};
