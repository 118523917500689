import React, { Component } from 'react';
import { connect } from 'react-redux';
import FlexiBanner from './FlexiBanner';
import LoadingView from '../LoadingView';
import EmmaErrorModal from '../EmmaErrorModal';
import {
  getIsLoading,
  getIsShowBanner,
  getBannerLabel,
} from '../../modules/eb-flexi/selectors/ebFlexiSelectors';
import {
  openEbFlexiModel,
  fetchEbFlexiData,
  updateClickFromBanner,
} from '../../modules/eb-flexi/actions/ebFlexiActions';
import { EB_FELXI_FROM } from '../../constants/constants';
import { resolveLocalizedText, getValidToken, isApp } from '../../utils';
import { getToken } from '../../selectors/authSelectors';
import { select } from '../../stores';
import {
  getGaPagePath,
  handleGaError,
} from '../../utils/generalGaEventAnalytics';
import { getLanguage } from '../../selectors/userSelectors';
import './styles.scss';

interface EBFlexiBannerViewProps {
  type?: string;
  isLoading: boolean;
  isShowBanner: boolean;
  bannerLabel: any;
  onOpenEbFlexiModel: () => void;
  onUpdateClickFromBanner: (isClickFromBaner: boolean) => void;
  onFetchEbFlexiData: (payload: any) => void;
}

interface EBFlexiBannerViewState {}

export class EBFlexiBannerView extends Component<
  EBFlexiBannerViewProps,
  EBFlexiBannerViewState
> {
  static defaultProps = {
    type: '',
  };

  constructor(props: EBFlexiBannerViewProps) {
    super(props);
    this.state = {};
    this.goToOpenEbFlexiModel = this.goToOpenEbFlexiModel.bind(this);
  }

  goToOpenEbFlexiModel() {
    const {
      onUpdateClickFromBanner,
      onOpenEbFlexiModel,
      bannerLabel: { content },
    } = this.props;
    const tcWindow: any = window;
    const token = select(getToken);
    const aToken = getValidToken(token);
    onOpenEbFlexiModel();
    onUpdateClickFromBanner(true);
    // ga KPI #55
    try {
      const bannerTitle = content[getLanguage()];

      const tcWindow: any = window;
      tcWindow.dataLayer.push({
        event: 'clickBanner',
        product: 'EB',
        bannerAction: 'click',
        bannerPlanName: 'EB',
        bannerTitle: bannerTitle,
        screenName: getGaPagePath(),
        userId: aToken,
      });

      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // if (!isApp()) {
      //   tcWindow.tC.event.clickBanner(tcWindow, {
      //     product: 'EB',
      //     bannerAction: 'click',
      //     bannerPlanName: 'EB',
      //     bannerTitle: bannerTitle,
      //     screenName: getGaPagePath(),
      //     AXAID: aToken,
      //   });
      // }
    } catch (e) {
      handleGaError(e);
    }
  }

  componentDidMount() {
    const { onFetchEbFlexiData, type } = this.props;
    if (type === EB_FELXI_FROM) {
      onFetchEbFlexiData({});
    }
  }

  render() {
    const { type, isLoading, isShowBanner, bannerLabel } = this.props;
    const { content } = bannerLabel;
    const ebFlexiclassName =
      type === EB_FELXI_FROM ? 'eb-flexi-banner-eb' : 'eb-flexi-banner';
    return (
      isShowBanner && (
        <div className={ebFlexiclassName}>
          <div
            onClick={this.goToOpenEbFlexiModel}
            data-enable-ga
            data-ga-type="button"
          >
            <FlexiBanner
              content={content ? resolveLocalizedText(content) : ''}
            />
          </div>
          <EmmaErrorModal />
        </div>
      )
    );
  }

  static mapStateToProps(state: any) {
    const isLoading = getIsLoading(state);
    const isShowBanner = getIsShowBanner(state);
    const bannerLabel = getBannerLabel(state);
    return {
      isLoading,
      isShowBanner,
      bannerLabel,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onOpenEbFlexiModel: () => dispatch(openEbFlexiModel()),
      onFetchEbFlexiData: (payload: any) => dispatch(fetchEbFlexiData(payload)),
      onUpdateClickFromBanner: (isClickFromBaner: boolean) =>
        dispatch(updateClickFromBanner(isClickFromBaner)),
    };
  }
}

export default connect(
  EBFlexiBannerView.mapStateToProps,
  EBFlexiBannerView.mapDispatchToProps,
)(EBFlexiBannerView);
