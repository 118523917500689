import {
  applyMiddleware,
  compose,
  createStore,
  Store,
  AnyAction,
  combineReducers,
} from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './rootReducer';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import CookieStorage from '../utils/CookieStorage';
import config from '../package.json';
import history from './browserHistory';
import _, { reduce } from 'lodash';
import reducerRegistry from '../registries/ReducerRegistry';
import sagaRegistry from '../registries/SagaRegistry';
import { getAuthCode } from '../selectors/authSelectors';
import { getSessionId } from '../utils';
import Reactotron from '../ReactotronConfig';
import Cookies from 'cookies-js';

let sagaMonitor = null;
if (process.env.NODE_ENV === 'development' && Reactotron.createSagaMonitor) {
  sagaMonitor = Reactotron.createSagaMonitor();
}
const sagaMiddleware = sagaMonitor
  ? createSagaMiddleware({ sagaMonitor })
  : createSagaMiddleware();

let store: Store;
let persistor: any;

/**
 * Lazy loading
 */
const injectedSagas = {} as any; // Injected lazy module
export const runSagas = (moduleName: string, sagas: any[]) => {
  if (!sagas) return;

  // don't execute again if the saga is already loaded before lazy loading e.g. preload-module.
  const registrySagas = sagaRegistry.getSagas();
  sagas.forEach((s) => {
    if (registrySagas.includes(s)) {
      const index = sagas.indexOf(s);
      sagas.splice(index, 1);
    }
  });

  // don't inject again if the module is already loaded.
  if (!injectedSagas[moduleName]) {
    injectedSagas[moduleName] = sagas;
    sagaRegistry.register(sagas);
    sagas.forEach((s) => {
      sagaMiddleware.run(s);
    });
  }
};
const createReducers = (moduleName: string, reducers: any) => {
  Object.keys(reducers).forEach((r) => {
    reducerRegistry.register(moduleName, r, reducers[r]);
  });
  const rootReducer = createRootReducer(history);
  const persistConfig = getPersistConfig();
  return persistReducer(persistConfig, rootReducer);
};
export const injectAsyncReducer = (moduleName: string, reducers: any) => {
  try {
    if (!moduleName || !reducers) {
      return;
    }

    const createdReducers = createReducers(moduleName, reducers) as any;
    if (createdReducers) {
      store.replaceReducer(createdReducers);
      persistor = persistStore(store);
    }
  } catch (e) {
    console.warn(e);
  }
};
/** End of lazy loading */

const transforms = [
  createTransform(
    (i: {}) => {
      return btoa(escape(JSON.stringify(i)));
    },
    (o: string) => {
      return JSON.parse(unescape(atob(o)));
    },
  ),
];

const getPersistConfig = () => {
  return {
    key: config.name,
    storage,
    transforms,
    whitelist: [...reducerRegistry.getPersitWhiteList()],
    stateReconciler: (inboundState: {}, originalState: {}) => {
      const code = getAuthCode(inboundState);
      const sessionId = getSessionId();
      const persistedState = code || sessionId ? inboundState : {};

      return _.merge({}, originalState, persistedState);
    },
  };
};

const configureStore = (preloadedState: any = {}) => {
  const enhancers = [];
  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const persistConfig = getPersistConfig();
  const rootReducer = createRootReducer(history);
  const pReducer = persistReducer(persistConfig, rootReducer);

  if (process.env.NODE_ENV === 'development' && Reactotron.createEnhancer) {
    store = createStore(
      pReducer,
      preloadedState,
      compose(
        applyMiddleware(routerMiddleware(history), sagaMiddleware),
        ...enhancers,
        Reactotron.createEnhancer(),
      ),
    );
  } else {
    store = createStore(
      pReducer,
      preloadedState,
      compose(
        applyMiddleware(routerMiddleware(history), sagaMiddleware),
        ...enhancers,
      ),
    );
  }

  sagaRegistry.getSagas().forEach((s) => {
    sagaMiddleware.run(s);
  });

  persistor = persistStore(store);
};

const select = <T>(selector: (state: {}) => T) => {
  return selector(store.getState());
};

const put = (action: AnyAction) => {
  return store.dispatch(action);
};

export { store, configureStore, persistor, select, put };
