import { useSelector } from 'react-redux';

export const useScopedSelector = <ScopedStore, T>(
  cb: (store: ScopedStore) => T,
  fallback: T,
  PKG_NAME: string,
) => {
  return useSelector((store) => {
    try {
      return cb(store[PKG_NAME]);
    } catch (err) {
      return fallback;
    }
  });
};
