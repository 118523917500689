import _ from 'lodash';
import {
  call,
  put,
  take,
  select,
  race,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { FRAME_APP_MOUNTED } from '../actions/frameActions';
import HomePagePopUpRegistry from '../../src/registries/HomePagePopUpRegistry';
import {
  EMMA_BETTER_ME,
  EMMA_MY_PRIVILEGES_OVERLAY,
} from '../constants/constants';
import {
  getCustomerBetterMe,
  getCustomerIsCwp,
} from '../selectors/userSelectors';
import {
  getMyPrivilegesOverlay,
  getBetterMeRedDotMaxCount,
} from '../selectors/sideBarItemSelectors';
import { FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS } from '../actions/sideBarItemActions';
import { BANNER_TO_SHOW } from '../actions/appActions';
import { EMMA_BETTER_ME_POPUP } from '../actions/myPrivilegesOverlayActions';
import { isClickCountFeature } from './sideBarItemSaga';

function* initPopup() {
  const myPrivilegesOverlay = yield select(getMyPrivilegesOverlay);
  // is clickCount less than Max Count
  const redDotMaxCount = yield select(getBetterMeRedDotMaxCount);
  const isFoundFeatureId = yield isClickCountFeature(
    EMMA_MY_PRIVILEGES_OVERLAY,
    redDotMaxCount,
  );
  if (JSON.stringify(myPrivilegesOverlay) !== '{}' && isFoundFeatureId) {
    yield HomePagePopUpRegistry.setFetchDataCompleted(EMMA_BETTER_ME, true);
  } else {
    yield HomePagePopUpRegistry.setFetchDataCompleted(EMMA_BETTER_ME);
  }
}

function* setShowPopup(): Iterable<any> {
  while (true) {
    yield take(BANNER_TO_SHOW);
    const isShowPopup = HomePagePopUpRegistry.getIsDisplay(EMMA_BETTER_ME);
    if (isShowPopup) {
      const show = { isShowPopupModal: true };
      yield put({ type: EMMA_BETTER_ME_POPUP, show });
    }
  }
}

function* myPrivilegesOverlaySaga() {
  yield take(FRAME_APP_MOUNTED);
  yield take(FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS);
  yield fork(initPopup);
  yield fork(setShowPopup);
}

export default myPrivilegesOverlaySaga;
