import {
  FETCH_MANNING_POLICIES_SUCCESS,
  FETCH_MANNING_STORES_SUCCESS,
  FETCH_MANNING_STORE_TIMES_SUCCESS,
  MANNING_COMMIT_BOOKING_SUCCESS,
  MANNING_COMMIT_BOOKING_FAILURE,
  MANNING_SHOW_MODAL,
  MANNING_HIDE_MODAL,
  FETCH_INSURED_NAME_SUCCESS,
  FETCH_INSURED_NAME_FAILURE,
  FETCH_INSURED_NAME,
  MANNING_INIT,
} from '../actions/manningAction';

const defaultState = {
  manningPolicies: [],
  manningStores: [],
  manningStoreTimes: [],
  appointmentData: {
    appointmentStore: '',
    appointmentDate: '',
    appointmentTime: '',
    contactEmail: '',
    mobileNo: '',
  },
  appointmentError: '',
  showModal: false,
  insuredName: '',
};
export interface appointmentState {
  manningPolicies?: any;
  manningStores?: any;
  manningStoreTimes?: any;
  appointmentData: {
    appointmentStore: string;
    appointmentDate: string;
    appointmentTime: string;
    contactEmail: string;
    mobileNo: string;
  };
  appointmentError?: string;
  showModal?: boolean;
  insuredName: string;
}

export interface RetrievePolicyDetailsResponse {
  policy: RetrievePolicyDetails;
}

export interface RetrievePolicyDetails {
  insuredDetails: {
    lastName: string;
  };
}

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case MANNING_INIT:
      return defaultState;
    case FETCH_MANNING_POLICIES_SUCCESS:
      nextState.manningPolicies = action.manningPolicies;
      return nextState;
    case FETCH_MANNING_STORES_SUCCESS:
      nextState.manningStores = action.manningStores;
      return nextState;
    case FETCH_MANNING_STORE_TIMES_SUCCESS:
      nextState.manningStoreTimes = action.manningStoreTimes;
      return nextState;
    case MANNING_COMMIT_BOOKING_SUCCESS:
      nextState.appointmentData = action.appointmentData;
      return nextState;
    case MANNING_COMMIT_BOOKING_FAILURE:
      nextState.appointmentError = action.appointmentError;
      return nextState;
    case MANNING_SHOW_MODAL:
      nextState.showModal = true;
      return nextState;
    case MANNING_HIDE_MODAL:
      nextState.showModal = false;
      return nextState;
    case FETCH_INSURED_NAME:
      nextState.insuredName = undefined;
      return nextState;
    case FETCH_INSURED_NAME_SUCCESS:
      try {
        const { retrievePolicyDetailsResponse } = action.payload;
        nextState.insuredName =
          retrievePolicyDetailsResponse.policy.insuredDetails.lastName;
      } catch (e) {
        return nextState;
      }
      return nextState;
    case FETCH_INSURED_NAME_FAILURE:
      nextState.insuredName = undefined;
      nextState.fetchInsuredNameFailure = action.payload.error;
      return nextState;
    default:
      return nextState;
  }
};
