import _ from 'lodash';

export const getEbFlexiApi = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-eb-flexi.ebFlexiContentApiUrl`,
    '',
  );
};

export const getOverlayLabel = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-eb-flexi.overlay`,
    {},
  );
};

export const getBannerLabel = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-eb-flexi.banner`,
    {},
  );
};

export const getEbFlexiOverlayVisible = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.ebFlexiOverlayShow`, false);
};

export const getEbFlexiIsShowPopupModal = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.isShowPopupModal`, false);
};

export const getIsClickFromBaner = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.isClickFromBaner`, false);
};

export const getIsLoading = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.isLoading`, false);
};

export const getIsShowBanner = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.isShowBanner`, false);
};

export const getPolicyCard = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.policyCard`, {});
};

export const getEbFlexiAccessToken = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.access_token`, '');
};

export const getEbFlexiIsShowLoadingBtn = (state: any) => {
  return _.get(state, `hk-emma-web-core/ebFlexi.isShowLoadingBtn`, '');
};

export const getEbFlexiOpenUrl = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-eb-flexi.ebFlexiOpenUrl`,
    '',
  );
};

export const getEbFlexiRedirectUrl = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-eb-flexi.ebFlexiRedirectUrl`,
    '',
  );
};
