import { put } from '../stores';
export const FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS = `FETCH_SIDEBAR_READ_FEATURE_SUCCESS`;
export const FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_FAILURE = `FETCH_SIDEBAR_READ_FEATURE_DOT_FAILURE`;
export const PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE =
  'PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE';
export const UPDATE_SIDEBAR_HAVE_READ_NEW_FEATURE_STATE =
  'UPDATE_SIDEBAR_HAVE_READ_NEW_FEATURE_STATE';
export const SIDEBAR_ITEM_MOUNTED = 'SIDEBAR_ITEM_MOUNTED';
export const PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE_SINGLE =
  'PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE_SINGLE';
export const SIDEBAR_ITEM_RED_DOT = 'SIDEBAR_ITEM_RED_DOT';
export const SKIP_SIDEBAR_TO_MYPRIVILEGES = 'SKIP_SIDEBAR_TO_MYPRIVILEGES';

export const pushHaveReadNewFeature = (
  serviceId: string,
  clickCount: number,
) => {
  return {
    type: PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE,
    payload: {
      serviceId: serviceId,
      clickCount: clickCount,
    },
  };
};

export const pushHaveReadNewFeatureSingle = (
  serviceId: string,
  clickCount: number,
  redDotMaxCount?: number,
) => {
  return {
    type: PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE_SINGLE,
    payload: {
      serviceId: serviceId,
      clickCount: clickCount,
      redDotMaxCount: redDotMaxCount,
    },
  };
};

export const sidebarItemMounted = () => {
  put({
    type: SIDEBAR_ITEM_MOUNTED,
  });
};

export const pushMyPrivilegesRedDot = () => {
  return {
    type: SIDEBAR_ITEM_RED_DOT,
  };
};

export const skipSidebarToMyPrivileges = () => {
  put({
    type: SKIP_SIDEBAR_TO_MYPRIVILEGES,
  });
};
