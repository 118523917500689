import React, { Component } from 'react';
import './styles.scss';

import { connect } from 'react-redux';

interface ContentWrapperProps {
  className?: string;
  children?: any;
}

class ContentWrapper extends Component<ContentWrapperProps> {
  render() {
    return (
      <div
        className={`content-wrapper ${this.props.className}`}
        key="content-wrapper"
      >
        {this.props.children}
      </div>
    );
  }
}

export default connect()(ContentWrapper);
