import {
  WOMAN_PRODUCT_INIT,
  FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS,
  FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS,
  FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS,
  SET_WOMAN_PRODUCT_IS_LOADING,
} from '../actions/checkCoverageAction';

const defaultState = {
  womanProductPolicies: [],
  policyNumber: '',
  entityCode: '',
  planCode: '',
  planName: '',
  coreBenefitList: [],
  benefitRemarks: [],
  keyExculsions: {},
  productRemarks: {},
  appointmentContact: [],
  isLoading: true,
};

export interface womanProductState {
  womanProductPolicies: Array<any>;
  policyNumber: string;
  entityCode: string;
  planCode: string;
  planName: string;
  coreBenefitList: Array<coreBenefitItem>;
  benefitRemarks: Array<any>;
  keyExculsions: object;
  productRemarks: object;
  appointmentContact: Array<any>;
  isLoading: boolean;
}

export interface coreBenefitItem {
  category: string;
  benefitNames: Array<any>;
  benefitRemarks: Array<any>;
  ordering: string;
  appointment: string;
  appointmentButtonDisable: boolean;
  textLink: string;
  textLinkContent: string;
  textLinkUrl: string;
  coverages: Array<any>;
  benefitCode: string;
  benefitUsedupMessage: string;
}

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case WOMAN_PRODUCT_INIT:
      return defaultState;
    case FETCH_WOMAN_PRODUCT_POLICIES_SUCCESS:
      nextState.womanProductPolicies = action.payload.womanProductPolicies;
      return nextState;
    case FETCH_WOMAN_PRODUCT_POLICY_COVERAGE_SUCCESS:
      const state = {
        ...nextState,
        ...action.payload.policyCoverage,
      };
      return state;
    case FETCH_WOMAN_PRODUCT_APPOINTMENT_CONTACT_SUCCESS:
      action.payload.appointmentContact.map((contact: any) => {
        contact.Appointments.map((dict: any) => {
          switch (dict.number) {
            case '(852) 2835 7878':
              dict.link = {};
              dict.link.en = 'tel:+85228357878';
              dict.link.zh = 'tel:+85228357878';
              dict.notes = {};
              dict.notes.en =
                'Please indicate as a customer of AXA Pink Medical';
              dict.notes.zh = '請表明為 AXA安盛「紅粉醫療」的客戶';
              break;
            case '(852) 3468 7141':
              dict.link = {};
              dict.link.en = 'https://cuclinic.hk/en/my_appointment/';
              dict.link.zh = 'https://cuclinic.hk/zh-hant/my_appointment/';
              break;
            case '(852) 2157 3846':
              dict.notes = {
                en: 'Please indicate as a customer of AXA Pink Medical',
                zh: '請表明為 AXA安盛「紅粉醫療」的客戶',
              };
              dict.link = {
                en: 'tel:+85221573846',
                zh: 'tel:+85221573846',
              };
              break;
          }
        });
      });
      nextState.appointmentContact = action.payload.appointmentContact;
      return nextState;
    case SET_WOMAN_PRODUCT_IS_LOADING:
      nextState.isLoading = action.isLoading;
      return nextState;
    default:
      return nextState;
  }
};
