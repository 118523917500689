import {
  UPDATE_PROFILE_EMAIL,
  UPDATE_SELECTED_LIST,
  RESET_CHANGE_EMAIL_FORM,
  UPDATE_EMAIL_CUST_STATE,
  UPDATE_EMAIL_CUST_STATE_SUCCESS,
  UPDATE_EMAIL_CUST_STATE_FAILURE,
  FETCH_EMAIL_OTHER_POLICY_LIST,
  FETCH_EMAIL_OTHER_POLICY_LIST_SUCCESS,
  FETCH_EMAIL_OTHER_POLICY_LIST_FAIL,
  UPDATE_EMAIL_OTHER_POLICIES,
  SEND_EMAIL_OTP_REQUEST,
  SEND_EMAIL_OTP_REQUEST_SUCCESS,
  SEND_EMAIL_OTP_REQUEST_FAIL,
  SUBMIT_CHANGE_EMAIL_FORM,
  SUBMIT_CHANGE_EMAIL_FORM_SUCCESS,
  SUBMIT_CHANGE_EMAIL_FORM_FAIL,
  UPDATE_EMAIL_FORM_STATES,
  UPDATE_EMAIL_GOGREEN_LIST,
} from '../actions/changeEmailAction';

const defaultState = {
  isLoading: false,
  newEmail: '',
  otherPolicyList: [],
  selectedList: [],
  transactions: [],
  successfulPolicies: [],
  failedPolicies: [],
  goGreenList: [],
  form: {
    agreeNotify: null,
    agreeUseOfData: null,
    agreeProvidedValidData: null,
    agreeRevokeEmail: null,
  },
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_EMAIL_GOGREEN_LIST:
      nextState.goGreenList = action.payload;
      return nextState;
    case UPDATE_EMAIL_FORM_STATES:
      nextState.form = { ...nextState.form, ...action.payload };
      return nextState;
    case UPDATE_PROFILE_EMAIL:
      nextState.newEmail = action.newEmail;
      return nextState;
    case UPDATE_SELECTED_LIST:
      nextState.selectedList = action.selectedList;
      return nextState;
    case UPDATE_EMAIL_OTHER_POLICIES:
    case FETCH_EMAIL_OTHER_POLICY_LIST:
      nextState.isLoading = true;
      return nextState;
    case FETCH_EMAIL_OTHER_POLICY_LIST_SUCCESS:
      nextState.otherPolicyList = action.otherPolicyList;
      nextState.isLoading = false;
      return nextState;
    case FETCH_EMAIL_OTHER_POLICY_LIST_FAIL:
      nextState.isLoading = false;
      return nextState;
    case RESET_CHANGE_EMAIL_FORM:
      nextState.newEmail = '';
      nextState.selectedList = [];
      nextState.form = {
        agreeNotify: null,
        agreeUseOfData: null,
        agreeProvidedValidData: null,
        agreeRevokeEmail: null,
      };
      return nextState;
    case SEND_EMAIL_OTP_REQUEST:
      return {
        ...nextState,
        isLoading: true,
      };
    case SEND_EMAIL_OTP_REQUEST_SUCCESS:
      return {
        ...nextState,
        isLoading: false,
      };
    case SEND_EMAIL_OTP_REQUEST_FAIL:
      return {
        ...nextState,
        isLoading: false,
      };
    case SUBMIT_CHANGE_EMAIL_FORM:
      return {
        ...nextState,
        isLoading: true,
      };
    case SUBMIT_CHANGE_EMAIL_FORM_SUCCESS:
      return {
        ...nextState,
        isLoading: false,
        transactionId: action.transactionId,
        transactions: action.transactions,
        successfulPolicies: action.successfulPolicies,
        failedPolicies: action.failedPolicies,
      };
    case SUBMIT_CHANGE_EMAIL_FORM_FAIL:
      return {
        ...nextState,
        isLoading: false,
      };
    default:
      return nextState;
  }
};
