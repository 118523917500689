import { GET_POLICY_NUMBER_SUCCESS } from '../actions/policyNumberActions';
import { SIGN_OUT } from '../actions/authActions';
import { getPnStateKey } from '../utils/policyNumberUtil';

export interface PolicyNumberState {
  [key: string]: string | undefined;
}

const initState: PolicyNumberState = {};

export default (state: PolicyNumberState = initState, action: any) => {
  const nextState: PolicyNumberState = { ...state };
  switch (action.type) {
    case SIGN_OUT:
      return {
        ...initState,
      };
    case GET_POLICY_NUMBER_SUCCESS:
      const key = getPnStateKey();
      nextState[key] = action && action.payload && action.payload.policyNumber;
      return nextState;
    default:
      return nextState;
  }
};
