import { combineReducers } from 'redux';
import myPrivilegesReducer from './myPrivilegesReducer';
import vhisReducer from './vhisReducer';
import dashboardReducer from './dashboardReducer';

export default combineReducers({
  myPrivileges: myPrivilegesReducer,
  homeVhis: vhisReducer,
  dashboard: dashboardReducer,
});
