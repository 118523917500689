import _ from 'lodash';
import {
  FETCH_FIREBASE_EXPERIMENTS_SUCCESS,
  FETCH_OPTIMIZE_EXPERIMENTS_SUCCESS,
} from '../actions/abTestActions';

const appInitState = {
  firebaseExperiments: {},
  optimizeExperiments: {},
};

export default (state: any = appInitState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_FIREBASE_EXPERIMENTS_SUCCESS:
      return {
        ...nextState,
        firebaseExperiments: action.payload,
      };
    case FETCH_OPTIMIZE_EXPERIMENTS_SUCCESS:
      return {
        ...nextState,
        optimizeExperiments: action.payload,
      };
    default:
      return nextState;
  }
};
