import React, { Component } from 'react';
import arrowRightIcon from './arrow-right@3x.png';
import speakerIcon from './speaker-icon@3x.png';
import './styles.scss';

interface FlexiBannerViewProps {
  content: string;
  imageLeftUrl?: string;
  imageRightUrl?: string;
  backGroundColor?: string;
  handleBannerClick?: () => void;
}

interface FlexiBannerViewState {}

export default class FlexiBannerView extends Component<
  FlexiBannerViewProps,
  FlexiBannerViewState
> {
  static defaultProps = {
    imageLeftUrl: speakerIcon,
    imageRightUrl: arrowRightIcon,
    backGroundColor: '#41949f',
  };

  constructor(props: FlexiBannerViewProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { backGroundColor, imageLeftUrl, imageRightUrl, content } =
      this.props;
    return (
      <div
        className="flexi-banner"
        style={{ backgroundColor: `${backGroundColor}` }}
      >
        <img alt="" src={imageLeftUrl} width="34" height="34" />
        <span className="flexi-banner_text">{content}</span>
        <img alt="" src={imageRightUrl} width="16" height="16" />
      </div>
    );
  }
}
