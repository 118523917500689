import _ from 'lodash';
import { stringFormatter, _get, resolveLocalizedText } from '../utils';
import { EmmaLoginConfig, EmmaWebwiewConfig } from '../types/authTypes';
import { EmmaInsuranceConfig } from '../types/configTypes';
import env from '../env';
import { FeatureLimitedWebInfo } from '../reducers/appReducer';

const emmaLoginModuleName = `emma-login`;
const emmaWebviewModuleName = `emma-webview`;
const emmaInsuranceModuleName = `emma-insurance`;
const emmaPolicyListModuleName = `policy-list.url`;
const emmaHomeNewsModuleName = `emma-home-news`;
const emmaMarketPlaceNewsModuleName = `emma-axa-promotional-offer`;
const emmaHomeRevampModuleName = `emma-home-revamp`;

export const getApp = <T>(state: {}): T => {
  return _get<T>(state, `hk-emma-web-core/app`);
};

export const getIsUserConfigLoaded = <T>(state: {}): T => {
  return _get<T>(state, `hk-emma-web-core/app.isUserConfigLoaded`);
};

export const getAppModuleConfig = <T>(state: {}, appModuleName: string): T => {
  return _get<T>(state, `hk-emma-web-core/app.moduleConfig.${appModuleName}`);
};

export const getPreVersion = (state: {}) => {
  return _get<string>(state, `hk-emma-web-core/app.preVersion`);
};

export const getPostVersion = (state: {}) => {
  return _get<string>(state, `hk-emma-web-core/app.postVersion`);
};

export const getCustStateApiUrl = (state: {}) => {
  return getAppModuleConfig<EmmaWebwiewConfig>(state, emmaWebviewModuleName)
    .custStateApiUrl;
};

export const getCustomerApiUrl = (state: {}) => {
  return getAppModuleConfig<EmmaInsuranceConfig>(
    state,
    emmaHomeRevampModuleName,
  ).customerApiUrl;
};

export const getAdditionalAccessTokens = (state: any) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .additionalAccessTokens;
};

export const getRefreshAccessTokenApiUrl = (state: any) => {
  const url = getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .accessToken.refreshAccessTokenApiUrl;
  return url;
};

export const getRequestAccessTokenApiUrl = (state: any) => {
  const url = getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .accessToken.requestAccessTokenApiUrl;
  return url;
};

export const getRefreshTokenAfter = (state: any) => {
  const config = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  );
  return config && config.accessToken && config.accessToken.refreshTokenAfter;
};

export const getTokenScope = (state: any) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .accessToken.scope;
};

export const getApiKey = (state: any) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .accessToken.apiKey;
};

export const getMaamRedirectUri = (state: any) => {
  const { redirectUri } = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  ).accessToken;
  return resolveLocalizedText(redirectUri);
};

export const getSignInUrl = (state: any) => {
  const emmaLoginConfig = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  );
  const url = emmaLoginConfig.signInUrl;
  return stringFormatter(resolveLocalizedText(url), {
    redirectUri: encodeURIComponent(
      resolveLocalizedText(emmaLoginConfig.accessToken.redirectUri),
    ),
  });
};

export const getSignUpUrl = (state: any) => {
  const url = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  ).signUpUrl;
  return resolveLocalizedText(url);
};

export const getSignOutUrl = (state: any) => {
  const url =
    getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
      .signOutUrl || env.SIGN_OUT_URL;
  return resolveLocalizedText(url);
};

export const getUserConfigUrl = (state: any) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .userConfigApiUrl;
};

export const getDeepLinkMapping = (state: {}) => {
  return _get<{ [appDeepLink: string]: string }>(
    state,
    `hk-emma-web-core/app.deepLinkMapping`,
  );
};

export const getFeatureLimitedWeb = (state: {}) => {
  return _get<FeatureLimitedWebInfo>(
    state,
    `hk-emma-web-core/app.featureLimitedWeb`,
  );
};

export const getAvatorsConfig = (state: {}) => {
  return _get<{ [avator: string]: string }>(
    state,
    `hk-emma-web-core/app.avators`,
  );
};

export const getAvators = (state: {}) => {
  return _get<{ [avator: string]: string }>(
    state,
    `hk-emma-web-core/home.avators`,
  );
};

export const getFacebookUrl = (state: {}) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .facebookUrl;
};

export const getYoutubeUrl = (state: {}) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .youTubeUrl;
};

export const getLinkedInUrl = (state: {}) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .linkedInUrl;
};

export const getInstagramUrl = (state: {}) => {
  return getAppModuleConfig<EmmaLoginConfig>(state, emmaLoginModuleName)
    .instagramUrl;
};

export const getPolicyUrl = (state: {}) => {
  return '#';
};

export const getPrivacyUrl = (state: {}) => {
  return '#';
};

export const getAxaWebUrl = (state: {}) => {
  const url = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  ).axaWebUrl;
  return resolveLocalizedText(url);
};

export const getContactUsUrl = (state: {}) => {
  const url = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  ).contactUsUrl;
  return resolveLocalizedText(url);
};

export const getPolicyListApiUrl = (state: {}) => {
  return getAppModuleConfig(state, emmaPolicyListModuleName);
};

export const getHomepageNewsApiUrl = (state: {}) => {
  return getAppModuleConfig(state, emmaHomeNewsModuleName);
};

export const getIdleTimeoutInMinutes = (state: any) => {
  const idleTimeoutInMinutes = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  ).idleTimeoutInMinutes;
  return idleTimeoutInMinutes;
};

export const getMarketPlaceNewsApiUrl = (state: {}) => {
  return getAppModuleConfig(state, emmaMarketPlaceNewsModuleName);
};

export const getDefaultRedDotMaxCount = (state: {}): number => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-home-revamp.customApi.haveReadNewFeature.redDotMaxCount`,
    0,
  );
};

export const getLaunchpadModule = (state: {}) => {
  return getAppModuleConfig(state, 'emma-launchpad');
};

export const getWebVersion = () => {
  const match = env.WEBAPP_VERSION.match(/^[0-9]+\.[0-9]+\.[0-9]+/);
  return match ? match[0] : ``;
};

export const getProfileVersion = (state: {}) => {
  return _.get(state, `hk-emma-web-core/app.preVersion`, '');
};

export const getWebEnv = () => {
  const environment = env.APP_VERSION_ENV;
  return `${environment !== 'PROD' ? `-${environment}` : ''}`;
};

export const getCorpWebUrl = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-common.corpWebDomain`,
    '',
  );
};

export const getCustomerSupportUrl = (state: any) => {
  const url = getAppModuleConfig<EmmaLoginConfig>(
    state,
    emmaLoginModuleName,
  )?.customerSupportUrl;
  return resolveLocalizedText(url);
};
