import {
  SUBMIT_DELETE_ACCOUNT_LOADING_END,
  SUBMIT_DELETE_ACCOUNT_LOADING_START,
} from '../actions/deleteAccountAction';

const defaultState = {
  isSubmitLoading: false,
};
export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case SUBMIT_DELETE_ACCOUNT_LOADING_START:
      nextState.isSubmitLoading = true;
      return nextState;
    case SUBMIT_DELETE_ACCOUNT_LOADING_END:
      nextState.isSubmitLoading = false;
      return nextState;
    default:
      return nextState;
  }
};
