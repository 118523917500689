import React, { FC, useRef } from 'react';
import './style.scss';

interface TncCheckboxProps {
  tncId: string;
  label: string;
  title: string;
  checked: boolean;
  openTncStyleModal: (id: string) => void;
  onChange: (isChecked: boolean) => void;
}

const TncCheckboxItem: FC<TncCheckboxProps> = ({
  tncId,
  checked,
  label,
  title,
  onChange,
  openTncStyleModal,
}) => {
  const onCheckboxChange = () => {
    if (typeof onChange !== 'function') return;
    onChange(!checked);
  };

  let inputRef: HTMLInputElement | null;

  const handleClick = () => {
    inputRef && inputRef.click();
  };

  return (
    <div
      className="emma-modal_tnc-checkbox-li emma-stylesheet-checkbox-big"
      key={`checkbox-${tncId}`}
      onClick={handleClick}
    >
      <input
        ref={(input) => (inputRef = input)}
        id={`checkbox-${tncId}`}
        name={`checkbox-${tncId}`}
        type="checkbox"
        onChange={onCheckboxChange}
        checked={checked}
      />
      <div className="emma-modal_tnc-checkbox-li_content">
        <label
          className="emma-modal_tnc-checkbox-li_label"
          htmlFor={`checkbox-${tncId}`}
        >
          {label}
        </label>
        <span
          className="emma-modal_tnc-checkbox-li_title"
          onClick={() => openTncStyleModal(tncId)}
          data-enable-ga
          data-ga-type="button"
        >
          {title}
        </span>
      </div>
    </div>
  );
};

export default TncCheckboxItem;
