import authReducer from './authReducer';
import sessionReducer from './sessionReducer';
import appReducer from './appReducer';
import uiReducer from './uiReducer';
import historyReducer from './historyReducer';
import homeReducer from '../modules/home/reducers/homeReducers';
import userReducer from './userReducer';
import policyListReducers from '../modules/policy-list/reducers/policyListReducers';
// import bannerReducer from '../modules/emma-notices/reducers/bannerReducer';
import vhisReducer from '../modules/axagoal/reducers';
import oxfordVrReducer from '../modules/ps-oxford-vr/reducers';
import stressCareReducer from '../modules/ps-stress-care/reducers';
import mentalHealthGuruReducer from '../modules/ps-mental-health-guru/reducers';
import smilingMindReducer from '../modules/ps-smiling-mind/reducers';
import moodgymReducer from '../modules/ps-moodgym/reducers';
import sideBarItemReducer from './sideBarItemReducer';
import manningsReducer from '../modules/mannings/reducers/manningReducer';
import fanclReducer from '../modules/fancl/reducers/fanclReducer';
import healthkeeperReducer from '../modules/healthkeeper/reducers';
import hkshwellnessexperienceReducer from '../modules/ps-hkshwellnessexperience/reducers';
import casemanagementReducer from '../modules/ps-casemanagement/reducers';
import manninghealthcollaborationReducer from '../modules/ps-manninghealthcollaboration/reducers';
import fanclskinandhealthtestReducer from '../modules/ps-fanclskinandhealthtest/reducers';
import wellnessprogrammeReducer from '../modules/ps-wellnessprogramme/reducers';
import preventionstageReducer from '../modules/ps-preventionstage/reducers';
import preventionstageplusReducer from '../modules/ps-preventionstageplus/reducers';
import treatmentandrehabilitationstageReducer from '../modules/ps-treatmentandrehabilitationstafe/reducers';
import treatmentandrehabilitationstageplusReducer from '../modules/ps-treatmentandrehabilitationstafeplus/reducers';
import jadediabetesReducer from '../modules/ps-jadediabetes/reducers';
import chronicdiseasemanagementReducer from '../modules/ps-chronicdiseasemanagement/reducers';
import senselyReducer from '../modules/ps-sensely/reducers';
// import senselysymptomcheckerReducer from '../modules/ps-senselysymptomchecker/reducers';
import myPrivilegesOverlayReducer from './myPrivilegesOverlayReducer';
import meditationReducer from '../modules/ps-meditation/reducers';
import teleconsultationReducer from '../modules/ps-teleconsultation/reducers';
import cdpReducer from '../modules/ps-cdp/reducers';
import luckyDrawReducer from '../modules/ps-luckydraw/reducers';
import tncDisclaimerReducer from './tncDisclaimerReducer';
import leadformmeetourconsultantReducer from '../modules/ps-leadformmeetourconsultant/reducers';
import offerOneReducer from '../modules/ps-offer-one/reducers';
import womanProductReducer from '../modules/check-coverage/reducers/checkCoverageReducer';
import fertilitysolutionReducer from '../modules/ps-fertilitysolution/reducers';
import cosmeticdermatologyReducer from '../modules/ps-cosmeticdermatology/reducers';
import refractivesurgeryReducer from '../modules/ps-refractivesurgery/reducers';
import maternityservicesReducer from '../modules/ps-maternityservices/reducers';
import womenproductleadReducer from '../modules/ps-womenproductlead/reducers';
import offersReducer from '../modules/offers/reducers';
import checkEBCoverageReducer from '../modules/check-coverage/reducers/checkEBCoverageReducer';
import customerProfileReducer from '../modules/emma-customer-profile/reducers';
import modalReducer from '../modules/emma-modal/reducers/modalReducer';
import storyTalerReducer from '../modules/ps-storytaler/reducers';
import promoReducer from '../modules/emma-axa-promotional-offer/reducers/promoReducer';
import goGreenSurveyReducer from '../modules/go-green-survey/reducers';
import changeAddressReducer from '../modules/emma-customer-profile/reducers/changeAddressReducer';
import abTestReducer from './abTestReducer';
import aktivoHealthSurveyReducer from '../modules/aktivo-health-survey/reducers';
import pnReducer from './policyNumberReducer';
import aktivoOnboardingReducer from '../modules/aktivo-onboarding/reducers';
import aktivoIndividualChallengeReducer from '../modules/aktivo-individual-challenge/reducers';
import exclusiveOffersReducer from '../modules/ps-exclusiveoffers/reducers';
import happyAgingReducer from '../modules/ps-happyaging/reducers';
import valueAddedServiceReducer from '../modules/ps-valueaddedservice/reducers';
import pinkMedicalReducer from '../modules/ps-pinkmedical/reducers';

// import { reducers as referralProgrammeReducers } from '../modules/referral-programme';

export default {
  app: { reducer: appReducer },
  abTest: { reducer: abTestReducer },
  pn: { reducer: pnReducer, persist: true },
  // comment: remove A-HKG-0018-V010
  // session: { reducer: sessionReducer },
  auth: { reducer: authReducer, persist: true },
  ui: { reducer: uiReducer },
  history: { reducer: historyReducer, persist: true },
  home: { reducer: homeReducer },
  user: { reducer: userReducer },
  policyList: { reducer: policyListReducers },
  vhis: { reducer: vhisReducer },
  // banner: { reducer: bannerReducer },
  oxfordvr: { reducer: oxfordVrReducer },
  cuhktourheart: { reducer: stressCareReducer },
  ehubmentalhealthguru: { reducer: mentalHealthGuruReducer },
  ehubmoodgym: { reducer: moodgymReducer },
  ehubsmilingmind: { reducer: smilingMindReducer },
  oxfordvrdisabled: { reducer: oxfordVrReducer },
  cuhktourheartdisabled: { reducer: stressCareReducer },
  ehubmentalhealthgurudisabled: { reducer: mentalHealthGuruReducer },
  ehubmoodgymdisabled: { reducer: moodgymReducer },
  ehubsmilingminddisabled: { reducer: smilingMindReducer },
  sidebaritem: { reducer: sideBarItemReducer },
  mannings: { reducer: manningsReducer },
  fancl: { reducer: fanclReducer },
  healthkeeper: { reducer: healthkeeperReducer },
  healthkeeperdisabled: { reducer: healthkeeperReducer },
  healthkeeperlocked: { reducer: healthkeeperReducer },
  vhisdisabled: { reducer: vhisReducer },
  hkshwellnessexperience: { reducer: hkshwellnessexperienceReducer },
  hkshwellnessexperiencedisabled: { reducer: hkshwellnessexperienceReducer },
  casemanagement: { reducer: casemanagementReducer },
  casemanagementdisabled: { reducer: casemanagementReducer },
  casemanagementlocked: { reducer: casemanagementReducer },
  manninghealthcollaboration: { reducer: manninghealthcollaborationReducer },
  manninghealthcollaborationdisabled: {
    reducer: manninghealthcollaborationReducer,
  },
  fanclskinandhealthtest: { reducer: fanclskinandhealthtestReducer },
  fanclskinandhealthtestdisabled: { reducer: fanclskinandhealthtestReducer },
  wellnessoffers: { reducer: wellnessprogrammeReducer },
  wellnessoffersdisabled: { reducer: wellnessprogrammeReducer },
  wellnessofferslocked: { reducer: wellnessprogrammeReducer },
  preventionstage: { reducer: preventionstageReducer },
  preventionstagedisabled: { reducer: preventionstageReducer },
  preventionstageplus: { reducer: preventionstageplusReducer },
  preventionstageplusdisabled: { reducer: preventionstageplusReducer },
  treatmentandrehabilitationstage: {
    reducer: treatmentandrehabilitationstageReducer,
  },
  treatmentandrehabilitationstagedisabled: {
    reducer: treatmentandrehabilitationstageReducer,
  },
  treatmentandrehabilitationstageplus: {
    reducer: treatmentandrehabilitationstageplusReducer,
  },
  treatmentandrehabilitationstageplusdisabled: {
    reducer: treatmentandrehabilitationstageplusReducer,
  },
  jadediabetes: { reducer: jadediabetesReducer },
  jadediabetesdisabled: { reducer: jadediabetesReducer },
  chronicdiseasemanagement: { reducer: chronicdiseasemanagementReducer },
  chronicdiseasemanagementdisabled: {
    reducer: chronicdiseasemanagementReducer,
  },
  chronicdiseasemanagementlocked: {
    reducer: chronicdiseasemanagementReducer,
  },
  sensely: { reducer: senselyReducer },
  senselydisabled: { reducer: senselyReducer },
  // senselysymptomchecker: { reducer: senselysymptomcheckerReducer },
  // senselysymptomcheckerdisabled: { reducer: senselysymptomcheckerReducer },
  fertilitysolution: { reducer: fertilitysolutionReducer },
  fertilitysolutiondisabled: { reducer: fertilitysolutionReducer },
  cosmeticdermatology: { reducer: cosmeticdermatologyReducer },
  cosmeticdermatologydisabled: { reducer: cosmeticdermatologyReducer },
  refractivesurgery: { reducer: refractivesurgeryReducer },
  refractivesurgerydisabled: { reducer: refractivesurgeryReducer },
  maternityservices: { reducer: maternityservicesReducer },
  maternityservicesdisabled: { reducer: maternityservicesReducer },
  myPrivilegesOverlay: { reducer: myPrivilegesOverlayReducer },
  neurummedication: { reducer: meditationReducer },
  teleconsultation: { reducer: teleconsultationReducer },
  cdp: { reducer: cdpReducer },
  luckydraw: { reducer: luckyDrawReducer },
  tncdisclaimer: { reducer: tncDisclaimerReducer },
  leadformmeetourconsultant: { reducer: leadformmeetourconsultantReducer },
  womenproductlead: { reducer: womenproductleadReducer },
  offers1: { reducer: offerOneReducer },
  womanProduct: { reducer: womanProductReducer },
  offers: { reducer: offersReducer },
  checkEBCoverage: { reducer: checkEBCoverageReducer },
  customerProfile: { reducer: customerProfileReducer },
  emmaModal: { reducer: modalReducer },
  storytaler: { reducer: storyTalerReducer },
  storytalerdisabled: { reducer: storyTalerReducer },
  storytalerlocked: { reducer: storyTalerReducer },
  promotionalOffer: { reducer: promoReducer },
  goGreenSurvey: { reducer: goGreenSurveyReducer },
  cicChangeAddress: { reducer: changeAddressReducer },
  aktivoHealthSurvey: { reducer: aktivoHealthSurveyReducer },
  aktivo: { reducer: aktivoOnboardingReducer },
  aktivoIndividualChallenge: { reducer: aktivoIndividualChallengeReducer },
  exclusiveoffers: { reducer: exclusiveOffersReducer },
  valueaddedservice: { reducer: valueAddedServiceReducer },
  happyaging: { reducer: happyAgingReducer },
  pinkmedical: { reducer: pinkMedicalReducer },
  // ...referralProgrammeReducers,
};
