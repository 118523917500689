import {
  SUBMIT_FORM,
  UPDATE_SUBMIT_FORM_LOADING,
  UPDATE_ERROR_DIALOG_DISPLAY,
} from '../actions';

const defaultState = {
  isSubmitLoading: false,
  isShowErrorDialog: false,
  submitOject: {},
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case SUBMIT_FORM:
      nextState.submitOject = action.payload;
      return nextState;
    case UPDATE_SUBMIT_FORM_LOADING:
      nextState.isSubmitLoading = action.isSubmitLoading;
      return nextState;
    case UPDATE_ERROR_DIALOG_DISPLAY:
      nextState.isShowErrorDialog = action.isShowErrorDialog;
      return nextState;
    default:
      return nextState;
  }
};
