import _ from 'lodash';
// import { joinPath, getBaseUrl, removeQueryParameters } from "../utils";
// import Cookies from 'cookies-js'
// import { getLanguage } from "../selectors/userSelectors";

export interface Env {
  WEBAPP_VERSION: string;
  BASE_NAME: string;
  URL_APP_INFO: string;
  SIGN_OUT_URL: string;
  APP_VERSION_USER_AGENT_PREFIX: string;
  APP_VERSION_ENV: string;
  GA_ENV: string;
  // APP_VERSION_EQ: string,
  // IAM_APP_ORIGINAL_NAME: string,
  // IAM_APP_NAME_REPLACEMENT: string,
  // MAAM_ORIGINAL_REDIRECT_URI: string,
  EMMA_SESSION_ID_COOKIE_NAME: string;
  // MULE_XP_EMMA_LIFE_POLICY_LIST_API: string,
  EMMA_APP_USER_AGENT_PATTERN: string;
  FIRST_CARE_FEATURE: boolean;
  GOOGLE_TAG_MANAGER_ID: string;
  CORP_WEB_COOKIES_NAME: string;
  IS_ENABLE_MOCK: boolean;
  // HKEMMA-100917 - GA3 Decommission - Remove TC Scripts
  // TAGCOMMANDER_HEADER_SCRIPT: string;
  // TAGCOMMANDER_BODY_SCRIPT: string;
  ENABLE_GOOGLE_OPTIMIZE_AB_TEST: boolean;
  EMMA_SYSTEM_DATE: string;
  FANCL_SYSTEM_DATE: string;
  FANCL_MAX_DATE: string;
  POST_MESSAGE_ORIGIN: any;
  EMMA_WEB_SYSTEM_MAINTENANCE: boolean;
  // temp added for aktivo & monorepo
  AKTIVO_ENDPOINT: string;
  AKTIVO_USE_PROXY: string;
  AKTIVO_API_CLIENT: string;
  ALAKE_API_ENDPOINT: string;
  GOOGLE_MAPS_KEY: string;
  INIT_APP_INFO: Record<string, any> | null;
  APP_INSIGHTS_INSTRUMENTATION_KEY: string;
  APP_INSIGHTS_CAPTURE_CAUGHT_ERROR: boolean;
  APP_INSIGHTS_DISABLE_CONSOLE_LOG: boolean;
  SENSELY_TOKEN_API_ENDPOINT: string;
  PRISMIC_API_URL: string;
}

const env: Env = {
  WEBAPP_VERSION: process.env.REACT_APP_WEBAPP_VERSION || ``,
  BASE_NAME: process.env.REACT_APP_BASE_NAME || ``,
  URL_APP_INFO: process.env.REACT_APP_URL_APP_INFO || ``,
  SIGN_OUT_URL: process.env.REACT_APP_SIGN_OUT_URL || ``,
  APP_VERSION_USER_AGENT_PREFIX:
    process.env.REACT_APP_APP_VERSION_USER_AGENT_PREFIX || ``,
  APP_VERSION_ENV: process.env.REACT_APP_APP_VERSION_ENV || ``,
  GA_ENV: process.env.REACT_APP_GA_ENV || ``,
  // APP_VERSION_EQ: process.env.REACT_APP_APP_VERSION_EQ || ``,
  // IAM_APP_ORIGINAL_NAME: process.env.REACT_APP_IAM_APP_ORIGINAL_NAME || ``,
  // IAM_APP_NAME_REPLACEMENT: process.env.REACT_APP_IAM_APP_NAME_REPLACEMENT || ``,
  // MAAM_ORIGINAL_REDIRECT_URI: process.env.REACT_APP_MAAM_ORIGINAL_REDIRECT_URI || ``,
  EMMA_SESSION_ID_COOKIE_NAME:
    process.env.REACT_APP_EMMA_SESSION_ID_COOKIE_NAME || ``,
  // MULE_XP_EMMA_LIFE_POLICY_LIST_API: process.env.REACT_APP_MULE_XP_EMMA_LIFE_POLICY_LIST_API || ``,
  EMMA_APP_USER_AGENT_PATTERN:
    process.env.REACT_APP_EMMA_APP_USER_AGENT_PATTERN || ``,
  FIRST_CARE_FEATURE:
    process.env.REACT_APP_FIRST_CARE_FEATURE == 'true' || false,
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || ``,
  ENABLE_GOOGLE_OPTIMIZE_AB_TEST:
    process.env.REACT_APP_ENABLE_GOOGLE_OPTIMIZE_AB_TEST === 'true' || false,
  CORP_WEB_COOKIES_NAME: process.env.REACT_APP_CORP_WEB_COOKIES_NAME || ``,
  IS_ENABLE_MOCK: process.env.REACT_APP_IS_ENABLE_MOCK === 'true' || false,
  // HKEMMA-100917 - GA3 Decommission - Remove TC Scripts
  // TAGCOMMANDER_HEADER_SCRIPT:
  //   process.env.REACT_APP_TAGCOMMANDER_HEADER_SCRIPT || ``,
  // TAGCOMMANDER_BODY_SCRIPT:
  //   process.env.REACT_APP_TAGCOMMANDER_BODY_SCRIPT || ``,
  EMMA_SYSTEM_DATE: process.env.REACT_APP_EMMA_SYSTEM_DATE || ``,
  FANCL_SYSTEM_DATE: process.env.REACT_APP_FANCL_SYSTEM_DATE || ``,
  FANCL_MAX_DATE: process.env.REACT_APP_FANCL_MAX_DATE || ``,
  POST_MESSAGE_ORIGIN:
    process.env.REACT_APP_APP_VERSION_ENV === 'LOCAL'
      ? '*'
      : window.location.origin,
  EMMA_WEB_SYSTEM_MAINTENANCE:
    process.env.REACT_APP_EMMA_WEB_SYSTEM_MAINTENANCE === 'true' || false,
  AKTIVO_ENDPOINT: process.env.REACT_APP_AKTIVO_ENDPOINT || '',
  AKTIVO_USE_PROXY: process.env.REACT_APP_AKTIVO_USE_PROXY || '',
  AKTIVO_API_CLIENT: process.env.REACT_APP_MOCK_AKTIVO_API_CLIENT || '',
  ALAKE_API_ENDPOINT: process.env.REACT_APP_ALAKE_API_ENDPOINT || '',
  GOOGLE_MAPS_KEY: process.env.REACT_APP_GOOGLE_MAPS_KEY || ``,
  INIT_APP_INFO: null,
  APP_INSIGHTS_INSTRUMENTATION_KEY:
    process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY || '',
  APP_INSIGHTS_CAPTURE_CAUGHT_ERROR:
    process.env.REACT_APP_APP_INSIGHTS_CAPTURE_CAUGHT_ERROR === 'true' || false,
  APP_INSIGHTS_DISABLE_CONSOLE_LOG:
    process.env.REACT_APP_APP_INSIGHTS_DISABLE_CONSOLE_LOG === 'true' || false,
  SENSELY_TOKEN_API_ENDPOINT:
    process.env.REACT_APP_SENSELY_TOKEN_API_ENDPOINT || '',
  PRISMIC_API_URL: process.env.REACT_APP_PRISMIC_API_URL || '',
};

// Run immediately when this file is loaded to avoid race condition
_.merge(env, window.__client_configs__ || {});
window.__client_configs__ = env;

export const initEnv = (): Promise<void> => {
  // env is now injected in HTML by server
  // @ts-ignore
  // _.merge(env, window.__client_configs__ || {});
  // // @ts-ignore
  // window.__client_configs__ = env;
  return Promise.resolve();

  // let p;
  // if (process.env.NODE_ENV === "development") {
  //     p = Promise.resolve().then(() => {
  //         // @ts-ignore
  //         window.__client_configs__ = env;
  //     });
  // } else {
  //     const envUrl = joinPath(getBaseUrl(), `env.json`);
  //     p = fetch(`${envUrl}?${Math.random()}`).then(res => res.json()).then(json => {
  //         _.merge(env, json);
  //         // @ts-ignore
  //         window.__client_configs__ = env;
  //     });
  // }
  // return p;
};

export default env;
