export function getRandom(): number {
  const randomBuffer: Uint32Array = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  return randomBuffer[0] / (0xffffffff + 1);
}

export function getRandomIntInclusive(max = 10, min = 0): number {
  const minValue: number = Math.ceil(min);
  const maxValue: number = Math.floor(max);
  return Math.floor(getRandom() * (maxValue - minValue + 1)) + minValue;
}
