import _ from 'lodash';
import { PolicyListService } from '../types/PolicyListService';
import { Policy } from '@axa-asia/utils';

export const getPoliciesSelector =
  (catid: {}) =>
  (state: {}): PolicyListService => {
    const policySelector = _.get(
      state,
      `hk-emma-web-core/policyList.policyList`,
    );
    const filteredPolicy =
      policySelector &&
      policySelector
        .filter((policies: any) =>
          catid === ''
            ? policies.policy.policyCategory === null
            : policies.policy.policyCategory === catid,
        )
        .sort(
          (a: any, b: any) =>
            new Date(a.paidToDateTime).getTime() -
            new Date(b.paidToDateTime).getTime(),
        );
    return filteredPolicy;
  };

export const getAllPoliciesSelector = (state: {}): {
  mdmId: string;
  policy: Policy;
  policyRiders?: Policy[];
}[] => {
  const policySelector = _.get(
    state,
    `hk-emma-web-core/policyList.policyList`,
    [],
  );
  return policySelector.sort(
    (a: any, b: any) =>
      new Date(a.paidToDateTime).getTime() -
      new Date(b.paidToDateTime).getTime(),
  );
};

export const getPolicyListLoading = (state: {}) => {
  const policyLisyLoadingSelector = _.get(
    state,
    `hk-emma-web-core/policyList.isLoading`,
  );
  return policyLisyLoadingSelector;
};

export const getPolicyListTitle = (state: {}) => {
  const policyListTitleSelector = _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-insurance.features`,
  );
  return policyListTitleSelector;
};

export const getFilterPolicyListByRegex = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-life.filterPolicyListByRegex`,
    {},
  );
};

export const getPolicyHiddenRidersList = (state: {}) => {
  const policyHiddenRidersListSelector = _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.policy-list.hiddenRiders`,
  );
  return policyHiddenRidersListSelector;
};

export const getIsHaveInsurePolicy = (state: {}) => {
  const policySelector = _.get(state, `hk-emma-web-core/policyList.policyList`);
  const isHaveInsurePolicy = _.every(
    policySelector,
    (item) => item.isOwnerPolicy,
  )
    ? false
    : true;
  return isHaveInsurePolicy;
};

export const getIsHaveNoInsurePolicy = (state: {}) => {
  const policySelector = _.get(state, `hk-emma-web-core/policyList.policyList`);
  const isHaveNoInsurePolicy = _.every(
    policySelector,
    (item) => item.isLifeOnlyInsuredPolicy,
  )
    ? false
    : true;
  return isHaveNoInsurePolicy;
};

export const getHelplines = (state: {}) => {
  const helpLines = _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-insurance.helpLine`,
  );
  return helpLines;
};

export const getOwnershipTypes = (state: {}) => {
  const helpLines = _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-insurance.ownershipType`,
  );
  return helpLines;
};

export const getPolicyListIsError = (state: {}) => {
  return _.get(state, `hk-emma-web-core/policyList.isError`);
};
