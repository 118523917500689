import React, { Component } from 'react';
import Slider from 'react-slick';
import './styles.scss';
import '../../common/scss/common.scss';
import { connect } from 'react-redux';
import { pushHaveReadNewFeatureSingle } from '../../actions/sideBarItemActions';
import { EMMA_INTRO } from '../../constants/constants';
import HomePagePopUpRegistry from '../../registries/HomePagePopUpRegistry';
import { getWelcomeLaunchpadFeatures } from '../../selectors/userSelectors';
import { string } from 'prop-types';
import { select } from '../../stores';
import { getToken } from '../../selectors/authSelectors';
import { getValidToken, isApp } from '../../utils';
import Lottie from 'lottie-react-web';
import { handleGaError } from '../../utils/generalGaEventAnalytics';

interface EmmaWelcomeSlickProps {
  onClickTryNow: Function;
  pushState: Function;
  slides: { src: string; alt: string; description: any; animation: any }[];
  getStartedLabel?: any;
  skipValue?: any;
  settings?: Object;
}
interface EmmaWelcomeSlickState {
  slickIndex: number;
}
class EmmaWelcomeSlick extends Component<
  EmmaWelcomeSlickProps,
  EmmaWelcomeSlickState
> {
  state = {
    slickIndex: 0,
  };

  onClickToGatc(animationButton: string) {
    const tcWindow: any = window;
    const token = select(getToken);
    const aToken = getValidToken(token);
    const displayedAnimationButton = animationButton;
    //ga#CWP5
    try {
      tcWindow.dataLayer.push({
        event: 'clickAnimationButton',
        displayedAnimationButton: displayedAnimationButton,
        userId: aToken,
      });
      
      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // if (!isApp()) {
      //   tcWindow.tC.event.clickAnimationButton(this, {
      //     displayedAnimationButton: displayedAnimationButton,
      //     AXAID: aToken,
      //   });
      // }
    } catch (e) {
      handleGaError(e);
    }
  }

  render() {
    const { onClickTryNow, pushState } = this.props;
    let settings = {};
    const defaultSettings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      arrows: false,
      afterChange: (slickIndex: number) => {
        this.setState({ slickIndex });
      },
    };
    if (!this.props.settings) {
      settings = defaultSettings;
    } else {
      settings = this.props.settings;
    }
    return (
      <div className="emma-welcome-wrap">
        <div className="content-align-right">
          <div></div>
          <div
            className="emma-welcome-carousel__skip"
            onClick={() => {
              this.onClickToGatc('Skip');
              onClickTryNow() || pushState();
            }}
            data-enable-ga
            data-ga-type="button"
          >
            {this.props.skipValue}
          </div>
        </div>
        <div className="emma-welcome-carousel emma-deprecated-slick">
          <Slider {...settings}>
            {this.props.slides.map((value, index) => {
              return (
                <div key={index} className="emma-welcome-carousel__inner">
                  <div className="content-align lottie-container">
                    {this.state.slickIndex === index && (
                      <Lottie
                        options={{
                          animationData: value.animation,
                          loop: false,
                          autoplay: false,
                        }}
                        speed={0.5}
                        width={'30%'}
                      />
                    )}
                  </div>
                  {
                    <div className="content-align">
                      <div className="emma-welcome-carousel__content">
                        {value.description &&
                          value.description.map(
                            (item: { text: string; highlighted: boolean }) => {
                              return (
                                <span
                                  className={
                                    item.highlighted
                                      ? 'emma-welcome-carousel__title'
                                      : 'emma-welcome-carousel__text'
                                  }
                                >
                                  {item.text}
                                </span>
                              );
                            },
                          )}
                      </div>
                    </div>
                  }
                </div>
              );
            })}
          </Slider>

          {/* <Slider {...settings} >
            {this.props.slides.map((value, index) => {
              console.log("index", index);
              return (
                <div key={index} className="emma-welcome-carousel__inner">
                  <div className="content-align">
                    <div className="animation" ref={this.getRef(index)}></div>
                  </div>
                  <div className="content-align">
                    <div className="emma-welcome-carousel__content">
                      {
                        value.description && value.description.map((item: { text: string; highlighted: boolean }) => {
                          return (
                            <span className={item.highlighted ? 'emma-welcome-carousel__title' : 'emma-welcome-carousel__text'}>{item.text}</span>
                          )
                        })
                      }
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider> */}

          {this.state.slickIndex === this.props.slides.length - 1 ? (
            <div className="emma-welcome-carousel__button content-align">
              <button
                type="button"
                onClick={() => {
                  this.onClickToGatc('Get Started');
                  onClickTryNow() || pushState();
                }}
                key={Math.random()}
                data-enable-ga
                data-ga-type="button"
                className="emma-stylesheet-primary-default-button"
              >
                {this.props.getStartedLabel}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  setAnimationsArr2(element: HTMLDivElement | null, index: number) {
    throw new Error('Method not implemented.');
  }
}
const mapStateToProps = (state: any) => ({
  launchpadFeatures: getWelcomeLaunchpadFeatures(state),
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    pushState: () => {
      HomePagePopUpRegistry.setIsShowPopupCompleted(EMMA_INTRO);
      dispatch(pushHaveReadNewFeatureSingle(EMMA_INTRO, 1, 2));
    },
  };
};
const areEqual = (prev: any, next: any) => {
  return prev.launchpadFeatures !== undefined;
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(EmmaWelcomeSlick, areEqual));
