import _ from 'lodash';
import { getAppModuleConfig } from '../../../selectors/configSelectors';
import { getCustomerPolicies } from '../../../selectors/userSelectors';
import {
  getBuildNumber,
  resolveLocalizedText,
  stringFormatter,
} from '../../../utils';
import { ChatbotConfig } from '../types/ChatbotConfig';

const CHATBOT_MODULE_NAME = 'chatbot';
const WEB_MODULE_NAME = '../../../modules/chatbot/chatbot';

export const getChatBotModuleConfig = (state: {}) => {
  const config = getAppModuleConfig<ChatbotConfig>(state, CHATBOT_MODULE_NAME);
  return config;
};

export const getChatBotModuleState = (state: {}) =>
  _.get(state, `${WEB_MODULE_NAME}`, {});

export const getChatbotUrl = (state: {}): string => {
  const config = getChatBotModuleConfig(state);
  const url = config && config.url;
  if (url) {
    return stringFormatter(resolveLocalizedText(url), {
      buildNumber: getBuildNumber(),
    });
  } else {
    return '';
  }
};

export const hasVhisPolicy = (state: any): boolean => {
  const customerPolicies = getCustomerPolicies(state) || [];
  const foundData = _.find(customerPolicies, function (o: any) {
    return o.isWiseGuardProPolicyFlag === 'Y';
  });
  return !!foundData;
  // return _.get(getChatBotModuleState(state), 'menuInfo.hasVhisPolicy', false);
};

export const getCallCentre = (state: any): string => {
  return hasVhisPolicy(state) ? 'vhisCallCentre' : 'callCentre';
};

export const getCallCentreTel = (state: any): string => {
  return _.get(
    getChatBotModuleConfig(state),
    `${getCallCentre(state)}.tel`,
    '',
  );
};
export const getCallCentreEmail = (state: any): string => {
  return _.get(
    getChatBotModuleConfig(state),
    `${getCallCentre(state)}.email`,
    '',
  );
};
export const getCallCentreFax = (state: any): string => {
  return _.get(
    getChatBotModuleConfig(state),
    `${getCallCentre(state)}.fax`,
    '',
  );
};
export const getCallCentreOperatingHours = (state: any): string => {
  return resolveLocalizedText(
    _.get(
      getChatBotModuleConfig(state),
      `${getCallCentre(state)}.operatingHours`,
      '',
    ),
  );
};
export const getCallCentreBusinessAddress = (state: any): string => {
  return resolveLocalizedText(
    _.get(
      getChatBotModuleConfig(state),
      `${getCallCentre(state)}.businessAddress`,
      '',
    ),
  );
};

export const getChatbotInjectMessage = (state: any): string => {
  return _.get(
    state,
    `hk-emma-web-module-chatbot/chatbot.injectedMessage`,
    false,
  );
};

export const getHideAvatar = (state: any): boolean => {
  const config = getChatBotModuleConfig(state);
  if (config) {
    return config.hideChatbotIcon;
  } else {
    return false;
  }
};

export const getMenuInformationApi = (state: {}): any => {
  const chatbotConfig: ChatbotConfig = getChatBotModuleConfig(state);
  if (!chatbotConfig) return null;
  return chatbotConfig.menuInformationUrl;
};

export const getShouldShowChatbot = (state: any): boolean => {
  return _.get(
    state,
    `hk-emma-web-module-chatbot/chatbot.shouldShowChatbot`,
    false,
  );
};

export const getSpeechBubbleList = (state: any) =>
  _.get(state, `hk-emma-web-module-chatbot/chatbot.speechBubbleList`, []);
export const getClosedSpeechBubble = (state: any) =>
  _.get(
    state,
    `hk-emma-web-module-chatbot/chatbot.closedSpeechBubbleCustState.closedSpeechBubble`,
    [],
  );
export const getClosedSpeechBubbleCustState = (state: any) =>
  _.get(
    state,
    `hk-emma-web-module-chatbot/chatbot.closedSpeechBubbleCustState`,
    {},
  );

// for close mode that no need to save to cust state (2 & 4)
export const getClosedSpeechBubbleLocalState = (state: any) =>
  _.get(
    state,
    `hk-emma-web-module-chatbot/chatbot.closedSpeechBubbleLocalState`,
    [],
  );
