import { getScreenNameAsync } from './WebScreenNameMappingUtil';

const screenData = {
  /**
   * Oldest url at the end
   */
  prevUrl: ['', ''],
  currentUrl: window.location.pathname,
  virtualHash: '',
  displayedButtonText: '',
};

const config: {
  callback: any;
  platform: string;
  screenNameMapping: Record<string, any[]>,
  getResolvedGaScreenName?: (pathname: string) => string
} = {
  callback: undefined,
  platform: undefined,
  screenNameMapping: undefined,
  getResolvedGaScreenName: undefined
};

/**
 * Trigger by listener when URL change (including URL hash change)
 * @param {*} callback
 * @param {*} platform
 * @param {*} screenNameMapping
 * @param {*} getResolvedGaScreenName Instead of using screenNameMapping, this function required to return a fully resolved screen name
 */
function onLocationUrlChange(callback, platform, screenNameMapping, getResolvedGaScreenName?: (pathname: string) => string) {
  try {
    screenData.currentUrl = window.location.pathname + screenData.virtualHash;
    if (
      typeof callback === 'function' &&
      screenData.prevUrl[0] !== screenData.currentUrl
    ) {
      // Get screen name
      if (getResolvedGaScreenName) {
        const preScreenName = getResolvedGaScreenName(screenData.prevUrl[0]);
        const currentScreenName = getResolvedGaScreenName(screenData.currentUrl);
        const currentScreenPath = screenData.currentUrl;

        // Pass to callback (should be a method to trigger GA)
        callback(preScreenName, currentScreenName, currentScreenPath);
      } else {
        Promise.all([
          getScreenNameAsync(screenData.prevUrl[0], platform, screenNameMapping),
          getScreenNameAsync(screenData.currentUrl, platform, screenNameMapping),
        ]).then((results) => {
          const preScreenName = results[0];
          const currentScreenName = results[1];
          const currentScreenPath = screenData.currentUrl;
  
          // Pass to callback (should be a method to trigger GA)
          callback(preScreenName, currentScreenName, currentScreenPath);
        });
      }
    }
    // updated preUrl to current url for next time url change
    updatePreviousPage(screenData.currentUrl);
  } catch (e) {
    console.error(e);
  }
}

function onNextRouteChange() {
  onLocationUrlChange(
    config.callback,
    config.platform,
    config.screenNameMapping,
    config.getResolvedGaScreenName
  );
}

/**
 * Register URL change listener with callback for GA screen change
 * @param {*} callback will be called when URL change
 * @param {*} history required for React app, can be undefined otherwise
 * @param {*} platform "WEB" | "APP" | "TABLET"
 * @param {*} screenNameMapping
 * @param {*} nextRouter required for NextJS app, can be undefined otherwise
 * * @param {*} getResolvedGaScreenName Instead of using screenNameMapping, this function required to return a fully resolved screen name
 */
function logPreviousUrl(callback, history, platform, screenNameMapping, nextRouter?, getResolvedGaScreenName?) {
  try {
    // Store config
    config.callback = callback;
    config.platform = platform;
    config.screenNameMapping = screenNameMapping;
    config.getResolvedGaScreenName = getResolvedGaScreenName;

    // First page as previous screen for next time url change
    updatePreviousPage(window.location.pathname + screenData.virtualHash);

    // Start listener to URL change
    if (history){
      history.listen(() => {
        onLocationUrlChange(callback, platform, screenNameMapping, getResolvedGaScreenName);
      });
    } else if (nextRouter) {
      nextRouter.events.on('routeChangeComplete', onNextRouteChange)
    }
    

    // Log the first loaded page
    onLocationUrlChange(callback, platform, screenNameMapping, getResolvedGaScreenName);
  } catch (e) {
    console.error(e);
  }
}

function updatePreviousPage(newScreen) {
  screenData.prevUrl[1] = screenData.prevUrl[0];
  screenData.prevUrl[0] = newScreen;
}

/**
 * Set the most recent clicked button text. It will be sent to GA KPI #64 in the next screen change
 * @param {*} displayedButtonText
 */
export const setDisplayedButtonText = (displayedButtonText) => {
  screenData.displayedButtonText = displayedButtonText;
};

/**
 * Call this when popup display or next popup display.
 * This will set popup name to virtual hash and trigger URL change listener (Mainly for KPI #64 and screen view)
 * The reason why use virtual hash is because URL hash will log into history and will impact of back/previous page behavior.
 * @param {*} gaPopupName
 */
export const triggerPopupDisplayScreenChange = (gaPopupName) => {
  screenData.virtualHash = `#${gaPopupName}`;
  onLocationUrlChange(
    config.callback,
    config.platform,
    config.screenNameMapping,
    config.getResolvedGaScreenName,
  );
};

/**
 * Call this when popup close.
 * If just display another popup, no need to call this but call onPopupDisplay().
 * This will clear virtual hash and trigger GA screen change (Mainly for KPI #64 and screen view)
 */
export const triggerPopupCloseScreenChange = () => {
  screenData.virtualHash = '';
  onLocationUrlChange(
    config.callback,
    config.platform,
    config.screenNameMapping,
    config.getResolvedGaScreenName,
  );
};

/**
 * Get the most recent clicked button text for other GA KPIs.
 */
export const getDisplayedButtonText = () => screenData.displayedButtonText;

/**
 * Get previous page for other GA KPIs.
 * Most recent url at index 0
 * @param  {number} index
 */
export const getPreviousUrl = (index?: number) =>
  index !== undefined && index >= 0
    ? screenData.prevUrl[index]
    : screenData.prevUrl[0];

/**
 * Get current page for other GA KPIs.
 */
export const getCurrentUrl = () => screenData.currentUrl;

/**
 * Get virtual hash for other GA KPIs.
 */
export const getVirtualHash = () => screenData.virtualHash;

export default logPreviousUrl;
