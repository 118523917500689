import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import {
  isShowModal,
  getPayload,
} from '../../../modules/emma-modal/selectors/selector';
import { objectFormat } from '../../../utils/WebUtils';
import { getLanguage } from '../../../selectors/userSelectors';

interface EmmaModalContainerProps {
  query: any;
  isShowModal: boolean;
}

interface EmmaModalContainerState {}

class EmmaModalContainer extends Component<
  EmmaModalContainerProps,
  EmmaModalContainerState
> {
  constructor(props: EmmaModalContainerProps) {
    super(props);
    this.state = {};
  }

  getModal(query: any) {
    const container = null;
    if (
      query &&
      query.moduleName === 'emma-modal' &&
      query.screenName === 'emma-modal-action-modal'
    ) {
      const ActionModalView = require('../ActionModal/ActionModalView').default;
      return (
        <ActionModalView
          {...{
            payload: query,
          }}
        />
      );
    }
    if (
      query &&
      query.moduleName === 'emma-modal' &&
      query.screenName === 'emma-modal-share-modal'
    ) {
      const ShareModalView = require('../ShareModal/ShareModalView').default;
      return (
        <ShareModalView
          {...{
            payload: query,
          }}
        />
      );
    }
    return container;
  }

  render() {
    const { isShowModal, query } = this.props;
    return isShowModal ? this.getModal(query) : null;
  }

  static mapStateToProps(state: any) {
    const language = getLanguage(state);
    const payload = getPayload(state);
    return {
      query: payload ? objectFormat(getPayload(state), language) : payload,
      isShowModal: isShowModal(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {};
  }
}

export default connect(
  EmmaModalContainer.mapStateToProps,
  EmmaModalContainer.mapDispatchToProps,
)(EmmaModalContainer);
