import { getVirtualHash } from './GaPreviousScreenUtil';

let EmmaGaConfig = {
  "button": "clickCTAButton",
  "tab": "clickCTAButton",
  "link": "clickCTAButton",
  "dropdown": "clickCTAButton",
}

export default function EmmaGaListener() {
  this.onClickBodyGaCallbacks = []
  this.bodyListenerInstance = null

  this.serve = clickBodyGaCallbacks => {
    this.onClickBodyGaCallbacks = clickBodyGaCallbacks
    this.bodyListenerInstance = document.body.addEventListener(
      'click',
      this.onClickBody,
    )
  }

  this.onClickBody = clickedEvent => {
    const virtualHash = getVirtualHash();
    const screenName = window.location.pathname + (virtualHash ? virtualHash : '');
    const displayedButtonText = this.parseDisplayingText(clickedEvent)
    const gaEventName = this.parseClickedElement(clickedEvent)

    if (gaEventName) {
      this.onClickBodyGaCallbacks.forEach(callback => {
        try {
          callback(gaEventName, displayedButtonText, screenName)
        } catch (e) {
          console.error(e)
        }
      })
    }
  }

  this.parseDisplayingText = (event: any) => {
    // Logic to get display text without HTML
    var htmlTag = '';
    var gaTarget = event.target;
    var findParentIndex = 0;
    var isHaveEnableGa = false;
    for (; findParentIndex < 7 && !(!gaTarget.parentElement || htmlTag);) {
      findParentIndex++;
      if (gaTarget.getAttribute('data-enable-ga')) {
        htmlTag = gaTarget.tagName.toLowerCase();
        isHaveEnableGa = true;
      } else {
        gaTarget = gaTarget.parentElement;
      }
    }

    var displayedButtonText = gaTarget.getAttribute('data-ga-text');
    if (!displayedButtonText) {
      displayedButtonText = gaTarget.innerText;
    }

    return displayedButtonText;
  }

  this.parseClickedElement = (event: any) => {
    let htmlTag = ''
    let gaTarget = event.target
    let findParentIndex = 0
    let isHaveEnableGa = false
    for (;findParentIndex<7 && !(!gaTarget.parentElement || htmlTag); ) {
      findParentIndex++
      if (gaTarget.getAttribute('data-enable-ga') && gaTarget.getAttribute('data-enable-ga') !== 'false') {
        htmlTag = gaTarget.tagName.toLowerCase()
        isHaveEnableGa = true
      } else {
        gaTarget = gaTarget.parentElement
      }
    }
    htmlTag = gaTarget.getAttribute('data-ga-type')
      ? gaTarget.getAttribute('data-ga-type')
      : htmlTag
    return isHaveEnableGa ? EmmaGaConfig[htmlTag] : ''
  }

  this.destory = () => {
    document.body.removeEventListener('click', this.bodyListenerInstance)
    this.bodyListenerInstance = null
  }
}
