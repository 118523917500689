import config from '../package.json';

const ID = `${config.name}/actions/offersFormActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const UPDATE_OFFERS_FORM_DATA = createActionName(
  'UPDATE_OFFERS_FORM_DATA',
);
export const INIT_OFFERS_FORM_PREFILL = createActionName(
  'INIT_OFFERS_FORM_PREFILL',
);
export const COMMIT_OFFERS_FORM = createActionName('COMMIT_OFFERS_FORM');
export const UPDATE_OFFERS_FORM_SUBMIT_RESPONSE = createActionName(
  'UPDATE_OFFERS_FORM_SUBMIT_RESPONSE',
);
export const SUBMIT_OFFERS_LEAD_FORM = createActionName(
  'SUBMIT_OFFERS_LEAD_FORM',
);
export const UPDATE_OFFERS_ISREVIEW = createActionName(
  'UPDATE_OFFERS_ISREVIEW',
);
export const UPDATE_OFFERS_REVIEW_LOADING = createActionName(
  'UPDATE_OFFERS_REVIEW_LOADING',
);
// HKEMMA-99501: offers API decommission - depreciated in Emma1.5
// export const FETCH_OFFERS_FEATURES = createActionName('FETCH_OFFERS_FEATURES');
// export const FETCH_OFFERS_FEATURES_SUCCESS = createActionName(
//   'FETCH_OFFERS_FEATURES_SUCCESS',
// );
// export const FETCH_OFFERS_FEATURES_FAILURE = createActionName(
//   'FETCH_OFFERS_FEATURES_FAILURE',
// );
export const UPDATE_PROMOTION_CHECKED = createActionName(
  'UPDATE_PROMOTION_CHECKED',
);
export const UPDATE_PROMOTION_ERROR_CODE = createActionName(
  'UPDATE_PROMOTION_ERROR_CODE',
);

// export const fetchOffers = () => ({
//   type: FETCH_OFFERS_FEATURES,
// });

export const updateOffersForm = (offersData: any) => ({
  type: UPDATE_OFFERS_FORM_DATA,
  offersData,
});

export const commitOffers = (submitOffersData: any, callback?: () => void) => ({
  type: COMMIT_OFFERS_FORM,
  submitOffersData,
  callback,
});

export const updateOffersFormResponse = (offersSubmitResponse: any) => ({
  type: UPDATE_OFFERS_FORM_SUBMIT_RESPONSE,
  offersSubmitResponse,
});

export const submitOffersLeadForm = (submitOffersLeadFormData: any) => ({
  type: SUBMIT_OFFERS_LEAD_FORM,
  submitOffersLeadFormData,
});

export const updateOffersIsReview = () => ({
  type: UPDATE_OFFERS_ISREVIEW,
});

export const updateReviewLoading = (loadingFlag: boolean) => ({
  type: UPDATE_OFFERS_REVIEW_LOADING,
  loadingFlag,
});

export const updatePromotionChecked = (checked: boolean) => ({
  type: UPDATE_PROMOTION_CHECKED,
  checked,
});

export const updatePromotionCode = (promotionErrorCode: string) => ({
  type: UPDATE_PROMOTION_ERROR_CODE,
  promotionErrorCode,
});
