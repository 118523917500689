import moment from 'moment';
import {
  AKTIVO_MODAL_DISPLAY,
  FETCH_AKTIVO_CUSTOMER_STATE_SUCCESS,
} from '../actions';

const defaultState = {
  showAktivoModal: false,
  disclaimerCustState: {},
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case AKTIVO_MODAL_DISPLAY:
      nextState.showAktivoModal = !!action.isShowAktivoModal;
      return nextState;
    case FETCH_AKTIVO_CUSTOMER_STATE_SUCCESS:
      nextState.disclaimerCustState =
        { ...action.disclaimerCustState, lastFatchTime: moment().format() } ||
        {};
      return nextState;
    default:
      return nextState;
  }
};
