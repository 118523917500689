import React, { Component, ComponentClass } from 'react';
import { LocalizedText } from '../types/LocalizedTextTypes';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { SideBarItemProps } from '../components/SideBarItem';

export interface SideBarUiItem {
  label: string;
  iconDefault: string;
  iconSelected: string;
}

/**
 * enableMobileRoute - true if you want to enable this route for app webview access. Webportal access will also be enabled by default.
 */
export interface RouteInfo {
  exact?: boolean;
  sidebarItem?: SideBarUiItem;
  container: React.ElementType; // container is ComponentClass
  enableMobileRoute?: boolean;
  excludedRoutes?: string[];
  includedRoutes?: string[];
}

export interface ModuleRouteInfo extends RouteInfo {
  moduleName: string;
}

export class RouteRegistry {
  private screens: { [route: string]: ModuleRouteInfo } = {};
  register(moduleName: string, routeName: string, route: RouteInfo) {
    this.screens[routeName] = { moduleName, ...route };
  }

  getScreens() {
    return this.screens;
  }
}

export default new RouteRegistry();
