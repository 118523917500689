import { combineReducers } from 'redux';
import changeEmail from './changeEmailReducer';
import changeMobile from './changeMobileReducer';
import changeContact from './changeContactReducer';
import customerProfile from './customerProfileReducer';
import customerResetUNOrPW from './customerResetUNOrPWReducer';
import customerResetUserName from './customerResetUserNameReducer';
import customerResetPassword from './customerResetPasswordReducer';
import customerProfileVerify from './customerProfileVerifyReducer';
import insuredAccess from './insuredAccessReducer';
import changeAddress from './changeAddressReducer';

export default combineReducers({
  changeAddress,
  changeEmail,
  changeMobile,
  changeContact,
  customerProfile,
  customerResetUNOrPW,
  customerResetUserName,
  customerResetPassword,
  customerProfileVerify,
  insuredAccess,
});
