import {
  FETCH_HOME_PROMO_SUCCESS,
  FETCH_HOME_PROMO_FAILURE,
  FETCH_HOME_PROMO_NOT_FOUND,
} from '../actions';

const defaultState = {
  homePromotion: {},
  error: {},
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_HOME_PROMO_SUCCESS:
      nextState.homePromotion = action.payload;
      return nextState;
    case FETCH_HOME_PROMO_FAILURE:
      nextState.homePromotion = {};
      nextState.error = action.payload;
      return nextState;
    case FETCH_HOME_PROMO_NOT_FOUND:
      nextState.homePromotion = {};
      return nextState;
    default:
      return nextState;
  }
};
