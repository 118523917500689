import { _get } from '../utils';
import config from '../package.json';

const reducerName = `${config.name}/abTest`;

const getFirebaseExperiments = (state: {}) => {
  return _get<any>(state, reducerName).firebaseExperiments;
};

const getOptimizeExperiments = (state: {}) => {
  return _get<any>(state, reducerName).optimizeExperiments;
};

/**
 * Return remote config in string format of <key>:<value>,<key>:<value>,...
 */
export const getExperimentInStr = (state: any): string => {
  const firebaseExperiments = getFirebaseExperiments(state);

  const experimentsArr = [];
  for (const [key, value] of Object.entries(firebaseExperiments)) {
    experimentsArr.push(`${key}:${value}`);
  }

  const optimizeExperiments = getOptimizeExperiments(state);
  for (const [key, value] of Object.entries(optimizeExperiments)) {
    experimentsArr.push(`${key}:${value}`);
  }
  return experimentsArr && experimentsArr.length > 0
    ? experimentsArr.join(',')
    : '';
};
