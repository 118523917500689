import { TestId, TestIdDict, TestIdMap } from './types';

/**
 * Please import the EmmaTestId
 */
export const EmmaTestId: TestIdMap = (() => {
  const keys = Object.values(TestId).filter((val) => typeof val === 'string');

  return keys.reduce((obj, acc, idx) => {
    obj[acc] = TestIdDict[idx];
    return obj;
  }, {} as TestIdMap);
})();
