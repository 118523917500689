import { get, set, del } from 'idb-keyval';
import { PrismicApiClient } from '@axa-asia/emma-prismic-api-client';
import env from '../env';
import { setEmmaHeaders } from '@axa-asia/common-api-utils';

const idbValidKey = 'prismic-api-client';

const match = env.WEBAPP_VERSION.match(/^[0-9]+\.[0-9]+\.[0-9]+/);
const webAppVersion = match ? match[0] : ``;

setEmmaHeaders({
  'X-AXA-APP-INFO': `${env.APP_VERSION_USER_AGENT_PREFIX}/WEB/${webAppVersion}-${env.APP_VERSION_ENV}`,
  'User-Agent': navigator.userAgent,
});

const prismicApiClientOptions = {
  persist: {
    persistClient: async (client) => {
      await set(idbValidKey, client);
    },
    restoreClient: async () => {
      return await get(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  },
};

const prismicApiClient = new PrismicApiClient(
  env.PRISMIC_API_URL,
  prismicApiClientOptions,
);
export default prismicApiClient;
