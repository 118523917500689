export const VHIS_TYPE = 'VHIS_TYPE';
export const VHIS_MODAL_DISPLAY = 'VHIS_MODAL_DISPLAY';
export const IS_ACCEPTED_DISCLAIMER = 'IS_ACCEPTED_DISCLAIMER';
export const IS_HEALTH_KEEPER_ACCOUNT_EXIST = 'IS_HEALTH_KEEPER_ACCOUNT_EXIST';
export const SUMMARY_DISPLAY_DATA = 'SUMMARY_DISPLAY_DATA';
export const FETCH_STEP_COUNT_SUMMARY = 'FETCH_STEP_COUNT_SUMMARY';
export const AXAGOAL_LOAD_FAIL = 'AXAGOAL_LOAD_FAIL';
export const FETCH_CAMPAIGN_STARTED = 'FETCH_CAMPAIGN_STARTED';
export const TOGGLE_CAMPAIGN_BUTTON = 'TOGGLE_CAMPAIGN_BUTTON';

export const FETCH_MENU_INFO = 'FETCH_MENU_INFO';
export const FETCH_MENU_INFO_SUCCESS = 'FETCH_MENU_INFO_SUCCESS';

export const FETCH_AXA_GOAL_CUSTOMER_STATE = 'FETCH_AXA_GOAL_CUSTOMER_STATE';
export const FETCH_AXA_GOAL_CUSTOMER_STATE_SUCCESS =
  'FETCH_AXA_GOAL_CUSTOMER_STATE_SUCCESS';
export const EMMA_AXA_GOAL_POPUP = 'EMMA_AXA_GOAL_POPUP';
export const ANCHOR_TO_RULES_MEMBERSHIP_YEAR =
  'ANCHOR_TO_RULES_MEMBERSHIP_YEAR';
export const IS_GET_TRACKER_STATUS = 'IS_GET_TRACKER_STATUS';
export const EMMA_AXA_GOAL_ISFIRST = 'EMMA_AXA_GOAL_ISFIRST';
export const CLOSED_AXA_GOAL_DISCLAIMER = 'CLOSED_AXA_GOAL_DISCLAIMER';

export const SHOW_AXA_GOAL_SEE_ALL_POLICY = 'SHOW_AXA_GOAL_SEE_ALL_POLICY';
export const SHOW_AXA_GOAL_SUPPORT = 'SHOW_AXA_GOAL_SUPPORT';

export const SHOW_MEMBERSHIP_TUTORIAL = 'SHOW_MEMBERSHIP_TUTORIAL';
export const HIDE_MEMBERSHIP_TUTORIAL = 'HIDE_MEMBERSHIP_TUTORIAL';

export const FETCH_AKTIVO_STEP_STATS = 'FETCH_AKTIVO_STEP_STATS';
export const FETCH_AKTIVO_STEP_STATS_SUCCESS =
'FETCH_AKTIVO_STEP_STATS_SUCCESS';

export const showMembershipTutorial = (ruleType = 'WGP') => ({
  type: SHOW_MEMBERSHIP_TUTORIAL,
  payload: {
    ruleType,
  },
});

export const hideMembershipTutorial = () => ({
  type: HIDE_MEMBERSHIP_TUTORIAL,
});

export const getVhisType = () => ({
  type: VHIS_TYPE,
});

export const fetchCampaignStarted = () => ({
  type: FETCH_CAMPAIGN_STARTED,
});
export const toggleCampaignButton = (showStartCampaignButton: boolean) => ({
  type: TOGGLE_CAMPAIGN_BUTTON,
  showStartCampaignButton,
});

export const setVhisType = (vhisType: string) => ({
  type: VHIS_TYPE,
  vhisType,
});

export const toggleVhisModelDisplay = (isShowVhisModalBoollean: boolean) => {
  const show = {
    isShowVhisModal: isShowVhisModalBoollean,
  };
  return {
    type: VHIS_MODAL_DISPLAY,
    show,
  };
};

export const onClosedAxaGoalDisclaimer = () => {
  return {
    type: CLOSED_AXA_GOAL_DISCLAIMER,
  };
};

export const updateSummaryDisplayData = (displayYear: string) => ({
  type: SUMMARY_DISPLAY_DATA,
  displayYear,
});

export const anchorToRulesMembership = (anchor: string) => ({
  type: ANCHOR_TO_RULES_MEMBERSHIP_YEAR,
  anchor,
});

export const updateIsGetTrackerStatus = (isGetTrackerStatus: boolean) => ({
  type: IS_GET_TRACKER_STATUS,
  isGetTrackerStatus,
});

export const updateIsFirstYear = (isFirstYear: boolean) => ({
  type: EMMA_AXA_GOAL_ISFIRST,
  isFirstYear,
});

export const showAxaGoalSeeAllPolicy = () => {
  return {
    type: SHOW_AXA_GOAL_SEE_ALL_POLICY,
  };
};

export const showAxaGoalSupport = (entityCode = '', ruleType = '') => {
  return {
    type: SHOW_AXA_GOAL_SUPPORT,
    entityCode,
    ruleType,
  };
};

export const fetchAktivoStepStats = (payload: any) => ({
  type: FETCH_AKTIVO_STEP_STATS,
  payload,
});
