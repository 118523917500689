import _ from 'lodash';
import { Disclaimer } from './type';

export const getDisclaimer = (state: {}, disclaimerId: string): Disclaimer => {
  const disclaimers = _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-common.disclaimers`,
    {},
  );
  for (const key in disclaimers) {
    if (key === disclaimerId) {
      const disclaimer: Disclaimer = disclaimers[key];
      return {
        ...disclaimer,
      };
    }
  }
  return {};
};

export const getShare = (state: {}, templateId: string): any =>
  _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-common.share.${templateId}`,
    undefined,
  );
