import config from '../package.json';
import {
  AddressFormForUpdate,
  AddressInLines,
} from '../types/changeAddressTypes';

const ID = `${config.name}/actions/customerProfileAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const LOAD_GOOGLE_MAP_API_SCRIPT = createActionName(
  'LOAD_GOOGLE_MAP_API_SCRIPT',
);
export const LOAD_GOOGLE_MAP_API_SCRIPT_SUCCESS = createActionName(
  'LOAD_GOOGLE_MAP_API_SCRIPT_SUCCESS',
);
export const AUTO_COMPLETE_ADDRESS_CHANGED = createActionName(
  'AUTO_COMPLETE_ADDRESS_CHANGED',
);
export const AUTO_COMPLETE_MAP_ADDRESS_CHANGED = createActionName(
  'AUTO_COMPLETE_MAP_ADDRESS_CHANGED',
);
export const AUTO_COMPLETE_MAP_ADDRESS_CHANGED_CONFIRM = createActionName(
  'AUTO_COMPLETE_MAP_ADDRESS_CHANGED_CONFIRM',
);
export const AUTO_COMPLETE_MAP_ADDRESS_RESET = createActionName(
  'AUTO_COMPLETE_MAP_ADDRESS_RESET',
);
export const SET_ADDRESS_FORM = createActionName('SET_ADDRESS_FORM');
export const CLEAR_ADDRESS_FORM = createActionName('CLEAR_ADDRESS_FORM');
export const UPDATE_ADDRESS_FORM_STATES = createActionName(
  'UPDATE_ADDRESS_FORM_STATES',
);

export const LOAD_GOOGLE_AUTO_COMPLETE_ADDRESS = createActionName(
  'LOAD_GOOGLE_AUTO_COMPLETE_ADDRESS',
);

export const FETCH_ADDRESS_OTHER_POLICY_LIST_SUCCESS = createActionName(
  'FETCH_ADDRESS_OTHER_POLICY_LIST_SUCCESS',
);
export const FETCH_ADDRESS_OTHER_POLICY_LIST_FAIL = createActionName(
  'FETCH_ADDRESS_OTHER_POLICY_LIST_FAIL',
);
export const UPDATE_ADDRESS_GOGREEN_LIST = createActionName(
  'UPDATE_ADDRESS_GOGREEN_LIST',
);
export const UPDATE_ADDRESS_OTHER_POLICIES = createActionName(
  'UPDATE_ADDRESS_OTHER_POLICIES',
);
export const UPDATE_SELECTED_LIST = createActionName('UPDATE_SELECTED_LIST');
export const REVIEW_CHANGE_ADDRESS_FORM = createActionName(
  'REVIEW_CHANGE_ADDRESS_FORM',
);

export const SUBMIT_CHANGE_ADDRESS_FORM = createActionName(
  'SUBMIT_CHANGE_ADDRESS_FORM',
);
export const SUBMIT_CHANGE_ADDRESS_FORM_SUCCESS = createActionName(
  'SUBMIT_CHANGE_ADDRESS_FORM_SUCCESS',
);
export const SUBMIT_CHANGE_ADDRESS_FORM_FAILURE = createActionName(
  'SUBMIT_CHANGE_ADDRESS_FORM_FAILURE',
);

export const UPDATE_ADDRESS_SAVE_FIELD = createActionName(
  'UPDATE_ADDRESS_SAVE_FIELD',
);

export const LOAD_GOOGLE_AUTO_COMPLETE_MAP = createActionName(
  'LOAD_GOOGLE_AUTO_COMPLETE_MAP',
);

export const SET_UP_SHOW_ADDRESS_POPUP = createActionName(
  'SET_UP_SHOW_ADDRESS_POPUP',
);
export const SET_UP_CONFIRM_LOADING_BUTTON = createActionName(
  'SET_UP_CONFIRM_LOADING_BUTTON',
);
export const SET_UP_IS_HK_ADDRESS_CASE = createActionName(
  'SET_UP_IS_HK_ADDRESS_CASE',
);

export const LOAD_GOOGLE_MAP_JS_SCRIPT = createActionName(
  'LOAD_GOOGLE_MAP_JS_SCRIPT',
);
export const UPDATELOAD_GOOGLE_MAP_JS_SCRIPT_STATUS = createActionName(
  'UPDATELOAD_GOOGLE_MAP_JS_SCRIPT_STATUS',
);
export const UPDATE_EDIT_PAGE_LOADING = createActionName(
  'UPDATE_EDIT_PAGE_LOADING',
);
export const UPDATE_IS_GOOGLE_MAP_JS_SCRIPT_ERROR = createActionName(
  'UPDATE_IS_GOOGLE_MAP_JS_SCRIPT_ERROR',
);
export const SET_IS_CHOSEN_NON_HK_ADDRESS = createActionName(
  'SET_IS_CHOSEN_NON_HK_ADDRESS',
);
export const SET_IS_CLEAR_SEARCH_VALUE = createActionName(
  'SET_IS_CLEAR_SEARCH_VALUE',
);
export const SET_IS_AUTO_FILLED = createActionName('SET_IS_AUTO_FILLED');

export const loadGoogleMapApiScript = () => ({
  type: LOAD_GOOGLE_MAP_API_SCRIPT,
});

export const loadGoogleMapJsScript = () => ({
  type: LOAD_GOOGLE_MAP_JS_SCRIPT,
});

export const updateLoadGoogleMapJsScriptStatus = (
  isGoogleMapJsScriptComplete: boolean,
) => ({
  type: UPDATELOAD_GOOGLE_MAP_JS_SCRIPT_STATUS,
  payload: {
    isGoogleMapJsScriptComplete,
  },
});

export const autoCompleteAddressChanged = (placeData: any) => ({
  type: AUTO_COMPLETE_ADDRESS_CHANGED,
  payload: {
    placeData,
  },
});

export const autoCompleteMapAddressChanged = (placeData: any) => ({
  type: AUTO_COMPLETE_MAP_ADDRESS_CHANGED,
  payload: {
    placeData,
  },
});

export const autoCompleteMapAddressChangedConfirm = () => ({
  type: AUTO_COMPLETE_MAP_ADDRESS_CHANGED_CONFIRM,
});

export const autoCompleteMapAddressReset = () => ({
  type: AUTO_COMPLETE_MAP_ADDRESS_RESET,
});

export const updateFormStates = (payload: any) => ({
  type: UPDATE_ADDRESS_FORM_STATES,
  payload,
});

export const updateGoGreenList = (payload: any) => ({
  type: UPDATE_ADDRESS_GOGREEN_LIST,
  payload,
});

export const updateAddressOtherPolices = () => ({
  type: UPDATE_ADDRESS_OTHER_POLICIES,
});

export const updateSelectedList = (selectedList: any[]) => ({
  type: UPDATE_SELECTED_LIST,
  selectedList,
});

export const reviewChangeAddressForm = () => ({
  type: REVIEW_CHANGE_ADDRESS_FORM,
});

export const loadGoogleAutoCompleteAddress = () => ({
  type: LOAD_GOOGLE_AUTO_COMPLETE_ADDRESS,
});

export const submitChangeAddressForm = (payload: any = {}) => ({
  type: SUBMIT_CHANGE_ADDRESS_FORM,
  payload,
});

export const updateAddressSaveField = (payload: AddressInLines) => ({
  type: UPDATE_ADDRESS_SAVE_FIELD,
  payload,
});

export const loadGoogleAutoCompleteMap = (objLocation?: any) => ({
  type: LOAD_GOOGLE_AUTO_COMPLETE_MAP,
  payload: {
    objLocation,
  },
});

export const setUpShowAddressPopup = (isShowAddressPopup: boolean) => ({
  type: SET_UP_SHOW_ADDRESS_POPUP,
  payload: {
    isShowAddressPopup,
  },
});

export const setUpConfirmLoadingButton = (isConfirmLoadingButton: boolean) => ({
  type: SET_UP_CONFIRM_LOADING_BUTTON,
  payload: {
    isConfirmLoadingButton,
  },
});

export const setUpIsHKAddressCase = (isHappyHKCase: boolean) => ({
  type: SET_UP_IS_HK_ADDRESS_CASE,
  payload: {
    isHappyHKCase,
  },
});

export const updateEditPageLoading = (isLoadingEditPage: boolean) => ({
  type: UPDATE_EDIT_PAGE_LOADING,
  payload: {
    isLoadingEditPage,
  },
});

export const setAddressForm = (payload: AddressFormForUpdate) => ({
  type: SET_ADDRESS_FORM,
  payload,
});

export const clearAddressForm = () => ({
  type: CLEAR_ADDRESS_FORM,
});

export const updateIsGoogleMapJsScriptError = (isError: boolean) => ({
  type: UPDATE_IS_GOOGLE_MAP_JS_SCRIPT_ERROR,
  isError,
});

export const setIsChosenNonHKAddress = (isNonHK: boolean) => ({
  type: SET_IS_CHOSEN_NON_HK_ADDRESS,
  payload: isNonHK,
});

export const setIsClearSearchValue = (isClear: boolean) => ({
  type: SET_IS_CLEAR_SEARCH_VALUE,
  payload: isClear,
});

export const setIsAutoFilled = (isAutoFilled: boolean) => ({
  type: SET_IS_AUTO_FILLED,
  payload: isAutoFilled,
});
