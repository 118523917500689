import { UserState } from '../reducers/userReducer';
import { _get } from '../utils';
import { AxaCustomer } from '../types/axaTypes';
import config from '../package.json';
import _ from 'lodash';
import { getMarketPlaceNewsApiUrl } from './configSelectors';
import { CUSTOMER_GENDER, ENTITY_CODE } from '../constants/constants';
import type { CustomerLogin } from '@axa-asia/utils';

const reducerName = `${config.name}/user`;

export const getLanguage = (state: any = {}) => {
  return window.location.pathname.split('/').find((s) => !!s) || 'en';
};

export const getCustomerLogin = (state: {}) => {
  return _get<CustomerLogin>(state, `${reducerName}.customer`);
};

export const getCustomer = (state: {}) => {
  return _get<AxaCustomer>(state, `${reducerName}.customer.profile[0]`);
};

export const getCmsProfile = (state: {}) => {
  return _get<string[]>(state, `${reducerName}.customer.profileCMS`) || [];
};

export const getCustomerGender = (state: {}) =>
  _get<string>(state, `${reducerName}.customer.profile[0].gender`, undefined);

export const getCustomerBetterMe = (state: {}) => {
  return _get<string>(state, `${reducerName}.customer.betterMeFlag`);
};

export const getCustomerInsuredPopUpFlag = (state: {}) => {
  return _get<string>(state, `${reducerName}.customer.insuredPopUpFlag`, 'N');
};

export const getCustomerECommEligibleFlag = (state: {}) => {
  return _get<string>(state, `${reducerName}.customer.ecommEligibleFlag`, 'N');
};

export const getCustomerPoliciesByPolicyNumber = (
  state: {},
  policyNumber: string,
) => {
  let policyModel;
  const policies = _get<string[]>(state, `${reducerName}.customer.policies`);
  if (policies) {
    policyModel = _.find(policies, function (o: any) {
      return o.policyNumber === policyNumber;
    });
  }
  return policyModel;
};

export const getCustomerIsCwp = (state: {}) => {
  const lobs = _get<string[]>(state, `${reducerName}.customer.lobs`);
  const isCwp = lobs && lobs.includes('CWP') ? true : false;
  return isCwp;
};

export const getCustomerIsLife = (state: {}) => {
  const lobs = _get<string[]>(state, `${reducerName}.customer.lobs`);
  const isLife =
    lobs &&
    lobs.some(
      (lob) =>
        lob.startsWith(ENTITY_CODE.LHK) || lob.startsWith(ENTITY_CODE.LMA),
    )
      ? true
      : false;
  return isLife;
};

export const getCustomerIsEHKOnly = (state: {}) => {
  const lobs = _get<string[]>(state, `${reducerName}.customer.lobs`);
  const isEHKOnly =
    lobs && lobs.includes(ENTITY_CODE.EHK) && lobs.length === 1 ? true : false;
  return isEHKOnly;
};

export const getCustomerIconGender = (state: {}) => {
  const isCWP = getCustomerIsCwp(state);
  const gender = getCustomerGender(state);

  return isCWP ? CUSTOMER_GENDER.UNKNOWN : gender;
};

export const getCustomerContentoType = (state: {}) => {
  const lobs = _get<string[]>(state, `${reducerName}.customer.lobs`);
  const policies = _get<any[]>(state, `${reducerName}.customer.policies`);
  const promotionOffer: any = getMarketPlaceNewsApiUrl(state);
  const axaEbList: any[] = (promotionOffer && promotionOffer.axaEbList) || [];

  const customerContentoType: string[] = [];
  if (lobs && lobs.length > 0) {
    lobs.forEach((item) => {
      if (item.includes(ENTITY_CODE.EHK)) {
        const result = policies.find((policy) =>
          axaEbList.find((substring) =>
            policy.policyNumber.startsWith(substring),
          ),
        );
        if (result) {
          customerContentoType.push('AXA_EB');
        } else {
          customerContentoType.push('NON_AXA_EB');
        }
      } else if (
        item.includes('CWP') ||
        item.includes('LHK') ||
        item.includes('G4HK')
      ) {
        !customerContentoType.includes('CWP_LIFE') &&
          customerContentoType.push('CWP_LIFE');
      }
    });
  }
  return customerContentoType;
};

export const getWelcomeLaunchpadFeatures = (state: {}): object => {
  return _get(state, `hk-emma-web-core/app.moduleConfig.emma-intro`, {});
  // return welcomeLaunchpadMock;
};

export const getCustomerPolicies = (state: {}) => {
  return _get<Array<any>>(state, `${reducerName}.customer.policies`, []);
};

export const getCustomerFeatures = (state: {}) => {
  return _get<Array<string>>(state, `${reducerName}.customer.features`, []);
};

export const getAxaGoalInsuredPolicies = (state: {}): any => {
  const policies = getCustomerPolicies(state);
  return policies.filter(
    (p: any) =>
      (p.isOwnIsuredFlag === 'Y' ||
        (p.isInsuredFlag === 'Y' && p.isOwnIsuredFlag !== 'Y')) && // is only-insured / OWN-INS
      p.axaGoalPolicy === 'Y',
  );
};

export const getAxaGoalRuleType = (state: {}): 'SH' | 'WGP' | 'BOTH' => {
  const policies = getAxaGoalInsuredPolicies(state);
  const result = policies.reduce(
    (acc, p) => {
      if (p.isWiseGuardProPolicyFlag === 'Y') {
        acc['WGP'] = true;
      }
      if (p.isSilverHairPolicyFlag === 'Y') {
        acc['SH'] = true;
      }
      return acc;
    },
    {
      WGP: false,
      SH: false,
    },
  );
  if (result.WGP && result.SH) {
    return 'BOTH';
  }
  if (result.SH) {
    return 'SH';
  }
  return 'WGP';
};

export const getAxaGoalWiseGuardProPolicies = (state: {}): any => {
  const policies = getAxaGoalInsuredPolicies(state);
  return policies.filter((p) => p.isWiseGuardProPolicyFlag === 'Y');
};

export const getAxaGoalSilverHairPolicies = (state: {}): any => {
  const policies = getAxaGoalInsuredPolicies(state);
  return policies.filter((p) => p.isSilverHairPolicyFlag === 'Y');
};
