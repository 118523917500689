import config from '../package.json';

const ID = `${config.name}/actions/psActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const CARD_VIEW_MOUNTED = createActionName('CARD_VIEW_MOUNTED');
export const DETAILS_VIEW_MOUNTED = createActionName('DETAILS_VIEW_MOUNTED');

export const CARD_VIEW_CONTENT_LOADED = createActionName(
  'CARD_VIEW_CONTENT_LOADED',
);
export const DETAILS_VIEW_CONTENT_LOADED = createActionName(
  'DETAILS_VIEW_CONTENT_LOADED',
);

export const FETCH_EHUB_AUTH_TOKEN_SUCCESS = createActionName(
  'FETCH_EHUB_AUTH_TOKEN_SUCCESS',
);
export const FETCH_EHUB_AUTH_TOKEN_FAILURE = createActionName(
  'FETCH_EHUB_AUTH_TOKEN_FAILURE',
);

export const fetchEHubAuthTokenSuccess = (authToken: string) => ({
  type: FETCH_EHUB_AUTH_TOKEN_SUCCESS,
  payload: {
    authToken,
  },
});

export const fetchEHubAuthTokenFailure = (error: any) => ({
  type: FETCH_EHUB_AUTH_TOKEN_FAILURE,
  payload: error,
});
