// Useful for standalone packages to get data from url
// All our application used different routing mechnisms

import { match } from 'path-to-regexp';

const withPrecedingSlash = (str: string) => (/^\//.test(str) ? str : `/${str}`);

const getVariableNamesFromEmmaUrl = (emmaUrl: string) => {
  const matches = emmaUrl.match(/\$\{[^{}]+\}/g) || [];
  return matches.map((m) => m.replace(/[${}]/g, ``)) as string[];
};

/**
 * Convert EMMA URL to React Router / Universal Router (EMMA WEB / LIFE / EB / FC / IAM) pattern
 * @param {string} emmaUrl Profile API URL pattern
 * @returns {string} Regex Url pattern that can be use on React Router / Universal Router (EMMA WEB / LIFE / EB / FC / IAM)
 */
export const getPathRegexpFromEmmaUrl = (emmaUrl: string) => {
  const escapedUrl = emmaUrl.split('?').join('\\?');
  const variableNames = getVariableNamesFromEmmaUrl(escapedUrl);

  return withPrecedingSlash(
    variableNames
      .map((x) => '${' + x + '}')
      .reduce(
        (str, template, i) => str.replace(template, `:${variableNames[i]}`),
        escapedUrl,
      ),
  );
};

/**
 * Get params from location.href and Profile API pattern
 * In order to get policy number, use {pn}
 * @param {string} emmaUrl Profile API URL pattern
 * @returns {Object} Object with key value pair
 */
export const getDataFromUrl = (emmaUrl: string) => {
  const fn = match(getPathRegexpFromEmmaUrl(emmaUrl), {
    decode: decodeURIComponent,
    start: false,
    end: false,
  });

  const asPath = location.pathname + location.search;

  const data = fn(asPath);

  const result = {};
  const hasPn = getVariableNamesFromEmmaUrl(emmaUrl).includes('policyNumber');

  if (data) {
    Object.assign(result, data.params ?? {});
  }

  if (hasPn) {
    result['policyNumber'] = false;
    if ('getEmmaPn' in window && typeof window.getEmmaPn === 'function') {
      result['policyNumber'] = window.getEmmaPn();
    }
  }

  return result;
};

/**
 * Repleace variables with values in Profile API Url pattern
 * @param {string} emmaUrl Profile API URL pattern
 * @param {Object} params Object with key value pair
 * @returns {string} url that can be use in goto
 */
export const getUrlWithParams = (
  emmaUrl: string,
  params: Record<string, string>,
) => {
  const variableNames = getVariableNamesFromEmmaUrl(emmaUrl);

  return variableNames
    .map((x) => '${' + x + '}')
    .reduce(
      (str, template, i) => str.replace(template, params[variableNames[i]]),
      emmaUrl,
    );
};
