import { _get } from '../utils';
import config from '../package.json';
import { store } from '../stores';
import { getPnStateKey } from '../utils/policyNumberUtil';
import { getAllPoliciesSelector } from '../modules/policy-list/selectors/policyListSelectors';

const reducerName = `${config.name}/pn`;

export const getPolicyNumber = (state: {}) => {
  const key = getPnStateKey();
  const policyNumber = _get(state, `${reducerName}.${key}`, '');
  return policyNumber;
};

export function getPolicyNumberFromStore() {
  const policyNumber = getPolicyNumber(store.getState());
  return policyNumber;
}

export function getAllPoliciesFromStore() {
  const policyList = getAllPoliciesSelector(store.getState()).map((e) => ({
    ...e?.policy,
    policyRiders: e?.policyRiders,
  }));
  return policyList;
}
