import config from '../package.json';

const ID = `${config.name}/actions/aktivoIndividualChallengeAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_START = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_START',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_END = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_END',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL_SUCCESS =
  createActionName('FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL_SUCCESS');

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED_SUCCESS =
  createActionName('FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED_SUCCESS');

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_START = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_START',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_END = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_END',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_SUCCESS =
  createActionName('FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_SUCCESS');

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL = createActionName(
  'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL',
);

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUCCESS =
  createActionName('FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUCCESS');

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT =
  createActionName('FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT');

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_START =
  createActionName(
    'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_START',
  );

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_END =
  createActionName(
    'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_END',
  );

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_SUCCESS =
  createActionName(
    'FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_SUCCESS',
  );

export const FILTER_AKTIVO_INDIVIDUAL_CHALLENGE = createActionName(
  'FILTER_AKTIVO_INDIVIDUAL_CHALLENGE',
);

export const FILTER_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS = createActionName(
  'FILTER_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS',
);

export const SORTING_AKTIVO_INDIVIDUAL_CHALLENGE = createActionName(
  'SORTING_AKTIVO_INDIVIDUAL_CHALLENGE',
);

export const SORTING_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS = createActionName(
  'SORTING_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS',
);

export const SET_AKTIVO_INDIVIDUAL_CHALLENGE_CURRENT_TAB_INDEX =
  createActionName('SET_AKTIVO_INDIVIDUAL_CHALLENGE_CURRENT_TAB_INDEX');

export const FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH_SUCCESS =
  createActionName('FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH_SUCCESS');

export const RELOAD_FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH =
  createActionName('RELOAD_FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH');

export const OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER = createActionName(
  'OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER',
);

export const OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING = createActionName(
  'OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING',
);

export const END_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER = createActionName(
  'END_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER',
);

export const END_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING = createActionName(
  'END_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING',
);

export const fetchAktivoIndividualChallenge = () => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE,
});

export const fetchAktivoIndividualChallengeSuccess = (data: any) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS,
  payload: {
    aktivoIndividualChallenge: data,
  },
});

export const fetchAktivoIndividualChallengeNoEroll = () => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL,
});

export const fetchAktivoIndividualChallengeNoErollSuccess = (data: any) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL_SUCCESS,
  payload: {
    aktivoIndividualChallengeNoEnroll: data,
  },
});

export const fetchAktivoIndividualChallengeCompleted = () => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED,
});

export const fetchAktivoIndividualChallengeCompletedSuccess = (data: any) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED_SUCCESS,
  payload: {
    aktivoIndividualChallengeCompleted: data,
  },
});

export const fetchAktivoIndividualChallengedDetail = (
  aktivoChallengeId: any,
) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL,
  payload: {
    aktivoChallengeId: aktivoChallengeId,
  },
});

export const fetchAktivoIndividualChallengedDetailSuccess = (data: any) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_SUCCESS,
  payload: {
    aktivoIndividualChallengeDetail: data,
  },
});

export const fetchAktivoIndividualChallengedEnrollDetail = (
  aktivoChallengeId: any,
) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL,
  payload: {
    aktivoChallengeId: aktivoChallengeId,
  },
});

export const fetchAktivoIndividualChallengedEnrollDetailSuccess = (
  data: any,
) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUCCESS,
  payload: {
    aktivoIndividualChallengeEnrollDetail: data,
  },
});

export const submitAktivoIndividualChallengedEnrollDetail = (
  aktivoChallengeId: any,
) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT,
  payload: {
    aktivoChallengeId: aktivoChallengeId,
  },
});

export const filterAktivoIndividualChallenge = (
  categoryList: any,
  challengeTypeList: any,
) => ({
  type: FILTER_AKTIVO_INDIVIDUAL_CHALLENGE,
  payload: {
    categoryList,
    challengeTypeList,
  },
});

export const filterAktivoIndividualChallengeSuccess = (
  categoryList: any,
  challengeTypeList: any,
) => ({
  type: FILTER_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS,
  payload: {
    categoryList,
    challengeTypeList,
  },
});

export const sortingAktivoIndividualChallenge = (orderBy: any) => ({
  type: SORTING_AKTIVO_INDIVIDUAL_CHALLENGE,
  payload: {
    orderBy,
  },
});

export const setAktivoIndividualChallengeCurrentTabIndex = (
  currentTabIndex: any,
) => ({
  type: SET_AKTIVO_INDIVIDUAL_CHALLENGE_CURRENT_TAB_INDEX,
  payload: {
    currentTabIndex,
  },
});

export const fetchAktivoIndividualChallengeHealthInsighSuccess = (
  data: any,
) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH_SUCCESS,
  payload: {
    aktivoIndividualChallengeHealthInsigh: data,
  },
});

export const reloadFetchAktivoIndividualChallengeHealthInsigh = () => ({
  type: RELOAD_FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH,
});

export const openAktivoIndividualChallengeFilter = () => ({
  type: OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER,
});

export const openAktivoIndividualChallengeSorting = () => ({
  type: OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING,
});

export const endAktivoIndividualChallengeFilter = () => ({
  type: END_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER,
});

export const endAktivoIndividualChallengeSorting = () => ({
  type: END_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING,
});

export const sortingAktivoIndividualChallengeSuccess = (orderBy: any) => ({
  type: SORTING_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS,
  payload: {
    orderBy,
  },
});

export const filterAktivoIndividualChallengeEnrollDetailSubmitSuccess = (
  aktivoChallengeId: any,
) => ({
  type: FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_SUCCESS,
  payload: {
    aktivoChallengeId,
  },
});
