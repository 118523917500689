import config from '../package.json';
const ID = `${config.name}/actions/customerProfileAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_CUSTOMER_PROFILE_FEATURES = createActionName(
  'FETCH_CUSTOMER_PROFILE_FEATURES',
);

export const FETCH_CUSTOMER_PROFILE_FEATURES_SUCCESS = createActionName(
  'FETCH_CUSTOMER_PROFILE_FEATURES_SUCCESS',
);

export const FETCH_CUSTOMER_PROFILE_FEATURES_FAILURE = createActionName(
  'FETCH_CUSTOMER_PROFILE_FEATURES_FAILURE',
);

export const CHANGE_CUSTOMER_PROFILE_LOADING = createActionName(
  'CHANGE_CUSTOMER_PROFILE_LOADING',
);

export const fetchCustomerPermissions = () => ({
  type: FETCH_CUSTOMER_PROFILE_FEATURES,
});
