type EnumDictionary<T extends string | symbol | number, U> = {
  [K in T]: U;
};

type TestIdString = keyof typeof TestId;

export type TestIdMap = Record<TestIdString, string>;

export enum Element {
  BUTTON = 'button',
  DROPDOWN = 'dropdown',
  INPUT = 'input',
}

export enum Action {
  BACK = 'back',
  CANCEL = 'cancel',
  DISMISS = 'dismiss',
  GOTO = 'goto',
  LOGIN = 'login',
  RETRY = 'retry',
  SKIP = 'skip',
  SUBMIT = 'submit',
  ENQUIRE = 'enquire',
  TEXT = 'text',
  UPLOAD = 'upload',
}

export enum Feature {
  ARTICLE_CARD = 'articleCard',
  AVATAR = 'avatar',
  CLAIM_TYPE = 'claimType',
  DL_DOC = 'dlDoc',
  FEATURE_ICON = 'featureIcon',
  LANGUAGE = 'language',
  POLICY_CARD = 'policyCard',
  PROMOTION_CARD = 'promotionCard',
  REGISTRATION = 'registration',
  REMINDER = 'reminder',
  TAB = 'tab',
}

export enum TestId {
  INPUT_TEXT,
  INPUT_UPLOAD,
  BUTTON_BACK,
  BUTTON_CANCEL,
  BUTTON_DISMISS,
  BUTTON_GOTO,
  BUTTON_LOGIN,
  BUTTON_RETRY,
  BUTTON_SKIP,
  BUTTON_SUBMIT,
  BUTTON_ARTICLE_CARD,
  BUTTON_AVATAR,
  BUTTON_DL_DOC,
  BUTTON_FEATURE_ICON,
  BUTTON_LANGUAGE,
  BUTTON_POLICY_CARD,
  BUTTON_PROMO_CARD,
  BUTTON_REGISTRATION,
  BUTTON_REMINDER,
  BUTTON_TAB,
  BUTTON_ENQUIRE,
  DROPDOWN_CLAIM_TYPE,
}

export const TestIdDict: EnumDictionary<TestId, string> = {
  [TestId.INPUT_TEXT]: `${Element.INPUT}-${Action.TEXT}`,
  [TestId.INPUT_UPLOAD]: `${Element.INPUT}-${Action.UPLOAD}`,
  [TestId.BUTTON_BACK]: `${Element.BUTTON}-${Action.BACK}`,
  [TestId.BUTTON_CANCEL]: `${Element.BUTTON}-${Action.CANCEL}`,
  [TestId.BUTTON_DISMISS]: `${Element.BUTTON}-${Action.DISMISS}`,
  [TestId.BUTTON_GOTO]: `${Element.BUTTON}-${Action.GOTO}`,
  [TestId.BUTTON_LOGIN]: `${Element.BUTTON}-${Action.LOGIN}`,
  [TestId.BUTTON_RETRY]: `${Element.BUTTON}-${Action.RETRY}`,
  [TestId.BUTTON_SKIP]: `${Element.BUTTON}-${Action.SKIP}`,
  [TestId.BUTTON_SUBMIT]: `${Element.BUTTON}-${Action.SUBMIT}`,
  [TestId.BUTTON_ARTICLE_CARD]: `${Element.BUTTON}-${Feature.ARTICLE_CARD}`,
  [TestId.BUTTON_AVATAR]: `${Element.BUTTON}-${Feature.AVATAR}`,
  [TestId.BUTTON_DL_DOC]: `${Element.BUTTON}-${Feature.DL_DOC}`,
  [TestId.BUTTON_FEATURE_ICON]: `${Element.BUTTON}-${Feature.FEATURE_ICON}`,
  [TestId.BUTTON_LANGUAGE]: `${Element.BUTTON}-${Feature.LANGUAGE}`,
  [TestId.BUTTON_POLICY_CARD]: `${Element.BUTTON}-${Feature.POLICY_CARD}`,
  [TestId.BUTTON_PROMO_CARD]: `${Element.BUTTON}-${Feature.PROMOTION_CARD}`,
  [TestId.BUTTON_REGISTRATION]: `${Element.BUTTON}-${Feature.REGISTRATION}`,
  [TestId.BUTTON_REMINDER]: `${Element.BUTTON}-${Feature.REMINDER}`,
  [TestId.BUTTON_TAB]: `${Element.BUTTON}-${Feature.TAB}`,
  [TestId.BUTTON_ENQUIRE]: `${Element.BUTTON}-${Action.ENQUIRE}`,
  [TestId.DROPDOWN_CLAIM_TYPE]: `${Element.DROPDOWN}-${Feature.CLAIM_TYPE}`,
};
