import { useState } from 'react';
import { singletonHook } from 'react-singleton-hook';

export const singletonState = <T>(
  initState: (() => T) | undefined,
  name: string,
) => {
  let globalSetState: (d: React.SetStateAction<T>) => void = () => {
    console.log(`you must ${name} before setting its state`);
  };

  let isHookInitResolve: () => void;
  const isHookInit = new Promise<void>((resolve) => {
    isHookInitResolve = () => resolve();
  }).then(() => {
    console.log(`Singleton hook ${name} is initialized`);
  });

  const useGlobalState = singletonHook(initState, () => {
    const [state, setState] = useState(initState);
    globalSetState = setState;
    isHookInitResolve();
    return state;
  });

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const setGlobalState = (s: React.SetStateAction<T>, cb = () => {}) => {
    isHookInit
      .then(() => {
        globalSetState(s);
        setTimeout(cb, 100);
      })
      .catch(console.error);
  };

  return { useGlobalState, setGlobalState };
};

export const singletonCallback = <T>(initCallback: T, name: string) => {
  const payload = singletonState(() => ({ cb: initCallback }), name);

  return {
    useGlobalCallback: () => payload.useGlobalState().cb,
    setGlobalCallback: (cb: T, cb2?: () => void) => {
      payload.setGlobalState(
        () => ({
          cb,
        }),
        cb2,
      );
    },
  };
};
