import React, { Component, StatelessComponent } from 'react';
import './styles.css';
import {
  resolvePublicAssets,
  isExternalAssets,
  isApp,
} from '../../../../utils';
import { select } from '../../../../stores';
import { getToken } from '../../../../selectors/authSelectors';
import { getValidToken } from '../../../../utils/index';
import {
  getGaPagePath,
  handleGaError,
} from '../../../../utils/generalGaEventAnalytics';

interface ChatbotButtonProps {
  isShowing: boolean;
  showChat: () => void;
  avator: any;
  avatorWithSpeechBubble: any;
  showingSpeechBubble?: boolean;
}

interface ChatbotButtonState {}

export default class ChatbotButton extends Component<
  ChatbotButtonProps,
  ChatbotButtonState
> {
  constructor(props: ChatbotButtonProps) {
    super(props);
    this.state = {};
  }

  onClickToGatc = () => {
    const tcWindow: any = window;
    const token = select(getToken);
    const aToken = getValidToken(token);
    try {
      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // tcWindow.tC.event.partnerClicks(tcWindow, {
      //   "icon": 'chatbot',
      //   "page": window.location.pathname
      // })
      //ga#25 (MP5 / DAY11)
      tcWindow.dataLayer.push({
        event: 'partnerClicks',
        icon: 'chatbot',
        screenName: getGaPagePath(),
        userId: aToken,
      });

      // HKEMMA-100917 - GA3 Decommission - Remove GA3
      // if (!isApp()) {
      //   tcWindow.tC.event.trackEvent(tcWindow, {
      //     icon: 'chatbot',
      //     screenName: getGaPagePath(),
      //     AXAID: aToken,
      //   });
      // }
    } catch (e) {
      handleGaError(e);
    }
  };

  render() {
    const {
      isShowing,
      showChat,
      avator,
      avatorWithSpeechBubble,
      showingSpeechBubble = false,
    } = this.props;
    const {} = this.state;

    return (
      <div className="chatbot-module-chatbot-button-component">
        <div
          className={`chatbotButton`}
          style={showingSpeechBubble ? { display: 'none' } : {}}
        >
          <img
            src={resolvePublicAssets(
              `/assets/images/animated_avator_shadow.png`,
            ).replace('.png', '@3x.png')}
            className="chatbot-shadow"
          />
          <img
            src={
              isExternalAssets(avator)
                ? avator
                : resolvePublicAssets(avator).replace('.png', '@3x.png')
            }
            className="chatbot-icon"
          />
          {/* <div onClick={showChat} className="rectangle" ><div><div></div><div></div><div></div></div></div> */}
          <div
            className={`clickable ${
              showingSpeechBubble ? 'circle-shadow' : ''
            }`}
            onClick={() => {
              this.onClickToGatc();
              showChat();
            }}
            data-enable-ga
            data-ga-type="button"
            data-ga-text="Chatbot icon"
          ></div>
        </div>
        <div
          className={`chatbotButton`}
          style={showingSpeechBubble ? {} : { display: 'none' }}
        >
          <img
            src={
              isExternalAssets(avatorWithSpeechBubble)
                ? avatorWithSpeechBubble
                : resolvePublicAssets(avatorWithSpeechBubble).replace(
                    '.png',
                    '@3x.png',
                  )
            }
            className="chatbot-icon"
          />
          <div
            className={`clickable circle-shadow`}
            onClick={() => {
              this.onClickToGatc();
              showChat();
            }}
            data-enable-ga
            data-ga-type="button"
            data-ga-text="Chatbot icon"
          ></div>
        </div>
      </div>
    );
  }
}
