export const getDocReferrer = (): string => {
  // List of document referrer whitelisted domains
  const whitelist: string[] = [
    '',
    'https://dev-emma.axa.com.hk',
    'https://sit-emma.axa.com.hk',
    'https://uat-emma.axa.com.hk',
    'https://preprod-emma.axa.com.hk',
    'https://emma.axa.com.hk',
    'https://emma-chatbot-nprd.axa.com.hk',
    'https://emma-chatbot-pprd.axa.com.hk',
    'https://emma-chatbot.axa.com.hk',
  ];

  const referrer: string | undefined = document?.referrer;
  // const clientConfig = getClientConfig();
  // const isDevelopment = clientConfig?.nodeEnv === 'development';
  const isDevelopment = /localhost(:\d+)?/.test(location.origin);

  if (isDevelopment) {
    return window.location.origin;
  }

  if (whitelist.some((url) => referrer.includes(url))) {
    return referrer;
  } else {
    throw new Error(`The document.referrer ${referrer} is not whitelisted.`);
  }
};
