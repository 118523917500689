export function maskMobileNumber(str: string) {
  if (!str) return;

  const tmp = [''];

  for (let i = str.length - 1; i >= 0; i--) {
    let l = tmp.length - 1;
    const curr = tmp[l];
    if (curr.length === 4) {
      tmp.push('');
      l++;
    }
    tmp[l] = str[i] + tmp[l];
  }

  tmp.reverse();

  // Mask all exclude the last section
  for (let i = 0; i < tmp.length - 1; i++) {
    tmp[i] = '*'.repeat(tmp[i].length);
  }

  // Mask the first digit of the last section
  const lastSectionInChar = [...tmp[tmp.length - 1]];
  lastSectionInChar[0] = '*';
  tmp[tmp.length - 1] = lastSectionInChar.join('');

  return tmp.join(' ');
}
