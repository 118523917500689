import { AktivoConfig } from './../types/authTypes';
import {
  FETCH_ACCESS_TOKEN_SUCCESS,
  FETCH_ACCESS_TOKEN_FAILURE,
  FETCH_AUTH_CODE_SUCCESS,
  FETCH_ADDITIONAL_ACCESS_TOKEN_SUCCESS,
  FETCH_ADDITIONAL_AUTH_CODE_SUCCESS,
  REFRESH_TOKEN_SUCCESS,
  REMOVE_EXPIRED_TOKEN,
  POST_REDIRECT_URI,
  SIGN_OUT,
  REFRESH_ADDITIONAL_ACCESS_TOKEN_SUCCESS,
  UPDATE_FORCE_LOGOUT_FLAG,
  SHOW_RELOGIN_POPUP,
  HIDE_RELOGIN_POPUP,
  FETCH_AKTIVO_CONFIG_SUCCESS,
  CLEAR_ID_TOKEN,
} from '../actions/authActions';
import { Action } from 'redux';
import { AxaAuth, AdditionalAxaAuth } from '../types/authTypes';

interface SetPostRedirectUriAction {
  postRedirectUri: string;
}
interface FetchAccessTokenSuccessAction {
  auth: AxaAuth;
}

interface FetchAuthCodeSuccessAction {
  code: string;
}

interface FetchAdditionalAuthCodeSuccessAction {
  codeOfScope: { [scope: string]: string };
}

interface FetchAdditionalAccessTokenSuccessAction {
  additionalAccessTokens: Array<AdditionalAxaAuth>;
}

interface RefreshAdditionalAccessTokenSuccessAction {
  additionalAccessToken: AdditionalAxaAuth;
}

interface UpdateForceLogoutFlagAction {
  isForceLogout: boolean;
}

interface FetchAccessTokenFailureAction {
  error?: Error;
}

interface FetchAktivoConfigSuccessAction {
  aktivoConfig: AktivoConfig;
}

type AuthAcionType = Action &
  FetchAccessTokenSuccessAction &
  FetchAccessTokenFailureAction &
  FetchAdditionalAccessTokenSuccessAction &
  FetchAuthCodeSuccessAction &
  FetchAdditionalAuthCodeSuccessAction &
  RefreshAdditionalAccessTokenSuccessAction &
  SetPostRedirectUriAction &
  UpdateForceLogoutFlagAction &
  FetchAktivoConfigSuccessAction;

export interface AuthState
  extends FetchAccessTokenSuccessAction,
    FetchAccessTokenFailureAction,
    SetPostRedirectUriAction,
    FetchAuthCodeSuccessAction,
    FetchAdditionalAccessTokenSuccessAction {
  tokens: Array<string>;
  codes: { [scope: string]: string };
  isShowReloginPopup: boolean;
  isForceLogout: boolean;
  aktivoConfig: AktivoConfig;
}

const initState = {
  auth: {
    refreshToken: '',
    token: '',
    idToken: '',
    fetchTime: '',
    expiresIn: 0,
  },
  aktivoConfig: {
    aktivoId: '',
    auth: {
      accessToken: '',
      expiresIn: 0,
    },
    devices: {
      GoogleFit: {
        connected: false,
      },
      GoogleFitCloud: {
        connected: false,
      },
      AppleHealth: {
        connected: false,
      },
      Fitbit: {
        connected: false,
      },
      Garmin: {
        connected: false,
      },
    },
    lastSyncTime: '',
    agreedTnc: false,
  },
  additionalAccessTokens: [],
  postRedirectUri: ``,
  tokens: [],
  code: ``,
  codes: {},
  isShowReloginPopup: false,
  isForceLogout: false,
} as AuthState;

export default (state = initState, action: AuthAcionType): AuthState => {
  const nextState = { ...state };
  switch (action.type) {
    case SHOW_RELOGIN_POPUP:
      nextState.isShowReloginPopup = true;
      return nextState;
    case HIDE_RELOGIN_POPUP:
      nextState.isShowReloginPopup = false;
      return nextState;
    case SIGN_OUT:
      return { ...initState, auth: { idToken: state.auth.idToken } };
    case CLEAR_ID_TOKEN:
      nextState.auth.idToken = '';
      return nextState;
    case FETCH_AUTH_CODE_SUCCESS:
      nextState.code = action.code;
      return nextState;
    case FETCH_ADDITIONAL_AUTH_CODE_SUCCESS:
      nextState.codes = { ...nextState.codes, ...action.codeOfScope };
      return nextState;
    case REFRESH_ADDITIONAL_ACCESS_TOKEN_SUCCESS:
      const auths = nextState.additionalAccessTokens;
      const auth = action.additionalAccessToken;
      // comment: remove A-HKG-0018-V010
      // const auth = { ...action.additionalAccessToken, token: '' };
      const index = auths.findIndex((a) => a.scope === auth.scope);
      if (index >= 0) {
        auths.splice(index, 1);
      }
      auths.push(auth);
      nextState.additionalAccessTokens = [...auths];
      return nextState;
    case REMOVE_EXPIRED_TOKEN:
      const { token } = nextState.auth;
      if (token) {
        nextState.tokens = [token, ...nextState.tokens.slice(0, 5)];
      }
      nextState.auth.token = ``;
      return nextState;
    case FETCH_ACCESS_TOKEN_SUCCESS:
    case REFRESH_TOKEN_SUCCESS:
      nextState.code = ``;
      nextState.auth = { ...action.auth };
      nextState.tokens = [action.auth.token, ...nextState.tokens.slice(0, 5)];
      // comment: remove A-HKG-0018-V010
      // nextState.auth = { ...action.auth, token: '' };
      // // nextState.tokens = [action.auth.token, ...nextState.tokens.slice(0,5)];
      return nextState;
    case FETCH_ACCESS_TOKEN_FAILURE:
      nextState.error = action.error;
      return nextState;
    case FETCH_ADDITIONAL_ACCESS_TOKEN_SUCCESS:
      nextState.additionalAccessTokens = [...action.additionalAccessTokens];
      // comment: remove A-HKG-0018-V010
      // const tokens = action.additionalAccessTokens.map(t => {
      //   return { ...t, token: '' }
      // });
      // nextState.additionalAccessTokens = [ ...tokens ];
      nextState.code = ``;
      nextState.codes = {};
      return nextState;
    case POST_REDIRECT_URI:
      nextState.postRedirectUri = action.postRedirectUri;
      return nextState;
    case UPDATE_FORCE_LOGOUT_FLAG:
      nextState.isForceLogout = action.isForceLogout;
      return nextState;
    case FETCH_AKTIVO_CONFIG_SUCCESS:
      nextState.aktivoConfig = { ...action.aktivoConfig };
      return nextState;
    default:
      return nextState;
  }
};
