import _ from 'lodash';
import {
  END_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER,
  END_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED_SUCCESS,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_END,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_START,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_SUCCESS,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_END,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_END,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_START,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_SUCCESS,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUCCESS,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH_SUCCESS,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL_SUCCESS,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_START,
  FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS,
  FILTER_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS,
  OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER,
  OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING,
  SET_AKTIVO_INDIVIDUAL_CHALLENGE_CURRENT_TAB_INDEX,
  SORTING_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS,
} from '../actions/aktivoIndividualChallengeAction';

const defaultState = {
  isLoading: false,
  aktivoIndividualChallengeEnroll: [],
  aktivoIndividualChallengeNoEnroll: [],
  aktivoIndividualChallengeCompleted: [],
  aktivoIndividualChallengeDetail: {},
  aktivoIndividualChallengeEnrollDetail: {},
  aktivoIndividualChallengeSubmitEnrollDetail: {},
  isEnrollLoading: false,
  isChallengeEnrollLoading: false,
  categoryList: [],
  challengeTypeList: [],
  orderBy: 'desc',
  currentTabIndex: 0,
  aktivoIndividualChallengeHomeNoEnroll: {},
  aktivoIndividualChallengeHealthInsigh: {},
  isFilter: false,
  isSorting: false,
  enrollStatus: {
    aktivoChallengeId: '',
    isEnroll: false,
  },
};
export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_START:
      nextState.isLoading = true;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_END:
      nextState.isLoading = false;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS:
      nextState.aktivoIndividualChallengeEnroll =
        action.payload.aktivoIndividualChallenge;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_NO_ENROLL_SUCCESS:
      nextState.aktivoIndividualChallengeNoEnroll =
        action.payload.aktivoIndividualChallengeNoEnroll;
      nextState.aktivoIndividualChallengeHomeNoEnroll =
        state.orderBy === 'desc'
          ? _.last(action.payload.aktivoIndividualChallengeNoEnroll)
          : _.head(action.payload.aktivoIndividualChallengeNoEnroll);
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_COMPLETED_SUCCESS:
      nextState.aktivoIndividualChallengeCompleted =
        action.payload.aktivoIndividualChallengeCompleted;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_START:
      nextState.isChallengeEnrollLoading = true;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_END:
      nextState.isChallengeEnrollLoading = false;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_DETAIL_SUCCESS:
      nextState.aktivoIndividualChallengeDetail =
        action.payload.aktivoIndividualChallengeDetail;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUCCESS:
      nextState.aktivoIndividualChallengeEnrollDetail =
        action.payload.aktivoIndividualChallengeEnrollDetail;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_START:
      nextState.isEnrollLoading = true;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_END:
      nextState.isEnrollLoading = false;
      return nextState;
    case FILTER_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS:
      nextState.categoryList = action.payload.categoryList;
      nextState.challengeTypeList = action.payload.challengeTypeList;
      return nextState;
    case SORTING_AKTIVO_INDIVIDUAL_CHALLENGE_SUCCESS:
      nextState.orderBy = action.payload.orderBy;
      return nextState;
    case SET_AKTIVO_INDIVIDUAL_CHALLENGE_CURRENT_TAB_INDEX:
      nextState.currentTabIndex = action.payload.currentTabIndex;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_HEALTH_INSIGH_SUCCESS:
      nextState.aktivoIndividualChallengeHealthInsigh =
        action.payload.aktivoIndividualChallengeHealthInsigh;
      return nextState;
    case OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER:
      nextState.isFilter = true;
      return nextState;
    case OPEN_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING:
      nextState.isSorting = true;
      return nextState;
    case END_AKTIVO_INDIVIDUAL_CHALLENGE_FILTER:
      nextState.isFilter = false;
      return nextState;
    case END_AKTIVO_INDIVIDUAL_CHALLENGE_SORTING:
      nextState.isSorting = false;
      return nextState;
    case FETCH_AKTIVO_INDIVIDUAL_CHALLENGE_ENROLL_DETAIL_SUBMIT_SUCCESS:
      nextState.enrollStatus.aktivoChallengeId =
        action.payload.aktivoChallengeId;
      nextState.enrollStatus.isEnroll = true;
      return nextState;
    default:
      return nextState;
  }
};
