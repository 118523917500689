import _ from 'lodash';

export const getCustomerProfileConfig = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile`,
  );
};

export const getCustomerProfileFeaturesConfig = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.profileFeatures`,
    [],
  );
};

export const getCustomerSettingFeaturesConfig = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.settingFeatures`,
    [],
  );
};

export const getCustomerProfileLabelsConfig = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.profileLabels`,
    {},
  );
};

export const getCustomerProfileModalConfig = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.profileModal`,
    {},
  );
};

export const getCheckForceLogoutDeepLink = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.checkForceLogoutDeepLink`,
    '',
  );
};

export const getReloginDialogDeepLink = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.reloginDialogDeepLink`,
    '',
  );
};

export const getForceLogoutFlagDeepLink = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.forceLogoutDeepLink`,
    '',
  );
};

export const getCustomerProfileFeatureUrl = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.customerProfileApi.customerProfileApi`,
    '',
  );
};

export const getCustomerProfileFeaturesApi = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/customerProfile.customerProfile.customerProfileFeatures.profileFeatures`,
    [],
  );
};

export const getCustomerSettingFeaturesApi = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/customerProfile.customerProfile.customerProfileFeatures.settingFeatures`,
    [],
  );
};

export const getCustomerUserData = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/customerProfile.customerProfile.userData`,
    {},
  );
};

export const getCustomerLastUpdateDate = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/customerProfile.customerProfile.lastUpdateDate`,
    '',
  );
};

export const getCustomerProfileLoading = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/customerProfile.customerProfile.isLoading`,
    false,
  );
};

export const getCustomerProfileCustKey = (state: {}) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-customer-profile.cicCustKey`,
    'EmmaCIC',
  );
};
