import React, { Component } from 'react';
import LoadingView from '../../../../components/LoadingView';
import {
  getToken,
  getTokenExpireIn,
  getTokenFetchTime,
} from '../../../../selectors/authSelectors';

import {
  refreshToken,
  tokenRefreshRequired,
} from '../../../../utils/myAxaFetchWithToken';

import './styles.css';
// import _ from 'lodash';
// import { putCustState, fetchCustState } from '../../../../actions/userActions';
// import env from '../../../../env';
import minimizeIcon from '../../assests/images/minimize-icons.svg';
import headerRectangleImg from '../../assests/images/rectangle.svg';
import {
  getChatbotInjectMessage,
  getChatbotUrl,
} from '../../selectors/configSelectors';
// import { goto } from '../../../../actions/historyActions';
// import EmmaSmile from '../../assests/images/emma-v-2-b-smile-fullcrop-glasses-hr-rgb.png'
// import EmmaSmile2x from '../../assests/images/emma-v-2-b-smile-fullcrop-glasses-hr-rgb@2x.png'
import { t } from '../../../../locales';
import { ngClass } from '../../../../utils';
// import browserHistory from '../../../../stores/browserHistory';
// import { resolvePublicAssets, isExternalAssets } from '../../../../utils';
import base64url from 'base64url';
import { History } from 'history';
import { connect } from 'react-redux';
import { getAvators } from '../../../../selectors/configSelectors';
import browserHistory from '../../../../stores/browserHistory';
import { sendChatbotInjectMessageToChatbotWebView } from '../../actions/chatbotAction';

const INJECT_MSG_DELAY_MS_BOT_NOT_INITED = 2000;
const INJECT_MSG_DELAY_MS_BOT_INITED = 1000;

interface ChatbotViewProps {
  token: string;
  tokenExpireIn: number;
  tokenFetchTime: string;
  //customer: string,
  hideChat: () => void;
  chatbotUrl: string;
  isShowingChat: boolean;
  avator: string;
  history?: History;
  chatBotInjectMessage: {
    text: string;
    value: any;
  };
  onResetSendChatbotInjectMessageToChatbotWebView: () => void;
}

interface ChatbotViewState {
  tokenRefreshing: boolean;
  isChatLoading: boolean;
  isSendingChatbotInjectMessage: boolean;
  isBotConnectionReady: boolean;
  hasOpenedChatOnce: boolean;
  injectMessageDelay: number;
  iframeSrc: string;
}

class ChatbotView extends Component<ChatbotViewProps, ChatbotViewState> {
  iframe?: HTMLIFrameElement;
  refreshTokenWorkerRunning = false;
  refreshTokenWorkerTimer?: NodeJS.Timeout;
  navigationChangeUnsubscriber: any;
  //emmaInstanceId: Array<string> = [];
  constructor(props: ChatbotViewProps) {
    super(props);
    this.state = {
      tokenRefreshing: false,
      isChatLoading: true,
      isSendingChatbotInjectMessage: false,
      isBotConnectionReady: false,
      hasOpenedChatOnce: false,
      injectMessageDelay: INJECT_MSG_DELAY_MS_BOT_NOT_INITED,
      iframeSrc: '',
    };

    this.handleReceivedMessage = this.handleReceivedMessage.bind(this);
    this.parseMessageFromChatbot = this.parseMessageFromChatbot.bind(this);
    this.postMessage = this.postMessage.bind(this);
    this.sendInjectMsg = this.sendInjectMsg.bind(this);
  }

  componentDidMount() {
    // console.log('CB::: mounted chatbot webview');
    window.addEventListener('message', this.handleReceivedMessage);
    this.navigationChangeUnsubscriber = browserHistory.listen(
      (location, action) => {
        const { chatbotUrl } = this.props;
        if (this.iframe && this.iframe.contentWindow && chatbotUrl) {
          // console.log('CB:::: using chatboturl');
          const url = new URL(chatbotUrl);
          const pageId = base64url.encode(
            location.pathname.replace(/^\/([en|zh])+\/emma/gi, ''),
          );
          // console.log('chatbot location change', pageId, url);
          this.iframe.contentWindow.postMessage(
            JSON.stringify({
              action: '@EMMA_JS/CHATBOT_PAGEID_UPDATE',
              payload: { pageId },
            }),
            url.origin,
          );
        }
      },
    );
  }

  componentWillUnmount() {
    this.navigationChangeUnsubscriber && this.navigationChangeUnsubscriber();
    window.removeEventListener(
      'message',
      this.handleReceivedMessage.bind(this),
    );
  }

  getSrc() {
    // always return the current page_id
    // but iframeSrc will be only invoking getSrc once;
    // to align as-is behaviour before 2024R3
    const { chatbotUrl } = this.props;
    const page_id = base64url.encode(
      window.location.pathname.replace(/^\/([en|zh])+\/emma/gi, ''),
    );

    // console.log('CB:::: getSrc page_id', page_id);

    return `${chatbotUrl}&page_id=${page_id}`;
  }

  getOverrideSrc() {
    const hrefUrl = new URL(window.location.href);
    const result = hrefUrl.searchParams.get('emmaJsTester');
    return result;
  }

  refreshTokenWorkerImpl = (): Promise<void> => {
    const { refreshTokenWorkerRunning } = this;

    return this.refreshTokenIfRequired().then(() => {
      return new Promise((res) => {
        this.refreshTokenWorkerTimer = setTimeout(() => {
          res();
        }, 5 * 1000);
      });
    });
  };
  refreshTokenWorker = (): Promise<void> =>
    this.refreshTokenWorkerImpl().then(() =>
      this.refreshTokenWorkerTimer
        ? this.refreshTokenWorker()
        : Promise.resolve(),
    );

  refreshTokenIfRequired(): Promise<void> {
    return new Promise((res) => {
      const { tokenRefreshing } = this.state;

      if (!tokenRefreshing && tokenRefreshRequired()) {
        this.setState({ tokenRefreshing: true }, () => {
          refreshToken().then(() => {
            this.setState({ tokenRefreshing: false }, () => res());
          });
        });
      } else {
        res();
      }
    });
  }

  handleReceivedMessage(event: any) {
    this.parseMessageFromChatbot(event);
  }

  parseMessageFromChatbot(event: any) {
    try {
      const message =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

      if (message.action && message.action.indexOf('@CUST/') == 0) {
        // Chatbot middleware telling mobile: to let Chatbot to control Back or Close for the iframe
        if (message.action === '@CUST/BOT_CONNECTION_READY') {
          // console.log('CB::: bot connections ready');
          this.setState({
            isBotConnectionReady: message.payload,
          });
          return true;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  postMessage(message: string): boolean {
    if (this.iframe && this.iframe.contentWindow) {
      this.iframe.contentWindow.postMessage(message, this.props.chatbotUrl);
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.chatBotInjectMessage && this.state.isBotConnectionReady) {
      // console.log('CB::: start injecting message');
      setTimeout(() => {
        this.sendInjectMsg();
      }, this.state.injectMessageDelay); // make it slower to let user see what happens
    }

    // check if user has opened the chat at least once (for loading iframe and preventing preload a wrong path)
    //
    if (this.props.isShowingChat && !this.state.hasOpenedChatOnce) {
      this.setState((state) => ({
        ...state,
        hasOpenedChatOnce: true,
        injectMessageDelay: INJECT_MSG_DELAY_MS_BOT_INITED,
        iframeSrc: this.getSrc(),
      }));
    }
  }

  async sendInjectMsg() {
    if (this.state.isSendingChatbotInjectMessage) {
      // lock
      return false;
    }

    const { chatBotInjectMessage } = this.props;
    if (!chatBotInjectMessage) {
      return false;
    }

    this.setState({
      isSendingChatbotInjectMessage: true, // lock
    });
    const { text, value } = chatBotInjectMessage;
    const msg = {
      action: '@EMMA_JS/CHATBOT_INJECT_MESSAGE',
      payload: {
        text,
        value,
      },
    };

    // console.log('CB::: sendInjectMsg', !!msg);
    const success = this.postMessage(JSON.stringify(msg));
    if (success) {
      this.props.onResetSendChatbotInjectMessageToChatbotWebView();
    } else {
      // // retry logic
      // await new Promise(resolve => {
      //   setTimeout(() => {
      //     resolve();
      //   }, 1000);
      // });
      // await this.sendInjectMsg(sendingChatbotInjectMessage);
    }

    this.setState({
      isSendingChatbotInjectMessage: false, // unlock
    });
  }

  hideLoading = () => {
    this.setState({ isChatLoading: false });
  };

  render() {
    const { hideChat, isShowingChat, avator } = this.props;
    const { tokenRefreshing, isChatLoading, hasOpenedChatOnce, iframeSrc } =
      this.state;
    const src = this.getOverrideSrc() ?? iframeSrc;

    // console.log('CB::: src', src);

    const toRender = [];

    if (!tokenRefreshing) {
      // console.log('CB:::: pushing iframe into web');
      // Added hasOpenedChat to fix on a chatbot src to prevent unintended loading of chats
      toRender.push(
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          className={`chatbot-iframe`}
          key={`iframe`}
          src={hasOpenedChatOnce ? src : ''}
          ref={(ref) => (this.iframe = ref || undefined)}
          onLoad={this.hideLoading}
        />,
      );
    }

    if (tokenRefreshing || isChatLoading) {
      toRender.push(<LoadingView key={`LoadingView`} />);
    }

    toRender.push(
      <img
        key={`closeButton`}
        className="chatbot-close-button"
        src={minimizeIcon}
        onClick={hideChat}
        data-enable-ga
        data-ga-type="button"
      />,
    );

    return (
      <div
        className={ngClass(
          {
            opened: isShowingChat,
          },
          'chatbot-view-container',
        )}
      >
        <div className="header-chatbot-web-img">
          <img src={headerRectangleImg} />
          <div className="emma-smile-container">
            <span className="emma-smile-text">{t('CHAT_TITLE')}</span>
          </div>
        </div>
        {toRender}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const token = getToken(state);
  const tokenExpireIn = getTokenExpireIn(state);
  const tokenFetchTime = getTokenFetchTime(state);
  const chatbotUrl = getChatbotUrl(state);
  const avators = getAvators(state);
  const chatBotInjectMessage = getChatbotInjectMessage(state);

  return {
    token,
    tokenExpireIn,
    tokenFetchTime,
    chatbotUrl,
    avator: avators && avators.chatBotHeaderIcon,
    chatBotInjectMessage,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onResetSendChatbotInjectMessageToChatbotWebView: () =>
    dispatch(sendChatbotInjectMessageToChatbotWebView(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatbotView);
