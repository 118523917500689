export const GET_POLICY_NUMBER_START = `GET_POLICY_NUMBER_START`;
export const GET_POLICY_NUMBER_SUCCESS = `GET_POLICY_NUMBER_SUCCESS`;

export const getPolicyNumberStart = () => {
  return {
    type: GET_POLICY_NUMBER_START,
    payload: {},
  };
};

export const getPolicyNumberSuccess = (policyNumber: string) => {
  return {
    type: GET_POLICY_NUMBER_SUCCESS,
    payload: {
      policyNumber,
    },
  };
};
