export const FETCH_FANCL_POLICIES = 'FETCH_FANCL_POLICIES';
export const FETCH_FANCL_POLICIES_SUCCESS = 'FETCH_FANCL_POLICIES_SUCCESS';
export const FETCH_FANCL_STORES = 'FETCH_FANCL_STORES';
export const FETCH_FANCL_STORES_SUCCESS = 'FETCH_FANCL_STORES_SUCCESS';
export const FETCH_FANCL_STORE_TIMES = 'FETCH_FANCL_STORE_TIMES';
export const FETCH_FANCL_STORE_TIMES_SUCCESS =
  'FETCH_FANCL_STORE_TIMES_SUCCESS';
export const FANCL_COMMIT_BOOKING = 'FANCL_COMMIT_BOOKING';
export const FANCL_COMMIT_BOOKING_SUCCESS = 'FANCL_COMMIT_BOOKING_SUCCESS';
export const FANCL_COMMIT_BOOKING_FAILURE = 'FANCL_COMMIT_BOOKING_FAILURE';
export const FANCL_SHOW_MODAL = 'FANCL_SHOW_MODAL';
export const FANCL_HIDE_MODAL = 'FANCL_HIDE_MODAL';

export const FANCL_INIT = 'FANCL_INIT';

export const getFanclPolicies = () => ({
  type: FETCH_FANCL_POLICIES,
});

export const getFanclPoliciesSuccess = (fanclPolicies: any) => ({
  type: FETCH_FANCL_POLICIES_SUCCESS,
  fanclPolicies: fanclPolicies,
});

export const getFanclStores = () => ({
  type: FETCH_FANCL_STORES,
});

export const getFanclStoresSuccess = (fanclStores: any) => ({
  type: FETCH_FANCL_STORES_SUCCESS,
  fanclStores: fanclStores,
});

export const getFanclStoreTimes = (
  pharmacyId: string,
  appointmentDate: string,
) => ({
  type: FETCH_FANCL_STORE_TIMES,
  payload: {
    pharmacyId,
    appointmentDate,
  },
});

export const getFanclStoreTimesSuccess = (fanclStoreTimes: any) => ({
  type: FETCH_FANCL_STORE_TIMES_SUCCESS,
  fanclStoreTimes: fanclStoreTimes,
});

export const commitBooking = (appointmentData: any) => ({
  type: FANCL_COMMIT_BOOKING,
  appointmentData: appointmentData,
});

export const commitBookingSuccess = (appointmentData: any) => ({
  type: FANCL_COMMIT_BOOKING_SUCCESS,
  appointmentData: appointmentData,
});

export const commitBookingFailure = (error: any) => ({
  type: FANCL_COMMIT_BOOKING_FAILURE,
  appointmentError: error,
});

export const showModal = () => ({
  type: FANCL_SHOW_MODAL,
});

export const hideModal = () => ({
  type: FANCL_HIDE_MODAL,
});
