import { call, put, takeLatest, select, take, fork } from 'redux-saga/effects';
import { BANNER_TO_SHOW } from '../actions/appActions';
import {
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  fetchCustState,
  FETCH_LAUNCHPAD_DATA,
  FETCH_LAUNCHPAD_DATA_SUCCESS,
  putCustState,
} from '../actions/userActions';
import { getCustomerApiUrl } from '../selectors/configSelectors';
import { myAxaFetchWithToken } from '../utils/myAxaFetchWithToken';
import { v4 as uuidv4 } from 'uuid';
import { EMMA_BETTER_ME, EMMA_INTRO } from '../constants/constants';
import HomePagePopUpRegistry from '../../src/registries/HomePagePopUpRegistry';
import { EMMA_WELCOME_LAUNCH_POPUP } from '../actions/myPrivilegesOverlayActions';
import { FRAME_APP_MOUNTED } from '../actions/frameActions';
import { isClickCountFeature } from './sideBarItemSaga';
import { FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS } from '../actions/sideBarItemActions';
import { getCustomerLogin, getWelcomeLaunchpadFeatures } from '../selectors/userSelectors';
import { setCustomerLogin } from '@axa-asia/utils';

function* fetchCustomer(): any {
  try {
    const url = yield select(getCustomerApiUrl);
    // const res = { status: 200, json: () => require('../mock/feature.json') }
    const res = yield call(myAxaFetchWithToken, `${url}?device=WEB`, {
      method: 'GET',
      headers: { 'x-axahk-msgid': uuidv4() },
    });
    if (res && res.status === 200) {
      const data = yield res.json() || {};
      //const { data } = res;
      yield put({
        type: FETCH_CUSTOMER_SUCCESS,
        customer: data.retrieveLoginInformation,
      });
    } else {
      throw res;
    }
  } catch (e) {
    yield HomePagePopUpRegistry.setFetchDataCompleted(EMMA_BETTER_ME);
    yield put({ type: FETCH_CUSTOMER_FAILURE, error: e });
  }
}

function* fetchLaunchpadData() {
  const launchpadFeatures = yield select(getWelcomeLaunchpadFeatures);
  const isFoundFeatureId = yield isClickCountFeature(EMMA_INTRO, 1);
  if (JSON.stringify(launchpadFeatures) !== '{}' && isFoundFeatureId) {
    yield HomePagePopUpRegistry.setFetchDataCompleted(EMMA_INTRO, true);
  } else {
    yield HomePagePopUpRegistry.setFetchDataCompleted(EMMA_INTRO);
  }
}

function* showLaunchpad(): Iterable<any> {
  while (true) {
    yield take(BANNER_TO_SHOW);
    const isShowPopup = HomePagePopUpRegistry.getIsDisplay(EMMA_INTRO);
    if (isShowPopup) {
      const show = { display: true };
      yield put({ type: EMMA_WELCOME_LAUNCH_POPUP, show });
    }
  }
}

function* setCustomerLoginHook(): any {
  const customerLogin = yield select(getCustomerLogin);
  yield take(FRAME_APP_MOUNTED);
  setCustomerLogin(customerLogin);
}

function* userSaga() {
  yield takeLatest(FETCH_CUSTOMER_REQUEST, fetchCustomer);
  yield takeLatest(FETCH_CUSTOMER_SUCCESS, setCustomerLoginHook);
  yield take(FRAME_APP_MOUNTED);
  yield take(FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS);
  yield fork(fetchLaunchpadData);
  yield fork(showLaunchpad);
}

export default userSaga;
