import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogHeader,
  DialogFooter,
  DialogBody,
} from '@axa-asia/emma-stylesheet/lib/components/Dialog';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@axa-asia/emma-stylesheet/lib/components/Modal';
import { t } from '../../locales';
import { hideErrorModal } from '../../actions/uiActions';
import { getErrorModalVisible } from '../../selectors/uiSelectors';
import './styles.scss';

export interface EmmaErrorModalProps {
  showModal: boolean;
  modalTitle: any;
  modalMessage: any;
  modalButtons: any;
  hideModal: () => void;
  onClickTryAgain: () => void;
}

interface EmmaErrorModalState {}

class EmmaErrorModal extends Component<
  EmmaErrorModalProps,
  EmmaErrorModalState
> {
  constructor(props: EmmaErrorModalProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  onHideModal(isGoBack = true) {
    this.props.hideModal();
    if (isGoBack) {
      window.history.back();
    }
  }

  render() {
    const {
      showModal,
      modalTitle,
      modalMessage,
      modalButtons,
      onClickTryAgain,
    } = this.props;

    return (
      <Dialog show={showModal}>
        <DialogHeader>
          <span>{modalTitle ? modalTitle : t('ERROR_MODAL_TITLE')}</span>
        </DialogHeader>
        <DialogBody>
          <div className="emma-error-modal-body">
            {modalMessage ? modalMessage : t('ERROR_MODAL_MESSAGE')}
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="emma-error-modal-footer">
            {modalButtons ? (
              modalButtons
            ) : (
              <button
                type="button"
                className="emma-stylesheet-primary-default-button"
                onClick={() => {
                  this.onHideModal(false);
                }}
                data-enable-ga
                data-ga-type="button"
              >
                {t('ERROR_MODAL_BUTTON_OK')}
              </button>
            )}
          </div>
        </DialogFooter>
      </Dialog>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    const showModal = getErrorModalVisible(state);
    const { modalTitle, modalMessage, modalButtons } = ownProps;
    return {
      showModal: showModal,
      modalTitle: modalTitle,
      modalMessage: modalMessage,
      modalButtons: modalButtons,
      onClickTryAgain: ownProps.onClickTryAgain,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      hideModal: () => {
        dispatch(hideErrorModal());
      },
    };
  }
}

export default connect(
  EmmaErrorModal.mapStateToProps,
  EmmaErrorModal.mapDispatchToProps,
)(EmmaErrorModal);
