import { EmmaInitPayload } from '@axa-asia/hk-emma-js';
import { useEffect, useState } from 'react';

export const useEmmaJS = () => {
  const [emmaJS, setEmmaJS] = useState<EmmaInitPayload>();

  useEffect(() => {
    import('@axa-asia/hk-emma-js').then((mod) => {
      mod.EmmaFactory.createInstance().then((emma) => {
        setEmmaJS(emma);
      });
    });
  }, []);

  return emmaJS;
};
