import { v4 as uuidv4 } from 'uuid';

export function initTabId() {
  const win: any = window;
  if (!win.sessionStorage.tabId) {
    win.sessionStorage.tabId = uuidv4();
  }
}

export function getTabId() {
  const win: any = window;
  return win.sessionStorage.tabId;
}
