import _, { merge, cloneDeep } from 'lodash';
import { CardViewState, CardViewPrivilegesState } from '../reducers';
import { MyPrivilegesConfig, SectionsConfig } from '../types';
import { resolveLocalizedText } from '../../../utils';
import { getAppModuleConfig } from '../../../selectors/configSelectors';

export const getCardViewConfig = (state: {}, moduleName: string) => {
  return _.get(state, `hk-emma-web-core/app.moduleConfig.${moduleName}`, {});
};

export const getCardViewState = (
  state: {},
  cardViewId: string,
  reducerSubName = 'myPrivileges',
): CardViewState => {
  return _.get(
    state,
    `hk-emma-web-core/${cardViewId}.${reducerSubName}.config.privileges`,
    {},
  );
};

export const getCardViewPrivilegesState = (
  state: {},
  cardViewId: string,
  reducerSubName = 'myPrivileges',
): CardViewPrivilegesState => {
  const detailPageStage = getDetailsPageStage(state, cardViewId);

  const baseConfig = _.get(state,`hk-emma-web-core/${cardViewId}.${reducerSubName}.config`, {});
  const basePrivileges = _.get(baseConfig, 'privileges', {});
  const stagePrivileges = _.get(baseConfig, `privilegesSpecialStage.${detailPageStage}`, {})
 
  const mergedPrivileges = merge(cloneDeep(basePrivileges), stagePrivileges);
  return mergedPrivileges;
};

export const getMyPrivileges = (state: {}): any => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-ps-generic.myPrivileges`,
    [],
  );
};

export const getDetailsPageInfo = (state, cardViewId) => {
  return _.get(
    state,
    `hk-emma-web-module-my-privileges/myPrivileges.detailsPageInfo.${cardViewId}`,
    '',
  );
};

export const getDetailsPageStage = (state, cardViewId) => {
  const info = getDetailsPageInfo(state, cardViewId);

  let stage = '';

  console.log('XXX getDetailsPageStage getDetailsPageInfo', info)

  switch (cardViewId) {
    case 'cdp': {
      // return 'NoSubscribed';
      // return 'A';
      // return 'B';
      // return 'C';
      // return 'AB';
      // return 'ABC';
      if (!info) return 'NoSubscribed';
      const venders = _.get(info, 'retrievePurchaseStatus.vendors', []).filter(v => v.purchaseStatus === 'Y');

      // if (venders.some(({ productCode, vendorId }) => productCode === 'P001' && vendorId === 'ECHEALTH')) {
      //   stage += 'A';
      // }

      // if (venders.some(({ productCode, vendorId }) => productCode === 'P002' && vendorId === 'ECHEALTH')) {
      //   stage += 'B';
      // }

      if (venders.some(({ productCode, vendorId }) => vendorId === 'DRGO')) {
        stage += 'C';
      }

      if (!stage) stage = 'NoSubscribed';
      break;
    }
    default:
      break;
  }

  return stage;
};

export const getOfferCardViewPrivilegesState = (
  state: {},
  featureId: string,
): any => {
  const offers = _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-my-privileges.offers.offerDetails`,
    [],
  );
  const offersForFeatureIdData = _.find(
    offers,
    (item) => item.featureId === featureId,
  );
  return offersForFeatureIdData ? offersForFeatureIdData.privileges : {};
};

export const getMyPrivilegesConfig = (state: {}): MyPrivilegesConfig => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-my-privileges.privileges`,
    {},
  );
};

export const getMyPrivilegeSections = (state: {}): Array<SectionsConfig> => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-my-privileges.privileges.sections`,
    {},
  );
};

export const getMyPrivilegeOfferSections =
  (state: {}): Array<SectionsConfig> => {
    return _.get(
      state,
      `hk-emma-web-core/app.moduleConfig.emma-my-privileges.offers.offerBanner`,
      {},
    );
  };

export const getMyPrivilegesFeatureUrl = (state: {}): string => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-my-privileges.emmaXpApiUrl`,
    '',
  );
};

export const getAuthToken = (
  state: {},
  reducerName: string,
  reducerSubName = 'myPrivileges',
): string => {
  return _.get(
    state,
    `hk-emma-web-core/${reducerName}.${reducerSubName}.authToken`,
    '',
  );
};

export const getAuthTokenParamName = (
  state: {},
  reducerName: string,
  reducerSubName = 'myPrivileges',
): string => {
  return _.get(
    state,
    `hk-emma-web-core/${reducerName}.${reducerSubName}.config.MyPrivilegesAuthToken.authTokenParamName`,
    '',
  );
};

export const getMyPrivilegesCardModal = (state: {}): any => {
  return _.get(state, `hk-emma-web-module-my-privileges/myPrivileges`);
};

export const getAdditionalCardsViewConfig = (state: {}, moduleName: string) => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.${moduleName}.privileges`,
    {},
  );
};

export const getIsLoadingCtnButtonState = (state: {}): object => {
  return _.get(state, `hk-emma-web-module-my-privileges/myPrivileges`, {});
};

export const getIsLoadingPage = (state: {}): boolean => {
  return _.get(
    state,
    `hk-emma-web-module-my-privileges/myPrivileges.isLoadingPage`,
    false,
  );
};

export const getCurrentTab = (state: {}): string => {
  return _.get(
    state,
    `hk-emma-web-module-my-privileges/myPrivileges.currentTab`,
    '',
  );
};

export const getCustomerStateUrl = (state: {}): string => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-home-revamp.getCustomerStateUrl`,
    '',
  );
};
