import React, { Component, ComponentClass } from 'react';
import './styles.css';

import { connect } from 'react-redux';
import env from '../../env';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import {
  joinPath,
  extractRelativeUrl,
  removeTrailingSlash,
  getBaseUrl,
} from '../../utils';
import { getLanguage } from '../../selectors/userSelectors';
import { t } from '../../locales';
import logo from '../../assets/images/logo-emmaby-axa-shadow-darkblue-horizontal.png';
import logo2x from '../../assets/images/logo-emmaby-axa-shadow-darkblue-horizontal@2x.png';
import logo3x from '../../assets/images/logo-emmaby-axa-shadow-darkblue-horizontal@3x.png';
import logoE from '../../assets/images/logo-e.png';
import logoE2x from '../../assets/images/logo-e@2x.png';
import logoE3x from '../../assets/images/logo-e@3x.png';
import iconMenu from '../../assets/images/icon-menu.png';
import iconMenu2x from '../../assets/images/icon-menu@2x.png';
import iconMenu3x from '../../assets/images/icon-menu@3x.png';
import history from '../../stores/browserHistory';
import { signOut } from '../../actions/authActions';
import { getToken } from '../../selectors/authSelectors';
import { getAxaWebUrl, getContactUsUrl } from '../../selectors/configSelectors';
import classNames from 'classnames';
import { EmmaSpace } from '@axa-asia/emma-stylesheet';
import { LOCALE_CHANGE_TO_HOMEPAGE_ROUTES } from '../../constants/constants';

library.add(faGlobeAmericas);

interface HeaderProps {
  lang: string;
  token: string;
  onSignOut: () => void;
  toggleSideBar: () => void;
  axaWebUrl: string;
  contactUsUrl: string;
}

class Header extends Component<HeaderProps> {
  componentDidMount() {}

  getOppositeLang() {
    const { lang } = this.props;
    return lang === 'en' ? 'zh' : 'en';
  }

  getOppositeLangLabel() {
    const { lang } = this.props;
    return lang === 'en' ? '中文' : 'EN';
  }

  getChangeLangHref() {
    const { lang } = this.props;
    const currentBaseUrl = removeTrailingSlash(
      joinPath(`/${lang}`, env.BASE_NAME),
    );

    // HKEMMA-113643 - Found out other features having the same issues
    // Multiple page features that having locale change issue (State gone / Back button issue)
    const isLocaleChangeToHomepage = LOCALE_CHANGE_TO_HOMEPAGE_ROUTES.some(
      (route) => window.location.href.includes(route),
    );
    const relativeUrl = isLocaleChangeToHomepage
      ? ''
      : extractRelativeUrl(currentBaseUrl);

    const result = joinPath(
      `/${this.getOppositeLang()}`,
      env.BASE_NAME,
      relativeUrl,
    );

    return result;
  }

  changeLang() {
    //window.open(this.getChangeLangHref());
    window.location.href = this.getChangeLangHref();
  }

  logout() {
    const { onSignOut } = this.props;
    onSignOut();
  }

  render() {
    const { token, toggleSideBar, axaWebUrl, contactUsUrl } = this.props;

    return [
      <header className={`${token ? 'responsive' : ''}`} key={`header`}>
        <div>
          <a
            className={EmmaSpace.mr16}
            href={axaWebUrl}
            data-enable-ga
            data-ga-type="button"
          >
            {t(`TOP_NAV_GO`)} {t(`BACK`)}
            {t(`SPACE`)}
            {t(`TO`)} AXA.COM.HK
          </a>
        </div>
        <div>
          <div className="v-sp"></div>
          <a
            className="contact-us"
            href={contactUsUrl}
            onClick={() => this.changeLang()}
            data-enable-ga
            data-ga-type="button"
          >
            {t('CONTACT_US')}
          </a>
          <div className="v-sp"></div>
          <span
            className={classNames('cursor-pointer', {
              [EmmaSpace.pr16]: !!token,
            })}
            onClick={() => {
              this.changeLang();
            }}
            data-enable-ga
            data-ga-type="button"
          >
            <FontAwesomeIcon icon="globe-americas" />
            <span className="lang-text">{this.getOppositeLangLabel()}</span>
          </span>
          {!!token && (
            <button
              className="emma-stylesheet-primary-default-button"
              onClick={() => this.logout()}
              data-enable-ga
              data-ga-type="button"
            >
              {t(`LOGOUT`)}
            </button>
          )}
        </div>
      </header>,
      <nav key={`nav`}>
        <div>
          {!!token && (
            <img
              className="iconMenu"
              src={iconMenu}
              srcSet={`${iconMenu2x} 2x, ${iconMenu3x} 3x`}
              onClick={() => toggleSideBar()}
              data-enable-ga
              data-ga-type="button"
            />
          )}
          <img
            className="logoE"
            src={logoE}
            srcSet={`${logoE2x} 2x, ${logoE3x} 3x`}
          />
          <img
            className="logoEmma"
            src={logo}
            srcSet={`${logo2x} 2x, ${logo3x} 3x`}
          />
        </div>
        <div></div>
      </nav>,
    ];
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {
      lang: getLanguage(state),
      token: getToken(state),
      axaWebUrl: getAxaWebUrl(state),
      contactUsUrl: getContactUsUrl(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onSignOut: () => dispatch(signOut()),
    };
  }
}

export default connect(
  Header.mapStateToProps,
  Header.mapDispatchToProps,
)(Header);
