import { BANNER_TO_SHOW } from '../actions/appActions';
import { store } from '../stores/index';
import {
  call,
  put,
  take,
  select,
  race,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import { __DEV__ } from '../utils';
export class HomePagePopUpRegistry {
  popUpModules: any;
  hasStartedShowing: any;

  constructor() {
    this.popUpModules = [];
  }

  register(newModuleName: string, priority: number) {
    if (__DEV__) {
      console.debug(
        'HomePagePopUpRegistry',
        'log',
        'setShowPopupModules',
        newModuleName,
        this.popUpModules,
      );
    }
    const existingModule = this.popUpModules.find(
      (popUpModule: { moduleName: string }) =>
        popUpModule.moduleName === newModuleName,
    );

    if (!existingModule) {
      this.popUpModules.push({
        moduleName: newModuleName,
        priority: priority,
        isFetchDataCompleted: false,
        isShowPopup: false,
      });
    }
    this.popUpModules.sort(
      (a: { priority: number }, b: { priority: number }) =>
        a.priority - b.priority,
    );
  }

  // set isFetchDataCompleted when module data load is completed
  setFetchDataCompleted(moduleName: string, isShowPopup = false) {
    if (__DEV__) {
      console.debug(
        'HomePagePopUpRegistry',
        'log',
        'setFetchDataCompleted',
        moduleName,
      );
    }
    this.popUpModules.forEach(
      (popUpModule: {
        moduleName: string;
        isFetchDataCompleted: boolean;
        isShowPopup: boolean;
      }) => {
        if (popUpModule.moduleName == moduleName) {
          popUpModule.isFetchDataCompleted = true;
          popUpModule.isShowPopup = isShowPopup;
        }
      },
    );
    this.goToDisplay();
  }

  goToDisplay() {
    const isAllFetchCompleted = this.popUpModules.every(
      (popupModule: { isFetchDataCompleted: any }) =>
        popupModule.isFetchDataCompleted,
    );
    if (isAllFetchCompleted) {
      // all module data load is completed, will be show target popup
      store.dispatch({ type: BANNER_TO_SHOW });
    }
  }
  getIsDisplay(moduleName: string) {
    const targetIndex = this.popUpModules.findIndex(
      (popupModule: { moduleName: any }) =>
        popupModule.moduleName == moduleName,
    );
    const currentIndex = this.popUpModules.findIndex(
      (item: { isFetchDataCompleted: any; isShowPopup: any }) =>
        item.isFetchDataCompleted && item.isShowPopup,
    );
    const isDisplay = targetIndex === currentIndex ? true : false;
    return isDisplay;
  }

  setIsShowPopupCompleted(moduleName: string) {
    this.popUpModules.forEach(
      (popUpModule: {
        moduleName: string;
        isFetchDataCompleted: boolean;
        isShowPopup: boolean;
      }) => {
        if (popUpModule.moduleName == moduleName) {
          popUpModule.isShowPopup = false;
        }
      },
    );
    store.dispatch({ type: moduleName }); //reset module reducer isShowPopupModal:false
    this.goToDisplay();
  }
}

export default new HomePagePopUpRegistry();
