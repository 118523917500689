import config from '../package.json';

const ID = `${config.name}/actions/dashboardActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_DASHBOARD_POLICY = createActionName(
  'FETCH_DASHBOARD_POLICY',
);
export const UPDATE_DASHBOARD_POLICY_LOADING = createActionName(
  'UPDATE_DASHBOARD_POLICY_LOADING',
);
export const FETCH_DASHBOARD_POLICY_SUCCESS = createActionName(
  'FETCH_DASHBOARD_POLICY_SUCCESS',
);

export const fetchAxaGoalDashboardPolicy = (payload: any) => ({
  type: FETCH_DASHBOARD_POLICY,
  payload,
});
