import React, { Component, ComponentClass } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.scss";
import routeRegistry from "../../registries/RouteRegistry";
import { Route } from "react-router-dom";
import {
  getAktivoAuth,
  getAktivoDeviceStatus,
  getAktivoMemberId,
  getToken,
  getTokenExpireIn,
  getTokenFetchTime,
} from "../../selectors/authSelectors";
import { connect } from "react-redux";
import browserHistory from "../../stores/browserHistory";
import { refreshTokenSuccess } from "../../actions/authActions";
import { isApp, getBaseUrl } from "../../utils";
import { getCustomerLogin, getLanguage } from "../../selectors/userSelectors";
import { putCustState, fetchCustState } from "../../actions/userActions";
import { frameAppMounted } from "../../actions/frameActions";
import env from "../../env";
import {
  EmmaFactory,
  TokenRefreshEventName,
  AxaAuth,
  EmmaReadyPayload,
  EmmaWebHandler,
  TopBarOption,
} from "@axa-asia/hk-emma-js";
import {
  getApiKeyFromRedux,
  getAxaAuthFromRedux,
  refreshToken,
} from "../../utils/myAxaFetchWithToken";
import { goto, hideBackButton } from "../../actions/historyActions";
import { getIsUserConfigLoaded } from "../../selectors/configSelectors";
import { getIsShowMembershipTutorial } from "../../modules/axagoal/selectors/vhisSelectors";
import "../../common/scss/modal.scss";
import LoadingView from "../../components/LoadingView";
import { verticalHandler } from "@axa-asia/hk-emma-web-module/lib/ga-lib/GaScrollDepthListener";

import MembershipYearDefinitionTutorial from "../../components/MembershipYearDefinitionTutorial";
import { getCorpWebIndicatorConfig } from "../../modules/emma-webview/selectors/webviewSelector";
import {
  getGaPagePath,
  trackScrollDepth,
  TrackScrollDepthDirection,
} from "../../utils/generalGaEventAnalytics";
import { getAllPoliciesFromStore, getPolicyNumberFromStore } from "../../selectors/policyNumberSelector";
import { AktivoAuth, AktivoDevices } from "../../types/authTypes";
import { getPreviousUrl } from "@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil";
import { CustomerLogin, useCustomerLogin } from "@axa-asia/utils";
import { store } from "../../stores";
interface FrameContainerProps {
  token: string;
  tokenExpireIn: number;
  tokenFetchTime: string;
  isUserConfigLoaded: boolean;
  lang: string;
  isShowMembershipTutorial: boolean;
  onRefreshToken: (auth: AxaAuth) => void;
  onHideBackButton: () => void;
  corpWebIndicator: any;
  aktivoId: string;
  aktivoAuth: AktivoAuth;
  aktivoDeviceStatus: AktivoDevices;
  scrollPanelInlineStyle: any;
  customerLogin: CustomerLogin;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FrameContainerState {}

class MobileFrameContainer extends Component<
  FrameContainerProps,
  FrameContainerState
> {
  emmaWebHandler: EmmaWebHandler;

  constructor(props: FrameContainerProps) {
    super(props);
    this.emmaWebHandler = new EmmaWebHandler();
  }

  showSideBar = () => {
    this.setState({ isShowingSideBar: true });
  };

  emmaJsReady(): EmmaReadyPayload {
    const {
      token,
      tokenExpireIn,
      tokenFetchTime,
      aktivoId,
      aktivoAuth,
      aktivoDeviceStatus,
    } = this.props;

    return {
      auth: {
        token,
        expiresIn: tokenExpireIn,
        fetchTime: tokenFetchTime,
      },
      deviceInfo: {
        platformOS: window.navigator.appVersion,
        userAgent: window.navigator.userAgent,
        timezone: `${new Date().getTimezoneOffset()}`,
      },
      env: {
        urlScheme: `${getBaseUrl()}/`,
        version: env.WEBAPP_VERSION,
        build: env.WEBAPP_VERSION,
      },
      aktivoConfig: {
        aktivoId,
        auth: aktivoAuth,
        devices: aktivoDeviceStatus,
      },
    };
  }

  handleSetTopBar = (options: TopBarOption) => {
    if (options.showBackBtn === false) {
      this.props.onHideBackButton();
    }
  };

  componentDidMount() {
    const { onHideBackButton, corpWebIndicator } = this.props;

    frameAppMounted();

    if (isApp()) {
      EmmaFactory.createInstance().then((e) => {
        e.instance.addEventListener(TokenRefreshEventName, (auth: AxaAuth) => {
          const { onRefreshToken } = this.props;
          onRefreshToken(auth);
        });
      });
    }

    this.emmaWebHandler.serve(
      () => this.emmaJsReady(),
      () => refreshToken(),
      (url) => goto(url),
      () => browserHistory.push("/home"),
      (key, value) => putCustState(key, value, `emma`),
      (key) => fetchCustState(key, `emma`),
      () => onHideBackButton(),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      () => Promise.resolve(""),
      corpWebIndicator.whitelistRegExp || [],
      () => {
        const policyNumber = getPolicyNumberFromStore();
        return Promise.resolve({ policyNumber: policyNumber || "" });
      },
      () => Promise.resolve(getPreviousUrl(1) || ""),
      () => {
        const customerLogin = getCustomerLogin(store.getState());
        return Promise.resolve(customerLogin)
      },
      (options) => this.handleSetTopBar(options),
      async () => getApiKeyFromRedux(),
      // TODO: add queue it here in R3
      async () => ({}),
      async () => ({ policyList: getAllPoliciesFromStore() }),
    );
  }

  handleScroll = verticalHandler((ratio: number) => {
    const screenName: string = getGaPagePath();

    if (
      [
        "/check-coverage",
        "/aktivo-my-wellness",
        "/referral-programme/referral-form",
        "/programme-registration/form",
      ].some((path) => screenName.includes(path))
    ) {
      trackScrollDepth(ratio, TrackScrollDepthDirection.VERTICAL, screenName);
    }
  });

  renderContentSection() {
    const { isShowMembershipTutorial } = this.props;
    const routeMap = routeRegistry.getScreens();
    const routes = Object.keys(routeMap);

    return (
      <section className="section">
        <div
          className={`mobile-scroll-panel no-modal-showing`}
          onScroll={this.handleScroll}
          style={this.props.scrollPanelInlineStyle}
        >
          {isShowMembershipTutorial && (
            <MembershipYearDefinitionTutorial
              showModal={isShowMembershipTutorial}
            />
          )}
          {routes.map((r) => {
            const Container: ComponentClass = routeMap[r].container;
            return (
              <Route
                exact={routeMap[r].exact}
                key={r}
                path={`${r}`}
                render={(props) => <Container {...props} />}
              />
            );
          })}
        </div>
      </section>
    );
  }

  render() {
    const { isUserConfigLoaded } = this.props;

    if (!isUserConfigLoaded) return <LoadingView />;

    return this.renderContentSection();
  }

  /**
   * The following function will be impacted by mobile-scroll-panel. Make page-specific CSS fix here.
   * 1. CIC Google Address Auto Complete Field
   */
  static getScrollPanelStyleByPage = (): {} => {
    const screenName: string = getGaPagePath();
    if (
      ["/customerprofile/changeaddress/edit"].some((path) =>
        screenName.includes(path)
      )
    ) {
      return { position: 'relative', width: '100%' };
    }
    return {};
  };

  static mapStateToProps(state: any, ownProps: any) {
    const lang = getLanguage(state);
    const isUserConfigLoaded: boolean = getIsUserConfigLoaded(state);
    const isShowMembershipTutorial: boolean =
      getIsShowMembershipTutorial(state);
    const scrollPanelInlineStyle =
      MobileFrameContainer.getScrollPanelStyleByPage();

    return {
      token: getToken(state),
      tokenExpireIn: getTokenExpireIn(state),
      tokenFetchTime: getTokenFetchTime(state),
      corpWebIndicator: getCorpWebIndicatorConfig(state),
      isUserConfigLoaded,
      lang,
      isShowMembershipTutorial,
      aktivoId: getAktivoMemberId(state),
      aktivoAuth: getAktivoAuth(state),
      aktivoDeviceStatus: getAktivoDeviceStatus(state),
      scrollPanelInlineStyle,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onRefreshToken: (auth: AxaAuth) => {
        dispatch(refreshTokenSuccess(auth));
      },
      onHideBackButton: () => dispatch(hideBackButton()),
    };
  }
}

const ConnectedFrameContainer = connect(
  MobileFrameContainer.mapStateToProps,
  MobileFrameContainer.mapDispatchToProps
)(MobileFrameContainer);

export default ConnectedFrameContainer;
