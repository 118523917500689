import {
  FETCH_PROFILE_FEATURES_SUCCESS,
  FETCH_PROFILE_FEATURES_FAILURE,
  FETCH_HEALTH_NEWS_SUCCESS,
  FETCH_HEALTH_NEWS_FAILURE,
  FETCH_AXA_BLOG_SUCCESS,
  FETCH_AXA_BLOG_FAILURE,
  FETCH_HAVE_READ_NEW_FEATURE_SUCCESS,
  FETCH_HAVE_READ_NEW_FEATURE_FAILURE,
  UPDATE_HAVE_READ_NEW_FEATURE_STATE,
  FETCH_MARKETPLACE_PROMOTION_NEWS_SUCCESS,
  FETCH_MARKETPLACE_PROMOTION_NEWS_FAILURE,
  FETCH_CONTENTO_AVATORS_SUCCESS,
  FETCH_CONTENTO_AVATORS_FAILURE,
  SET_AVATORS,
} from '../actions';

const defaultState = {
  profileFeatures: [],
  news: {
    health: [],
    axa: [],
    marketplace: [],
    blogArticles: [],
  },
  fetchPromo: {},
  avators: {},
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_PROFILE_FEATURES_SUCCESS:
      nextState.profileFeatures = action.featuresItems;
      return nextState;
    case FETCH_PROFILE_FEATURES_FAILURE:
      nextState.profileFeaturesError = action.error;
      return nextState;
    case FETCH_HEALTH_NEWS_SUCCESS:
      nextState.news.health = action.news;
      return nextState;
    case FETCH_AXA_BLOG_SUCCESS:
      nextState.news.blogArticles = action.data;
      return nextState;
    case FETCH_HEALTH_NEWS_FAILURE:
    case FETCH_AXA_BLOG_FAILURE:
      nextState.news.error = action.error;
      return nextState;
    case FETCH_MARKETPLACE_PROMOTION_NEWS_SUCCESS:
      nextState.news.marketplace = action.news;
      return nextState;
    case FETCH_MARKETPLACE_PROMOTION_NEWS_FAILURE:
      nextState.news.marketplace.error = action.error;
      return nextState;
    case FETCH_CONTENTO_AVATORS_SUCCESS:
    case SET_AVATORS:
      nextState.avators = action.payload;
      return nextState;
    case FETCH_CONTENTO_AVATORS_FAILURE:
      if (
        nextState.appInfo &&
        nextState.appInfo.avators &&
        nextState.appInfo.avators.default
      ) {
        nextState.avators = nextState.appInfo.avators.default;
      }
      return nextState;
    default:
      return nextState;
  }
};
