export type EmmaHeaders = Partial<{
  AXAID: string;
  DeviceID: string;
  'X-AXA-APP-INFO': string;
  'User-Agent': string;
}>;

let emmaHeaders: EmmaHeaders = {};

export const setEmmaHeaders = (headers: EmmaHeaders) => {
  emmaHeaders = Object.assign(emmaHeaders, headers);
};

export const getEmmaHeaders = () => emmaHeaders;

/*
  Url is a reserved word in some Logger library,
  rename url to link
*/
export const getLogsInfo = <T extends Record<string, unknown>>(
  link: string,
  qs: T,
) => {
  return {
    link,
    ...qs,
    ...emmaHeaders,
  };
};
