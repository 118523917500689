import {
  CARD_VIEW_MOUNTED,
  CARD_VIEW_CONTENT_LOADED,
  DETAILS_VIEW_MOUNTED,
  DETAILS_VIEW_CONTENT_LOADED,
} from '../actions';

const defaultState = {};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case CARD_VIEW_MOUNTED:
      nextState.config = defaultState;
      return nextState;
    case CARD_VIEW_CONTENT_LOADED:
      nextState.config = action.config;
      return nextState;
    case DETAILS_VIEW_MOUNTED:
      nextState.config = defaultState;
      return nextState;
    case DETAILS_VIEW_CONTENT_LOADED:
      nextState.config = action.config;
      return nextState;
    default:
      return nextState;
  }
};
