import React, { Component } from 'react';
import './styles.css';
import { connect } from 'react-redux';
import { withLoadingWidgetHoc } from '../../../../components/lazyLoading/with-loading-widget-hoc';
import { EmmaErrorScreen } from '@axa-asia/emma-stylesheet';
import { t } from '../../../../locales';
import { getCustomerSupportUrl } from '../../../../selectors/configSelectors';

interface MaintenanceScreenProps {
  customerSupportUrl: string;
}

class CoreApiErrorMaintenanceScreen extends Component<MaintenanceScreenProps> {
  goToCustomerSupportPage = () => {
    let url = this.props.customerSupportUrl;
    if (!url) {
      url = 'https://emma.axa.com.hk/en/iam-fe/emma/customer-support';
    }
    window.location.href = url;
  };

  render() {
    return (
      <div className="App">
        <EmmaErrorScreen
          title={t('LOGIN_ERROR_TITLE')}
          description={t('LOGIN_ERROR_DESC')}
          buttonText={t('CALL_CUSTOMER_SUPPORT')}
          onClick={() => this.goToCustomerSupportPage()}
        />
      </div>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {
      customerSupportUrl: getCustomerSupportUrl(state),
    };
  }
}

export default connect(
  CoreApiErrorMaintenanceScreen.mapStateToProps,
  {},
)(withLoadingWidgetHoc(CoreApiErrorMaintenanceScreen));
