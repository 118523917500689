import _ from 'lodash';
import { FETCH_CUSTOMER_SUCCESS } from '../actions/userActions';
import { consumerLogin } from '../types/axaTypes';
import { Action } from 'redux';
import { FETCH_MOBILE_MODULE_CONFIG_SUCCESS } from '../actions/mobileModuleConfigAction';

const userInitState = {};

interface FetchCustomerSuccessAction {
  payload?: any;
  customer?: consumerLogin;
}

interface FetchCustomerFailureAction {
  error?: Error;
}

type UserAcionType = Action &
  FetchCustomerSuccessAction &
  FetchCustomerFailureAction;

export interface UserState
  extends FetchCustomerSuccessAction,
    FetchCustomerFailureAction {}

export default (state: UserState = userInitState, action: UserAcionType) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_CUSTOMER_SUCCESS:
      nextState.customer = action.customer;
      return nextState;
    case FETCH_MOBILE_MODULE_CONFIG_SUCCESS:
      nextState.customer = {
        profile: [{ ...action.payload.config.customerProfile }] as any,
        features: [],
      };
      return nextState;
    default:
      return nextState;
  }
};
