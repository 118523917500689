import _ from 'lodash';
import config from '../package.json';
import { CorpWebIndicatorConfig } from '../../../utils/emmaUrlConverter';

export const EMMA_WEBVIEW_MODULE_NAME = 'emma-webview';

export const getCorpWebIndicatorConfig =
  (state: {}): CorpWebIndicatorConfig => {
    return _.get(
      state,
      `hk-emma-web-core/app.moduleConfig.${EMMA_WEBVIEW_MODULE_NAME}.corpWebIndicator`,
      {},
    );
  };

export const getCorpWebCookieName = (state: any): string => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.${EMMA_WEBVIEW_MODULE_NAME}.corpWebCookieName`,
    '',
  );
};
