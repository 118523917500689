import _ from 'lodash';
import config from '../package.json';
import {
  VhisConfig,
  AchievedDayType,
  VhisAppModuleConfigType,
} from '../types/VhisConfig';

import { getAppModuleConfig } from '../../../selectors/configSelectors';

import Newbie from '../../../assets/images/vhis/icon--vhis--newbie.png';
import Jade from '../../../assets/images/vhis/icon--vhis--jade.png';
import Crystal from '../../../assets/images/vhis/icon--vhis--crystal.png';
import Diamond from '../../../assets/images/vhis/icon--vhis--diamond.png';
import { resolveLocalizedText, isApp } from '../../../utils';
import moment from 'moment';
import { YEAR_MONTH_DAY_FORMAT } from '../../../components/Calendar/components/MonthView/MonthView';

const emmaVhisModuleName = `emma-vhis`;

export const getVhisModuleConfig = (state: {}): VhisConfig => {
  return _.get(state, `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}`);
};

const updateType = (obj: AchievedDayType) => {
  obj.nextState.displayIcon = obj.icon;
  obj.nextState.displayTxt = obj.iconTxt;
  obj.nextState.targetAchievedDisplayIcon = obj.targetIcon;
  obj.nextState.targetAchievedDisplayTxt = obj.targetTxt;
  obj.nextState.targetAchievedDays = obj.targetDays;
  obj.nextState.currentAchievedDays = obj.calAchievedDays;
  obj.nextState.nextVhisType = obj.nextVhisType;
};

export const getDiamondLevelDays = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  const AchievedTypeConfig = VhisAppModuleConfig.axaGoalRules;
  const { diamondLevelDays } = AchievedTypeConfig;
  return diamondLevelDays;
};

export const getSilverHairChallengerLevelDays = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  const AchievedTypeConfig = VhisAppModuleConfig.silverHairRules;
  const { newbieAchievedLevelDays } = AchievedTypeConfig;
  return newbieAchievedLevelDays;
};

export const getCampaignDetail = (
  state: {},
  isFirstYear = true,
  type = 'WGP',
): any => {
  const nextState = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.retrieveStepCountSummaryResponse`,
  );
  nextState.stepCount = nextState.stepCount.sort(
    (a: { month: string }, b: { month: string }) => {
      return moment(new Date(`${b.month}-1`)).diff(
        moment(new Date(`${a.month}-1`)),
        'months',
      );
    },
  );
  nextState.lastYearStepCount = nextState.lastYearStepCount.sort(
    (a: { month: string }, b: { month: string }) => {
      return moment(new Date(`${b.month}-1`)).diff(
        moment(new Date(`${a.month}-1`)),
        'months',
      );
    },
  );

  nextState.totalAchieveDaysWithBonus = parseInt(
    nextState.totalAchieveDaysWithBonus,
  );

  nextState.lastYearTotalAchievedDaysWithBonus = parseInt(
    nextState.lastYearTotalAchievedDaysWithBonus,
  );

  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );

  // for check vhis achievement type base on achieved days
  const calAchievedDays = isFirstYear
    ? nextState.totalAchieveDaysWithBonus
    : nextState.lastYearTotalAchievedDaysWithBonus;
  let maxReached = false;
  if (type === 'SH') {
    const silverHairAchievedTypeConfig = VhisAppModuleConfig.silverHairRules;
    const {
      newbieLevelDays,
      newbieTxtKey,
      newbieAchievedLevelDays,
      newbieAchievedTxtKey,
    } = silverHairAchievedTypeConfig;
    if (calAchievedDays >= newbieAchievedLevelDays) {
      updateType({
        calAchievedDays: calAchievedDays,
        icon: Newbie,
        iconTxt: newbieAchievedTxtKey,
        targetIcon: Newbie,
        targetTxt: newbieAchievedTxtKey,
        targetDays: newbieAchievedLevelDays,
        currentDay: newbieAchievedLevelDays,
        nextVhisType: newbieAchievedTxtKey,
        nextState: nextState,
      });
      maxReached = true;
    } else {
      updateType({
        calAchievedDays: calAchievedDays,
        icon: Newbie,
        iconTxt: newbieTxtKey,
        targetIcon: Newbie,
        targetTxt: newbieTxtKey,
        targetDays: newbieAchievedLevelDays,
        currentDay: newbieLevelDays,
        nextVhisType: newbieAchievedTxtKey,
        nextState: nextState,
      });
    }
  } else {
    const AchievedTypeConfig = VhisAppModuleConfig.axaGoalRules;
    const {
      crystalLevelDays,
      crystalTxtKey,
      jadeLevelDays,
      jadeTxtKey,
      diamondLevelDays,
      diamondTxtKey,
      newbieLevelDays,
      newbieTxtKey,
    } = AchievedTypeConfig;

    if (
      calAchievedDays >= crystalLevelDays &&
      calAchievedDays < jadeLevelDays
    ) {
      updateType({
        calAchievedDays: calAchievedDays,
        icon: Crystal,
        iconTxt: crystalTxtKey,
        targetIcon: Jade,
        targetTxt: jadeTxtKey,
        targetDays: jadeLevelDays,
        currentDay: crystalLevelDays,
        nextVhisType: jadeTxtKey,
        nextState: nextState,
      });
    } else if (
      calAchievedDays >= jadeLevelDays &&
      calAchievedDays < diamondLevelDays
    ) {
      updateType({
        calAchievedDays: calAchievedDays,
        icon: Jade,
        iconTxt: jadeTxtKey,
        targetIcon: Diamond,
        targetTxt: diamondTxtKey,
        targetDays: diamondLevelDays,
        currentDay: jadeLevelDays,
        nextVhisType: diamondTxtKey,
        nextState: nextState,
      });
    } else if (calAchievedDays >= diamondLevelDays) {
      updateType({
        calAchievedDays: calAchievedDays,
        icon: Jade,
        iconTxt: jadeTxtKey,
        targetIcon: Diamond,
        targetTxt: diamondTxtKey,
        targetDays: diamondLevelDays,
        currentDay: newbieLevelDays,
        nextVhisType: diamondTxtKey,
        nextState: nextState,
      });
      maxReached = true;
    } else {
      updateType({
        calAchievedDays: calAchievedDays,
        icon: Newbie,
        iconTxt: newbieTxtKey,
        targetIcon: Crystal,
        targetTxt: crystalTxtKey,
        targetDays: crystalLevelDays,
        currentDay: newbieLevelDays,
        nextVhisType: crystalTxtKey,
        nextState: nextState,
      });
    }
  }
  const calendar = nextState.calendarView || [];
  const lastCalendar = nextState.lastCalendarView || [];
  const sumCalendar = isFirstYear ? calendar : lastCalendar;
  const achievedDates: any = {};
  sumCalendar.forEach(
    (c: any) =>
      (achievedDates[moment(c.stepDate).format(YEAR_MONTH_DAY_FORMAT)] =
        c.freeDayFlag),
  );
  nextState.startDate = isFirstYear
    ? new Date(moment(nextState.stepCountStartDate).format('YYYY-MM-DD'))
    : new Date(moment(nextState.lastStepCountStartDate).format('YYYY-MM-DD'));
  nextState.endDate = isFirstYear
    ? new Date(moment(nextState.stepCountEndDate).format('YYYY-MM-DD'))
    : new Date(moment(nextState.lastStepCountEndDate).format('YYYY-MM-DD'));
  nextState.achievedDates = achievedDates;
  return {
    ...nextState,
    maxReached: maxReached,
  };
};

export const getDisplayYearData = (state: {}): any => {
  const currentYear = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.campaignDetail.currentYear`,
  );
  const previousYear = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.campaignDetail.previousYear`,
  );
  const currentState = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.campaignDetail.dropdownValue`,
  );
  let displayData: object = [];
  if (currentState === 'PREVIOUS_CAMPAIGN_YEAR') {
    displayData = previousYear;
  } else {
    displayData = currentYear;
  }
  return displayData;
};

export const getEmmaAppLink = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  const link = resolveLocalizedText(VhisAppModuleConfig.emmaAppLink);
  return link;
};

export const getMenuInformationApi = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.menuInformationApi;
};

export const getMembershipTutorial = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.membershipTutorial;
};

export const getSilverHairMembershipTutorial = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.silverHairMembershipTutorial;
};

export const getStepCountSummaryApi = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.stepCountSummaryApi;
};

export const getDisclaimerInfoApiApi = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.disclaimerInfoApiApi;
};
export const getDisclaimerInfoApiChannel = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.disclaimerInfoApiChannel;
};
export const getDisclaimerInfoApiKey = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.disclaimerInfoApiKey;
};

export const getLeafletUrlObj = (state: {}): any => {
  const VhisAppModuleConfig: VhisAppModuleConfigType = getAppModuleConfig(
    state,
    emmaVhisModuleName,
  );
  if (!VhisAppModuleConfig) return null;
  return VhisAppModuleConfig.leafletUrl;
};

export const getShowStartCampaignButton = (state: {}): any => {
  const result = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.showStartCampaignButton`,
  );
  return result;
};

export const getAxaGoalCustomerState = (state: {}): string => {
  return _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.axaGoalCustomerState`,
    undefined,
  );
};

export const getMenuInfo = (state: {}): string => {
  return _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.menuInformationData`,
    undefined,
  );
};

export const getRulesAnchor = (state: {}): any => {
  const result = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.anchor`,
  );
  return result;
};

export const getaxaGoalRulesInfo = (state: {}): any => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-vhis.axaGoalRulesInfo`,
    {},
  );
};

export const getaxaGoalTrackerModal = (state: {}): any => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.emma-vhis.trackerModal`,
  );
};

export const getIsGetTrackerStatus = (state: {}): any => {
  const result = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.isGetTrackerStatus`,
  );
  return result;
};

export const getAxagoalIsFirstYear = (state: {}): any => {
  return _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.isFirstYear`,
  );
};

export const getIsShowMembershipTutorial = (state: {}): any => {
  return _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.isShowMembershipTutorial`,
  );
};

export const getAxagoalLoading = (state: {}) => {
  const axagoalLoadingSelector = _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.isLoading`,
  );
  return axagoalLoadingSelector;
};

export const getAxaGoalSupportEntityCode = (state: {}): any => {
  return _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.entityCode`,
  );
};

export const getMembershipTutorialRuleType = (state: {}): string => {
  return _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.membershipTutorialRuleType`,
    '',
  );
};

export const getAktivoStepStats = (state: {}): any => {
  return _.get(
    state,
    `hk-emma-web-core/vhis.${config.homeVhisReducerSubName}.aktivoStepStats`,
  );
};
