import config from '../package.json';
import { REDUCER_NAME } from '../reducers/uiReducer';

const reducerName = `${config.name}/${REDUCER_NAME}`;

export const getDialogAttributes = (state: any) => {
  return state[reducerName].dialog;
};

export const getTNCDialogAttributes = (state: any) => {
  return state[reducerName].tncDialog;
};

export const getModalVisible = (state: any) => {
  return state[reducerName].modal.visible;
};

export const getEmmaTncStyleModelAttributes = (state: any) => {
  return state[reducerName].emmaTncStyleModel;
};

export const getEmmaContactCallModelAttributes = (state: any) => {
  return state[reducerName].emmaContactCallModel;
};

export const getErrorModalVisible = (state: any) => {
  return state[reducerName].errorModal.visible;
};
