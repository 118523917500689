import React from 'react';
import { SuspenseLoadingWidget } from '@axa-asia/emma-loading-widget';

// This hide the initial loading widget, for non-async component, on mount

export function withLoadingWidgetHoc(WrappedComponent) {
  return class AsyncComponentMock extends React.Component {
    static displayName = `withLoadingWidgetHoc(${
      WrappedComponent.displayName || 'unknown'
    })`;
    state = { loaded: false };
    componentDidMount() {
      this.setState({ loaded: true });
    }
    render() {
      return this.state.loaded ? (
        <WrappedComponent {...this.props} />
      ) : (
        <SuspenseLoadingWidget />
      );
    }
  };
}
