export const SESSION_REMOVE_EXPIRED_TOKEN = 'SESSION_REMOVE_EXPIRED_TOKEN';
export const SESSION_REFRESH_TOKEN = 'SESSION_REFRESH_TOKEN';
export const SESSION_FETCH_ACCESS_TOKEN = 'SESSION_FETCH_ACCESS_TOKEN';
export const SESSION_FETCH_ADDITIONAL_ACCESS_TOKEN =
  'SESSION_FETCH_ADDITIONAL_ACCESS_TOKEN';
export const SESSION_REFRESH_ADDITIONAL_ACCESS_TOKEN =
  'SESSION_REFRESH_ADDITIONAL_ACCESS_TOKEN';

export interface AxaAuth {
  authToken: string;
  additionalAccessToken?: string;
}

export const expired = () => ({
  type: SESSION_REMOVE_EXPIRED_TOKEN,
});

export const fetchSessionAccessToken = (token: string) => ({
  type: SESSION_FETCH_ACCESS_TOKEN,
  token,
});

export const refreshSessionToken = (token: string) => ({
  type: SESSION_REFRESH_TOKEN,
  token,
});

export const fetchSessionAdditionalToken = (additionalTokens: any[]) => ({
  type: SESSION_FETCH_ADDITIONAL_ACCESS_TOKEN,
  additionalTokens,
});

export const refreshSessionAdditionalToken = (additionalToken: any) => ({
  type: SESSION_REFRESH_ADDITIONAL_ACCESS_TOKEN,
  additionalToken,
});
