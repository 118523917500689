export const TEAM_CHALLENGE_TYPE = 'TEAM_CHALLENGE_TYPE';
export const TEAM_CHALLENGE_MODAL_DISPLAY = 'TEAM_CHALLENGE_MODAL_DISPLAY';
export const IS_ACCEPTED_DISCLAIMER = 'IS_ACCEPTED_DISCLAIMER';
export const IS_HEALTH_KEEPER_ACCOUNT_EXIST = 'IS_HEALTH_KEEPER_ACCOUNT_EXIST';
export const SUMMARY_DISPLAY_DATA = 'SUMMARY_DISPLAY_DATA';
export const FETCH_STEP_COUNT_SUMMARY = 'FETCH_STEP_COUNT_SUMMARY';
export const AXAGOAL_LOAD_FAIL = 'AXAGOAL_LOAD_FAIL';
export const FETCH_CAMPAIGN_STARTED = 'FETCH_CAMPAIGN_STARTED';
export const TOGGLE_CAMPAIGN_BUTTON = 'TOGGLE_CAMPAIGN_BUTTON';

export const getVhisType = () => ({
  type: TEAM_CHALLENGE_TYPE,
});

export const fetchCampaignStarted = () => ({
  type: FETCH_CAMPAIGN_STARTED,
});
export const toggleCampaignButton = (showStartCampaignButton: boolean) => ({
  type: TOGGLE_CAMPAIGN_BUTTON,
  showStartCampaignButton,
});

export const setVhisType = (vhisType: string) => ({
  type: TEAM_CHALLENGE_TYPE,
  vhisType,
});

export const toggleVhisModelDisplay = (isShowVhisModalBoollean: boolean) => {
  const show = {
    isShowVhisModal: isShowVhisModalBoollean,
  };
  return {
    type: TEAM_CHALLENGE_MODAL_DISPLAY,
    show,
  };
};

export const updateSummaryDisplayData = (displayYear: string) => ({
  type: SUMMARY_DISPLAY_DATA,
  displayYear,
});
