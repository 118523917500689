import config from '../package.json';

const ID = `${config.name}/actions/checkEBCoverageAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const SUBMIT_DELETE_ACCOUNT = createActionName('SUBMIT_DELETE_ACCOUNT');
export const SUBMIT_DELETE_ACCOUNT_LOADING_START = createActionName(
  'SUBMIT_DELETE_ACCOUNT_LOADING_START',
);
export const SUBMIT_DELETE_ACCOUNT_LOADING_END = createActionName(
  'SUBMIT_DELETE_ACCOUNT_LOADING_END',
);

export const submitDeleteAccount = () => ({
  type: SUBMIT_DELETE_ACCOUNT,
});
