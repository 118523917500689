import {
  UPDATE_RESET_USER_NAME_EMAIL,
  UPDATE_RESET_USER_NAME_MOBILE,
  UPDATE_SUBMIT_LOADING,
  CHANGE_CUR_TAB,
} from '../actions/customerResetUserNameAction';

const defaultState = {
  isSubmitLoading: false,
  email: '',
  mobile: '',
  curUserNameTab: '',
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_RESET_USER_NAME_EMAIL:
      nextState.email = action.email || '';
      return nextState;
    case UPDATE_RESET_USER_NAME_MOBILE:
      nextState.mobile = action.mobile || '';
      return nextState;
    case CHANGE_CUR_TAB:
      nextState.curUserNameTab = action.curUserNameTab || '';
      return nextState;
    case UPDATE_SUBMIT_LOADING:
      nextState.isSubmitLoading = action.isSubmitLoading || false;
      return nextState;
    default:
      return nextState;
  }
};
