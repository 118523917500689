import React, { Component } from 'react';
import './styles.css';

import { connect } from 'react-redux';
import env from '../../env';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import {
  joinPath,
  extractRelativeUrl,
  removeTrailingSlash,
  getBaseUrl,
} from '../../utils';
import { getLanguage } from '../../selectors/userSelectors';
import {
  getFacebookUrl,
  getYoutubeUrl,
  getLinkedInUrl,
  getInstagramUrl,
  getPolicyUrl,
  getPrivacyUrl,
  getAxaWebUrl,
  getContactUsUrl,
} from '../../selectors/configSelectors';
import { t } from '../../locales';
import history from '../../stores/browserHistory';
import facebookIcon from '../../assets/images/facebook-white.svg';
import instagramIcon from '../../assets/images/instagram-white.svg';
import linkedInIcon from '../../assets/images/linkedin-white.svg';
import youtubeIcon from '../../assets/images/youtube-white.svg';
import { LOCALE_CHANGE_TO_HOMEPAGE_ROUTES } from '../../constants/constants';

library.add(faGlobeAmericas);

interface FooterProps {
  currentLanguage: string;
  onSignOut: () => void;
  token: string;
  facebookUrl: string;
  youtubeUrl: string;
  linkedInUrl: string;
  instagramUrl: string;
  policyUrl: string;
  privacyUrl: string;
  axaWebUrl: string;
  contactUsUrl: string;
  responsive: boolean;
}

class Footer extends Component<FooterProps> {
  static defaultProps = {
    responsive: true,
  };

  componentDidMount() {}

  getOppositeLang() {
    const { currentLanguage } = this.props;
    return currentLanguage === 'en' ? 'zh' : 'en';
  }

  getOppositeLangLabel() {
    const { currentLanguage } = this.props;
    return currentLanguage === 'en' ? '中文' : 'ENGLISH';
  }

  getChangeLangHref(targetLang?: string) {
    const { currentLanguage } = this.props;
    const currentBaseUrl = removeTrailingSlash(
      joinPath(`/${currentLanguage}`, env.BASE_NAME),
    );

    // HKEMMA-113643 - Found out other features having the same issues
    // Multiple page features that having locale change issue (State gone / Back button issue)
    const isLocaleChangeToHomepage = LOCALE_CHANGE_TO_HOMEPAGE_ROUTES.some(
      (route) => window.location.href.includes(route),
    );
    const relativeUrl = isLocaleChangeToHomepage
      ? ''
      : extractRelativeUrl(currentBaseUrl);

    const resultLang = targetLang ? targetLang : this.getOppositeLang();
    const result = joinPath(`/${resultLang}`, env.BASE_NAME, relativeUrl);

    return result;
  }

  changeLang(targetLang?: string) {
    //window.open(this.getChangeLangHref());
    window.location.href = this.getChangeLangHref(targetLang);
  }

  render() {
    const {
      currentLanguage,
      facebookUrl,
      youtubeUrl,
      linkedInUrl,
      instagramUrl,
      policyUrl,
      privacyUrl,
      axaWebUrl,
      contactUsUrl,
      responsive,
    } = this.props;

    const languageList: { code: string; label: string; className: string }[] = [
      {
        code: 'en',
        label: 'ENGLISH',
        className: 'english',
      },
      {
        code: 'zh',
        label: '中文',
        className: 'chinese',
      },
    ];
    return (
      <footer className={`${responsive ? 'responsive' : ''}`}>
        <div className="footer_desktop-lang-social-media-btn">
          <div className={`footer__lang`}>
            <span>
              <span className="lang-text">{t('FOOTER_LANGUAGE')}</span>
              {languageList.map((lang, idx) => (
                <span
                  key={idx}
                  className={`${
                    currentLanguage === lang.code && 'current-lang'
                  } ${lang.className} cursor-pointer`}
                  onClick={() => {
                    this.changeLang(lang.code);
                  }}
                  data-enable-ga
                  data-ga-type="button"
                >
                  {lang.label}
                </span>
              ))}
            </span>
          </div>
          <div className={`footer__social-media-btn`}>
            <div className="link" data-enable-ga data-ga-type="link">
              <a target="_blank" href={facebookUrl} rel="noreferrer">
                <img src={facebookIcon} />
              </a>
            </div>
            <div className="link" data-enable-ga data-ga-type="link">
              <a target="_blank" href={youtubeUrl} rel="noreferrer">
                <img src={youtubeIcon} />
              </a>
            </div>
            <div className="link" data-enable-ga data-ga-type="link">
              <a target="_blank" href={linkedInUrl} rel="noreferrer">
                <img src={linkedInIcon} />
              </a>
            </div>
            <div className="link" data-enable-ga data-ga-type="link">
              <a target="_blank" href={instagramUrl} rel="noreferrer">
                <img src={instagramIcon} />
              </a>
            </div>
          </div>
        </div>
        <div className="footer__tablet-axa-web-contact-us">
          <div className={`link`}>
            <a href={axaWebUrl} data-enable-ga data-ga-type="link">
              {t('FOOTER_GO_BACK_AXA_WEB')}
            </a>
          </div>
          <div className={`link`} data-enable-ga data-ga-type="link">
            <a href={contactUsUrl}>{t('FOOTER_CONTACT_US')}</a>
          </div>
        </div>
        <div className="footer__line"></div>
        <div className="footer__tablet-lang-copyright">
          <div className={`footer__lang`}>
            <span
              className="cursor-pointer"
              onClick={() => {
                this.changeLang();
              }}
              data-enable-ga
              data-ga-type="button"
            >
              <FontAwesomeIcon className="icon" icon="globe-americas" />
              {this.getOppositeLangLabel()}
            </span>
          </div>
          <div className="footer__line"></div>
          <div className={`footer__copyright`}>
            <div className="empty-space"></div>
            <span>
              {`${t(
                'FOOTER_COPTYRIGHT_LABEL_1',
              )} ${new Date().getFullYear()} ${t('FOOTER_COPTYRIGHT_LABEL_2')}`}
            </span>
          </div>
        </div>
      </footer>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {
      currentLanguage: getLanguage(state),
      facebookUrl: getFacebookUrl(state),
      youtubeUrl: getYoutubeUrl(state),
      linkedInUrl: getLinkedInUrl(state),
      instagramUrl: getInstagramUrl(state),
      policyUrl: getPolicyUrl(state),
      privacyUrl: getPrivacyUrl(state),
      axaWebUrl: getAxaWebUrl(state),
      contactUsUrl: getContactUsUrl(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {};
  }
}

export default connect(
  Footer.mapStateToProps,
  Footer.mapDispatchToProps,
)(Footer);
