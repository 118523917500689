import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  OK_DIALOG,
  TOGGLE_HIDE_DIALOG,
  OPEN_TNC,
  ACCEPT_TNC,
  CLOSE_TNC,
  CHANGE_CHECKBOX_VALUE_TNC,
  FETCH_TC_FAILURE,
  SHOW_MODAL,
  HIDE_MODAL,
  OPEN_TNC_STYLE_MODEL,
  CLOSE_TNC_STYLE_MODEL,
  OK_TNC_STYLE_MODEL,
  CHECK_RADIO_DIALOG,
  OPEN_CALL_SUPPORT_MODEL,
  CLOSE_CALL_SUPPORT_MODEL,
  SHOW_ERROR_MODAL,
  HIDE_ERROR_MODAL,
} from '../actions/uiActions';
import { TOGGLE_ALL_INSURANCE_SERVICES } from '../modules/home/actions';

export const REDUCER_NAME = 'ui';

const defaultState = {
  homepage: {
    showAllInsuranceService: false,
    lockedModalShow: false,
  },
  dialog: {
    visible: false,
    title: '',
    body: '',
    modalType: 'lockedModal',
    isCheckRadio: false,
  },
  closeDialog: '',
  openDialog: '',
  tncDialog: {
    visible: false,
    tnc_first_checkbox: null,
    tnc_second_checkbox: null,
    tnc_third_checkbox: null,
    error: null,
  },
  modal: {
    visible: false,
  },
  emmaTncStyleModel: {
    visible: false,
    title: '',
    body: '',
    modalType: 'lockedModal',
    isPreModel: false,
  },
  emmaContactCallModel: {
    visible: false,
    title: '',
    bodyContent: [],
    modalType: 'lockedModal',
  },
  errorModal: {
    visible: false,
  },
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case OPEN_DIALOG:
      nextState.dialog = { ...action.payload, visible: true };
      return nextState;
    case CLOSE_DIALOG:
      nextState.dialog = null;
      return nextState;
    case OK_DIALOG:
      nextState.dialog = null;
      return nextState;
    case TOGGLE_HIDE_DIALOG:
      if (!nextState.dialog) {
        nextState.dialog = defaultState.dialog;
      }
      nextState.dialog.visible = !nextState.dialog.visible;
      return nextState;
    case TOGGLE_ALL_INSURANCE_SERVICES:
      nextState.homepage.showAllInsuranceService =
        !nextState.homepage.showAllInsuranceService;
      return nextState;
    case OPEN_TNC:
      nextState.tncDialog = {
        ...action.payload,
        ...defaultState.tncDialog,
        visible: true,
      };
      return nextState;
    case ACCEPT_TNC:
      // nextState.tncDialog = Object.assign({}, state.tncDialog, {
      //     visible: false
      // });
      // nextState.tncDialog = {...action.payload, visible: false};
      return nextState;
    case CLOSE_TNC:
      nextState.tncDialog = null;
      return nextState;
    case CHANGE_CHECKBOX_VALUE_TNC:
      nextState.tncDialog = Object.assign({}, state.tncDialog, {
        [action.payload]: !state.tncDialog[action.payload],
      });
      return nextState;
    case FETCH_TC_FAILURE:
      nextState.tncDialog = Object.assign(
        {},
        {
          error: action.error,
        },
      );
      return nextState;
    case SHOW_MODAL:
      nextState.modal = {
        visible: true,
      };
      return nextState;
    case HIDE_MODAL:
      nextState.modal = {
        visible: false,
      };
      return nextState;
    case OPEN_TNC_STYLE_MODEL:
      if (action.payload.isPreModel) {
        nextState.dialog.visible = !nextState.dialog.visible;
      }
      nextState.emmaTncStyleModel = { ...action.payload, visible: true };
      return nextState;
    case CLOSE_TNC_STYLE_MODEL:
      if (nextState.emmaTncStyleModel.isPreModel) {
        nextState.dialog.visible = !nextState.dialog.visible;
      }
      nextState.emmaTncStyleModel = null;
      return nextState;
    case OK_TNC_STYLE_MODEL:
      if (nextState.emmaTncStyleModel.isPreModel) {
        nextState.dialog.visible = !nextState.dialog.visible;
      }
      nextState.emmaTncStyleModel = null;
      return nextState;
    case CHECK_RADIO_DIALOG:
      nextState.dialog.isCheckRadio = action.payload;
      return nextState;
    case OPEN_CALL_SUPPORT_MODEL:
      nextState.emmaContactCallModel = { ...action.payload, visible: true };
      return nextState;
    case CLOSE_CALL_SUPPORT_MODEL:
      nextState.emmaContactCallModel = null;
      return nextState;
    case SHOW_ERROR_MODAL:
      nextState.errorModal = {
        visible: true,
      };
      return nextState;
    case HIDE_ERROR_MODAL:
      nextState.errorModal = {
        visible: false,
      };
      return nextState;
    default:
      return nextState;
  }
};
