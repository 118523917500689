import config from '../package.json';
const ID = `${config.name}/actions/eb-flexi`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const OPEN_EB_OVERLAY_MODEL = createActionName('OPEN_EB_OVERLAY_MODEL');
export const CLOSE_EB_OVERLAY_MODEL = createActionName(
  'CLOSE_EB_OVERLAY_MODEL',
);
export const EMMA_EB_FLEXI_POPUP = createActionName('EMMA_EB_FLEXI_POPUP');
export const UPDATE_EB_FLEXI_LOADING = createActionName(
  'UPDATE_EB_FLEXI_LOADING',
);
export const FETCH_EB_FLEXI_SUCCESS = createActionName(
  'FETCH_EB_FLEXI_SUCCESS',
);
export const FETCH_EB_FLEXI_DATA = createActionName('FETCH_EB_FLEXI_DATA');
export const UPDATE_CLICK_FROM_BANNER = createActionName(
  'UPDATE_CLICK_FROM_BANNER',
);
export const UPDATE_EB_FLEXI_TOKEN = createActionName('UPDATE_EB_FLEXI_TOKEN');
export const GO_TO_REDIRECT = createActionName('GO_TO_REDIRECT');
export const EB_FLEXI_SHOW_LOADING_BTN = createActionName(
  'EB_FLEXI_SHOW_LOADING_BTN',
);
export const EB_FLEXI_HIDE_LOADING_BTN = createActionName(
  'EB_FLEXI_HIDE_LOADING_BTN',
);

export const openEbFlexiModel = () => ({
  type: OPEN_EB_OVERLAY_MODEL,
});

export const closeEbFlexiModel = () => ({
  type: CLOSE_EB_OVERLAY_MODEL,
});

export const fetchEbFlexiData = (payload: any) => ({
  type: FETCH_EB_FLEXI_DATA,
  payload,
});

export const updateClickFromBanner = (isClickFromBaner: boolean) => ({
  type: UPDATE_CLICK_FROM_BANNER,
  isClickFromBaner,
});

export const goToRedirect = () => ({
  type: GO_TO_REDIRECT,
});

export const setupShowLoadingBTN = () => ({
  type: EB_FLEXI_SHOW_LOADING_BTN,
});
