import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@axa-asia/emma-stylesheet/lib/components/Modal';
import {
  triggerPopupDisplayScreenChange,
  triggerPopupCloseScreenChange,
} from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';
import PolicyCard from '@axa-asia/emma-stylesheet/lib/components/PolicyCard/PolicyCard';
import { openDisclaimer } from '../../../modules/emma-common/actions';
import {
  openEbFlexiModel,
  closeEbFlexiModel,
  updateClickFromBanner,
  goToRedirect,
  setupShowLoadingBTN,
} from '../../../modules/eb-flexi/actions/ebFlexiActions';
import {
  getEbFlexiOverlayVisible,
  getEbFlexiIsShowPopupModal,
  getIsClickFromBaner,
  getPolicyCard,
  getOverlayLabel,
  getEbFlexiIsShowLoadingBtn,
  getEbFlexiOpenUrl,
  getEbFlexiRedirectUrl,
} from '../../../modules/eb-flexi/selectors/ebFlexiSelectors';
import HomePagePopUpRegistry from '../../../registries/HomePagePopUpRegistry';
import {
  EMMA_EB_FLEXI,
  EB_FELXI_FROM,
  EB_FLEXI_DISCLAIMER_ID,
} from '../../../constants/constants';
import { joinPath, getBaseUrl, resolveLocalizedText } from '../../../utils';
import './styles.scss';
import {
  trackNavigationOverlayClicked,
  trackNavigationOverlayDisplay,
} from '../../../utils/generalGaEventAnalytics';
import { setDisplayedButtonText } from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';
import { SmallButton } from '@axa-asia/emma-stylesheet';

/**
 * type - meaning of type prop is that where the entry point from.
 * 	If value is 'eb', EB-only account is logged in and display 'Download Emma App' board.
 * 	If value is not 'eb', normal account is logged in and display home page.
 */
interface EBFlexiOverlayViewProps {
  type?: string;
  isShowOverlay: boolean;
  isClickFromBaner: boolean;
  policyCard: any;
  overlayLabel: any;
  isShowLoadingBtn: boolean;
  ebFlexiOpenUrl: string;
  ebFlexiRedirectUrl: string;
  onOpenDisclaimer: (
    id: string,
    params: any,
    isGoBack: boolean,
    callback: () => void,
  ) => {};
  onOpenEbFlexiModel: () => void;
  onCloseEbFlexiModel: () => void;
  onUpdateClickFromBanner: (isClickFromBaner: boolean) => void;
  onGoToRedirect: () => void;
  onSetupShowLoadingBTN: () => void;
}

interface EBFlexiOverlayViewState {}

class EBFlexiOverlayView extends Component<
  EBFlexiOverlayViewProps,
  EBFlexiOverlayViewState
> {
  static defaultProps = {};

  constructor(props: EBFlexiOverlayViewProps) {
    super(props);
    this.state = {};
    this.onHideModal = this.onHideModal.bind(this);
    this.onClickedCloseBtn = this.onClickedCloseBtn.bind(this);
    this.showDisclaimer = this.showDisclaimer.bind(this);

    if (this.props.isShowOverlay) {
      trackNavigationOverlayDisplay(EMMA_EB_FLEXI);
    }
  }

  componentDidUpdate(prevProps: EBFlexiOverlayViewProps) {
    if (
      prevProps.isShowOverlay !== this.props.isShowOverlay &&
      this.props.isShowOverlay
    ) {
      trackNavigationOverlayDisplay(EMMA_EB_FLEXI);
      triggerPopupDisplayScreenChange('PS-EBFlexi/Home-entryPop');
    }
  }

  onClickedCloseBtn() {
    const { overlayLabel } = this.props;
    const { closeButton } = overlayLabel;
    setDisplayedButtonText(
      closeButton ? resolveLocalizedText(closeButton) : '',
    );
    trackNavigationOverlayClicked(
      resolveLocalizedText(closeButton),
      EMMA_EB_FLEXI,
    );
    this.onHideModal();
  }

  onHideModal() {
    const {
      onCloseEbFlexiModel,
      onUpdateClickFromBanner,
      isClickFromBaner,
      type,
      overlayLabel,
    } = this.props;

    if (!isClickFromBaner && type !== EB_FELXI_FROM) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(EMMA_EB_FLEXI);
    }

    onCloseEbFlexiModel();
    onUpdateClickFromBanner(false);

    triggerPopupCloseScreenChange();
  }

  goToGetEbFlexiToken() {
    const { onOpenDisclaimer, policyCard, ebFlexiOpenUrl, ebFlexiRedirectUrl } =
      this.props;
    this.onHideModal();
    const { policyNumber, certificateNumber } = policyCard;

    onOpenDisclaimer(EB_FLEXI_DISCLAIMER_ID, null, false, () => {
      HomePagePopUpRegistry.setIsShowPopupCompleted(EMMA_EB_FLEXI);
      const ebFlexiState = encodeURIComponent(
        `{"policyNo":"${policyNumber}","certNo":"${certificateNumber}"}`,
      );
      const openUrl = ebFlexiOpenUrl
        .replace('${redirectUri}', ebFlexiRedirectUrl)
        .replace('${state}', ebFlexiState);
      window.open(openUrl);
    });
  }

  showDisclaimer() {
    const { overlayLabel } = this.props;
    const { continueButton } = overlayLabel;
    trackNavigationOverlayClicked(
      resolveLocalizedText(continueButton),
      EMMA_EB_FLEXI,
    );
    this.goToGetEbFlexiToken();
  }

  render() {
    const { isShowOverlay, policyCard, overlayLabel, isShowLoadingBtn } =
      this.props;
    const { title, content, selectPolicyCard, continueButton, closeButton } =
      overlayLabel;
    return (
      <div className="eb-flexi-overlay-modal">
        <Modal show={isShowOverlay} onHide={this.onClickedCloseBtn} type="emma">
          <div className="eb-flexi-overlay">
            <div className="eb-flexi-overlay_top d-flex">
              <SmallButton
                className="mt-3 mr-3 ml-auto"
                varient="ghost"
                onClick={this.onClickedCloseBtn}
                data-enable-ga="true"
                data-ga-type="button"
              >
                {closeButton ? resolveLocalizedText(closeButton) : ''}
              </SmallButton>
            </div>
            <div className="eb-flexi-overlay_body">
              <div className="eb-flexi-overlay_title">
                {title ? resolveLocalizedText(title) : ''}
              </div>
              <div className="eb-flexi-overlay_content">
                {content ? resolveLocalizedText(content) : ''}
              </div>
              <div className="eb-flexi-overlay_select_policy">
                {selectPolicyCard ? resolveLocalizedText(selectPolicyCard) : ''}
              </div>
              <div className="eb-flexi-overlay_policy_card">
                <PolicyCard {...policyCard} />
              </div>
            </div>
          </div>
          <div className="eb-flexi-overlay_button content-align">
            <button
              data-enable-ga
              data-ga-type="button"
              className={`emma-stylesheet-primary-default-button ${
                isShowLoadingBtn
                  ? 'emma-stylesheet-left-default-loading-icon-button'
                  : ''
              }`}
              disabled={isShowLoadingBtn}
              onClick={this.showDisclaimer}
            >
              {continueButton ? resolveLocalizedText(continueButton) : ''}
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    const { type } = ownProps;
    const ebFlexiOverlayVisible = getEbFlexiOverlayVisible(state);
    const isShowPopupModal = getEbFlexiIsShowPopupModal(state);
    const isShowOverlay =
      type === EB_FELXI_FROM
        ? ebFlexiOverlayVisible
        : ebFlexiOverlayVisible && isShowPopupModal;

    const isClickFromBaner = getIsClickFromBaner(state);
    const policyCard = getPolicyCard(state);
    const overlayLabel = getOverlayLabel(state);
    const isShowLoadingBtn = getEbFlexiIsShowLoadingBtn(state);
    const ebFlexiOpenUrl = getEbFlexiOpenUrl(state);
    const ebFlexiRedirectUrl = getEbFlexiRedirectUrl(state);
    return {
      isShowOverlay,
      isClickFromBaner,
      policyCard,
      overlayLabel,
      isShowLoadingBtn,
      ebFlexiOpenUrl,
      ebFlexiRedirectUrl,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onOpenDisclaimer: (
        id: string,
        params: any,
        isGoBack: boolean,
        callback: () => void,
      ) => dispatch(openDisclaimer(id, params, isGoBack, callback)),
      onOpenEbFlexiModel: () => dispatch(openEbFlexiModel()),
      onCloseEbFlexiModel: () => dispatch(closeEbFlexiModel()),
      onUpdateClickFromBanner: (isClickFromBaner: boolean) =>
        dispatch(updateClickFromBanner(isClickFromBaner)),
      onGoToRedirect: () => dispatch(goToRedirect()),
      onSetupShowLoadingBTN: () => dispatch(setupShowLoadingBTN()),
    };
  }
}

export default connect(
  EBFlexiOverlayView.mapStateToProps,
  EBFlexiOverlayView.mapDispatchToProps,
)(EBFlexiOverlayView);
