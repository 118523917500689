import { put } from 'redux-saga/effects';

export const SHOW_AKTIVO_MODAL = 'SHOW_AKTIVO_MODAL';
export const AKTIVO_BACK = 'AKTIVO_BACK';
export const AKTIVO_SHOW_MY_WELLNESS = 'AKTIVO_SHOW_MY_WELLNESS';
export const AKTIVO_MODAL_DISPLAY = 'AKTIVO_MODAL_DISPLAY';
export const FETCH_AKTIVO_CUSTOMER_STATE = 'FETCH_AKTIVO_CUSTOMER_STATE';
export const FETCH_AKTIVO_CUSTOMER_STATE_SUCCESS =
  'FETCH_AKTIVO_CUSTOMER_STATE_SUCCESS';
export const AKTIVO_ENTER_DEEPLINK_WITH_CHECKING =
  'AKTIVO_ENTER_DEEPLINK_WITH_CHECKING';

export const toggleAktivoModelDisplay = (isShowAktivoModal: boolean) => {
  return {
    type: AKTIVO_MODAL_DISPLAY,
    isShowAktivoModal,
  };
};
