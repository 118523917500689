import {
  FETCH_DASHBOARD_POLICY_SUCCESS,
  UPDATE_DASHBOARD_POLICY_LOADING,
} from '../actions/dashboardAction';

const defaultState = {
  isLoading: false,
  policyTitle: '',
  dashboardPolicies: [],
  isShowViewAllCard: false,
  policyLength: 0,
  isShowTrackingDevice: false,
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_DASHBOARD_POLICY_LOADING:
      nextState.isLoading = action.isLoading || false;
      return nextState;
    case FETCH_DASHBOARD_POLICY_SUCCESS:
      const { payload = {} } = action;
      nextState.dashboardPolicies = payload.dashboardPolicies;
      nextState.policyTitle = payload.policyTitle;
      nextState.isShowViewAllCard = payload.isShowViewAllCard;
      nextState.policyLength = payload.policyLength;
      nextState.isShowTrackingDevice = payload.isShowTrackingDevice;
      return nextState;
    default:
      return nextState;
  }
};
