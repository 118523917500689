import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './stores/browserHistory';
import LoadingView from './components/LoadingView';
import { store, persistor } from './stores';
import RootContainer from './containers/RootContainer';
import { PersistGate } from 'redux-persist/integration/react';
import { goto } from './actions/historyActions';
import {
  Profile,
  setCustomerLogin,
  setGoto,
  useCustomerLogin,
  useGoto,
} from '@axa-asia/utils';
import { getCustomer } from './selectors/userSelectors';
import { isMobileRoute } from './actions/appActions';

const App = () => {
  const [isInit, setIsInit] = useState(false);
  useGoto();
  useCustomerLogin();

  useEffect(() => {
    setGoto((s, mode) => {
      goto(s, mode);
    }, () => {
      setIsInit(true)
    });
  }, []);

  return (
    <Provider store={store}>
      {isMobileRoute() && <CustomerDataInit />}
      <PersistGate loading={<LoadingView />} persistor={persistor}>
        <ConnectedRouter history={history}>
          {isInit ? <RootContainer /> : <LoadingView />}
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

const CustomerDataInit = () => {
  const customer = useSelector(getCustomer);
  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      setCustomerLogin({
        // @ts-ignore
        profile: [customer as Profile],
        // axaId: '',
        // loginId: '',
        // lobs: [],
        // features: [],
        // insuredPopUpFlag: 'N',
        // policies: [],
      });
    }, 3000);
  }, [customer]);

  return null;
};

export default () =>
  new Promise<void>((res) => {
    const rootElement = document.getElementById('root');

    if (rootElement) {
      rootElement.removeAttribute('class');
      ReactDOM.render(<App />, rootElement, () => res());
    }
  });
