import {
  FETCH_FANCL_POLICIES_SUCCESS,
  FETCH_FANCL_STORES_SUCCESS,
  FETCH_FANCL_STORE_TIMES_SUCCESS,
  FANCL_COMMIT_BOOKING_SUCCESS,
  FANCL_COMMIT_BOOKING_FAILURE,
  FANCL_SHOW_MODAL,
  FANCL_HIDE_MODAL,
  FANCL_INIT,
} from '../actions/fanclAction';

const defaultState = {
  fanclPolicies: undefined,
  fanclStores: [],
  fanclStoreTimes: [],
  appointmentData: {
    appointmentStore: '',
    appointmentDate: '',
    appointmentTime: '',
    contactEmail: '',
    mobileNo: '',
  },
  appointmentError: '',
  showModal: false,
  name: '',
};
export interface appointmentState {
  fanclPolicies?: any;
  fanclStores?: any;
  fanclStoreTimes?: any;
  appointmentData: {
    appointmentStore: string;
    appointmentDate: string;
    appointmentTime: string;
    contactEmail: string;
    mobileNo: string;
  };
  appointmentError?: string;
  showModal?: boolean;
  name: string;
}

export interface RetrievePolicyDetailsResponse {
  policy: RetrievePolicyDetails;
}

export interface RetrievePolicyDetails {
  insuredDetails: {
    lastName: string;
  };
}

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FANCL_INIT:
      return defaultState;
    case FETCH_FANCL_POLICIES_SUCCESS:
      nextState.fanclPolicies = action.fanclPolicies;
      return nextState;
    case FETCH_FANCL_STORES_SUCCESS:
      nextState.fanclStores = action.fanclStores;
      return nextState;
    case FETCH_FANCL_STORE_TIMES_SUCCESS:
      nextState.fanclStoreTimes = action.fanclStoreTimes;
      return nextState;
    case FANCL_COMMIT_BOOKING_SUCCESS:
      nextState.appointmentData = action.appointmentData;
      return nextState;
    case FANCL_COMMIT_BOOKING_FAILURE:
      nextState.appointmentError = action.appointmentError;
      return nextState;
    case FANCL_SHOW_MODAL:
      nextState.showModal = true;
      return nextState;
    case FANCL_HIDE_MODAL:
      nextState.showModal = false;
      return nextState;
    default:
      return nextState;
  }
};
