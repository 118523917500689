import config from '../package.json';
const ID = `${config.name}/actions/changeContactAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const UPDATE_VERIFY_LOADING = createActionName('UPDATE_VERIFY_LOADING');
export const UPDATE_CHANGE_CONTACT_LOADING = createActionName(
  'UPDATE_CHANGE_CONTACT_LOADING',
);
export const FETCH_POLICY_CARDS = createActionName('FETCH_POLICY_CARDS');
export const FETCH_POLICY_CARDS_SUCCESS = createActionName(
  'FETCH_POLICY_CARDS_INFO_SUCCESS',
);
export const FETCH_GO_GREEN_POLICY_LIST = createActionName(
  'FETCH_GO_GREEN_POLICY_LIST',
);
export const FETCH_GO_GREEN_POLICY_LIST_SUCCESS = createActionName(
  'FETCH_GO_GREEN_POLICY_LIST_SUCCESS',
);
export const SUBMIT_GO_GREEN_POLICY_LIST = createActionName(
  'SUBMIT_GO_GREEN_POLICY_LIST',
);
export const SUBMIT_GO_GREEN_POLICY_LIST_SUCCESS = createActionName(
  'SUBMIT_GO_GREEN_POLICY_LIST_SUCCESS',
);
export const VERIFY_CODE = createActionName('VERIFY_CODE');
export const RESEND_CODE = createActionName('RESEND_CODE');
export const RESEND_CODE_SUCCESS = createActionName('RESEND_CODE_SUCCESS');
export const RESEND_CODE_FAIL = createActionName('RESEND_CODE_FAIL');

export const SAVE_SELECTED_POLICY = createActionName('SAVE_SELECTED_POLICY');

export const DISPLAY_ERROR_CODE = createActionName('DISPLAY_ERROR_CODE');

export const INIT_VERIFY_ERROR_CODE = createActionName(
  'INIT_VERIFY_ERROR_CODE',
);

export const fetchPolicyCards = (payload: any) => ({
  type: FETCH_POLICY_CARDS,
  payload,
});

export const saveSelectedPolicy = (policy: any) => ({
  type: SAVE_SELECTED_POLICY,
  policy,
});

export const fetchGoGreenPolicyList = () => ({
  type: FETCH_GO_GREEN_POLICY_LIST,
});

export const verifyCode = (payload: any) => ({
  type: VERIFY_CODE,
  payload,
});

export const resendCode = () => ({
  type: RESEND_CODE,
});
