import { EMMA_BETTER_ME_POPUP } from '../actions/myPrivilegesOverlayActions';
import { EMMA_WELCOME_LAUNCH_POPUP } from '../actions/myPrivilegesOverlayActions';
import { EMMA_BETTER_ME } from '../constants/constants';
import { FETCH_LAUNCHPAD_DATA_SUCCESS } from '../actions/userActions';

const defaultState = {
  isShowPopupModal: false,
  isShowLaunchpad: false,
  launchpadData: {},
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case EMMA_BETTER_ME_POPUP:
      nextState.isShowPopupModal = action.show.isShowPopupModal;
      return nextState;
    case EMMA_BETTER_ME:
      nextState.isShowPopupModal = false;
      return nextState;
    case EMMA_WELCOME_LAUNCH_POPUP:
      nextState.isShowLaunchpad = action.show.display;
      return nextState;
    case FETCH_LAUNCHPAD_DATA_SUCCESS:
      nextState.launchpadData = action.launchpadData;
      return nextState;
    default:
      return nextState;
  }
};
