export enum CMSProfile {
  'EB-Whitelist' = 'EB-Whitelist',
  'MCV' = 'MCV',
  'LHK-Agent' = 'LHK-Agent',
  'LHK-Broker' = 'LHK-Broker',
  'LHK-SwP' = 'LHK-SwP',
  'LHK-Direct' = 'LHK-Direct',
  'LMA-Agent' = 'LMA-Agent',
  'LMA-Broker' = 'LMA-Broker',
  'LMA-Direct' = 'LMA-Direct',
  'PnC-Agent' = 'PnC-Agent',
  'PnC-Broker' = 'PnC-Broker',
  'PnC-Direct' = 'PnC-Direct',
  'PnC-DPP' = 'PnC-DPP',
  'EB-Banca' = 'EB-Banca',
  'EB-Agent' = 'EB-Agent',
  'EB-Broker' = 'EB-Broker',
  'EB-Direct' = 'EB-Direct',
  'EB-Macau' = 'EB-Macau',
  'EB-Blacklist' = 'EB-Blacklist',
  'Others' = 'Others',
  'CWP' = 'CWP',
  'Placeholder' = 'Placeholder',
}
