import {
  UPDATE_BUTTON_LOADING,
  UPDATE_PAGE_LOADING,
  UPDATE_HEALTH_SURVEY_DATA,
  SAVE_ACCESS_TOKEN,
  UPDATE_ENTRY,
} from '../actions';
import {
  AKTIVO_HEALTH_SURVEY_LBS_DEFAULT_VALUE,
  AKTIVO_HEALTH_SURVEY_LBS,
  AKTIVO_HEALTH_SURVEY_TALL_DEFAULT_VALUE,
  AKTIVO_HEALTH_SURVEY_BED_TIME_DEFAULT_VALUE,
  AKTIVO_HEALTH_SURVEY_WAKE_UP_TIME_DEFAULT_VALUE,
  AKTIVO_HEALTH_SURVEY_SMOKE_DEFAULT_VALUE,
  AKTIVO_HEALTH_SURVEY_DRINK_DEFAULT_VALUE,
  AKTIVO_HEALTH_SURVEY_GENDER_DEFAULT_VALUE,
  AKTIVO_HEALTH_SURVEY_DOB_DEFAULT_VALUE,
} from '../constants';

const defaultState = {
  isLoading: false,
  isPageLoading: false,
  healthSurveyData: {
    gender: AKTIVO_HEALTH_SURVEY_GENDER_DEFAULT_VALUE,
    dob: AKTIVO_HEALTH_SURVEY_DOB_DEFAULT_VALUE,
    bedTime: AKTIVO_HEALTH_SURVEY_BED_TIME_DEFAULT_VALUE,
    wakeUpTime: AKTIVO_HEALTH_SURVEY_WAKE_UP_TIME_DEFAULT_VALUE,
    tall: AKTIVO_HEALTH_SURVEY_TALL_DEFAULT_VALUE,
    weight: AKTIVO_HEALTH_SURVEY_LBS_DEFAULT_VALUE,
    weightUnit: AKTIVO_HEALTH_SURVEY_LBS,
    smoke: AKTIVO_HEALTH_SURVEY_SMOKE_DEFAULT_VALUE,
    drink: AKTIVO_HEALTH_SURVEY_DRINK_DEFAULT_VALUE,
  },
  token: {
    access_token: '',
    aktivo_id: '',
  },
  entry: '',
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_BUTTON_LOADING:
      nextState.isLoading = action.isLoading;
      return nextState;
    case UPDATE_PAGE_LOADING:
      nextState.isPageLoading = action.isLoading;
      return nextState;
    case UPDATE_HEALTH_SURVEY_DATA:
      nextState.healthSurveyData = {
        ...nextState.healthSurveyData,
        ...action.payload,
      };
      return nextState;
    case SAVE_ACCESS_TOKEN:
      nextState.token = action.payload;
      return nextState;
    case UPDATE_ENTRY:
      nextState.entry = action.entry;
      return nextState;
    default:
      return nextState;
  }
};
