import { AuthState } from '../reducers/authReducer';
import { _get } from '../utils';
import config from '../package.json';
import { HistoryState } from '../reducers/historyReducer';

const reducerName = `${config.name}/history`;

export const getPreviousTitle = (state: {}) => {
  const titleStackClone = _get<HistoryState>(
    state,
    reducerName,
  ).titleStack.slice();
  return titleStackClone.pop();
};

export const getHideBackButton = (state: {}) => {
  return _get<HistoryState>(state, reducerName).hideBackButton;
};
