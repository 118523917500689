export const maskEmail = (email: string): string => {
  if (!email) return;

  const [username, fullDomain] = email.split('@');
  const beforeDomain = fullDomain.split('.')[0];

  const afterDomain =
    '.' +
    fullDomain
      .split('.')
      .filter((_, i) => i !== 0)
      .join('.');

  if (username.length > 7) {
    const n = email.length - fullDomain.length - 3;
    const maskedUsername =
      username.slice(0, username.length - n) + '*'.repeat(n);
    return `${maskedUsername}@${fullDomain}`;
  } else if (username.length > 3) {
    const n = email.length - fullDomain.length - 3;
    const maskedUsername =
      username.slice(0, username.length - n) + '*'.repeat(n);
    const n2 = fullDomain.length - afterDomain.length - 1;
    const maskedDomain = beforeDomain[0] + '*'.repeat(n2) + afterDomain;
    return `${maskedUsername}@${maskedDomain}`;
  } else {
    const n = email.length - fullDomain.length - 2;
    const maskedUsername =
      username.slice(0, username.length - n) + '*'.repeat(n);
    const n2 = fullDomain.length - afterDomain.length;
    const maskedDomain = '*'.repeat(n2) + afterDomain;
    return `${maskedUsername}@${maskedDomain}`;
  }
};
