import './styles.css';
import React, { Component } from 'react';
import _ from 'lodash';
import {
  extractRelativeUrl,
  resolvePublicAssets,
  ngClass,
  isApp,
} from '../../utils';
import history from '../../stores/browserHistory';
import { UnregisterCallback } from 'history';
import { EMMA_MY_PRIVILEGES_MODULE_NAME } from '../../constants/constants';
import { getToken } from '../../selectors/authSelectors';
import { getValidToken } from '../../utils/index';
import { getCurrentTab } from '../../modules/myPrivileges/selectors/myPrivilegesSelector';
import { select } from '../../stores';
import {
  getGaPagePath,
  handleGaError,
  trackNavigationTab,
} from '../../utils/generalGaEventAnalytics';
import { maskPN } from '@axa-asia/hk-emma-web-module/lib/ga-lib/MaskingUtil';

export interface SideBarItemProps {
  label: string;
  iconDefault: string;
  iconSelected: string;
  route: string;
  moduleName: string;
  isShowRedDot: boolean;
  excludedRoutes?: string[];
  includedRoutes?: string[];
}

interface SideBarItemState {
  hover: boolean;
}

export default class SideBarItem extends Component<
  SideBarItemProps,
  SideBarItemState
> {
  unregisterHistoryListener?: UnregisterCallback;
  constructor(props: SideBarItemProps) {
    super(props);
    this.state = {
      hover: false,
    };

    this.onClickToGatc = this.onClickToGatc.bind(this);
  }

  componentWillUnmount() {
    this.unregisterHistoryListener && this.unregisterHistoryListener();
  }

  componentDidMount() {
    this.unregisterHistoryListener = history.listen(() => {
      this.forceUpdate();
    });
  }

  isSelected() {
    const { route, excludedRoutes, includedRoutes } = this.props;
    const relativeUrl = extractRelativeUrl();

    const isExcluded = _.some(excludedRoutes, (excludedRoute) =>
      relativeUrl.startsWith(excludedRoute),
    );
    const isIncluded = _.some(includedRoutes, (includedRoute) =>
      relativeUrl.startsWith(includedRoute),
    );

    return (!isExcluded && relativeUrl.startsWith(route)) || isIncluded;
  }

  onClickToGatc(icon: string) {
    const { route } = this.props;
    const tcWindow: any = window;
    const token = select(getToken);
    const aToken = getValidToken(token);
    try {
      trackNavigationTab(icon, `/emma-webportal${getGaPagePath()}`);

      if (aToken) {
        const myPrivilegesCurrentTap = select(getCurrentTab) || '';
        let page = `/emma-webportal${route}`;
        if (route === '/marketplace/myprivileges') {
          page = `${page}#${myPrivilegesCurrentTap.toLowerCase()}`;
        }

        const tcWindow: any = window;
        tcWindow.dataLayer.push({
          event: 'screenView',
          page: maskPN(page),
          userId: aToken,
        });

      // HKEMMA-100917 - GA3 Decommission - Remove GA3
        // if (!isApp()) {
        //   tcWindow.tC.event.virtualPageview(tcWindow, {
        //     page: page,
        //     AXAID: aToken,
        //   });
        // }
      }
    } catch (e) {
      handleGaError(e);
    }
  }

  render() {
    const { hover } = this.state;
    const { label, iconDefault, iconSelected, moduleName, isShowRedDot } =
      this.props;

    const iconDefaultPath = resolvePublicAssets(iconDefault);
    const iconSelectedPath = resolvePublicAssets(iconSelected);
    //`sidebar-item ${this.isSelected() ? `selected` : ``}`
    return (
      <span
        className={ngClass(
          {
            selected: this.isSelected(),
            hover,
          },
          `sidebar-item`,
        )}
        onMouseOut={() => this.setState({ hover: false })}
        onMouseOver={() => this.setState({ hover: true })}
        onClick={() => {
          this.onClickToGatc(label);
        }}
        data-enable-ga
        data-ga-type="button"
      >
        <div className="sidebar-item-nav">
          {moduleName === EMMA_MY_PRIVILEGES_MODULE_NAME && isShowRedDot && (
            <span className="feature-dot"></span>
          )}
          <img
            style={!hover && !this.isSelected() ? {} : { display: 'none' }}
            key={iconDefaultPath}
            className={`sidebar-item-img ${
              moduleName === EMMA_MY_PRIVILEGES_MODULE_NAME && isShowRedDot
                ? 'sidebar-heart-img'
                : ''
            }`}
            alt={iconDefaultPath}
            src={iconDefaultPath}
          />
          <img
            style={hover || this.isSelected() ? {} : { display: 'none' }}
            key={iconSelectedPath}
            className={`sidebar-item-img ${
              moduleName === EMMA_MY_PRIVILEGES_MODULE_NAME && isShowRedDot
                ? 'sidebar-heart-img'
                : ''
            }`}
            alt={iconSelectedPath}
            src={iconSelectedPath}
          />
        </div>
        <span key={`span`}>{label}</span>
      </span>
    );
  }
}
