// @flow
import { Emma, EmmaFactory, EmmaInitPayload } from '@axa-asia/hk-emma-js';
import { setCustomerLogin } from '@axa-asia/utils';
import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import {
  FETCH_MOBILE_MODULE_CONFIG,
  fetchMobileModuleConfigSuccess,
} from '../actions/mobileModuleConfigAction';

interface FetchMobileModuleConfigPayload {
  action: string;
  payload: {
    moduleName: string;
  };
}

function getUserConfig() {
  const promiseFunc = (resolve: any) => {
    EmmaFactory.createInstance().then((e) => {
      e.instance.getWebUserConfig('').then((userConfig: any) => {
        resolve(userConfig);
      });
    });
  };
  return new Promise<any>(promiseFunc);
}

function* fetchMobileModuleConfig(action: any) {
  const userConfig: {} = yield call(getUserConfig);

  // @ts-ignore
  setCustomerLogin({
    // @ts-ignore
    profile: [userConfig.customerProfile],
    // axaId: '',
    // loginId: '',
    // lobs: [],
    // features: [],
    // insuredPopUpFlag: 'N',
    // policies: [],
  });
  yield put(fetchMobileModuleConfigSuccess(userConfig));
}

function* mobileModuleConfigSaga(): any {
  yield takeLatest(FETCH_MOBILE_MODULE_CONFIG, fetchMobileModuleConfig);
}

export default mobileModuleConfigSaga;
