import _ from 'lodash';
export const _get = <TState>(
  obj: any,
  path: string,
  defaultValue?: TState,
): TState => _.get(obj, path, defaultValue);

export const getIsWelcome = (state: {}) => {
  const search = _get<string>(state, 'router.location.search', '');
  const regexForTeamChallengePageURL = /.*entryPoint=welcome.*/;
  return regexForTeamChallengePageURL.test(search);
};
