import { useMemo } from 'react';
import { getDataFromUrl, getUrlWithParams } from '../helpers/url';

export const useUrlWithParams = (
  emmaUrl: string,
  params: Record<string, string>,
) => {
  return getUrlWithParams(emmaUrl, params);
};

export const useDataFromUrl = (emmaUrl: string) => {
  return useMemo(() => {
    return getDataFromUrl(emmaUrl);
  }, [emmaUrl]);
};
