import {
  FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS,
  FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_FAILURE,
  UPDATE_SIDEBAR_HAVE_READ_NEW_FEATURE_STATE,
  SIDEBAR_ITEM_RED_DOT,
} from '../actions/sideBarItemActions';

const defaultState = {
  profileFeatures: [],
  news: {
    health: [],
    axa: [],
  },
  isShowRedDot: true,
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS:
      const { payload } = action;
      if (payload) {
        nextState.haveReadNewFeatures = action.payload;
      }
      return nextState;
    case FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_FAILURE:
      nextState.haveReadNewFeaturesError = action.error;
      return nextState;
    case UPDATE_SIDEBAR_HAVE_READ_NEW_FEATURE_STATE:
      const { serviceId } = action.payload;
      if (
        nextState.haveReadNewFeatures &&
        nextState.haveReadNewFeatures.features &&
        !nextState.haveReadNewFeatures.features.includes(serviceId)
      ) {
        nextState.haveReadNewFeatures.features.push(serviceId);
      }
      return nextState;
    case SIDEBAR_ITEM_RED_DOT:
      nextState.isShowRedDot = false;
      return nextState;
    default:
      return nextState;
  }
};
