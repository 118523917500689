import {
  VHIS_TYPE,
  VHIS_MODAL_DISPLAY,
  SUMMARY_DISPLAY_DATA,
  FETCH_STEP_COUNT_SUMMARY,
  TOGGLE_CAMPAIGN_BUTTON,
  FETCH_AXA_GOAL_CUSTOMER_STATE_SUCCESS,
  FETCH_MENU_INFO_SUCCESS,
  EMMA_AXA_GOAL_POPUP,
  ANCHOR_TO_RULES_MEMBERSHIP_YEAR,
  IS_GET_TRACKER_STATUS,
  EMMA_AXA_GOAL_ISFIRST,
  SHOW_MEMBERSHIP_TUTORIAL,
  HIDE_MEMBERSHIP_TUTORIAL,
  SHOW_AXA_GOAL_SUPPORT,
} from '../actions/vhisAction';
import { EMMA_AXA_GOAL, ENTITY_CODE } from '../../../constants/constants';
import { FETCH_AKTIVO_STEP_STATS_SUCCESS } from '../actions/vhisAction';

const defaultState = {
  retrieveStepCountSummaryResponse: {
    policyNumber: '',
    entityCode: ENTITY_CODE.LHK,
    totalAchievedDays: '0',
    totalAchieveDaysWithBonus: '0',
    membershipStartDate: '',
    membershipEndDate: '',
    achievedStatus: '',
    discountPercentage: '0',
    bonusAchievedDays: '0',
    stepCount: [],
    discounts: [],
    lastMembershipStartDate: '',
    lastMembershipEndDate: '',
    lastYearStepCount: [],
    lastYearTotalAchievedDaysWithBonus: '0',
    lastYearTotalAchievedDays: '0',
    lastYearTotalBonusDays: '0',
    lastYearEarlybirdBonusAchievedDays: '0',
    totalBonusDays: '0',
    stepCountStartDate: '',
    stepCountEndDate: '',
    lastStepCountStartDate: '',
    laststepCountEndDate: '',
    stepCountMultiAxaIdErrorFlag: 'N',
  },
  showStartCampaignButton: true,
  isGetTrackerStatus: false,
  isFirstYear: true,
  isLoading: true,
  isShowMembershipTutorial: false,
  membershipTutorialRuleType: '',
  aktivoStepStats: [],
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case SHOW_MEMBERSHIP_TUTORIAL:
      nextState.isShowMembershipTutorial = true;
      nextState.membershipTutorialRuleType = action.payload?.ruleType;
      return nextState;
    case HIDE_MEMBERSHIP_TUTORIAL:
      nextState.isShowMembershipTutorial = false;
      nextState.membershipTutorialRuleType = '';
      return nextState;
    case VHIS_TYPE:
      return nextState;
    case VHIS_MODAL_DISPLAY:
      nextState.isShowVhisModal = action.show.isShowVhisModal;
      nextState.isOutOfPopupQueue = action.show.isOutOfPopupQueue || false;
      return nextState;
    case SUMMARY_DISPLAY_DATA:
      nextState.campaignDetail.dropdownValue = action.displayYear;
      return nextState;
    case FETCH_STEP_COUNT_SUMMARY:
      nextState.isLoading = false;
      const state = {
        ...nextState,
        ...action.stepCountSummaryData,
      };
      return state;
    case TOGGLE_CAMPAIGN_BUTTON:
      nextState.showStartCampaignButton = action.showStartCampaignButton;
      return nextState;
    case FETCH_AXA_GOAL_CUSTOMER_STATE_SUCCESS:
      const { custValue } = action.payload;
      nextState.axaGoalCustomerState = custValue;
      return nextState;
    case FETCH_MENU_INFO_SUCCESS:
      const { menuInformationData } = action.payload;
      nextState.menuInformationData = menuInformationData;
      return nextState;
    case EMMA_AXA_GOAL_POPUP:
      nextState.isShowPopupModal = action.show.isShowPopupModal;
      nextState.isOutOfPopupQueue = false;
      return nextState;
    case EMMA_AXA_GOAL:
      nextState.isShowPopupModal = false;
      nextState.isOutOfPopupQueue = false;
      return nextState;
    case ANCHOR_TO_RULES_MEMBERSHIP_YEAR:
      nextState.anchor = action.anchor;
      return nextState;
    case IS_GET_TRACKER_STATUS:
      nextState.isGetTrackerStatus = action.isGetTrackerStatus;
      return nextState;
    case EMMA_AXA_GOAL_ISFIRST:
      nextState.isFirstYear = action.isFirstYear;
      return nextState;
    case SHOW_AXA_GOAL_SUPPORT:
      nextState.entityCode = action.entityCode;
      return nextState;
    case FETCH_AKTIVO_STEP_STATS_SUCCESS:
      nextState.aktivoStepStats = action.payload.aktivoStepStats;
      return nextState;
    default:
      return nextState;
  }
};
