import React, { Component } from 'react';
import { t } from '../../../../locales';
import { connect } from 'react-redux';
import { isApp } from '../../../../utils/';
import classNames from 'classnames';

import ContentWrapper from '../../../../components/ContentWrapper/';
import ImageText from '../../../../components/ImageText/';
import { EmmaFactory } from '@axa-asia/hk-emma-js';

import { getIsWelcome } from '../../selectors/teamChallengeSelectors';

// import image
import BannerImage from '../../../../assets/images/team-challenge/game-rulles-illustration.jpg';
import Jade from '../../../../assets/images/vhis/icon--vhis--jade.png';
import Crystal from '../../../../assets/images/vhis/icon--vhis--crystal.png';
import Diamond from '../../../../assets/images/vhis/icon--vhis--diamond.png';
import './style.scss';
import { fetchCampaignStarted } from '../../actions/teamChallengeAction';

interface RulesScreenProps {
  isWelcome: boolean;
}

class RulesScreen extends Component<RulesScreenProps> {
  constructor(props: any) {
    super(props);
    this.goToAppView = this.goToAppView.bind(this);
  }

  componentDidMount() {
    if (isApp()) {
      const title = t(`TEAM_CHALLENGE_RULES_AND_REGULATIONS`);
      if (title) {
        EmmaFactory.createInstance().then((p) =>
          p.instance.setNavigationTitle(title),
        );
      }
    }
  }

  goToAppView(url: string) {
    if (isApp()) {
      EmmaFactory.createInstance().then((e) => {
        e.instance.goTo(url);
      });
    }
  }

  openWindow(url: string) {
    window.open(url);
  }

  render() {
    const checkIsApp = isApp();
    const { isWelcome } = this.props;
    const vhisAsClasses = classNames({
      'team-challenge-as': true,
      'team-challenge-as--withPaddingBottom': !checkIsApp,
    });

    return (
      <div className={vhisAsClasses}>
        <ContentWrapper>
          {/* Rules and regulations start */}
          <div className="team-challenge-rules">
            {!checkIsApp && (
              <h1 className="team-challenge-rules__title">
                {t('TEAM_CHALLENGE_RULES_AND_REGULATIONS')}
              </h1>
            )}
          </div>
          <div className="team-challenge-rules__full-width">
            <img
              className="team-challenge-rules__banner-img"
              src={BannerImage}
              alt=""
            />
          </div>
          <div className="team-challenge-rules">
            <p className="team-challenge-rules__sub-title">
              {t(`TEAM_CHALLENGE_RULES_MORE_HITS_MORE_FUN_TITLE`)}
            </p>
            <p className="team-challenge-rules__content">
              {t('TEAM_CHALLENGE_RULES_AIMS')}
            </p>
            <p className="team-challenge-rules__sub-title">
              {t(`TEAM_CHALLENGE_RULES_HOW_TO_GET_POINTS_TITLE`)}
            </p>
            <p className="team-challenge-rules__content">
              {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_POINTS_CONTENT_P1')}
            </p>
            {/* <p className="team-challenge-rules__content">{t('TEAM_CHALLENGE_RULES_HOW_TO_GET_POINTS_CONTENT_P2')}</p> */}

            <p className="team-challenge-rules__sub-title">
              {t(`TEAM_CHALLENGE_RULES_HOW_TO_GET_START_TITLE`)}
            </p>

            <div className="team-challenge-rules__container">
              <div className="team-challenge-rules__section">
                <div className="team-challenge-rules__step-row">
                  <div className="team-challenge-rules__step-line"></div>
                  <div>
                    <div className="team-challenge-rules__step-title">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_1')}
                    </div>
                    <div className="team-challenge-rules__step-content">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_1_DESC')}
                    </div>
                  </div>
                </div>

                <div className="team-challenge-rules__step-row">
                  <div className="team-challenge-rules__step-line"></div>
                  <div className="team-challenge-rules__step-title">
                    {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_2')}
                  </div>
                </div>

                {/* <div className="team-challenge-rules__step-row">
                  <div className="team-challenge-rules__step-line"></div>
                  <div>
                    <div className="team-challenge-rules__step-title">{t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_3')}</div>
                    <div className="team-challenge-rules__step-content">{t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_3_DESC')}</div>
                  </div>
                </div> */}

                <div className="team-challenge-rules__step-row">
                  <div className="team-challenge-rules__step-line"></div>
                  <div>
                    <div className="team-challenge-rules__step-title">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_4')}
                    </div>
                    <div className="team-challenge-rules__step-content">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_4_DESC')}
                    </div>
                  </div>
                </div>

                <div className="team-challenge-rules__step-row">
                  <div className="team-challenge-rules__step-line"></div>
                  <div>
                    <div className="team-challenge-rules__step-title">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_5')}
                    </div>
                    <div className="team-challenge-rules__step-content">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_5_DESC')}
                    </div>
                  </div>
                </div>

                <div className="team-challenge-rules__step-row">
                  <div className="team-challenge-rules__step-line team-challenge-rules__step-line--last"></div>
                  <div>
                    <div className="team-challenge-rules__step-title">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_6')}
                    </div>
                    <div className="team-challenge-rules__step-content">
                      {t('TEAM_CHALLENGE_RULES_HOW_TO_GET_START_STEP_6_DESC')}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {checkIsApp && isWelcome && (
              <p className="content-align">
                <a
                  className="team-challenge-rules__btn team-challenge-rules__btn--blue"
                  onClick={() => {
                    this.goToAppView(
                      'team-challenge/disclaimer?disclaimerId=team-challenge-create-team',
                    );
                  }}
                  data-enable-ga
                  data-ga-type="button"
                >
                  {t('TEAM_CHALLENGE_RULES_BTN_CREATE_TEAM')}
                </a>
              </p>
            )}

            {checkIsApp && isWelcome && (
              <div className="content-align">
                <a
                  className="team-challenge-rules__btn"
                  onClick={() => {
                    this.goToAppView(
                      'team-challenge/disclaimer?disclaimerId=team-challenge-got-invitation',
                    );
                  }}
                  target="about:blank"
                  data-enable-ga
                  data-ga-type="button"
                >
                  {t('TEAM_CHALLENGE_RULES_BTN_GOT_INVITATION')}
                </a>
              </div>
            )}
          </div>
          <div className="team-challenge-rules__full-width">
            <div className="team-challenge-rules__footer">
              <div className="team-challenge-rules__footer__title">
                {t(`TEAM_CHALLENGE_RULES_FOOTER_TITLE`)}
              </div>
              <p className="team-challenge-rules__footer__content">
                {t('TEAM_CHALLENGE_RULES_FOOTER_CONTENT')}
              </p>
            </div>
          </div>
          {/* Rules and regulations end */}
        </ContentWrapper>
      </div>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    const isWelcome = getIsWelcome(state);
    return {
      isWelcome: isWelcome,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onFetchCampaignStarted: () => dispatch(fetchCampaignStarted()),
    };
  }
}

export default connect(
  RulesScreen.mapStateToProps,
  RulesScreen.mapDispatchToProps,
)(RulesScreen);
