import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { History } from 'history';

import reducerRegistry from '../registries/ReducerRegistry';

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    ...reducerRegistry.getReducers(),
  });
