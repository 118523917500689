import {
  AUTO_COMPLETE_ADDRESS_CHANGED,
  UPDATE_ADDRESS_FORM_STATES,
  UPDATE_ADDRESS_GOGREEN_LIST,
  FETCH_ADDRESS_OTHER_POLICY_LIST_SUCCESS,
  FETCH_ADDRESS_OTHER_POLICY_LIST_FAIL,
  UPDATE_SELECTED_LIST,
  UPDATE_ADDRESS_SAVE_FIELD,
  SUBMIT_CHANGE_ADDRESS_FORM,
  SUBMIT_CHANGE_ADDRESS_FORM_SUCCESS,
  SUBMIT_CHANGE_ADDRESS_FORM_FAILURE,
  AUTO_COMPLETE_MAP_ADDRESS_CHANGED,
  AUTO_COMPLETE_MAP_ADDRESS_CHANGED_CONFIRM,
  SET_UP_SHOW_ADDRESS_POPUP,
  SET_UP_CONFIRM_LOADING_BUTTON,
  SET_UP_IS_HK_ADDRESS_CASE,
  UPDATELOAD_GOOGLE_MAP_JS_SCRIPT_STATUS,
  UPDATE_EDIT_PAGE_LOADING,
  UPDATE_IS_GOOGLE_MAP_JS_SCRIPT_ERROR,
  SET_ADDRESS_FORM,
  CLEAR_ADDRESS_FORM,
  AUTO_COMPLETE_MAP_ADDRESS_RESET,
  SET_IS_CHOSEN_NON_HK_ADDRESS,
  SET_IS_CLEAR_SEARCH_VALUE,
  SET_IS_AUTO_FILLED,
} from '../actions/changeAddressAction';
import { Place, PlaceType } from '../types/changeAddressTypes';

const defaultState = {
  hkAddressInput: {
    flat: '',
    block: '',
    floor: '',
    streetBuildingEstate1: '',
    streetBuildingEstate2: '',
    district: '',
  },
  MapAddressInput: {
    streetBuildingEstate1: '',
    streetBuildingEstate2: '',
    district: '',
    region: '',
  },
  goGreenList: [],
  isLoading: false,
  otherPolicyList: [],
  selectedList: [],
  address: {
    addrLine1: '',
    addrLine2: '',
    addrLine3: '',
    addrLine4: '',
    districtAddress: '',
    country: '',
  },
  form: {
    notify: null,
    useOfData: null,
    providedValidData: null,
  },
  transactionId: '',
  isShowAddressPopup: false,
  isConfirmLoadingButton: false,
  isHappyHKCase: false,
  isGoogleMapJsScriptComplete: false,
  isLoadingEditPage: false,
  isGoogleMapJsScriptError: false,
  isChosenNonHKAddress: false,
  isClearSearchValue: false,
  isAutoFilled: false,
};

export type MapAddressInputType = typeof defaultState.MapAddressInput;

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case AUTO_COMPLETE_ADDRESS_CHANGED: {
      const { placeData } = action.payload;
      // nextState.hkAddressInput.streetBuildingEstate1 =
      //   placeData.streetBuildingEstate1;
      // nextState.hkAddressInput.streetBuildingEstate2 =
      //   placeData.streetBuildingEstate2;
      // nextState.hkAddressInput.district = placeData.district;
      return {
        ...nextState,
        hkAddressInput: {
          flat: nextState.hkAddressInput.flat,
          floor: nextState.hkAddressInput.floor,
          block: nextState.hkAddressInput.block,
          streetBuildingEstate1: placeData.streetBuildingEstate1,
          streetBuildingEstate2: placeData.streetBuildingEstate2,
          district: placeData.district,
        },
      };
    }
    case AUTO_COMPLETE_MAP_ADDRESS_CHANGED:
      return {
        ...nextState,
        MapAddressInput: { ...action.payload.placeData },
      };
    case AUTO_COMPLETE_MAP_ADDRESS_CHANGED_CONFIRM:
      // nextState.hkAddressInput.streetBuildingEstate1 =
      //   nextState.MapAddressInput.streetBuildingEstate1;
      // nextState.hkAddressInput.streetBuildingEstate2 =
      //   nextState.MapAddressInput.streetBuildingEstate2;
      // nextState.hkAddressInput.district = nextState.MapAddressInput.district;
      return {
        ...nextState,
        hkAddressInput: {
          flat: nextState.hkAddressInput.flat,
          floor: nextState.hkAddressInput.floor,
          block: nextState.hkAddressInput.block,
          streetBuildingEstate1:
            nextState.MapAddressInput.streetBuildingEstate1,
          streetBuildingEstate2:
            nextState.MapAddressInput.streetBuildingEstate2,
          district: nextState.MapAddressInput.district,
        },
      };
    case AUTO_COMPLETE_MAP_ADDRESS_RESET:
      return {
        ...nextState,
        MapAddressInput: { ...defaultState.MapAddressInput },
      };
    case UPDATE_ADDRESS_FORM_STATES:
      nextState.form = { ...nextState.form, ...action.payload };
      return nextState;
    case UPDATE_ADDRESS_GOGREEN_LIST:
      nextState.goGreenList = action.payload;
      return nextState;
    case FETCH_ADDRESS_OTHER_POLICY_LIST_SUCCESS:
      nextState.otherPolicyList = action.otherPolicyList;
      nextState.isLoading = false;
      return nextState;
    case FETCH_ADDRESS_OTHER_POLICY_LIST_FAIL:
      nextState.isLoading = false;
      return nextState;
    case UPDATE_SELECTED_LIST:
      return {
        ...nextState,
        selectedList: action.selectedList,
      };
    case UPDATE_ADDRESS_SAVE_FIELD:
      return {
        ...nextState,
        address: { ...action.payload },
      };
    case SUBMIT_CHANGE_ADDRESS_FORM:
      return {
        ...nextState,
        isLoading: true,
      };
    case SUBMIT_CHANGE_ADDRESS_FORM_SUCCESS:
      return {
        ...nextState,
        isLoading: false,
        transactionId: action.transactionId,
        transactions: action.transactions,
      };
    case SUBMIT_CHANGE_ADDRESS_FORM_FAILURE:
      return {
        ...nextState,
        isLoading: false,
      };
    case SET_UP_SHOW_ADDRESS_POPUP:
      return {
        ...nextState,
        isShowAddressPopup: action.payload.isShowAddressPopup,
      };
    case SET_UP_CONFIRM_LOADING_BUTTON:
      return {
        ...nextState,
        isConfirmLoadingButton: action.payload.isConfirmLoadingButton,
      };
    case SET_UP_IS_HK_ADDRESS_CASE:
      return {
        ...nextState,
        isHappyHKCase: action.payload.isHappyHKCase,
      };
    case UPDATELOAD_GOOGLE_MAP_JS_SCRIPT_STATUS:
      return {
        ...nextState,
        isGoogleMapJsScriptComplete: action.payload.isGoogleMapJsScriptComplete,
      };
    case UPDATE_EDIT_PAGE_LOADING:
      return {
        ...nextState,
        isLoadingEditPage: action.payload.isLoadingEditPage,
      };
    case SET_ADDRESS_FORM:
      if (action.payload) {
        Object.keys(action.payload).map((key) => {
          nextState.hkAddressInput[key] = action.payload[key];
        });
      }
      return nextState;
    case CLEAR_ADDRESS_FORM:
      nextState.hkAddressInput = { ...defaultState.hkAddressInput };
      return nextState;
    case UPDATE_IS_GOOGLE_MAP_JS_SCRIPT_ERROR:
      nextState.isGoogleMapJsScriptError = action.isError;
      return nextState;
    case SET_IS_CHOSEN_NON_HK_ADDRESS:
      nextState.isChosenNonHKAddress = action.payload === true;
      return nextState;
    case SET_IS_CLEAR_SEARCH_VALUE:
      nextState.isClearSearchValue = action.payload === true;
      return nextState;
    case SET_IS_AUTO_FILLED:
      nextState.isAutoFilled = action.payload === true;
      return nextState;
    default:
      return nextState;
  }
};

export function getPlaceName(placeData: Place, targetType: PlaceType) {
  const firstObj = placeData.address_components.find((v) =>
    v.types.includes(targetType),
  );
  return firstObj ? firstObj.long_name || firstObj.short_name : '';
}

export function getPlaceNameByMultipleTypes(
  placeData: Place,
  targetType: PlaceType[],
) {
  const firstObj = placeData.address_components.find((v) => {
    let result = true;
    for (const type of targetType) {
      result = result && v.types.includes(type);
      if (!result) {
        break;
      }
    }
    return result;
  });
  return firstObj ? firstObj.long_name || firstObj.short_name : '';
}
