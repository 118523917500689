import _ from 'lodash';
import config from '../configs/AppConfigs';
import { ExperimentType, Variant } from '../configs/types';
/* Mock
    import ExperimentsConfig from '../../../mock/google-optimize-ab-test.web.json'
*/

export const getExperimentsConfigFromModuleConfig = (moduleConfig: any) => {
  return _.get(moduleConfig, `${config.moduleName}`);
};

/** Check this flag to indicate if experiments config is fetched or not */
export const isExperimentsConfigReady = (state: any): boolean => {
  return (
    _.get(state, `${config.moduleName}/googleOptimizeABTest`, undefined) !==
    undefined
  );
};

export const getExperiments = (state: any): any => {
  return _.get(state, `${config.moduleName}/googleOptimizeABTest.experiments`);
};

export const getGTMEnvironmentConfig = (state: any): any => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.${config.moduleName}.GTMEnvironmentConfig`,
  );
};

export const getUserGroupsApi = (state: any): string => {
  return _.get(
    state,
    `hk-emma-web-core/app.moduleConfig.${config.moduleName}.userGroupApi`,
    '',
  );
};

export const getCurrentExperiments = (state: any): ExperimentType[] => {
  const experiments: ExperimentType[] = getExperiments(state)
    ? Object.values(getExperiments(state))
    : [];
  const google_optimize: any = (window as any).google_optimize;

  // Check if the experiments in config is running, experiments can be run simultaneously
  return experiments.filter(
    (exp: any) => google_optimize && google_optimize.get(exp.experimentID),
  );
};

export const getTargetExperiment = (state: any, componentName: string) => {
  const currentExperiments: ExperimentType[] = getCurrentExperiments(state);

  // Match experiment with component name
  return currentExperiments.find(
    (exp) =>
      exp.components.find((component) => component.name === componentName) !==
      undefined,
  );
};

export const getCurrentExperimentVariant = (
  state: any,
  componentName: string,
): Variant | undefined => {
  const google_optimize: any = (window as any).google_optimize;
  const targetExperiment = getTargetExperiment(state, componentName);

  const componentConfig =
    targetExperiment &&
    targetExperiment.components.find(
      (component) => component.name === componentName,
    );
  const variants = componentConfig ? componentConfig.variants : [];
  const currentVariantID = targetExperiment
    ? google_optimize.get(targetExperiment.experimentID)
    : '0';

  return variants.find((v) => v.id === currentVariantID);
};
