import React, { Component } from 'react';
import landingPage from '../../assets/images/maintenance/landing-page@2x.png';
import { getLanguage } from '../../selectors/userSelectors';
import './style.scss';

interface MaintenanceCommonScreenProps {
  content: any;
  onSignOut: () => void;
}

class MaintenanceCommonScreen extends Component<MaintenanceCommonScreenProps> {
  onGotoDownLoad = (url: string) => {
    const lang = getLanguage();
    url = url.replace('${language}', lang);
    window.open(url);
  };

  render() {
    const {
      content: {
        title,
        desc,
        downloadButton: { text, url },
        logoutText,
      },
    } = this.props;
    return (
      <div className="emma-maintenance-common-screen-content">
        <div className="emma-maintenance-common-screen content-level-wrap">
          <div className="emma-maintenance-common-screen-left">
            <div className="emma-maintenance-common-screen-left-image">
              <img src={landingPage}></img>
            </div>
          </div>
          <div className="emma-maintenance-common-screen-right">
            <div className="emma-maintenance-common-screen-right-title">
              {title}
            </div>
            <div className="emma-maintenance-common-screen-right-desc">
              {desc}
            </div>
            <div className="emma-maintenance-common-screen-right-button">
              <div className="emma-maintenance-common-screen-right-button-download content-align">
                <button
                  className="emma-stylesheet-primary-default-button"
                  onClick={() => this.onGotoDownLoad(url)}
                >
                  {text}
                </button>
              </div>
              <div className="emma-maintenance-common-screen-right-button-logout content-align">
                <button
                  className="emma-stylesheet-primary-ghost-button"
                  onClick={this.props.onSignOut}
                >
                  {logoutText}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MaintenanceCommonScreen;
