import { EmmaFactory } from '@axa-asia/hk-emma-js';
import { injectAsyncReducer, put, runSagas, select } from '../stores';
import { isApp, getValidToken, getPlatformStr } from '../utils';
import { getExperimentInStr } from '../selectors/abTestSelector';
import { setupExperimentIdsToDataLayer } from '../utils/generalGaEventAnalytics';

export const FETCH_FIREBASE_EXPERIMENTS_SUCCESS = `FETCH_FIREBASE_EXPERIMENTS_SUCCESS`;
export const FETCH_OPTIMIZE_EXPERIMENTS_SUCCESS = `FETCH_OPTIMIZE_EXPERIMENTS_SUCCESS`;

export const initExperiment = async () => {
  const getFirebaseExperiments = () => {
    return new Promise((resolve, reject) => {
      if (isApp()) {
        EmmaFactory.createInstance()
          .then((e) => {
            e.instance
              .getFirebaseRemoteConfig()
              .then((remoteConfig) => {
                resolve(remoteConfig);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve({});
      }
    });
  };

  const getOptimizeExperiments = () => {
    return new Promise((resolve, reject) => {
      // TODO - Allen
      resolve({});
    });
  };

  const [firebaseExperiments, optimizeExperiments] = await Promise.all([
    getFirebaseExperiments(),
    getOptimizeExperiments(),
  ]);

  put({
    type: FETCH_FIREBASE_EXPERIMENTS_SUCCESS,
    payload: firebaseExperiments,
  });
  put({
    type: FETCH_OPTIMIZE_EXPERIMENTS_SUCCESS,
    payload: optimizeExperiments,
  });

  const experimentIdsStr = select(getExperimentInStr);
  setupExperimentIdsToDataLayer(experimentIdsStr);
};
