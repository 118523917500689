import { PostMsgParams } from '../../partners/types/partnerTypes';

import config from '../package.json';
const ID = `${config.name}/actions/ehubActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const SHOW_AXA_GOAL_DASHBOARD = 'SHOW_AXA_GOAL_DASHBOARD';

export const OPEN_DISCLAIMER = `OPEN_DISCLAIMER`;

export const CTA_BUTTON_TRIGGER = `CTA_BUTTON_TRIGGER`;

export const DOWNLOAD_DOCUMENT = createActionName('DOWNLOAD_DOCUMENT');

export const openDisclaimer = (
  disclaimerId: string,
  params?: PostMsgParams,
  isGoBack = false,
  okActionCallBack?: () => void,
) => {
  return {
    type: OPEN_DISCLAIMER,
    payload: {
      disclaimerId: disclaimerId,
      params: params,
      isGoBack: isGoBack,
      okActionCallBack,
    },
  };
};

export const downloadDocument = (url: string) => ({
  type: DOWNLOAD_DOCUMENT,
  url,
});
