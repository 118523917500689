/**
 * Mask policy number on the given value by 'X'
 * Life - [\d]{3}-[\d]{7}
 * EB - [\d]{8}-[A-Z]{2}
 * FC - [A-Z]{2}[\d]{6}
 * GI - [A-Z\d]{1}[\d]{7}
 *
 * For example
 * Input: 999-9999999
 * Output: XXXXXXXXXXX
 */
export const maskPN = (targetVal: string) => {
  const regex =
    /([\d]{3}-[\d]{7})|([\d]{8}[A-Z]{2})|([A-Z]{2}[\d]{6})|([A-Z\d]{1}[\d]{7})/;
  const maskedVal = targetVal.replace(regex, (v) => "X".repeat(v.length));
  return maskedVal;
};
