import config from '../../../modules/myPrivileges/package.json';

import { put } from '../../../stores';
const ID = `${config.name}/actions/myPrivilegesActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const COMPONENT_MOUNTED = createActionName('COMPONENT_MOUNTED');
export const CONTENT_LOADED = createActionName('CONTENT_LOADED');
export const EHUB_ID_RETRIEVED = createActionName('EHUB_ID_RETRIEVED');
export const GO_TO = createActionName('GO_TO');
export const GO_TO_DETAILS_VIEW = createActionName('GO_TO_DETAILS_VIEW');
export const OPEN_EHUB_DISCLAIMER = createActionName('OPEN_EHUB_DISCLAIMER');

export const FETCH_EHUB_AUTH_TOKEN = createActionName('FETCH_EHUB_AUTH_TOKEN');
export const CTA_BUTTON_ONCLICK = createActionName('CTA_BUTTON_ONCLICK');
export const CTA_BUTTON_POST_TRIGGER = createActionName(
  'CTA_BUTTON_POST_TRIGGER',
);
export const FETCH_MY_PRIVILEGES_FEATURES = createActionName(
  'FETCH_MY_PRIVILEGES_FEATURES',
);
export const FETCH_MY_PRIVILEGES_FEATURES_SUCCESS = createActionName(
  'FETCH_MY_PRIVILEGES_FEATURES_SUCCESS',
);
export const FETCH_MY_PRIVILEGES_FEATURES_FAILURE = createActionName(
  'FETCH_MY_PRIVILEGES_FEATURES_FAILURE',
);
export const SET_CTN_BUTTON_LOADING = createActionName(
  'SET_CTN_BUTTON_LOADING',
);
export const SET_CTN_BUTTON_NOT_LOADING = createActionName(
  'SET_CTN_BUTTON_NOT_LOADING',
);
export const SET_PAGE_LOADING = createActionName('SET_PAGE_LOADING');
export const SET_PAGE_NOT_LOADING = createActionName('SET_PAGE_NOT_LOADING');
export const MYPRIVILEGES_MODAL_DISPLAY = 'MYPRIVILEGES_MODAL_DISPLAY';
export const MY_PRIVILEGES_SWITCH_CURRENT_TAB = createActionName(
  'MY_PRIVILEGES_SWITCH_CURRENT_TAB',
);

export const FETCH_DETAILS_PAGE_INFO = createActionName(
  'FETCH_DETAILS_PAGE_INFO',
);

export const UPDATE_DETAILS_PAGE_INFO = createActionName(
  'UPDATE_DETAILS_PAGE_INFO',
);

export const fetchMyPrivilegesAuthToken = (
  url: string,
  fetchMyPrivilegesAuthTokenSuccess: (payload: any) => void,
  fetchMyPrivilegesAuthTokenFailure: (error: any) => void,
) => ({
  type: FETCH_EHUB_AUTH_TOKEN,
  payload: {
    url,
    fetchMyPrivilegesAuthTokenSuccess,
    fetchMyPrivilegesAuthTokenFailure,
  },
});

export const goTo = (cardViewId: string, url: string) => ({
  type: GO_TO,
  payload: {
    url,
    cardViewId,
  },
});

export const openMyPrivilegesDisclaimer = (
  disclaimerId: string,
  cardViewId: string,
) => ({
  type: OPEN_EHUB_DISCLAIMER,
  payload: {
    disclaimerId,
    cardViewId,
  },
});

export const triggerCTAButton = (cardViewId: string, disclaimerId = '') => ({
  type: CTA_BUTTON_ONCLICK,
  payload: {
    disclaimerId,
    cardViewId,
  },
});

export const toggleModelDisplay = (isShowVhisModal = false, cardId = '') => {
  return {
    type: MYPRIVILEGES_MODAL_DISPLAY,
    isShowVhisModal,
    cardId,
  };
};

export const myPrivilegesFeatures = () => {
  put({
    type: FETCH_MY_PRIVILEGES_FEATURES,
  });
};

export const setCtnButtonLoading = () => ({
  type: SET_CTN_BUTTON_LOADING,
});

export const setCtnButtonNotLoading = () => ({
  type: SET_CTN_BUTTON_NOT_LOADING,
});

export const setPageLoading = () => ({
  type: SET_PAGE_LOADING,
});
export const setPageNotLoading = () => ({
  type: SET_PAGE_NOT_LOADING,
});

export const switchCurrentTab = (currentTab: string) => ({
  type: MY_PRIVILEGES_SWITCH_CURRENT_TAB,
  currentTab,
});

export const fetchDetailsPageInfo = cardViewId => ({
  type: FETCH_DETAILS_PAGE_INFO,
  payload: { cardViewId },
});
