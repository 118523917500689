import React, { Component } from 'react';
import { connect } from 'react-redux';
import { t } from '../../../locales';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@axa-asia/emma-stylesheet/lib/components/Modal';
import { resolvePublicAssets, isExternalAssets } from '../../../utils';
import './styles.scss';
import HomePagePopUpRegistry from '../../../registries/HomePagePopUpRegistry';
import { hideModal } from '../../../modules/emma-modal/actions';
import {
  trackNavigationOverlayClicked,
  trackNavigationOverlayDisplay,
} from '../../../utils/generalGaEventAnalytics';
import { goto } from '../../../actions/historyActions';

// for bullet point reason, it required to use any in default
interface FullscreenModalViewInterface {
  journeyName: any;
  titleBoldText: any;
  subtitle: any;
  numberOfBullets: any;
  localImagePath: any;
  topRightButtonText: any;
  blueButtonText: any;
  whiteButtonText: any;
  gaEnabled: any;
  gaScreenName: any;
  topRightButtonGaText: any;
  blueButtonGaText: any;
  whiteButtonGaText: any;
  topRightButtonDeeplink: any;
  blueButtonDeeplink: any;
  whiteButtonDeeplink: any;
  topRightButtonWebAction: any;
  blueButtonWebAction: any;
  whiteButtonWebAction: any;
  topRightButtonIsCallJourneyEnd: any;
  blueButtonIsCallJourneyEnd: any;
  whiteButtonIsCallJourneyEnd: any;
  isGrayAreaClose: any;
  isGrayAreaCallJourneyEnd: any;
  topRightButtonCloseModal: any;
  blueButtonCloseModal: any;
  whiteButtonCloseModal: any;
  imageWidth: any;
  imageHeight: any;
  titleAlignment: any;
  titleMarginTop: any;
  subtitleAlignment: any;
  subtitleMarginTop: any;
  whiteButtonBorderColor: any;
}

interface FullscreenModalViewProps {
  buttonAction: (action: any, props: any) => void;
  payload: any;
  hideDialog: () => void;
}

interface FullscreenModalViewState {}

class FullscreenModalView extends Component<
  FullscreenModalViewProps,
  FullscreenModalViewState
> {
  constructor(props: FullscreenModalViewProps) {
    super(props);
    this.state = {};
    this.closeModal = this.closeModal.bind(this);
    this.topRightButtonClicked = this.topRightButtonClicked.bind(this);
    this.blueButtonClicked = this.blueButtonClicked.bind(this);
    this.whiteButtonClicked = this.whiteButtonClicked.bind(this);
    this.grayAreaClicked = this.grayAreaClicked.bind(this);
  }

  componentDidMount() {
    const { journeyName } = this.props.payload;
    trackNavigationOverlayDisplay(journeyName);
  }

  componentWillReceiveProps(nextProps: any) {
    const { journeyName } = this.props.payload;
    if (
      nextProps &&
      nextProps.journeyName !== journeyName &&
      nextProps.journeyName
    ) {
      trackNavigationOverlayDisplay(nextProps.journeyName);
    }
  }

  closeModal() {
    this.props.hideDialog();
  }

  topRightButtonClicked() {
    const {
      topRightButtonText,
      topRightButtonDeeplink,
      topRightButtonWebAction,
      topRightButtonIsCallJourneyEnd,
      journeyName,
      topRightButtonCloseModal,
    } = this.props.payload;

    trackNavigationOverlayClicked(topRightButtonText, journeyName);

    if (topRightButtonCloseModal) {
      this.closeModal();
    }
    if (topRightButtonDeeplink) {
      goto(topRightButtonDeeplink);
    }
    if (topRightButtonWebAction) {
      this.props.buttonAction(topRightButtonWebAction, this.props);
    }
    if (topRightButtonIsCallJourneyEnd === true && journeyName) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(journeyName);
    }
  }

  blueButtonClicked() {
    const {
      blueButtonText,
      blueButtonDeeplink,
      blueButtonWebAction,
      blueButtonIsCallJourneyEnd,
      journeyName,
      blueButtonCloseModal,
    } = this.props.payload;

    trackNavigationOverlayClicked(blueButtonText, journeyName);

    if (blueButtonCloseModal) {
      this.closeModal();
    }
    if (blueButtonDeeplink) {
      goto(blueButtonDeeplink);
    }
    if (blueButtonWebAction) {
      this.props.buttonAction(blueButtonWebAction, this.props);
    }
    if (blueButtonIsCallJourneyEnd === true && journeyName) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(journeyName);
    }
  }

  whiteButtonClicked() {
    const {
      whiteButtonText,
      whiteButtonDeeplink,
      whiteButtonWebAction,
      whiteButtonIsCallJourneyEnd,
      journeyName,
      whiteButtonCloseModal,
    } = this.props.payload;

    trackNavigationOverlayClicked(whiteButtonText, journeyName);

    if (whiteButtonCloseModal) {
      this.closeModal();
    }
    if (whiteButtonDeeplink) {
      goto(whiteButtonDeeplink);
    }
    if (whiteButtonWebAction) {
      this.props.buttonAction(whiteButtonWebAction, this.props);
    }
    if (whiteButtonIsCallJourneyEnd === true && journeyName) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(journeyName);
    }
  }

  grayAreaClicked() {
    const { isGrayAreaClose, isGrayAreaCallJourneyEnd, journeyName } =
      this.props.payload;
    if (isGrayAreaClose === true) {
      this.closeModal();
    }
    if (isGrayAreaCallJourneyEnd === true && journeyName) {
      HomePagePopUpRegistry.setIsShowPopupCompleted(journeyName);
    }
  }

  renderBullets() {
    const { numberOfBullets } = this.props.payload;
    const rows = [];
    for (let index = 1; index <= numberOfBullets; index += 1) {
      const value = this.props.payload[`bullet${index}`];
      const splits = value.split(' ');
      const left = splits[0];
      const right = splits.slice(1).join(' ');
      rows.push(
        <li className="emma-modal-body-main-content-fullscreen-li">{right}</li>,
      );
    }
    return (
      <ul className="emma-modal-body-main-content-fullscreen-ul">{rows}</ul>
    );
  }

  render() {
    const type = 'emma';
    const { payload } = this.props;
    const {
      titleBoldText,
      localImagePath,
      subtitle,
      numberOfBullets,
      topRightButtonText,
      blueButtonText,
      whiteButtonText,
      imageWidth,
      imageHeight,
    } = payload;

    return (
      <Modal
        show={true}
        onHide={this.closeModal}
        onClick={this.grayAreaClicked}
        className={'emma-stylesheet-modal-container-fullscreen'}
      >
        <ModalBody
          className={'emma-stylesheet-modal-body-fullscreen'}
          onClick={(event: any) => {
            event.stopPropagation();
          }}
        >
          <div className="emma-modal-body-main-content-fullscreen">
            {topRightButtonText ? (
              <div
                className="emma-modal-body-main-content-fullscreen-top-right-button"
                onClick={this.topRightButtonClicked}
                data-enable-ga
                data-ga-type="button"
              >
                {topRightButtonText}
              </div>
            ) : null}
            {localImagePath ? (
              <img
                alt=""
                className="emma-modal-body-main-content-fullscreen-img"
                src={resolvePublicAssets(localImagePath).replace(
                  '.png',
                  '@3x.png',
                )}
                style={
                  imageWidth && imageHeight
                    ? {
                        width: imageWidth,
                        height: imageHeight,
                      }
                    : {}
                }
              />
            ) : null}
            {titleBoldText ? (
              <div
                style={{
                  ...(payload.titleAlignment
                    ? { textAlign: payload.titleAlignment }
                    : {}),
                  ...(payload.titleMarginTop
                    ? { marginTop: payload.titleMarginTop }
                    : {}),
                }}
                className="emma-modal-body-main-content-fullscreen-title"
              >
                {titleBoldText}
              </div>
            ) : null}
            {subtitle ? (
              <div
                style={{
                  ...(payload.subtitleAlignment
                    ? { textAlign: payload.subtitleAlignment }
                    : {}),
                  ...(payload.subtitleMarginTop
                    ? { marginTop: payload.subtitleMarginTop }
                    : {}),
                }}
                className="emma-modal-body-main-content-fullscreen-subtitle"
              >
                {subtitle}
              </div>
            ) : null}
            {numberOfBullets && numberOfBullets > 0
              ? this.renderBullets()
              : null}
            {blueButtonText ? (
              <button
                style={
                  payload.blueButtonMarginTop
                    ? { marginTop: payload.blueButtonMarginTop }
                    : {}
                }
                className="emma-stylesheet-primary-default-button emma-stylesheet-primary-default-button-fullscreen"
                onClick={this.blueButtonClicked}
                data-enable-ga
                data-ga-type="button"
              >
                {blueButtonText}
              </button>
            ) : null}
            {whiteButtonText ? (
              <button
                className="emma-stylesheet-primary-ghost-button emma-stylesheet-primary-ghost-button-fullscreen"
                onClick={this.whiteButtonClicked}
                data-enable-ga
                data-ga-type="button"
                style={{
                  ...(payload.whiteButtonBorderColor
                    ? { borderColor: payload.whiteButtonBorderColor }
                    : {}),
                }}
              >
                {whiteButtonText}
              </button>
            ) : null}
          </div>
        </ModalBody>
      </Modal>
    );
  }

  static mapStateToProps(state: any) {
    return {};
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      hideDialog: () => {
        dispatch(hideModal());
      },
      buttonAction: (action: any, props: FullscreenModalViewProps) => {
        dispatch({
          type: action,
          payload: { ...props, ...props.payload },
        });
      },
    };
  }
}

export default connect(
  FullscreenModalView.mapStateToProps,
  FullscreenModalView.mapDispatchToProps,
)(FullscreenModalView);
