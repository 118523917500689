import config from '../package.json';
const ID = `${config.name}/actions/go-green-survey`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const SUBMIT_FORM = createActionName('SUBMIT_FORM');
export const UPDATE_SUBMIT_FORM_LOADING = createActionName(
  'UPDATE_SUBMIT_FORM_LOADING',
);
export const UPDATE_ERROR_DIALOG_DISPLAY = createActionName(
  'UPDATE_ERROR_DIALOG_DISPLAY',
);

export const submitForm = (
  payload: any,
  callback?: (isSuccess: boolean) => void,
) => ({
  type: SUBMIT_FORM,
  payload,
  callback,
});

export const updateErrorDialogDisplay = (isShowErrorDialog: boolean) => ({
  type: UPDATE_ERROR_DIALOG_DISPLAY,
  isShowErrorDialog,
});

export const updateSubmitFromLoading = (isSubmitLoading: boolean) => ({
  type: UPDATE_SUBMIT_FORM_LOADING,
  isSubmitLoading,
});
