/**
 * IMPORTANT - You should use this function ONLY when you have no way to check dependency readiness.
 * Purpose - This is a simple retry function to auto retry the function call in sync.
 * Use case - when you need to call a function but its dependency is not ready.
 * No return is supported.
 * @param {*} fn
 * @param {*} maxTimes
 * @param {*} intervalMs
 * @param  {...any} args
 */
export function retryFnCall(
  fn: any,
  maxTimes: number,
  intervalMs: number,
  ...args: any[]
): void {
  try {
    fn(...args);
  } catch (error) {
    console.warn(
      `Function call is failed. Retry remaining times: ${--maxTimes}: `,
      error,
    );
    if (--maxTimes === 0) {
      return;
    }
    setTimeout(() => {
      retryFnCall(fn, --maxTimes, intervalMs, args);
    }, intervalMs);
  }
}
