import {
  FETCH_CUSTOMER_PROFILE_FEATURES_FAILURE,
  FETCH_CUSTOMER_PROFILE_FEATURES_SUCCESS,
  CHANGE_CUSTOMER_PROFILE_LOADING,
} from '../actions/customerProfileAction';

const defaultState = {
  isLoading: false,
  userData: {},
  lastUpdateDate: '',
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case CHANGE_CUSTOMER_PROFILE_LOADING:
      nextState.isLoading = action.isLoading;
      return nextState;
    case FETCH_CUSTOMER_PROFILE_FEATURES_SUCCESS:
      nextState.customerProfileFeatures = action.featuresItems;
      nextState.userData = action.userData;
      nextState.lastUpdateDate = action.lastUpdateDate;
      return nextState;
    case FETCH_CUSTOMER_PROFILE_FEATURES_FAILURE:
      nextState.customerProfileFeaturesError = action.error;
      return nextState;
    default:
      return nextState;
  }
};
