import { combineReducers } from 'redux';
import ehubReducer from './ehubReducer';
import myPrivilegesReducer from './myPrivilegesReducer';
import checkCoverageReducer from './checkCoverageReducer';

export default combineReducers({
  ehub: ehubReducer,
  myPrivileges: myPrivilegesReducer,
  womanProduct: checkCoverageReducer,
});
