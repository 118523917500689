import { SEND_OTP_LOADING } from '../actions/customerProfileVerifyAction';

const defaultState = {
  isSubmitLoading: false,
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case SEND_OTP_LOADING:
      nextState.isSubmitLoading = action.isSubmitLoading || false;
      return nextState;
    default:
      return nextState;
  }
};
