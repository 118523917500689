import React, { Component, ComponentClass } from 'react';
import './styles.css';
import routeRegistry from '../../registries/RouteRegistry';

import {
  getToken,
  getRefreshToken,
  getPostLoginRedirectUri,
} from '../../selectors/authSelectors';
import { connect } from 'react-redux';
import browserHistory from '../../stores/browserHistory';
import { setPostRedirectUri } from '../../actions/authActions';
import { popTitle, goToView } from '../../actions/historyActions';

import {
  __DEV__,
  extractRelativeUrl,
  sameRoute,
  resolvePublicAssets,
} from '../../utils';
import {
  getPreviousTitle,
  getHideBackButton,
} from '../../selectors/historySelector';
import { t } from '../../locales';

import classnames from 'classnames';
import { EmmaTestId } from '@axa-asia/auto-test-client';

interface BackNavContainerProps {
  previousTitle: string | undefined;
  onPopTitle: () => void;
  isHideBackButton: boolean;
}

interface BackNavContainerState {
  routeRoot?: string;
}

class BackNavContainer extends Component<
  BackNavContainerProps,
  BackNavContainerState
> {
  pageTop: any;
  componentDidMount() {
    window.addEventListener(`popstate`, (e) => {
      const { onPopTitle } = this.props;
      onPopTitle();
    });

    browserHistory.listen(() => {
      this.forceUpdate();
    });
  }

  scrollToTop = () => {
    if (this.pageTop) {
      this.pageTop.scrollIntoView({ behavior: 'auto' });
    }
  };
  componentDidUpdate() {
    this.scrollToTop();
  }

  getRouteRoot() {
    const routeMap = routeRegistry.getScreens();
    const routes = Object.keys(routeMap);

    const relativeUrl = extractRelativeUrl();
    const routeRoot = routes
      .filter((r) => !!routeMap[r].sidebarItem)
      .find((r) => relativeUrl.startsWith(r));

    return routeRoot;
  }

  getBackTitle() {
    const { previousTitle } = this.props;

    let target;

    if (previousTitle) {
      target = previousTitle;
    }
    // else{

    //   const routeRoot = this.getRouteRoot();
    //   const relativeUrl = extractRelativeUrl();

    //   if(routeRoot && !sameRoute(routeRoot, relativeUrl)){
    //     const routeMap = routeRegistry.getScreens();
    //     const sidebarItem = routeMap[routeRoot].sidebarItem as SideBarItemProps;
    //     target = sidebarItem.label
    //   }
    // }

    return `${t('BACK')}`;
  }

  sameRoute() {
    const routeRoot = this.getRouteRoot();
    const relativeUrl = extractRelativeUrl();
    const sameRoot = sameRoute(routeRoot, relativeUrl);

    return (
      routeRoot &&
      (sameRoot ||
        relativeUrl.includes('customerprofile/changeemail/') ||
        relativeUrl.includes('customerprofile/changemobile/') ||
        relativeUrl.includes('customerprofile/contactdetails/') ||
        relativeUrl.includes('customerprofile/changecontact') ||
        relativeUrl.includes('customerprofile/reset/usename') ||
        relativeUrl.includes('customerprofile/reset/password') ||
        relativeUrl.includes('customerprofile/reset/verify') ||
        relativeUrl.includes('customerprofile/reset/usename/confirm') ||
        relativeUrl.includes('customerprofile/reset/password/confirm') ||
        relativeUrl.includes('home/life/axagoal/dashboard') ||
        relativeUrl.includes('myprivileges/axagoal/dashboard') ||
        (relativeUrl.includes('customerprofile/changeaddress/') &&
          !relativeUrl.includes(
            'customerprofile/changeaddress/chooselocation',
          )) ||
        relativeUrl.includes('customerprofile/reset/password/confirm') ||
        relativeUrl.includes('home/life/axagoal/dashboard') ||
        relativeUrl.includes('myprivileges/axagoal/dashboard') ||
        relativeUrl.includes('home/aktivo-individual-challenge') ||
        relativeUrl.includes('home/aktivo-my-wellness/home'))
    );
  }

  goBack() {
    const { previousTitle } = this.props;

    if (window.location.href.includes('/payment-result')) {
      // Redirect to homepage instead of payment page from payment result page
      browserHistory.push('/home');
    } else if (
      window.location.href.includes(
        '/emma/marketplace/myprivileges/offers/form/success',
      )
    ) {
      goToView('emma-my-privileges/direct');
    } else if (typeof previousTitle === `string`) {
      window.history.back();
    } else {
      const routeRoot = this.getRouteRoot();
      browserHistory.push(routeRoot || `/home`);
    }
  }

  blueBackButton() {
    const relativeUrl = extractRelativeUrl();
    return relativeUrl.includes('home/aktivo-my-wellness/my-wellness');
  }

  render() {
    const { isHideBackButton } = this.props;

    const backTitle = this.getBackTitle();
    const sameRoot = this.sameRoute();

    return (
      <div>
        <div
          ref={(el) => {
            this.pageTop = el;
          }}
        ></div>
        {isHideBackButton || sameRoot ? null : (
          <nav
            className={classnames('back-nav', {
              'blue-nav': this.blueBackButton(),
            })}
          >
            <span
              className="cursor-pointer"
              onClick={() => {
                this.goBack();
              }}
              data-enable-ga
              data-ga-type="button"
              data-testid={EmmaTestId['BUTTON_BACK']}
            >
              <img
                className={`left-arrow`}
                src={resolvePublicAssets(
                  `/assets/images/drop-down-arrow-blue.svg`,
                )}
                alt="left-arrow-icon"
              />
              {backTitle}
            </span>
          </nav>
        )}
      </div>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    return {
      token: getToken(state),
      refreshToken: getRefreshToken(state),
      postLoginRedirectUri: getPostLoginRedirectUri(state),
      previousTitle: getPreviousTitle(state) ? getPreviousTitle(state) : '',
      isHideBackButton: getHideBackButton(state),
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onSetPostRedirectUri: (uri: string) => dispatch(setPostRedirectUri(uri)),
      onPopTitle: () => dispatch(popTitle()),
    };
  }
}

export default connect(
  BackNavContainer.mapStateToProps,
  BackNavContainer.mapDispatchToProps,
)(BackNavContainer);
