import $url from 'url';
import {
  resolveComplexDeepLink,
  resolveWebDeepLink,
  joinPath,
  getBaseUrl,
  isApp,
} from '../utils';
import { getPolicyNumber } from '../utils/policyNumberUtil';
import browserHistory from '../stores/browserHistory';
import { put, select } from '../stores';
import { getToken } from '../selectors/authSelectors';
import jwtDecode from 'jwt-decode';
import { getValidToken } from '../utils/index';
import { getCurrentTab } from '../modules/myPrivileges/selectors/myPrivilegesSelector';
import { PostMsgParams } from '../modules/partners/types/partnerTypes';
import { REDIRECT_READY, REDIRECT_ACTION } from '../modules/partners/actions';
import { EmmaFactory } from '@axa-asia/hk-emma-js';
import { getLanguage } from '../selectors/userSelectors';
import {
  trackDefaultPageView,
  handleGaError,
} from '../utils/generalGaEventAnalytics';
import { isMobileRoute, MOB_PREFIX } from './appActions';
import { startBannerRouting } from '../modules/emma-common/actions/bannerRoutingActions';
import { hideChatbot } from '../modules/chatbot/actions/chatbotAction';
import env from '../env';
import { getPolicyNumberSuccess } from './policyNumberActions';

const BANNER_ROUTING = 'emma-banner-routing';
export const PUSH_TITLE = 'PUSH_TITLE';
export const POP_TITLE = 'POP_TITLE';
export const HIDE_BACK_BUTTON = 'HIDE_BACK_BUTTON';
export const SHOW_BACK_BUTTON = 'SHOW_BACK_BUTTON';

export const pushTitle = (title: string) => ({
  type: PUSH_TITLE,
  title,
});

export const popTitle = () => ({
  type: POP_TITLE,
});

export const hideBackButton = () => ({
  type: HIDE_BACK_BUTTON,
});

export const showBackButton = () => ({
  type: SHOW_BACK_BUTTON,
});

export const goto = (
  appDeepLink: string,
  currentTitle?: string,
  params?: PostMsgParams,
  isReplace?: boolean,
) => {
  //`emma-life/managefund?policy_number=502-133667&entity_code=NHK`);//
  const tcWindow: any = window;
  const token = select(getToken);
  const aToken = getValidToken(token);
  const myPrivilegesCurrentTap = select(getCurrentTab) || '';
  const complexDeepLink = resolveComplexDeepLink(appDeepLink);
  if (complexDeepLink) {
    put(complexDeepLink);
    return;
  }
  let webDeepLink = isNormalWebRouting(appDeepLink)
    ? appDeepLink === '/'
      ? 'home'
      : appDeepLink
    : resolveWebDeepLink(appDeepLink) || '';

  if (!webDeepLink && !isBannerRouting(appDeepLink)) {
    return;
  }
  if (webDeepLink === 'marketplace/myprivileges') {
    webDeepLink = `${webDeepLink}#${myPrivilegesCurrentTap.toLowerCase()}`;
  }
  if (aToken) {
    trackDefaultPageView(`/emma-webportal/${webDeepLink}`);
  }

  // Grab policy number from deeplink for removing PN from URL
  const storePolicyNumber = () => {
    const policyNumber = getPolicyNumber(appDeepLink);
    if (policyNumber) {
      put(getPolicyNumberSuccess(policyNumber));
    }
    return false;
  };

  const openInNewTab = () => {
    if (webDeepLink.startsWith(`^`)) {
      const deepLink = webDeepLink.substring(1);

      let absUrl = joinPath(getBaseUrl(), deepLink);
      if (deepLink && deepLink.startsWith('http')) {
        const language = select(getLanguage);
        absUrl = deepLink
          .replace('${language}', language)
          .replace('/undefined/', `/${language}/`);
      }
      const newTab = window.open(absUrl);
      if (newTab) {
        setupPostMsg(newTab, params);
      }
      return true;
    }
    return false;
  };

  const changeHash = () => {
    if (webDeepLink.startsWith(`#`)) {
      window.location.hash = webDeepLink;
      return true;
    }
    return false;
  };

  const openBannerSaga = () => {
    if (isBannerRouting(appDeepLink)) {
      const urlObj = $url.parse(appDeepLink, true);
      put(startBannerRouting(urlObj.query));
      return true;
    }
    return false;
  };

  const pushHistory = () => {
    const mobPrefix = isMobileRoute() ? `/${MOB_PREFIX}` : '';
    if (isReplace) {
      browserHistory.replace(`${mobPrefix}/${webDeepLink}`);
    } else {
      browserHistory.push(`${mobPrefix}/${webDeepLink}`);
    }
    put(hideChatbot());

    return true;
  };

  [
    storePolicyNumber,
    openInNewTab,
    changeHash,
    openBannerSaga,
    pushHistory,
  ].find((f) => f());
  if (currentTitle) {
    put(pushTitle(currentTitle));
  }
};

export const setupPostMsg = (
  newWindow: Window,
  postMsgParams: PostMsgParams = { action: REDIRECT_ACTION },
) => {
  const eventListener = (event: MessageEvent) => {
    const data: PostMsgParams = event.data;
    if (!data || !data.action) {
      return;
    }

    switch (data.action) {
      case REDIRECT_READY:
        // TODO: POST_MESSAGE - May redirect to anywhere (* > window.location.host)
        newWindow.postMessage(postMsgParams, env.POST_MESSAGE_ORIGIN);
        window.removeEventListener('message', eventListener);
        break;
      default:
        break;
    }
  };

  window.addEventListener('message', eventListener, false);
};

export const goToView = (
  appDeepLink: string,
  currentTitle?: string,
  params?: PostMsgParams,
) => {
  if (isApp()) {
    EmmaFactory.createInstance().then((e) => {
      e.instance.goTo(appDeepLink);
    });
  } else {
    goto(appDeepLink, currentTitle, params);
  }
};

function isBannerRouting(webDeepLink: string) {
  return webDeepLink.startsWith(BANNER_ROUTING);
}

function isNormalWebRouting(webDeepLink: string) {
  // allow / as homepage
  if (webDeepLink === '/') return true;
  const normalWebRoutes = ['referral-programme', 'programme-registration'];
  return normalWebRoutes.some((r) => webDeepLink.startsWith(r));
}

/**
 * Deprecated - Found that there is no way to defect browser forward button.
 * If go back a few page then click forward button on browser to go forward one page
 * Then there is no way to calculate whether users is going forward or not
 * Therefore, deprecate this logic first
 *
 * Use case: Clicking back button on successful page to go back to a few of previous page.
 * This function can help you to know how many page to go back.
 * Improve: combine with action, redux and app level state
 */

/*
let tempGoBackNum = 0;
export const historyGo = (num: number) => {
    tempGoBackNum = num;
    browserHistory.go(num);
}

interface HistoryIndex {
    id: string,
    index: number,
}
let historyIndexList: HistoryIndex[] = [];
const onHistoryStackPushed = () => {
    historyIndexList.forEach((historyIndex) => {
        historyIndex.index--;
    });
};
const onHistoryStackPoped = () => {
    historyIndexList.forEach((historyIndex) => {
        if (tempGoBackNum < 0) {
            historyIndex.index += -tempGoBackNum;
        } else {
            historyIndex.index++;
        }
    });
    tempGoBackNum = 0;
};
browserHistory.listen((location, action) => {
    if (action === 'POP') {
        onHistoryStackPoped();
    } else if (action === 'PUSH') {
        onHistoryStackPushed();
    }
    // console.log('historyIndexList:', historyIndexList);
});
export const CHANGE_CONTACT_ID = 'changeContact';
export const CHANGE_EMAIL_EDIT_ID = 'changeEmailEdit';
export const CHANGE_MOBILE_EDIT_ID = 'changeMobileEdit';
export const registerHistoryIndex = (id: string) => {
    const removedOldOneList = historyIndexList.filter(function(e) { return e.id !== id });
    removedOldOneList.push({
        id,
        index: 0,
    });
    historyIndexList = removedOldOneList;
}
export const getHistoryIndexById = (id: string) => {
    const historyIndex = historyIndexList.find(historyIndex => historyIndex.id === id);
    return historyIndex ? historyIndex.index : 0;
}
*/
