import uuidv4 from 'uuid/v4';
import { UserGroup } from '../configs/types';
import { getUserGroupsApi } from '../selectors/googleOptimizeABTestSelector';
import { getUserGroups } from '../selectors/userGroupSelector';
import { select, put } from '../../../stores';
import { myAxaFetchWithToken } from '../../../utils/myAxaFetchWithToken';

import config from '../configs/AppConfigs';

const ID = `${config.moduleName}/actions/configActions.ts`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_USER_GROUPS_START = createActionName(
  'FETCH_USER_GROUPS_START',
);
export const FETCH_USER_GROUPS_SUCCESS = createActionName(
  'FETCH_USER_GROUPS_SUCCESS',
);
export const FETCH_USER_GROUPS_FAILURE = createActionName(
  'FETCH_USER_GROUPS_FAILURE',
);

export function fetchUserGroupsSuccess(groups: UserGroup[]) {
  return {
    type: FETCH_USER_GROUPS_SUCCESS,
    payload: {
      groups,
    },
  };
}

export function fetchUserGroupsFailure(error: any) {
  return {
    type: FETCH_USER_GROUPS_FAILURE,
    payload: {
      error,
    },
  };
}

// Update UserGroupID to GA4 user-properties
export const updateUserGroupID = () => {
  try {
    const groups = select(getUserGroups) as UserGroup[];
    const groupIdsStr: string = groups.map((v: any) => v.id).join(',');
    const tcWindow: any = window;
    tcWindow.gtag('set', 'user_properties', { custom_group_id: groupIdsStr });
    console.log('updateUserGroupID success');
  } catch {
    console.log('updateUserGroupID failure');
    // Try again after 10s, wait for gtag set-up by GTM
    setTimeout(updateUserGroupID, 10000);
  }
};

// Fetch UserGroups data from API
export const fetchUserGroups = async () => {
  try {
    const url: string = select(getUserGroupsApi);

    // Empty URL, no need to fetch
    // This is blocking the entire flow, causing the website slow
    if (!url) {
      return;
    }

    const res = await myAxaFetchWithToken(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-axahk-msgid': uuidv4(),
      },
    });

    if (res && res.status === 200) {
      const data = await res.json();
      const groups = (data.groups as UserGroup[]) || [];
      put(fetchUserGroupsSuccess(groups));
    } else {
      throw res;
    }
  } catch (e) {
    put({ type: FETCH_USER_GROUPS_FAILURE, payload: e });
  }
};
