const getPageHeight = () => {
  const body = document.body;
  const html = document.documentElement;
  const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
  return height;
}

export const verticalHandlerV2 = (callback) => {
  let currVerticalRatio = 0;

  return (event) => {
    const ratio = Math.round(
      ((window.innerHeight + window.pageYOffset) / getPageHeight()) * 100
    );

    if (ratio !== currVerticalRatio) {
      currVerticalRatio = ratio;
      if (ratio === 25 || ratio === 50 || ratio === 75 || ratio === 100) {
        callback(ratio);
      }
    }
  };
};

export const verticalHandler = (callback) => {
  let currVerticalRatio = 0;

  return (event) => {
    if (event.target === event.currentTarget) {
      const ratio = Math.round(
        ((event.target.scrollTop + event.target.offsetHeight) /
          event.target.scrollHeight) *
          100
      );

      if (ratio !== currVerticalRatio) {
        currVerticalRatio = ratio;
        if (ratio === 25 || ratio === 50 || ratio === 75 || ratio === 100) {
          callback(ratio);
        }
      }
    }
  };
};

export const horizontalHandler = (callback) => {
  let currHorizontalRatio = 0;

  return (event) => {
    if (event.target === event.currentTarget) {
      const ratio = Math.round(
        ((event.target.scrollLeft + event.target.offsetWidth) /
          event.target.scrollWidth) *
          100
      );

      if (ratio !== currHorizontalRatio) {
        currHorizontalRatio = ratio;
        if (ratio === 25 || ratio === 50 || ratio === 75 || ratio === 100) {
          callback(ratio);
        }
      }
    }
  };
};
