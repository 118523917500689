import React, { ReactElement, useEffect, useMemo, useRef } from 'react';
import { JS_DOWNLOADING_LOADER_ID, MAX_ANIM_TIME_INTERVAL } from './constants';
import { dispatchLoadingMessage } from './dispatch';
import { LoadingWidgetProps } from './types';

let counter = 0;

/**
 * @category Control Loading State
 * @example
  <LoadingWidget isLoading={true} target="overlay | rhs" />
 */
export const LoadingWidget: (props: LoadingWidgetProps) => ReactElement = ({
  id,
  isLoading = false,
  target = 'overlay',
  content = 'spinner',
  maxAnimEndTime = MAX_ANIM_TIME_INTERVAL,
}) => {
  const loaderId = useMemo(() => {
    if (id) return id;
    return `loading-widget-react-id-${counter++}`;
  }, []);

  const ref = useRef();

  useEffect(() => {
    const _target = target === 'local' ? ref.current : target;
    if (!target) return () => null;
    if (!isLoading) return () => null;

    dispatchLoadingMessage({
      loaderId,
      isLoading: true,
      target: _target,
      content,
      maxAnimEndTime,
    });

    return () => {
      dispatchLoadingMessage({
        loaderId,
        isLoading: false,
        target: _target,
        content,
        maxAnimEndTime,
      });
    };
  }, [loaderId, isLoading, target, content]);

  return target === 'local' ? (
    <div ref={ref} className="emma-spinner-overlay-container-local" />
  ) : null;
};

/**
 * @category Control Loading State
 * @example
 * // Shorthand for React Suspense
  <Suspense fallback={SuspenseLoadingWidget} />
 */
export const SuspenseLoadingWidget = () => (
  <LoadingWidget id={JS_DOWNLOADING_LOADER_ID} target="rhs" isLoading />
);
