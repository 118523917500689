import config from '../package.json';

const ID = `${config.name}/actions/luckydrawAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_LUCKYDRAW_TOKEN = createActionName('FETCH_LUCKYDRAW_TOKEN');
export const FETCH_LUCKYDRAW_TOKEN_SUCCESS = createActionName(
  'FETCH_LUCKYDRAW_TOKEN_SUCCESS',
);
export const FETCH_LUCKYDRAW_TOKEN_FAILURE = createActionName(
  'FETCH_LUCKYDRAW_TOKEN_FAILURE',
);
export const REDIRECT_TO_LUCKYDRAW = createActionName('REDIRECT_TO_LUCKYDRAW');
export const REDIRECT_TO_LUCKYDRAW_FAILURE = createActionName(
  'REDIRECT_TO_LUCKYDRAW_FAILURE',
);

export const fetchLuckydrawToken = (url: string) => ({
  type: FETCH_LUCKYDRAW_TOKEN,
  payload: { url, fetchLuckydrawTokenSuccess, fetchLuckydrawTokenFailure },
});

export const fetchLuckydrawTokenSuccess = (authToken: string) => ({
  type: FETCH_LUCKYDRAW_TOKEN_SUCCESS,
  payload: {
    luckydrawToken: authToken,
  },
});

export const fetchLuckydrawTokenFailure = (error: any) => ({
  type: FETCH_LUCKYDRAW_TOKEN_FAILURE,
  payload: { error },
});

export const redirectToLuckydraw = () => ({
  type: REDIRECT_TO_LUCKYDRAW,
});

export const redirectToLuckydrawFailure = (error: any) => ({
  type: REDIRECT_TO_LUCKYDRAW_FAILURE,
  payload: { error },
});
