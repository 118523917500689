import { PostMsgParams } from '../../partners/types/partnerTypes';

import config from '../package.json';
const ID = `${config.name}/actions/modalActions`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const SHOW_MODAL = createActionName('SHOW_MODAL');
export const HIDE_MODAL = createActionName('HIDE_MODAL');
export const DEEPLINK_MODAL_POPUP = createActionName('DEEPLINK_MODAL_POPUP');

export const showModal = (payload: any) => ({
  type: SHOW_MODAL,
  payload,
});

export const hideModal = () => ({
  type: HIDE_MODAL,
});
