import queryString from 'query-string';
import { getPlatformOS } from './common';

export const MAP_LOCATION_URL = 'https://www.google.com/maps/search/';
export const MAP_ANDROID_URL = 'https://www.google.com/maps';

export const getGoogleMapLang = (lang: string) => {
  return lang === 'zh' ? 'zh-HK' : lang;
};

type Coordinates = {
  lat: number;
  lng: number;
};

type Lang = 'en' | 'zh';

export const getMapLink = (
  location: Coordinates,
  address: string,
  id: 'appleMaps' | 'googleMaps' | 'amaps' | 'googleMapsWeb',
  language?: Lang,
) => {
  switch (id) {
    case 'appleMaps':
      return queryString.stringifyUrl({
        url: 'maps://',
        query: {
          ...(location?.lat && location?.lng
            ? {
                ll: `${location?.lat},${location?.lng}`,
              }
            : {}),
          q: address,
        },
      });
    case 'googleMaps':
      return queryString.stringifyUrl({
        url:
          getPlatformOS() === 'ios' ? 'comgooglemapsurl://' : MAP_ANDROID_URL,
        query: {
          q:
            location?.lat && location?.lng
              ? `${location?.lat},${location?.lng}(${address})`
              : address,
        },
      });
    case 'amaps':
      return queryString.stringifyUrl({
        url: 'amapuri://viewMap',
        query: {
          ...(location?.lat && location?.lng
            ? {
                lat: location?.lat,
                lon: location?.lng,
              }
            : {}),
          poiname: address,
          dev: 1,
        },
      });

    case 'googleMapsWeb':
      return queryString.stringifyUrl({
        url: MAP_LOCATION_URL,
        query: {
          map_action: 'map',
          q:
            location?.lat && location?.lng
              ? `${location.lat},${location.lng}`
              : address,
          ...(language ? { hl: getGoogleMapLang(language) } : {}),
        },
      });
    default:
      return '';
  }
};
