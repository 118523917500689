export const TOGGLE_ALL_INSURANCE_SERVICES = `TOGGLE_ALL_SERVICES`;
export const FETCH_PROFILE_FEATURES = `FETCH_PROFILE_FEATURES`;
export const FETCH_PROFILE_FEATURES_SUCCESS = `FETCH_PROFILE_FEATURES_SUCCESS`;
export const FETCH_PROFILE_FEATURES_FAILURE = `FETCH_PROFILE_FEATURES_FAILURE`;
export const FETCH_HEALTH_NEWS_SUCCESS = `FETCH_HEALTH_NEWS_SUCCESS`;
export const FETCH_HEALTH_NEWS_FAILURE = `FETCH_HEALTH_NEWS_FAILURE`;

export const FETCH_AXA_BLOG_SUCCESS = `FETCH_AXA_BLOG_SUCCESS`;
export const FETCH_AXA_BLOG_FAILURE = `FETCH_AXA_BLOG_FAILURE`;

export const FETCH_HAVE_READ_NEW_FEATURE_SUCCESS = `FETCH_READ_FEATURE_SUCCESS`;
export const FETCH_HAVE_READ_NEW_FEATURE_FAILURE = `FETCH_READ_FEATURE_DOT_FAILURE`;
export const PUSH_HAVE_READ_NEW_FEATURE = 'PUSH_HAVE_READ_NEW_FEATURE';
export const UPDATE_HAVE_READ_NEW_FEATURE_STATE =
  'UPDATE_HAVE_READ_NEW_FEATURE_STATE';

export const FETCH_MARKETPLACE_PROMOTION_NEWS_SUCCESS = `FETCH_MARKETPLACE_PROMOTION_NEWS_SUCCESS`;
export const FETCH_MARKETPLACE_PROMOTION_NEWS_FAILURE = `FETCH_MARKETPLACE_PROMOTION_NEWS_FAILURE`;

export const FETCH_CONTENTO_AVATORS_SUCCESS = `FETCH_CONTENTO_AVATORS_SUCCESS`;
export const FETCH_CONTENTO_AVATORS_FAILURE = `FETCH_CONTENTO_AVATORS_FAILURE`;
export const SET_AVATORS = `SET_AVATORS`;

export const pushHaveReadNewFeature = (serviceId: string) => {
  return {
    type: PUSH_HAVE_READ_NEW_FEATURE,
    payload: {
      serviceId: serviceId,
    },
  };
};

export const iconAction = (actionType: string, payload: any) => {
  return {
    type: actionType,
    payload,
  };
};
