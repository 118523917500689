import { GA_BANNER_CATEGORY_OTHER } from '../constants/gaConstants';

export const transformBannerCategory = (
  bannerCategory = '',
  customBannerCategory = '',
): string => {
  return bannerCategory === GA_BANNER_CATEGORY_OTHER
    ? customBannerCategory
    : bannerCategory;
};
