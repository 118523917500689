import config from '../package.json';
const ID = `${config.name}/actions/customerResetPassword`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const SUBMIT_RESET_PASSWORD = createActionName('SUBMIT_RESET_PASSWORD');

export const UPDATE_RESET_PASSWORD = createActionName('UPDATE_RESET_PASSWORD');

export const UPDATE_SUBMIT_LOADING = createActionName('UPDATE_SUBMIT_LOADING');

export const submitResetPassword = (payload: any) => ({
  type: SUBMIT_RESET_PASSWORD,
  payload,
});

export const updateResetPassword = (password: string) => ({
  type: UPDATE_RESET_PASSWORD,
  password,
});
