import {
  STORE_HEALTH_KEEPER_TOKEN,
  CLEAR_HEALTH_KEEPER_TOKEN,
  SHOW_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG,
  HIDE_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG,
} from '../actions/healthKeeperAction';
import moment from 'moment';

const defaultState = {
  token: {
    expireTime: undefined,
    accessToken: undefined,
  },
  isShowHealthKeeperModal: false,
};

export default (state: any = defaultState, action: any) => {
  let nextState = { ...state };
  switch (action.type) {
    case STORE_HEALTH_KEEPER_TOKEN:
      const currentTime = moment();
      const expireTime = currentTime
        .add(action.payload.expires_in - 3600, 'seconds')
        .format('x');
      nextState = {
        ...nextState,
        token: {
          expireTime: expireTime,
          accessToken: action.payload.access_token,
        },
      };
      return nextState;
    case CLEAR_HEALTH_KEEPER_TOKEN:
      nextState = {
        ...nextState,
        token: {
          expireTime: undefined,
          accessToken: undefined,
        },
      };
      return nextState;
    case SHOW_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG:
      nextState = {
        ...nextState,
        isShowHealthKeeperModal: true,
      };
      return nextState;
    case HIDE_CREATE_HEALTH_KEEPER_ACCOUNT_DIALOG:
      nextState = {
        ...nextState,
        isShowHealthKeeperModal: false,
      };
      return nextState;
    default:
      return nextState;
  }
};
