export const FETCH_MANNING_POLICIES = 'FETCH_MANNING_POLICIES';
export const FETCH_MANNING_POLICIES_SUCCESS = 'FETCH_MANNING_POLICIES_SUCCESS';
export const FETCH_MANNING_STORES = 'FETCH_MANNING_STORES';
export const FETCH_MANNING_STORES_SUCCESS = 'FETCH_MANNING_STORES_SUCCESS';
export const FETCH_MANNING_STORE_TIMES = 'FETCH_MANNING_STORE_TIMES';
export const FETCH_MANNING_STORE_TIMES_SUCCESS =
  'FETCH_MANNING_STORE_TIMES_SUCCESS';
export const MANNING_COMMIT_BOOKING = 'MANNING_COMMIT_BOOKING';
export const MANNING_COMMIT_BOOKING_SUCCESS = 'MANNING_COMMIT_BOOKING_SUCCESS';
export const MANNING_COMMIT_BOOKING_FAILURE = 'MANNING_COMMIT_BOOKING_FAILURE';
export const MANNING_SHOW_MODAL = 'MANNING_SHOW_MODAL';
export const MANNING_HIDE_MODAL = 'MANNING_HIDE_MODAL';

export const FETCH_INSURED_NAME = 'FETCH_INSURED_NAME';
export const FETCH_INSURED_NAME_SUCCESS = 'FETCH_INSURED_NAME_SUCCESS';
export const FETCH_INSURED_NAME_FAILURE = 'FETCH_INSURED_NAME_FAILURE';

export const MANNING_INIT = 'MANNING_INIT';

export const fetchInsuredName = (policyNumber: string, entityCode: string) => ({
  type: FETCH_INSURED_NAME,
  payload: {
    policyNumber: policyNumber,
    entityCode: entityCode,
  },
});

export const fetchInsuredNameSuccess = (data: any) => ({
  type: FETCH_INSURED_NAME_SUCCESS,
  payload: {
    ...data,
  },
});

export const fetchInsuredNameFailure = (data: any) => ({
  type: FETCH_INSURED_NAME_FAILURE,
  payload: {
    ...data,
  },
});

export const getManningPolicies = () => ({
  type: FETCH_MANNING_POLICIES,
});

export const getManningPoliciesSuccess = (manningPolicies: any) => ({
  type: FETCH_MANNING_POLICIES_SUCCESS,
  manningPolicies: manningPolicies,
});

export const getManningStores = () => ({
  type: FETCH_MANNING_STORES,
});

export const getManningStoresSuccess = (manningStores: any) => ({
  type: FETCH_MANNING_STORES_SUCCESS,
  manningStores: manningStores,
});

export const getManningStoreTimes = (
  pharmacyId: string,
  appointmentDate: string,
) => ({
  type: FETCH_MANNING_STORE_TIMES,
  payload: {
    pharmacyId,
    appointmentDate,
  },
});

export const getManningStoreTimesSuccess = (manningStoreTimes: any) => ({
  type: FETCH_MANNING_STORE_TIMES_SUCCESS,
  manningStoreTimes: manningStoreTimes,
});

export const commitBooking = (appointmentData: any) => ({
  type: MANNING_COMMIT_BOOKING,
  appointmentData: appointmentData,
});

export const commitBookingSuccess = (appointmentData: any) => ({
  type: MANNING_COMMIT_BOOKING_SUCCESS,
  appointmentData: appointmentData,
});

export const commitBookingFailure = (error: any) => ({
  type: MANNING_COMMIT_BOOKING_FAILURE,
  appointmentError: error,
});

export const showModal = () => ({
  type: MANNING_SHOW_MODAL,
});

export const hideModal = () => ({
  type: MANNING_HIDE_MODAL,
});
