import {
  UPDATE_OFFERS_FORM_DATA,
  UPDATE_OFFERS_FORM_SUBMIT_RESPONSE,
  UPDATE_OFFERS_ISREVIEW,
  UPDATE_OFFERS_REVIEW_LOADING,
  FETCH_OFFERS_FEATURES_SUCCESS,
  FETCH_OFFERS_FEATURES_FAILURE,
  UPDATE_PROMOTION_CHECKED,
  UPDATE_PROMOTION_ERROR_CODE,
} from '../actions/offersFormAction';

const defaultState = {
  loadingFlag: false,
  isReview: false,
  offersData: {
    infoObj: {
      name: '',
      gender: '',
      birthday: '',
      email: '',
      mobileCountryCode: '852',
      mobile: '',
      consultantMobileCountryCode: '852',
      consultantMobile: '',
      product: '',
      promotionCode: '',
      agentCode: '',
    },
    dependentArr: [],
    gaClientId: '',
    gaTrackId: '',
  },
  offersSubmitResponse: {
    title: '',
    msg: '',
  },
  offersFeaturesConfig: {
    offersFeatures: [],
    offersFeaturesError: null,
    offersFetched: false,
  },
  isPromotionChecked: false,
  promotionErrorCode: '',
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case UPDATE_OFFERS_FORM_DATA:
      nextState.isReview = false;
      nextState.offersData = action.offersData;
      return nextState;
    case UPDATE_OFFERS_FORM_SUBMIT_RESPONSE:
      nextState.offersSubmitResponse = action.offersSubmitResponse;
      return nextState;
    case UPDATE_OFFERS_ISREVIEW:
      nextState.isReview = true;
      return nextState;
    case UPDATE_OFFERS_REVIEW_LOADING:
      nextState.loadingFlag = action.loadingFlag;
      return nextState;
    case FETCH_OFFERS_FEATURES_SUCCESS:
      nextState.offersFeaturesConfig.offersFeatures = action.featuresItems;
      nextState.offersFeaturesConfig.offersFeaturesError = null;
      nextState.offersFeaturesConfig.offersFetched = true;
      return nextState;
    case FETCH_OFFERS_FEATURES_FAILURE:
      nextState.offersFeaturesConfig.offersFeatures = [];
      nextState.offersFeaturesConfig.offersFeaturesError = action.error;
      nextState.offersFeaturesConfig.offersFetched = true;
      return nextState;
    case UPDATE_PROMOTION_CHECKED:
      nextState.isPromotionChecked = action.checked;
      return nextState;
    case UPDATE_PROMOTION_ERROR_CODE:
      nextState.promotionErrorCode = action.promotionErrorCode;
      return nextState;
    default:
      return nextState;
  }
};
