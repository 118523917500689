import {
  AktivoConfig,
  AktivoDevices,
  AktivoAuth,
  TrackerDevices,
} from './../types/authTypes';
import { AuthState } from '../reducers/authReducer';
import { _get } from '../utils/';
import config from '../package.json';

const reducerName = `${config.name}/auth`;
const sessionReducerName = `${config.name}/session`;

export const getToken = (state: {}) => {
  return _get<AuthState>(state, reducerName).auth.token;
  // comment: remove A-HKG-0018-V010
  // return _get<any>(state, sessionReducerName).authToken;
};

export const getRefreshToken = (state: {}) => {
  return _get<AuthState>(state, reducerName).auth.refreshToken;
};

export const getIdToken = (state: {}) => {
  return _get<AuthState>(state, reducerName).auth.idToken;
};

export const getTokens = (state: {}) =>
  _get<AuthState>(state, reducerName).tokens;
// comment: remove A-HKG-0018-V010
// _get<any>(state, sessionReducerName).tokens;
export const getTokenFetchTime = (state: {}) =>
  _get<AuthState>(state, reducerName).auth.fetchTime;

export const getTokenExpireIn = (state: {}) =>
  _get<AuthState>(state, reducerName).auth.expiresIn;

export const getAdditionalAccessTokens = (state: {}) => {
  const result = _get<AuthState>(state, reducerName).additionalAccessTokens;
  return result;
  // const tokens = _get<any>(state, sessionReducerName).additionalTokens;
  // return result.map(r => {
  //   const token = tokens.find((t: any) => t.scope === r.scope);
  //   return {
  //     ...r,
  //     token: token ? token.token : ''
  //   }
  // });
};
export const getAuthCode = (state: {}) =>
  _get<AuthState>(state, reducerName).code;
export const getIsForceLogout = (state: {}) =>
  _get<AuthState>(state, reducerName).isForceLogout;
export const getIsShowReloginPopup = (state: {}) =>
  _get<AuthState>(state, reducerName).isShowReloginPopup;
export const getAdditionalAuthCodes = (state: {}) =>
  _get<AuthState>(state, reducerName).codes;

export const getPostLoginRedirectUri = (state: {}) =>
  _get<AuthState>(state, reducerName).postRedirectUri;

const isMatchPath = (state: {}, reg: RegExp) => {
  const pathname = _get<string>(state, 'router.location.pathname');
  return reg.test(pathname);
};

export const isSkipPrivilegesApi = (state: {}) => {
  return isMatchPath(state, /\/home\/life\/team-challenge\/rules.*/);
};

export const getIsTeamChallengeRule = (state: {}) => {
  return isMatchPath(state, /\/home\/life\/team-challenge\/rules.*/);
};

export const getIsManningsAppointment = (state: {}) => {
  return isMatchPath(state, /\/myprivileges\/mannings\/appointment.*/);
};

export const getIsSenselyWidget = (state: {}) => {
  return isMatchPath(state, /\/external\/sensely-widget/);
};

export const getIsFanclAppointment = (state: {}) => {
  return isMatchPath(
    state,
    /\/myprivileges\/fanclskinandhealthtest\/appointment.*/,
  );
};

export const getIsCheckCoverage = (state: {}) => {
  return isMatchPath(state, /\/home\/check-coverage.*/);
};

export const getIsNeurumHealthContainer = (state: {}) => {
  return isMatchPath(state, /\/neurum-health.*/);
};

export const getIsStorytalerContainer = (state: {}) => {
  return isMatchPath(state, /\/storytaler-health.*/);
};

export const getIsOffersContainer = (state: {}) => {
  return isMatchPath(state, /\/myprivileges\/offers\/form.*/);
};

export const getIsCustomerProfileContainer = (state: {}) => {
  return isMatchPath(state, /\/customerprofile.*/);
};

export const getIsGoGreenSurveyContainer = (state: {}) => {
  return isMatchPath(state, /\/myprivileges\/offers\/go-green-survey-form.*/);
};

export const getIsAktivoHealthSurveyContainer = (state: {}) => {
  return isMatchPath(state, /\/myprivileges\/aktivo-health-survey.*/);
};

export const getUrlToken = (state: {}) => {
  let searchParam = _get<string>(state, 'router.location.search');
  searchParam = searchParam.replace('?', '');
  const params = searchParam.split('&');
  const result = params.find((param) => {
    const splits = param.split('=');
    if (splits && splits.length > 1 && splits[0] == 'token') {
      return true;
    }
    return false;
  });
  return result ? result.split('=')[1] : '';
};

export const getIsDeleteAccount = (state: {}) => {
  return isMatchPath(state, /\/home\/delete-account.*/);
};

export const getIsAktivoIndividualChallenge = (state: {}) => {
  return isMatchPath(state, /\/home\/aktivo-individual-challenge.*/);
};
export const getAktivoAuth = (state: {}) =>
  _get<AktivoAuth>(state, `${reducerName}.aktivoConfig.auth`, {});

export const getAktivoToken = (state: {}) =>
  _get<string>(state, `${reducerName}.aktivoConfig.auth.accessToken`, '');

export const getAktivoMemberId = (state: {}) =>
  _get<string>(state, `${reducerName}.aktivoConfig.aktivoId`, '');

export const getAktivoDeviceStatus = (state: {}) =>
  _get<any>(state, `${reducerName}.aktivoConfig.devices`, {});

export const getAktivoAgreedTnc = (state: {}) =>
  _get<any>(state, `${reducerName}.aktivoConfig.agreedTnc`, false);
