import { SimpleEffect } from '@redux-saga/types';
import { ForkEffectDescriptor } from 'redux-saga/effects';

export type SimpleSagaType = () => IterableIterator<
  SimpleEffect<'FORK', ForkEffectDescriptor>
>;

export class SagaRegistry {
  private sagas: Array<SimpleSagaType> = [];
  register(sagas: Array<SimpleSagaType>) {
    Array.prototype.push.apply(this.sagas, sagas);
  }

  getSagas(): Array<SimpleSagaType> {
    return this.sagas;
  }
}

export default new SagaRegistry();
