import { CHANGE_CUR_UPDATE_KEY } from '../actions/customerResetUNOrPWAction';

const defaultState = {
  curUpdateKey: '',
};

export default (state: any = defaultState, action: any) => {
  const nextState = { ...state };
  switch (action.type) {
    case CHANGE_CUR_UPDATE_KEY:
      nextState.curUpdateKey = action.curUpdateKey || '';
      return nextState;
    default:
      return nextState;
  }
};
