import React, { Component } from 'react';
import { connect } from 'react-redux';
import EBFlexiOverlay from './EBFlexiOverlay';
import { getIsShowBanner } from '../../modules/eb-flexi/selectors/ebFlexiSelectors';
import { EB_FELXI_FROM } from '../../constants/constants';
import './styles.scss';

interface EBFlexOverlayViewProps {
  type?: string;
  isShowBanner?: boolean;
}

interface EBFlexOverlayViewState {}

export class EBFlexOverlayView extends Component<
  EBFlexOverlayViewProps,
  EBFlexOverlayViewState
> {
  static defaultProps = {
    type: '',
  };

  constructor(props: EBFlexOverlayViewProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { type, isShowBanner } = this.props;
    return isShowBanner && <EBFlexiOverlay type={type} />;
  }

  static mapStateToProps(state: any) {
    const isShowBanner = getIsShowBanner(state);
    return {
      isShowBanner,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {};
  }
}

export default connect(
  EBFlexOverlayView.mapStateToProps,
  EBFlexOverlayView.mapDispatchToProps,
)(EBFlexOverlayView);
