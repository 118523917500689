// Hack for suppressing useLayoutEffect from SSR
// Ref: https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a

import { useLayoutEffect, useEffect } from 'react';

const useIsomorphicLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

// Ensure the name used in components is useLayoutEffect
export default useIsomorphicLayoutEffect;
