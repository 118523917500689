import { AppInsightsClient } from '@axa-asia/app-insights-client';
import {
  getAxaIdFromUrl,
  getClientIdFromUrl,
  getGa4ClientIdFromUrl,
} from './generalGaEventAnalytics';
import { isApp } from './';
import env from '../env';

/** Call to start app insight */
export const startAppInsight = async (axaId = '') => {
  // Read env config from window because App Insights is the first one to proceed so that it can capture initization error.
  // @ts-ignore
  const config = window.__client_configs__ || {};
  const instrumentationKey = config.APP_INSIGHTS_INSTRUMENTATION_KEY;
  if (instrumentationKey) {
    const ga4ClientId = getGa4ClientIdFromUrl() || '';
    const _axaId = axaId || getAxaIdFromUrl();
    const platform = isApp() ? 'app' : 'web-portal';
    const app = 'emma-web';
    const instance = AppInsightsClient.getInstance();
    instance.init({
      instrumentationKey,
      axaId: _axaId,
      ga4ClientId,
      app,
      platform,
      captureCaughtError: config.APP_INSIGHTS_CAPTURE_CAUGHT_ERROR === 'true',
      disableConsoleLog: config.APP_INSIGHTS_DISABLE_CONSOLE_LOG === 'true',
    });
  }
  return Promise.resolve();
};
