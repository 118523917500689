import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { getCurrentExperimentVariant } from '../../../../google-optimize-ab-test/selectors/googleOptimizeABTestSelector';
import { LoginButtonProps } from './components/LoginButton';

type ShapeTypes = 'original' | 'round' | 'parallelogram';

interface LoginButtonABTestProps extends LoginButtonProps {
  shape: ShapeTypes;
}

export default function withLoginButtonsABTestHoc(Button: any) {
  class LoginButtonsABTestHoc extends React.Component<LoginButtonABTestProps> {
    render() {
      const { shape } = this.props;
      const originalStyle: CSSProperties = {};
      const circleButtonStyle: CSSProperties = {
        borderRadius: '50%',
      };
      const parallelogramButtonStyle: CSSProperties = {
        transform: 'skew(-20deg)',
        borderRadius: '0',
      };

      const styleMap: { [S in ShapeTypes]: CSSProperties } = {
        original: originalStyle,
        round: circleButtonStyle,
        parallelogram: parallelogramButtonStyle,
      };

      return (
        <Button
          style={shape ? styleMap[shape] : styleMap['original']}
          {...this.props}
        />
      );
    }
  }

  const mapStateToProps = (state: any) => {
    const currentExperimentVariant = getCurrentExperimentVariant(
      state,
      'login-btn',
    );
    const shape: ShapeTypes = currentExperimentVariant
      ? (currentExperimentVariant.value as ShapeTypes)
      : 'original';

    return { shape };
  };

  const ConnectedHoc = connect(mapStateToProps)(LoginButtonsABTestHoc);

  return ConnectedHoc;
}
