import { singletonCallback } from './singleton-hooks';

type GotoFn = (
  s: string,
  mode?: 'PUSH' | 'REPLACE',
  isCheckCookie?: boolean,
) => void;

const goto: GotoFn = () => {
  console.warn('Please setGoto before using useGoto');
};

const payload = singletonCallback<GotoFn>(goto, 'useGoto');

/**
 * Bridge shared state between application and modules
 * @category Setters
 * @example
setGoto((string) => router.push(string));
**/
export const setGoto = payload.setGlobalCallback;

/**
 * Access goto function from any modules with hooks
 * @category Hooks
 * @example
const uri = '/whatever';
const goto = useGoto();
useEffect(() => { goto(uri) }, []);
**/
export const useGoto = payload.useGlobalCallback;
