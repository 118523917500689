import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalDialog,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';
import Slider from 'react-slick';
import { t } from '../../locales';
import { hideErrorModal } from '../../actions/uiActions';
import { getErrorModalVisible } from '../../selectors/uiSelectors';
import {
  getMembershipTutorial,
  getMembershipTutorialRuleType,
  getSilverHairMembershipTutorial,
} from '../../modules/axagoal/selectors/vhisSelectors';
import { hideMembershipTutorial } from '../../modules/axagoal/actions/vhisAction';
import { LocalizedText } from '../../types/LocalizedTextTypes';
import {
  resolveLocalizedText,
  resolvePublicAssets,
  isMobileWeb,
} from '../../utils';
import './styles.scss';
import {
  triggerPopupDisplayScreenChange,
  triggerPopupCloseScreenChange,
} from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';

export interface MembershipYearDefinitionTutorialProps {
  showModal: boolean;
  onCloseModal?: (...args: any[]) => unknown;
  slideContent: any;
  onHideMembershipTutorial: () => void;
  ruleType: 'WGP' | 'SH';
}

interface MembershipYearDefinitionTutorialState {
  currSlide: number;
  isMobileScreen: boolean;
}

class MembershipYearDefinitionTutorial extends Component<
  MembershipYearDefinitionTutorialProps,
  MembershipYearDefinitionTutorialState
> {
  BANNER_CAROUSEL_CONFIG: { [prop: string]: unknown };
  BANNER_BOTTOM_CAROUSEL_CONFIG: { [prop: string]: unknown };
  bannerRef: any;
  bottomBannerRef: any;

  constructor(props: MembershipYearDefinitionTutorialProps) {
    super(props);
    this.state = {
      currSlide: 0,
      isMobileScreen: isMobileWeb(),
    };
    this.BANNER_CAROUSEL_CONFIG = {
      dots: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      cssEase: 'linear',
      arrows: false,
      fade: false,
      beforeChange: this.handleBeforeChangeSlide,
      onInit: () => {
        const index = 0;
        triggerPopupDisplayScreenChange(
          `HS-AXAGoal-AcSummary-DateSlide${index + 1}-pop`,
        );
      },
    };
    this.BANNER_BOTTOM_CAROUSEL_CONFIG = {
      dots: false,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      cssEase: 'linear',
      arrows: false,
      fade: true,
      beforeChange: this.handleBeforeChangeSlide,
    };
  }

  handleResize() {
    this.setState({
      isMobileScreen: isMobileWeb(),
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize.bind(this));
  }

  handleClose = () => {
    const { onHideMembershipTutorial } = this.props;
    const { currSlide } = this.state;

    if (currSlide === 3) onHideMembershipTutorial();
  };

  handleNext = () => {
    const { currSlide } = this.state;

    this.handleBeforeChangeSlide(currSlide, currSlide + 1);
  };

  handleBeforeChangeSlide = (oldIndex: number, newIndex: number) => {
    triggerPopupDisplayScreenChange(
      `HS-AXAGoal-AcSummary-DateSlide${newIndex + 1}-pop`,
    );

    this.setState({ currSlide: newIndex }, () => {
      this.bannerRef && this.bannerRef.slickGoTo(newIndex);
      this.bottomBannerRef && this.bottomBannerRef.slickGoTo(newIndex);
    });
  };

  onHideModal(isGoBack = true) {
    const { onCloseModal } = this.props;

    triggerPopupCloseScreenChange();

    onCloseModal && onCloseModal();
  }

  render() {
    const { showModal, onHideMembershipTutorial, slideContent } = this.props;
    const BANNER_SLIDES = [
      <div className="MembershipYearDefinitionTutorial__banner">
        <img
          src={resolvePublicAssets(
            resolveLocalizedText(
              isMobileWeb() ? slideContent[0].image : slideContent[0].imageWeb,
            ),
          )}
          alt=""
          key={1}
          className="MembershipYearDefinitionTutorial__img"
        />
        <div className="MembershipYearDefinitionTutorial__banner-text">
          <div className="MembershipYearDefinitionTutorial__banner-text-title">
            {slideContent[0].title}
          </div>
          <div className="">{slideContent[0].desc}</div>
        </div>
      </div>,
      <div className="MembershipYearDefinitionTutorial__banner">
        <img
          src={resolvePublicAssets(
            resolveLocalizedText(
              isMobileWeb() ? slideContent[1].image : slideContent[1].imageWeb,
            ),
          )}
          alt=""
          key={2}
          className="MembershipYearDefinitionTutorial__img"
        />
        <div className="MembershipYearDefinitionTutorial__banner-text">
          <div className="MembershipYearDefinitionTutorial__banner-text-title">
            {slideContent[1].title}
          </div>
          <div className="">{slideContent[1].desc}</div>
        </div>
      </div>,
      <div className="MembershipYearDefinitionTutorial__banner">
        <img
          src={resolvePublicAssets(
            resolveLocalizedText(
              isMobileWeb() ? slideContent[2].image : slideContent[2].imageWeb,
            ),
          )}
          alt=""
          key={3}
          className="MembershipYearDefinitionTutorial__img"
        />
        <div className="MembershipYearDefinitionTutorial__banner-text">
          <div className="MembershipYearDefinitionTutorial__banner-text-title">
            {slideContent[2].title}
          </div>
          <div className="">{slideContent[2].desc}</div>
        </div>
      </div>,
      <div className="MembershipYearDefinitionTutorial__banner">
        <img
          src={resolvePublicAssets(
            resolveLocalizedText(
              isMobileWeb() ? slideContent[3].image : slideContent[3].imageWeb,
            ),
          )}
          alt=""
          key={4}
          className="MembershipYearDefinitionTutorial__img"
        />
        <div className="MembershipYearDefinitionTutorial__banner-text">
          <div className="MembershipYearDefinitionTutorial__banner-text-title">
            {slideContent[3].title}
          </div>
          <div className="">{slideContent[3].desc}</div>
        </div>
      </div>,
    ];
    const BANNER_BOTTOM_SLIDES = [
      <div>
        <button
          className="MembershipYearDefinitionTutorial__banner-bottom emma-stylesheet-primary-ghost-button"
          key={1}
          data-enable-ga
          data-ga-type="button"
          onClick={this.handleNext}
        >
          {t('MEMBERSHIP_NEXT')}
        </button>
      </div>,
      <div>
        <button
          className="MembershipYearDefinitionTutorial__banner-bottom emma-stylesheet-primary-ghost-button"
          key={2}
          data-enable-ga
          data-ga-type="button"
          onClick={this.handleNext}
        >
          {t('MEMBERSHIP_NEXT')}
        </button>
      </div>,
      <div>
        <button
          className="MembershipYearDefinitionTutorial__banner-bottom emma-stylesheet-primary-ghost-button"
          key={3}
          data-enable-ga
          data-ga-type="button"
          onClick={this.handleNext}
        >
          {t('MEMBERSHIP_NEXT')}
        </button>
      </div>,
      <div>
        <button
          className="MembershipYearDefinitionTutorial__banner-bottom emma-stylesheet-primary-default-button"
          key={4}
          data-enable-ga
          data-ga-type="button"
          onClick={this.handleClose}
        >
          {t('MEMBERSHIP_CLOSE')}
        </button>
      </div>,
    ];
    return (
      <Modal
        className="MembershipYearDefinitionTutorial__container"
        show={showModal}
        onHide={() => {
          this.onHideModal();
        }}
        container={this}
      >
        <ModalBody className="MembershipYearDefinitionTutorial__content  emma-deprecated-slick">
          <button
            className="MembershipYearDefinitionTutorial__modal-close emma-stylesheet-bodyText-L"
            onClick={() => onHideMembershipTutorial()}
            data-enable-ga
            data-ga-type="button"
          >
            {t('EMMA_BANNER_CLOSE')}
          </button>
          <div className="MembershipYearDefinitionTutorial__body">
            <Slider
              key={1}
              {...this.BANNER_CAROUSEL_CONFIG}
              ref={(ref) => (this.bannerRef = ref)}
            >
              {BANNER_SLIDES}
            </Slider>
            <Slider
              key={2}
              {...this.BANNER_BOTTOM_CAROUSEL_CONFIG}
              ref={(ref) => (this.bottomBannerRef = ref)}
            >
              {BANNER_BOTTOM_SLIDES}
            </Slider>
          </div>
        </ModalBody>
      </Modal>
    );
  }

  static mapStateToProps(state: any, ownProps: any) {
    const ruleType = getMembershipTutorialRuleType(state);
    let slideContent = resolveLocalizedText(
      getMembershipTutorial(state).slides,
    );
    if (ruleType === 'SH') {
      slideContent = resolveLocalizedText(
        getSilverHairMembershipTutorial(state).slides,
      );
    }
    return {
      slideContent,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      onHideMembershipTutorial: () => {
        triggerPopupCloseScreenChange();
        dispatch(hideMembershipTutorial());
      },
    };
  }
}

export default connect(
  MembershipYearDefinitionTutorial.mapStateToProps,
  MembershipYearDefinitionTutorial.mapDispatchToProps,
)(MembershipYearDefinitionTutorial);
