import React, { FC } from 'react';
import { t } from '../../../../../../locales';

export interface LoginButtonProps {
  go: (href: string) => void;
  signInUrl: string;
  style?: React.CSSProperties;
}

const LoginButton: FC<LoginButtonProps> = ({ go, signInUrl, style }) => {
  return (
    <button
      style={style}
      className="emma-stylesheet-primary-default-button"
      onClick={() => go(signInUrl)}
      data-enable-ga
      data-ga-type="button"
    >
      {t(`WELCOME_LOGIN`)}
    </button>
  );
};

export default LoginButton;
