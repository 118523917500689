import React from 'react';
import moment from 'moment';
import { t } from '../../../../locales';
import './style.scss';
import { CALENDAR_MONTH_WEEK_UNIT } from '../../../../constants/constants';
import { getLanguage } from '../../../../selectors/userSelectors';
import _ from 'lodash';

const START_WITH = {
  SUN: 'sun',
  SAT: 'sat',
};

const NUM_WEEKDAYS = 7;

export const YEAR_MONTH_DAY_FORMAT = 'YYYYMMDD';

const lang = getLanguage();

interface StepStats {
  refDate: string;
  value: number;
}

export interface MonthViewProps {
  viewDate: Date;
  startWith?: 'sun' | 'sat';
  achievedDates?: { [date: string]: string };
  startDate?: Date;
  endDate?: Date;
  aktivoStepStats?: StepStats[];
}

const MonthView: React.FC<MonthViewProps> = ({
  startWith = START_WITH.SUN,
  viewDate,
  achievedDates, //data from XPAPI
  aktivoStepStats,
  startDate,
  endDate,
}) => {
  const shiftBy = startWith === START_WITH.SAT ? -1 : 0;
  const weekdays = Array(NUM_WEEKDAYS)
    .fill(0)
    .map((_, d) => (d + shiftBy + NUM_WEEKDAYS) % NUM_WEEKDAYS);

  const weekdayLabels = CALENDAR_MONTH_WEEK_UNIT;

  const startViewDate = moment(viewDate).startOf('month').startOf('week');
  const endViewDate = moment(viewDate).endOf('month').endOf('week');
  const viewDatesDiffInDays = moment(endViewDate).diff(startViewDate, 'days');

  const monthDates = Array(viewDatesDiffInDays + 1)
    .fill(0)
    .map((_, d) => moment(startViewDate).add(d, 'day').toDate());

  const achievedDatesFromAktivo = aktivoStepStats
    ? aktivoStepStats.reduce((obj, { refDate, value }) => {
        const formattedDate = moment(refDate).format(YEAR_MONTH_DAY_FORMAT);
        obj[formattedDate] = value;
        return obj;
      }, {})
    : {};

  const formattedStartDate = moment(startDate).format(YEAR_MONTH_DAY_FORMAT);
  const formattedEndDate = moment(endDate).format(YEAR_MONTH_DAY_FORMAT);

  return (
    <div className="month-view">
      {weekdays.map((weekday, i) => (
        <div className="month-view-cell month-view-cell-weekday" key={weekday}>
          <div>{_.get(weekdayLabels[weekday].title, `${lang}`, '')}</div>
        </div>
      ))}
      {monthDates.map((monthDate) => {
        const formattedMonthDate = moment(monthDate).format(
          YEAR_MONTH_DAY_FORMAT,
        );
        const isSameMonth =
          moment(monthDate).get('month') === moment(viewDate).get('month');

        //refers to HKEMMA-89956 AC3
        const isAchievedDate = achievedDates[formattedMonthDate] === 'Y';

        const isCurrentDateDataProcessing =
          !isAchievedDate &&
          achievedDatesFromAktivo[formattedMonthDate] >= 10000;

        const isNonAchievedDate =
          achievedDates[formattedMonthDate] === 'N' &&
          !(achievedDatesFromAktivo[formattedMonthDate] >= 10000);

        const isCurrentDate =
          isSameMonth &&
          moment().format(YEAR_MONTH_DAY_FORMAT) === formattedMonthDate;
        const isStartDate =
          isSameMonth && startDate && formattedStartDate === formattedMonthDate;
        const isEndDate =
          isSameMonth && endDate && formattedEndDate === formattedMonthDate;

        const isFutureDate = monthDate.getTime() > new Date().getTime();

        const getCurrentDateClassName = () => {
          if (isFutureDate) {
            return '';
          }

          if (isAchievedDate && !isCurrentDate) {
            return 'month-view-cell-dot-label-achieve';
          }

          if (isCurrentDateDataProcessing) {
            return 'month-view-cell-dot-label-processing';
          }

          if (isNonAchievedDate) {
            return 'month-view-cell-dot-label-non-achieve';
          }
          return '';
        };

        return (
          <div className="month-view-cell" key={formattedMonthDate}>
            {isStartDate && (
              <div className="month-view-cell-start content-align">
                <div className="month-view-cell-start-span">
                  {t('AXA_GOAL_RECORD_START')}
                </div>
              </div>
            )}
            {isEndDate && (
              <div className="month-view-cell-end content-align">
                <div className="month-view-cell-end-span">
                  {t('AXA_GOAL_RECORD_END')}
                </div>
              </div>
            )}
            <div
              className={`${
                isCurrentDate
                  ? 'month-view-cell-current-date content-align'
                  : ''
              }`}
            >
              {isSameMonth ? moment(monthDate).format('D') : ''}
            </div>
            {isSameMonth && (
              <div
                className={`month-view-cell-dot-label ${getCurrentDateClassName()}`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MonthView;
