import _ from 'lodash';
import config from '../configs/AppConfigs';

const ID = `${config.moduleName}/actions/configActions.ts`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_EXPERIMENT_CONFIG_START = createActionName(
  'FETCH_EXPERIMENT_CONFIG_START',
);
export const FETCH_EXPERIMENT_CONFIG_SUCCESS = createActionName(
  'FETCH_EXPERIMENT_CONFIG_SUCCESS',
);
export const FETCH_EXPERIMENT_CONFIG_FAILURE = createActionName(
  'FETCH_EXPERIMENT_CONFIG_FAILURE',
);

export function fetchExperimentConfigSuccess(experimentsConfig: any) {
  const experiments = _.get(experimentsConfig, 'experiments', undefined);
  const userGroupApi: string | undefined = _.get(
    experimentsConfig,
    'userGroupApi',
    undefined,
  );

  return {
    type: FETCH_EXPERIMENT_CONFIG_SUCCESS,
    payload: {
      experiments,
      userGroupApi,
    },
  };
}
