import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';
import { ArgType } from 'reactotron-core-client';
import { EmmaFactory } from '@axa-asia/hk-emma-js';

declare global {
  interface Console {
    tron: any;
  }
}

interface PluginConfig {
  except?: string[];
}

const tron = Reactotron.configure()
  .use(reactotronRedux())
  .use(sagaPlugin({ except: [''] }));
if (process.env.NODE_ENV === 'development') {
  tron.connect();
}
tron.clear!();

Reactotron.onCustomCommand({
  command: 'Web Goto Deeplink',
  handler: (params) => {
    const historyAction = require('./actions/historyActions');
    historyAction.goto(params.deeplinkPath);
  },

  // Optional settings
  title: 'Web String Deeplink', // This shows on the button
  description: 'Example: emma-modal-popup?templateId=eRegistrationPopup', // This shows below the button
  args: [
    {
      name: 'deeplinkPath',
      // @FlowIssue
      type: ArgType.String,
    },
  ],
});

Reactotron.onCustomCommand({
  command: 'App Goto Deeplink',
  handler: (params) => {
    EmmaFactory.createInstance().then((e) => {
      e.instance.goTo(params.deeplinkPath);
    });
  },

  // Optional settings
  title: 'Mobile String Deeplink', // This shows on the button
  description: 'rules-clicked, which is mobile deeplink', // This shows below the button
  args: [
    {
      name: 'deeplinkPath',
      // @FlowIssue
      type: ArgType.String,
    },
  ],
});

Reactotron.onCustomCommand({
  command: 'Web Trigger Action',
  handler: (params) => {
    const store = require('./stores/index').store;
    let payload = null;
    if (params.payload) {
      if (params.payload.startsWith('{')) {
        payload = JSON.parse(params.payload);
      } else {
        payload = params.payload;
      }
    }

    store.dispatch({
      type: params.actionType,
      payload,
    });
  },

  // Optional settings
  title: 'Web Trigger Action', // This shows on the button
  args: [
    {
      name: 'actionType',
      // @FlowIssue
      type: ArgType.String,
    },
    {
      name: 'payload',
      // @FlowIssue
      type: ArgType.String,
    },
  ],
});

export default tron;
