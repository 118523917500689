import _ from 'lodash';
import {
  HaveTncDisclaimerCustomApiRes,
  HaveTncDisclaimerCustomApiConfig,
} from '../types/tncDisclaimerTypes';
import { getDisclaimer } from '../modules/emma-common/selectors/ehubSelector';
import { Disclaimer } from '../modules/emma-common/selectors/type';

export const getHaveTncDisclaimerCustomApiConfig =
  (state: {}): HaveTncDisclaimerCustomApiConfig => {
    return _.get(
      state,
      `hk-emma-web-core/app.moduleConfig.emma-home-revamp.customApi.tncDisclaimerState`,
    );
  };

export const getHaveTncDisclaimerState =
  (state: {}): HaveTncDisclaimerCustomApiRes => {
    return _.get(
      state,
      `hk-emma-web-core/tncdisclaimer.tncDisclaimerState`,
      {},
    );
  };

export const isInDisclaimer = (state: {}, disclaimerId: string): boolean => {
  const tncDisclaimerState = getHaveTncDisclaimerState(state);
  const isInDisclaimer =
    tncDisclaimerState.disclaimerIds &&
    tncDisclaimerState.disclaimerIds.includes(disclaimerId)
      ? true
      : false;
  return isInDisclaimer;
};

export const isShowDisclaimer = (state: {}, disclaimerId: string): boolean => {
  const disclaimer: Disclaimer = getDisclaimer(state, disclaimerId);
  let isShowDisclaimer = true;
  if (disclaimer.showOnce) {
    isShowDisclaimer = !isInDisclaimer(state, disclaimerId);
  }
  return isShowDisclaimer;
};

export const getTncDisclaimerUrl = (
  state: {},
  disclaimerId: string,
): string => {
  const disclaimer: Disclaimer = getDisclaimer(state, disclaimerId);
  const url =
    disclaimer.okButton &&
    disclaimer.okButton.deepLink &&
    disclaimer.okButton.deepLink.query
      ? disclaimer.okButton.deepLink.query.id
      : '';
  return url;
};

export const isNewTncDisclaimer = (
  state: {},
  disclaimerId: string,
): boolean => {
  const disclaimer: Disclaimer = getDisclaimer(state, disclaimerId);
  let isNewTncDisclaimer = false;
  if (disclaimer.okButton && disclaimer.okButton.saveTncDisclaimerState) {
    isNewTncDisclaimer = !isInDisclaimer(state, disclaimerId);
  }
  return isNewTncDisclaimer;
};

export const isShowTncDisclaimerToggle = (
  state: {},
  disclaimerId: string,
): boolean => {
  const disclaimer: Disclaimer = getDisclaimer(state, disclaimerId);
  let isShowTncDisclaimerToggle = false;
  if (disclaimer.tncToggles && disclaimer.tncToggles.length > 0) {
    isShowTncDisclaimerToggle = !isInDisclaimer(state, disclaimerId);
  }
  return isShowTncDisclaimerToggle;
};
