import _ from 'lodash';
import {
  call,
  put,
  take,
  select,
  race,
  fork,
  takeLatest,
} from 'redux-saga/effects';
import {
  FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS,
  FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_FAILURE,
  PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE,
  UPDATE_SIDEBAR_HAVE_READ_NEW_FEATURE_STATE,
  SIDEBAR_ITEM_MOUNTED,
  PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE_SINGLE,
  SIDEBAR_ITEM_RED_DOT,
  SKIP_SIDEBAR_TO_MYPRIVILEGES,
} from '../actions/sideBarItemActions';
import { FRAME_APP_MOUNTED } from '../actions/frameActions';
import { putCustState, fetchCustState } from '../actions/userActions';
import {
  getHaveReadNewFeatureCustomApiConfig,
  getHaveReadNewFeatures,
  getBetterMeRedDotMaxCount,
  getMyPrivilegesRedDotMaxCount,
} from '../selectors/sideBarItemSelectors';
import { HaveReadNewFeatureCustomApiRes } from '../types/sideBarItemTypes';
import {
  EMMA_BETTER_ME,
  EMMA_MY_PRIVILEGES,
  EMMA_MY_PRIVILEGES_OVERLAY,
} from '../constants/constants';
import HomePagePopUpRegistry from '../../src/registries/HomePagePopUpRegistry';
import { NewFeaturepiRes } from '../types/sideBarItemTypes';

function* transferHaveReadNewFeaturesState(Features: any) {
  const redDotMaxCount = yield select(getBetterMeRedDotMaxCount);
  let featuresResult: Array<NewFeaturepiRes> = [];
  if (typeof Features === 'object') {
    if (typeof Features.length == 'number') {
      Features.map((item: any) => {
        const feactureItem = {
          id: '',
          clickCount: 1,
        };
        if (typeof item === 'string') {
          feactureItem.id = item;
          feactureItem.clickCount =
            item === EMMA_MY_PRIVILEGES ? redDotMaxCount : 1;
          featuresResult.push(feactureItem);
        } else if (typeof item === 'object' && item !== null) {
          featuresResult.push(item);
        }
      });
    } else {
      featuresResult = Features;
    }
  }
  return featuresResult;
}

function* fetchHaveReadNewFeaturesState() {
  try {
    const config = yield select(getHaveReadNewFeatureCustomApiConfig);
    const res = yield fetchCustState(config.key, config.channel);
    let haveReadNewFeatures: HaveReadNewFeatureCustomApiRes;
    try {
      haveReadNewFeatures = JSON.parse(decodeURI(res));
      haveReadNewFeatures.features = yield transferHaveReadNewFeaturesState(
        haveReadNewFeatures.features,
      );
    } catch (error) {
      haveReadNewFeatures = {
        features: [],
      };
    }
    yield put({
      type: FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS,
      payload: haveReadNewFeatures,
    });
  } catch (e) {
    yield HomePagePopUpRegistry.setFetchDataCompleted(EMMA_BETTER_ME);
    yield put({ type: FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_FAILURE, error: e });
  }
}

export function* clickFeatureCount(serviceId: string) {
  const haveReadNewFeature = yield select(getHaveReadNewFeatures);
  const features = haveReadNewFeature ? haveReadNewFeature.features : undefined;
  const NewFeaturesResult = _.find(
    features,
    (item: { id: string }) => item.id === serviceId,
  );
  const clickCount = NewFeaturesResult ? NewFeaturesResult['clickCount'] : 0;
  return clickCount;
}

export function* isClickCountFeature(
  serviceId: string,
  redDotMaxCount: number,
) {
  const clickCount = yield clickFeatureCount(serviceId);
  const isClickCount = clickCount < redDotMaxCount ? true : false;
  return isClickCount;
}

function* pushCommonHaveReadNewFeature(
  serviceId: any,
  clickCount: number,
  redDotMaxCount: number,
) {
  const haveReadNewFeatures: HaveReadNewFeatureCustomApiRes = yield select(
    getHaveReadNewFeatures,
  );
  const config = yield select(getHaveReadNewFeatureCustomApiConfig);
  const isClickCount = yield isClickCountFeature(serviceId, redDotMaxCount);
  if (
    haveReadNewFeatures &&
    haveReadNewFeatures.features &&
    serviceId &&
    isClickCount
  ) {
    const defaultFeatures = {
      id: serviceId,
      clickCount: clickCount,
    };
    // when the feature is click
    haveReadNewFeatures.features.map((item) => {
      if (item.id === serviceId) {
        item.clickCount = clickCount;
      }
      return item;
    });
    const features = haveReadNewFeatures
      ? haveReadNewFeatures.features
      : undefined;
    let currentService = _.find(
      features,
      (item: { id: string }) => item.id === serviceId,
    );
    if (!currentService) {
      // when the feature is not click
      currentService = defaultFeatures;
      haveReadNewFeatures.features.push(defaultFeatures);
    }
    yield put({
      type: UPDATE_SIDEBAR_HAVE_READ_NEW_FEATURE_STATE,
      payload: { serviceId: currentService },
    });
    yield putCustState(
      config.key,
      JSON.stringify(haveReadNewFeatures),
      config.channel,
    );
  }
}

// Click on the Overlay (whole & image) or My Privilege (on tab) to redirect My Privilege and count 5 time.
function* pushHaveReadNewFeature(action: any) {
  yield pushSiderBarHaveReadNewFeature();
}

// Click on other area out of the overlay to dismiss the overlay and count 1 time.
function* pushHaveReadNewFeatureSingle(action: any) {
  const { serviceId, clickCount, redDotMaxCount } = action.payload;
  const clickOriginCount = yield clickFeatureCount(serviceId);
  yield pushCommonHaveReadNewFeature(
    serviceId,
    clickCount + clickOriginCount,
    redDotMaxCount,
  );
}

function* pushSiderBarHaveReadNewFeature() {
  const serviceId = EMMA_MY_PRIVILEGES_OVERLAY;
  const redDotMaxCount = yield select(getBetterMeRedDotMaxCount);
  yield pushCommonHaveReadNewFeature(serviceId, redDotMaxCount, redDotMaxCount);
  take(UPDATE_SIDEBAR_HAVE_READ_NEW_FEATURE_STATE);
  const myPrivilegeId = EMMA_MY_PRIVILEGES;
  const clickOriginCount = yield clickFeatureCount(myPrivilegeId);
  const myPrivilegesRedDotMaxCount = yield select(
    getMyPrivilegesRedDotMaxCount,
  );
  yield pushCommonHaveReadNewFeature(
    myPrivilegeId,
    1 + clickOriginCount,
    myPrivilegesRedDotMaxCount,
  );
  // set siderbar red dot
  yield put({ type: SIDEBAR_ITEM_RED_DOT });
}

function* sideBarItemSaga() {
  yield take(FRAME_APP_MOUNTED);
  yield fork(fetchHaveReadNewFeaturesState);
  yield takeLatest(PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE, pushHaveReadNewFeature);
  yield takeLatest(
    PUSH_SIDEBAR_HAVE_READ_NEW_FEATURE_SINGLE,
    pushHaveReadNewFeatureSingle,
  );
  yield takeLatest(
    SKIP_SIDEBAR_TO_MYPRIVILEGES,
    pushSiderBarHaveReadNewFeature,
  );
  yield take(SIDEBAR_ITEM_MOUNTED);
  yield take(FETCH_SIDEBAR_HAVE_READ_NEW_FEATURE_SUCCESS);
  // Fix: remove to fix unexpected click count logged.
  // yield fork(pushSiderBarHaveReadNewFeature);
}

export default sideBarItemSaga;
