import { injectAsyncReducer, runSagas } from '../../stores';
import { LazyModuleDependeny } from '../../types/configTypes';
const lazyImportedModules = [] as string[];

import { ModuleConfig } from 'src/types/configTypes';

type ModuleConfigNamedExportModule = { default: ModuleConfig };
type Result = ModuleConfig['routes'][string]['container'];

export function asyncLoadModule(
  module: string,
  loadContainer?: boolean,
  route?: string,
) {
  return new Promise<Result>((resolve, reject) => {
    import(`../../modules/${module}/`).then(
      (loadedModule: ModuleConfigNamedExportModule) => {
        const moduleConfig = loadedModule.default;

        if (!(module in lazyImportedModules)) {
          injectAsyncReducer(moduleConfig.config.name, moduleConfig.reducers);
          runSagas(moduleConfig.config.name, moduleConfig.sagas);
          moduleConfig.lazyModuleDependencies &&
            moduleConfig.lazyModuleDependencies.forEach(
              (dependency: LazyModuleDependeny) => {
                asyncLoadModule(dependency.moduleFolderName);
              },
            );
        }

        if (!loadContainer || (!route && route !== '')) return;

        const routeConfig = moduleConfig.routes[route];
        if (!routeConfig) {
          reject(
            new Error(
              'Async-component cannot map to the real route config. Please check lazy loading config.',
            ),
          );
        }

        const container = routeConfig.container;
        if (!container) {
          reject(
            new Error(
              'Async-component cannot find the real component. Please check lazy loading config.',
            ),
          );
        }

        resolve(container);
      },
    );
  });
}
