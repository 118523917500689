import React from 'react';
import { ComponentClass } from 'react';

class RootComponentRegistry {
  private component?: ComponentClass;
  private componentReady?: () => void;
  private listeners: (() => void)[] = [];

  onComponentReady(callback: () => void) {
    this.componentReady = callback;
  }

  /** component is ComponentClass */
  set(component: any) {
    this.component = component;
    this.componentReady && this.componentReady();
    this.listeners.forEach((listener) => listener());
  }

  listenSet = (callback: () => void) => {
    this.listeners.push(callback);
  };

  empty() {
    return !this.component;
  }

  render(props: {}) {
    if (this.component) {
      const Component = this.component;
      return <Component {...props} />;
    } else {
      return null;
    }
  }
}

export default new RootComponentRegistry();
