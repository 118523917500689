import config from '../package.json';
const ID = `${config.name}/actions/InsuredAccessAction`;
const createActionName = (name: string) => `app/${ID}/${name}`;

export const FETCH_INSURED_POLICY_LIST = createActionName(
  'FETCH_INSURED_POLICY_LIST',
);
export const FETCH_INSURED_POLICY_LIST_SUCCESS = createActionName(
  'FETCH_INSURED_POLICY_LIST_SUCCESS',
);
export const FETCH_INSURED_POLICY_LIST_FAILURE = createActionName(
  'FETCH_INSURED_POLICY_LIST_FAILURE',
);

export const UPDATE_INSURED_ACCESS = createActionName('UPDATE_INSURED_ACCESS');
export const UPDATE_INSURED_ACCESS_SUCCESS = createActionName(
  'UPDATE_INSURED_ACCESS_SUCCESS',
);
export const UPDATE_INSURED_ACCESS_FAILURE = createActionName(
  'UPDATE_INSURED_ACCESS_FAILURE',
);

export const FETCH_INSURED_POLICY_DETAIL = createActionName(
  'FETCH_INSURED_POLICY_DETAIL',
);
export const FETCH_INSURED_POLICY_DETAIL_SUCCESS = createActionName(
  'FETCH_INSURED_POLICY_DETAIL_SUCCESS',
);
export const FETCH_INSURED_POLICY_DETAIL_FAILURE = createActionName(
  'FETCH_INSURED_POLICY_DETAIL_FAILURE',
);

export const FETCH_INSURED_ACCESS_LAST_UPDATED = createActionName(
  'FETCH_INSURED_ACCESS_LAST_UPDATED',
);
export const FETCH_INSURED_ACCESS_LAST_UPDATED_SUCCESS = createActionName(
  'FETCH_INSURED_ACCESS_LAST_UPDATED_SUCCESS',
);
export const FETCH_INSURED_ACCESS_LAST_UPDATED_FAILURE = createActionName(
  'FETCH_INSURED_ACCESS_LAST_UPDATED_FAILURE',
);

export const RESET_INSURED_ACCESS_PAGE = createActionName(
  'RESET_INSURED_ACCESS_PAGE',
);

export const fetchInsuredPolicyList = () => ({
  type: FETCH_INSURED_POLICY_LIST,
});

export const updateInsuredAccess = (
  policyNumber: string,
  insuredAccessFlag: string,
  custKey: string,
  callback: () => void,
) => ({
  type: UPDATE_INSURED_ACCESS,
  policyNumber,
  insuredAccessFlag,
  callback,
  custKey,
});

export const resetInsuredAccessPage = () => ({
  type: RESET_INSURED_ACCESS_PAGE,
});

export const fetchInsuredPolicyDetail = (
  policyNumber: string,
  entityCode: string,
) => ({
  type: FETCH_INSURED_POLICY_DETAIL,
  policyNumber,
  entityCode,
});

export const fetchInsuredAccessLastUpdated = (
  custKey: string,
  destination: string,
) => ({
  type: FETCH_INSURED_ACCESS_LAST_UPDATED,
  custKey,
  destination,
});
