import { memoSanitize } from '@axa-asia/emma-safely-set-inner-html';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactModal from 'react-modal';
import TncCheckbox from './components/TncCheckbox';
import { getDialogAttributes } from '../../selectors/uiSelectors';

import { resolvePublicAssets, isExternalAssets } from '../../utils';

import {
  closeDialog,
  okDialog,
  toggleHideDialog,
  otherDialog,
  putOpenEmmaTncStyleModel,
  checkRadioDialog,
} from '../../actions/uiActions';
import {
  triggerPopupDisplayScreenChange,
  triggerPopupCloseScreenChange,
} from '@axa-asia/hk-emma-web-module/lib/ga-lib/GaPreviousScreenUtil';
import './styles.scss';
import { t } from '../../locales';
import { getAvators } from '../../selectors/configSelectors';
import { Toggle } from '@axa-asia/emma-stylesheet';

interface EmmaModalViewProps {
  dialog: any;
  closeDialog: any;
  okDialog: any;
  otherDialog: any;
  toggleHideDialog: any;
  openEmmaTncStyleModel: (disclaimerId: string) => void;
  tncCount: number;
  checkRadioDialog: any;
  avator: string;
}

interface EmmaModalViewState {
  currentTncCount: number;
}

class EmmaModalView extends Component<EmmaModalViewProps, EmmaModalViewState> {
  constructor(props: EmmaModalViewProps) {
    super(props);
    this.state = {
      currentTncCount: 0,
    };
    this.checkAllRadioButton = this.checkAllRadioButton.bind(this);
    this.onCloseDialog = this.onCloseDialog.bind(this);
  }

  componentDidUpdate(prevProps: EmmaModalViewProps) {
    if (
      this.props.dialog &&
      prevProps.dialog &&
      this.props.dialog.visible !== prevProps.dialog.visible &&
      !this.props.dialog.visible
    ) {
      triggerPopupCloseScreenChange();
    }
  }

  checkAllRadioButton(isRadio: boolean) {
    const { currentTncCount } = this.state;
    const { checkRadioDialog } = this.props;
    const TncCount = isRadio ? currentTncCount + 1 : currentTncCount - 1;
    this.setState({
      currentTncCount: TncCount,
    });
    checkRadioDialog(isRadio);
  }

  onCloseDialog(closeAction: any) {
    const { closeDialog } = this.props;
    this.setState({
      currentTncCount: 0,
    });
    closeDialog(closeAction);
  }

  render() {
    const {
      dialog,
      closeDialog,
      okDialog,
      otherDialog,
      toggleHideDialog,
      openEmmaTncStyleModel,
      tncCount,
      avator,
    } = this.props;
    const { currentTncCount } = this.state;
    const isValidButton =
      tncCount == 0 || (tncCount > 0 && tncCount === currentTncCount)
        ? true
        : false;
    let header,
      modalClass,
      okButton,
      closeButton,
      otherButton,
      additionBodyClass,
      tncToggles: Array<JSX.Element> = [],
      tncCheckboxes: Array<JSX.Element> = [];
    if (dialog) {
      const okButtonText = dialog.okButtonText ? dialog.okButtonText : 'OK';
      const otherButtonText = dialog.otherButtonText
        ? dialog.otherButtonText
        : '';

      tncToggles =
        dialog.tncToggles && dialog.tncToggles.length > 0
          ? dialog.tncToggles.map(
              (
                item: { label: string; title: string; tncId: string },
                index: number,
              ) => (
                <div className="emma-modal_tnc-toggle-li" key={index}>
                  <div className="emma-modal_tnc-toggle-li_label">
                    <span className="emma-modal_tnc-toggle-li_span">
                      {item.label}
                    </span>
                    <span
                      className="emma-modal_tnc-toggle-li_title"
                      onClick={() => openEmmaTncStyleModel(item.tncId)}
                      data-enable-ga
                      data-ga-type="button"
                    >
                      {item.title}
                    </span>
                  </div>
                  <div className="emma-modal_tnc-toggle-li_checkbox">
                    <Toggle
                      onChange={this.checkAllRadioButton}
                      checked={dialog.isCheckRadio}
                    />
                  </div>
                </div>
              ),
            )
          : [];

      tncCheckboxes =
        dialog.tncCheckboxes && dialog.tncCheckboxes.length > 0
          ? dialog.tncCheckboxes.map(
              (
                item: { label: string; title: string; tncId: string },
                index: number,
              ) => {
                const { label, title, tncId } = item;
                return (
                  <TncCheckbox
                    label={label}
                    title={title}
                    tncId={tncId}
                    checked={dialog.isCheckRadio}
                    onChange={this.checkAllRadioButton}
                    openTncStyleModal={openEmmaTncStyleModel}
                  />
                );
              },
            )
          : [];

      if (dialog.modalType === 'lockedModal') {
        header = (
          <div>
            <img
              className="emma-modal-locked__img"
              alt="emma-smile-glasses.png"
              src={
                isExternalAssets(avator) ? avator : resolvePublicAssets(avator)
              }
            />
            <div
              className="emma-modal-locked__text"
              dangerouslySetInnerHTML={{ __html: memoSanitize(dialog.title) }}
            ></div>
          </div>
        );
        modalClass = 'emma-modal emma-modal-locked';
        if (dialog.okAction) {
          okButton = (
            <button
              className={
                isValidButton
                  ? 'emma-stylesheet-primary-default-button'
                  : 'emma-stylesheet-primary-secondary-default-disabled-button'
              }
              onClick={() => {
                if (isValidButton) okDialog(dialog.okAction);
              }}
              data-enable-ga
              data-ga-type="button"
            >
              {okButtonText}
            </button>
          );
        } else {
          okButton = (
            <button
              className={
                isValidButton
                  ? 'emma-stylesheet-primary-default-button'
                  : 'emma-stylesheet-primary-secondary-default-disabled-button'
              }
              onClick={() => {
                if (isValidButton) toggleHideDialog();
              }}
              data-enable-ga
              data-ga-type="button"
            >
              {okButtonText}
            </button>
          );
        }
        if (dialog.closeAction) {
          closeButton = (
            <span
              className="emma-modal-locked__close"
              onClick={() => this.onCloseDialog(dialog.closeAction)}
              data-enable-ga
              data-ga-type="button"
            >
              {' '}
              {t('CLOSE')}{' '}
            </span>
          );
        } else {
          closeButton = (
            <span
              className="emma-modal-locked__close"
              onClick={() => toggleHideDialog()}
              data-enable-ga
              data-ga-type="button"
            >
              {' '}
              {t('CLOSE')}{' '}
            </span>
          );
        }
        if (dialog.otherDialog && otherButtonText) {
          otherButton = (
            <button
              className="emma-stylesheet-primary-ghost-button"
              onClick={() => otherDialog(dialog.otherAction)}
              data-enable-ga
              data-ga-type="button"
            >
              {otherButtonText}
            </button>
          );
        }
      } else {
        header = <div>{dialog.title}</div>;
        modalClass = 'emma-modal';
        okButton = (
          <button
            className="emma-stylesheet-primary-default-button"
            onClick={() => okDialog(dialog.okAction)}
            data-enable-ga
            data-ga-type="button"
          >
            {okButtonText}
          </button>
        );

        if (dialog.otherDialog && otherButtonText) {
          otherButton = (
            <button
              className="emma-stylesheet-primary-ghost-button"
              onClick={() => otherDialog(dialog.otherAction)}
              data-enable-ga
              data-ga-type="button"
            >
              {otherButtonText}
            </button>
          );
        }
      }
      additionBodyClass =
        dialog.otherDialog && otherButtonText ? 'emma-modal__addition-btn' : '';
    }

    return (
      <div>
        {dialog && (
          <ReactModal
            isOpen={dialog.visible}
            contentLabel="Minimal Modal Example"
            className={modalClass}
            overlayClassName="emma-modal__overlay"
            appElement={document.getElementById('root') || undefined}
          >
            <div className="emma-modal-wrapper">
              <h1 className="emma-modal__title">
                {header}
                {closeButton}
              </h1>
              <div className={'emma-modal__body ' + additionBodyClass}>
                <div
                  className={'emma-modal__body_content'}
                  dangerouslySetInnerHTML={{
                    __html: memoSanitize(dialog.body),
                  }}
                />
                {tncToggles.length > 0 && (
                  <div className="emma-modal_tnc-toggle-list">{tncToggles}</div>
                )}
                {tncCheckboxes.length > 0 && (
                  <div className="emma-modal_tnc-checkbox-list">
                    {' '}
                    {tncCheckboxes}
                  </div>
                )}
              </div>
              <div className="emma-modal__btn-wrapper">
                {okButton}
                {otherButton}
              </div>
            </div>
          </ReactModal>
        )}
      </div>
    );
  }

  static mapStateToProps(state: any) {
    const dialogState = Object.assign({}, getDialogAttributes(state));
    const tncCount =
      dialogState && dialogState.tncToggles && dialogState.tncToggles.length > 0
        ? dialogState.tncToggles.length
        : 0;
    const avators = getAvators(state);
    return {
      dialog: dialogState,
      tncCount: tncCount,
      avator: avators && avators.discliamerIcon,
    };
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      closeDialog: (payload: any) => dispatch(closeDialog(payload)),
      okDialog: (payload: any) => dispatch(okDialog(payload)),
      otherDialog: (payload: any) => dispatch(otherDialog(payload)),
      toggleHideDialog: () => dispatch(toggleHideDialog()),
      openEmmaTncStyleModel: (disclaimerId: string) => {
        dispatch(putOpenEmmaTncStyleModel(disclaimerId, true));
      },
      checkRadioDialog: (payload: any) => dispatch(checkRadioDialog(payload)),
    };
  }
}

export default connect(
  EmmaModalView.mapStateToProps,
  EmmaModalView.mapDispatchToProps,
)(EmmaModalView);
