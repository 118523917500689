import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { goto } from '../../../../actions/historyActions';
import { isExternalLink } from '../../../../utils';

import ImgCloseButton from '../../../../assets/images/chatbot/icon-16-px-functional-cross-blue@3x.png';
import ImgFooter from '../../../../assets/images/chatbot/speech-bubble-footer-bg@3x.png';
import ImgHeaderBg from '../../../../assets/images/chatbot/speech-bubble-header-bg@3x.png';
import ImgTitleBgLeft from '../../../../assets/images/chatbot/speech-bubble-title-left@3x.png';
import ImgTitleBgRight from '../../../../assets/images/chatbot/speech-bubble-title-right@3x.png';
import {
  getCustomerIsCwp,
  getCustomerIsLife,
} from '../../../../selectors/userSelectors';
import { select } from '../../../../stores';
import { trackBannerAction } from '../../../../utils/firebaseAnalyticsEvents';
import { getGaPagePath } from '../../../../utils/generalGaEventAnalytics';

import { EmmaSpace, EmmaTruncateText } from '@axa-asia/emma-stylesheet';
import classNames from 'classnames';
import { getAvatorsConfig } from '../../../../selectors/configSelectors';
import { transformBannerCategory } from '../../../../utils/gaUtils';
import './styles.scss';

interface BannerContentProps {
  pageID: string; // handled on parent level
  bubbleSwitch?: boolean;
  headerTitle?: string;
  content: string; //=text
  buttonText: string; // =ctaButtonText
  landingUrl: string;
  closeText: string; // =closeText
  appearAfterSecond?: number;
  dismissAfterSecond?: number;
  startDate: string; // filtered from API
  endDate: string; // filtered from API
  showMoreLabel: string;
  showLessLabel: string;
  occasionDay?: string;
  gaId?: string;
  closeMode?: string;
  shouldShowCloseIcon?: boolean;
  platform?: string;
  buttonText2?: string;
  landingUrl2?: string;
  sbId: string;
  gaCampaignName?: string;
  gaBannerCategory?: string;
  gaCustomBannerCategory?: string;
}

interface Props {
  language: string;
  onClose?: (sbId: string, closeMode: string, sbDetails: any) => void;
  bannerContent: BannerContentProps;
  onAutoAppear?: () => void;
  onAutoDisappear?: () => void;
}

const SB_MIN_APPEAR_AFTER_SECOND = 1;
const SB_DEFAULT_BUBBLE_SWTICH = true;
const SB_DEFAULT_CLOSE_TEXT = '';
const SB_DEFAULT_APPEAR_AFTER_SECOND = 0;
const SB_DEFAULT_DISMISS_AFTER_SECOND = 0;
const SB_DEFAULT_CLOSE_MODE = 'forever';
const SB_DEFAULT_SHOULD_SHOW_CLOSE_ICON = true;

let throttledCtaBtnClickFunc: any;
export const SpeechPopUp = ({
  language,
  onClose,
  bannerContent,
  onAutoAppear,
  onAutoDisappear,
}: Props) => {
  const [hasBeenClosed, setHasBeenClosed] = React.useState(true);
  const [shouldAppearNow, setShouldAppearNow] = React.useState(true);
  const appearTimer = useRef<any>();
  const dismissTimer = useRef<any>();
  const {
    headerTitle = '',
    content,
    buttonText,
    landingUrl,
    buttonText2 = '',
    landingUrl2 = '',
    closeText = SB_DEFAULT_CLOSE_TEXT,
    bubbleSwitch = SB_DEFAULT_BUBBLE_SWTICH,
    appearAfterSecond = SB_DEFAULT_APPEAR_AFTER_SECOND,
    dismissAfterSecond = SB_DEFAULT_DISMISS_AFTER_SECOND,
    showMoreLabel,
    showLessLabel,
    pageID,
    startDate,
    sbId,
    closeMode = SB_DEFAULT_CLOSE_MODE,
    shouldShowCloseIcon = SB_DEFAULT_SHOULD_SHOW_CLOSE_ICON,
  } = bannerContent;
  const gaBannerTitle = useMemo(() => {
    return `SB - ${headerTitle}`;
  }, [headerTitle]);
  const gaLob = select(getCustomerIsLife)
    ? 'Life'
    : select(getCustomerIsCwp)
    ? 'CWP'
    : '';
  const screenName: string = getGaPagePath();
  const avatorsConfig = select(getAvatorsConfig);
  const tcWindow: any = window;

  const gaBannerCategory = useMemo(() => {
    return transformBannerCategory(
      bannerContent.gaBannerCategory,
      bannerContent.gaCustomBannerCategory,
    );
  }, [bannerContent.gaBannerCategory, bannerContent.gaCustomBannerCategory]);

  React.useEffect(() => {
    tcWindow.dataLayer.push({
      event: 'clickBanner',
      product: gaLob,
      bannerAction: 'display',
      bannerPlanName: gaLob,
      bannerTitle: gaBannerTitle,
      screenName: screenName,
      campaignName: bannerContent.gaCampaignName ?? '',
      bannerCategory: gaBannerCategory,
    });
    trackBannerAction(gaLob, 'display', gaLob, gaBannerTitle, screenName);

    throttledCtaBtnClickFunc = _.throttle(function (url) {
      tcWindow.dataLayer.push({
        event: 'clickBanner',
        product: gaLob,
        bannerAction: 'click',
        bannerPlanName: gaLob,
        bannerTitle: gaBannerTitle,
        screenName: screenName,
        campaignName: bannerContent.gaCampaignName ?? '',
        bannerCategory: gaBannerCategory,
      });
      trackBannerAction(gaLob, 'click', gaLob, gaBannerTitle, screenName);

      if (isExternalLink(url)) {
        window.open(url);
      } else {
        const encodedDeeplink = url
          .replace('[[#', encodeURIComponent('[[#'))
          .replace('#]]', encodeURIComponent('#]]'));

        goto(encodedDeeplink); // deeplink
      }
    }, 1000);
  }, [
    content,
    landingUrl,
    headerTitle,
    gaLob,
    bannerContent.gaCampaignName,
    gaBannerTitle,
    gaBannerCategory,
  ]);

  React.useEffect(() => {
    return () => {
      appearTimer.current && clearTimeout(appearTimer.current);
      dismissTimer.current && clearTimeout(dismissTimer.current);
    };
  }, []);

  React.useEffect(() => {
    appearTimer.current && clearTimeout(appearTimer.current);
    dismissTimer.current && clearTimeout(dismissTimer.current);

    if (Object.keys(bannerContent).length === 0) {
      setShouldAppearNow(false);
      return;
    }

    function checkShouldHide() {
      if (dismissAfterSecond) {
        // appear

        dismissTimer.current && clearTimeout(dismissTimer.current);

        setShouldAppearNow(true);
        onAutoAppear?.();

        dismissTimer.current = setTimeout(() => {
          onAutoDisappear?.();
          setShouldAppearNow(false);
        }, dismissAfterSecond * 1000);
      }
    }
    const actualTime =
      SB_MIN_APPEAR_AFTER_SECOND > (appearAfterSecond ?? 0)
        ? SB_MIN_APPEAR_AFTER_SECOND
        : appearAfterSecond;
    setShouldAppearNow(false);

    appearTimer.current && clearTimeout(appearTimer.current);
    appearTimer.current = setTimeout(() => {
      setShouldAppearNow(true);
      checkShouldHide();
    }, actualTime * 1000);
  }, [appearAfterSecond, dismissAfterSecond, bannerContent]);

  const defaultHeader = language === 'en' ? 'DO YOU KNOW' : '你知道嗎?';

  //TODO::::::::
  return (
    <div
      className="chatbot-speech-bubble chatbot-speech-bubble__container"
      style={
        !bubbleSwitch || !shouldAppearNow || !hasBeenClosed
          ? { display: 'none' }
          : {}
      }
    >
      {headerTitle && (
        <div className="chatbot-speech-bubble__headerImg">
          <img className="chatbot-speech-bubble__headerImg" src={ImgHeaderBg} />
          <div className="chatbot-speech-bubble__headerTitleImg">
            <img src={ImgTitleBgLeft} />
            {/* <img src={ImgTitleBgCenter} /> */}
            <span>{headerTitle || defaultHeader}</span>
            <img src={ImgTitleBgRight} />
          </div>
        </div>
      )}
      {shouldShowCloseIcon && (
        <img
          className="chatbot-speech-bubble__closeImage"
          src={ImgCloseButton}
          onClick={() => {
            tcWindow.dataLayer.push({
              event: 'clickBanner',
              product: gaLob,
              bannerAction: 'close',
              bannerPlanName: gaLob,
              bannerTitle: gaBannerTitle,
              screenName: screenName,
              campaignName: bannerContent.gaCampaignName ?? '',
              bannerCategory: gaBannerCategory,
            });
            trackBannerAction(gaLob, 'close', gaLob, gaBannerTitle, screenName);
            setHasBeenClosed(false);
            onClose && onClose(sbId, closeMode, bannerContent);
          }}
        />
      )}

      <div
        className={classNames(
          'chatbot-speech-bubble__textContainer',
          shouldShowCloseIcon ? EmmaSpace.pt12 : '',
        )}
      >
        <span className="chatbot-speech-bubble__contentText">
          <EmmaTruncateText
            maxLine={5}
            className="text-container-scrollbar"
            enableShowMore={true}
            contentMaxHeight="190px"
            showMoreLabel={
              showMoreLabel ??
              avatorsConfig?.speechBubbleShowMoreLabel?.[language]
            }
            showLessLabel={
              showLessLabel ??
              avatorsConfig?.speechBubbleShowLessLabel?.[language]
            }
            showMoreIcon="functional/arrow-bottom"
            showLessIcon="functional/arrow-top"
            iconSize={16}
            iconPosition="end"
            buttonPosition="flex-end"
            textContainerClassName={classNames(EmmaSpace['mb8'])}
          >
            <div>{content}</div>
          </EmmaTruncateText>
        </span>
        <a
          onClick={() => {
            throttledCtaBtnClickFunc(landingUrl);
          }}
          data-enable-ga
          data-ga-type="button"
          className="chatbot-speech-bubble__buttonText"
        >
          {buttonText}
        </a>
        {buttonText2 && (
          <a
            onClick={() => {
              throttledCtaBtnClickFunc(landingUrl2);
            }}
            data-enable-ga
            data-ga-type="button"
            className="chatbot-speech-bubble__buttonText"
          >
            {buttonText2}
          </a>
        )}
        {!!closeText && (
          <a
            onClick={() => {
              tcWindow.dataLayer.push({
                event: 'clickBanner',
                product: gaLob,
                bannerAction: 'close',
                bannerPlanName: gaLob,
                bannerTitle: gaBannerTitle,
                screenName: screenName,
                campaignName: bannerContent.gaCampaignName ?? '',
                bannerCategory: gaBannerCategory,
              });
              trackBannerAction(
                gaLob,
                'close',
                gaLob,
                gaBannerTitle,
                screenName,
              );
              setHasBeenClosed(false);
              onClose && onClose(sbId, closeMode, bannerContent);
            }}
            data-enable-ga
            data-ga-type="button"
            className="chatbot-speech-bubble__buttonText chatbot-speech-bubble__closeText"
          >
            {closeText}
          </a>
        )}
      </div>
      <img className="chatbot-speech-bubble__footerImg" src={ImgFooter} />
    </div>
  );
};

export default SpeechPopUp;
